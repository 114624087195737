

<section class="content-header sub-head"><h1> 
  Product List </h1><ol class="breadcrumb"><li>
    <a href="#"><i class="fa fa-dashboard"></i> Home</a></li><li><a href="#">Tables</a></li>
    <li class="active">Data tables</li></ol></section>
<div class="row">
    <!-- left column -->
    <div class="col-md-12">
    <div class="box box-primary">
    
      <!-- /.box-header -->
      <!-- form start -->
      <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" >
        <div class="box-body">
          
          <div class="form-group col-md-10">
            <input type="hidden" formControlName="ID" class="form-control">
            <label for="exampleInputEmail1">Title</label>
            <input formControlName="Title" placeholder="Enter Sub Category Name"
            id="Title" type="text" class="form-control" id="input-1">
        <div class="validation-error" style="color: red;"
            *ngIf="(form.get('Title').touched && form.get('Title').invalid && form.get('Title').hasError('required')) ">
            This field is required</div>
          </div>
          <div class="form-group col-md-10"> <label for="email-b-f"> Description</label>
            
           <textarea formControlName="Description" placeholder="Enter Description"
            id="Description" type="text" class="form-control" id="input-1"></textarea>
            <!-- <ck-editor [config]="editorConfig" name="DeliveryInformation" [formControl]="Description" skin="moono-lisa"
                language="en"></ck-editor> -->
            <div class="validation-error" style="color: red;"
                *ngIf="form.get('Description').touched && form.get('Description').invalid && form.get('Description').hasError('required')">
                This field is required</div> 
        </div>
        <div class="form-group col-md-10">
            <label for="exampleInputEmail1">Author</label>
            <input formControlName="Author" placeholder="Enter Author Name"
            id="Title" type="text" class="form-control" id="input-1">
        <div class="validation-error" style="color: red;"
            *ngIf="(form.get('Author').touched && form.get('Author').invalid && form.get('Author').hasError('required')) ">
            This field is required</div>
          </div>
          <div class="form-group col-md-10">
            <label for="email-b-f">  Image</label>

            <input type="file" multiple (change)="onFileChanged($event)" accept="image/*"
                formControlName="Image" id="Image" class="form-control">

        </div>
        </div>
        <!-- /.box-body -->
  
        <div class="box-footer">
          <button type="submit" class="btn btn-danger" id="btnsubmit">Submit</button>
        </div>
      </form>
    </div>
  </div>
  </div>
  <div class="box">
    <div class="box-header">
      <h3 class="box-title">BlogContent List</h3>
    </div>
    <!-- /.box-header -->
    <div class="box-body">
      <div class="table-responsive">
      <table id="example1" class="table table-bordered table-striped">
        <thead>
        <tr>
            <th>S.NO</th>
            <th>Title</th>
            <th>Description</th>
            <th>Author </th>
            <th>Image</th>
            <th>Action</th>
            <th>Edit</th>            
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bloglist of listBlogContent;let i=index">
          <td>{{i+1}}</td>
          <td>{{bloglist.Title}}</td>
                          
          <td><span [innerHtml]="bloglist.Description"></span></td>
          <td>{{bloglist.Author}}</td>
        
          <td style="width: 100px;"><img height="100px" [src]="bloglist.Image"></td>
          <td>
            <input type="checkbox" value="IsActive" name="IsActive"(change)="BlogsActivedeactive(bloglist.ID,bloglist.IsActive)" [checked]="bloglist.IsActive" >
              
        </td>
        <td>
            <button (click)="onEdit(bloglist)"  class="btn btn-icon" aria-label="Product details">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
      </td>
        </tr>
         </tbody>
      
      </table>
      </div>
    </div>
    <!-- /.box-body -->
  </div>
