import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

declare function encodeURIComponent(text): any;
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient,private router:Router) { }
  userAuthentication(model): Observable<any> {
  debugger;
    // const data = 'username=' + LoginData.email + '&password=' + encodeURIComponent(LoginData.password) + '&grant_type=password';
    // const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    // return this.http.post(this.rootUrl + '/Token', data, { headers: reqHeader });
    const data = {"UserName": model.email, "password": model.password,'type':'backend'} ;
    const header = new HttpHeaders({ 'Content-Type': 'application/json', 'accept': 'text/plain', 'No-Auth': 'True'});
    return this.http.post(this.rootUrl + "/Login/Login", data, { headers: header });
  }



  logOut(){
    localStorage.removeItem('userInfo');
    this.router.navigate(['/login']);
  };

  ForgetPassword(email:any, Type:any):Observable<any>{
    return this.http.get<any>(this.rootUrl+'/Login/Forgetpassword',{params:{email,Type}});
  }

  VerifyOTP(BO:any):Observable<any>{
    return this.http.post(this.rootUrl+'/Login/VerifyOTP',BO);
  }

  ResetPassword(ResetPassword:any):Observable<any>{
    return this.http.post<any>(this.rootUrl+"/Login/ResetPassword",ResetPassword);

  }
}
