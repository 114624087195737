import { Injectable, ViewChild } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as XLSX from 'XLSX';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';
import * as FileSaver from 'file-saver';
import { parse } from 'path';




@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  // Decrypt the encrypted value


  encryptValue(plainText: string): string {
    try {
      // Encrypt the value using CryptoJS
      const encrypted = CryptoJS.AES.encrypt(plainText, 'neha@hexa').toString();
      return encrypted;
    } catch (error) {
      console.error('Encryption error:', error);
      return ''; // Handle encryption errors appropriately
    }
  }
  decryptValue(encryptedValue: string): string {
    try {
      // Decrypt the value using CryptoJS
      const bytes = CryptoJS.AES.decrypt(encryptedValue, 'neha@hexa');
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption error:', error);
      return ''; // Handle decryption errors appropriately
    }
  };


  exportAsXLSX(json: any, excelFileName: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'sheet 1': worksheet }, SheetNames: ['sheet 1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });

    FileSaver.saveAs(data, excelFileName + new Date().getTime() + EXCEL_EXTENSION);
  }

  getUserID(): number {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      const UserDetails = JSON.parse(userInfo);
      if (UserDetails.userId) {
        const userid = this.decryptValue(UserDetails.userId);
        return parseInt(userid);
      }
      return 0;
    }
    return 0;
  }
}

