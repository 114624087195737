<!-- Content Header (Page header) -->
<section class="content-header">
  <h1>
    User Types

  </h1>

</section>
<!-- Main content -->
<section class="content user-permission-new">
  <div class="row">
    <!-- left column -->
    <div class="col-md-12 margin-left-np">
      <div class="box box-danger">

        <!-- /.box-header -->
        <!-- form start -->
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="box-body">
            <div class="form-group col-md-6 " style="padding-left: 0px;">
              <label for="exampleInputEmail1">TypeName <span style="color: red;">*</span></label>
              <input id="typeName" formControlName="typeName" id="typeName" class="form-control validation-field"
                placeholder="Enter User Type" type="text">
              <div class="validation-error" style="color: red;"
                *ngIf="(form.get('typeName').touched && form.get('typeName').invalid && form.get('typeName').hasError('required')) ">
                This field is required
              </div>
            </div>
            <div class="col-md-4 mt-5">
              <button type="submit" class="btn btn-success marginright15" id="UpsertBtn">Submit</button>
              <button type="button" class="btn btn-secondary" id="">Cancel</button>
            </div>

          </div>

        </form>
      </div>
    </div>
  </div>

  <section class="content-header sub-head ">
    <h1>
      User Types List
    </h1>
  </section>
  <div class="row">
    <!-- left column -->
    <div class="col-md-12 margin-left-np">

      <div class="box">
        <div class="box-header">
          <div class="search-box-new">
            <div class="row">
              <div class="col-md-12 text-right">



                 
            <!-- <div  class="dropdown user user-menu " style="display: inline-block; margin-right: 10px;">
              <a  data-toggle="dropdown" class="btn btn-primary" aria-expanded="false">
                <span>Filter <i  class="fa fa-angle-down" style="margin-left: 8px;"></i></span>
              </a>
              <ul  class="dropdown-menu dropy">
                <li  class="user-footer">
                  <div ><a >Show&nbsp;Active&nbsp;List</a></div>
                </li>
                <li  class="user-footer second-butt">
                  <div ><a >Show&nbsp;Deactive&nbsp;List</a></div>
                </li>
                <li  class="user-footer second-butt">
                  <div ><a >Show&nbsp;All </a></div>
                </li>
              </ul>
            </div> -->



            <!-- new select  -->

            <!-- new select option  -->


     <!-- new select option  -->


     <select class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
       
      <option  value="selected" >Active&nbsp;List</option>
      <option value="ONEMONTH">Deactive&nbsp;List</option>
      <option value="THREEMONTHS">All</option>
      
    </select>




              </div>
            </div>
          </div>
         
        </div>
        <!-- /.box-header -->
        <div class="box-body">
          <div class="pad-new">
            <div class="table-responsive ">
              <table id="example1" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>TypeName</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of getUsertypes|paginate:{
              itemsPerPage:tableSize,
              currentPage:page,
              totalItems:count,  
            }
            ;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{item.name}}</td>

                    <td class="last-child">
                      <input id="checkBoxAinA{{item.id}}" class="margin-right-2"
                        (change)="ActiveInactiveUserType(item.id,item.isActive)" type="checkbox"
                        [checked]="item.isActive">
                      <button type="button" (click)="onEdit(item)" class="btn btn-icon" aria-label="Product details">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>

              </table>
              <div *ngIf="getUsertypes.length>0;else elseblock">

              </div>

            </div>
            <div class="row mt-5" *ngIf="getUsertypes.length>tableSize">
              <div class="col-sm-3">
                <select (change)="OnTableSizeChange($event.target.value)" class="form-control w-auto">
                  <option *ngFor="let item of tableSizes" value="{{item}}">{{item}}</option>
                </select>
              </div>
              <div class="col-sm-9  text-right">
                <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next"
                  (pageChange)="OnTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
          </div>


        </div>
        <!-- /.box-body -->
      </div>
    </div>
  </div>

</section>

<ng-template #elseblock>
  <div class="no-data">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <h4>No Data Found</h4>
  </div>
</ng-template>