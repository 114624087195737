



<section class="content-header sub-head ">
  <h1>
    Update Existing Inventory

  </h1>

</section>
<section class="content sub-cat">
  <div class="box product-box">
    <div class="box-body2  ">
      <div class="">
        <div class="row">

          <div class="col-md-8 mb-3" style="margin-bottom: 30px;">
            <div class="row">
              <div class="col-md-4 mb-2 gd">
                <ng-select id="productCategoryID" placeholder="Select Category" [items]="dropDownCategoryList"
                  [(ngModel)]="this.filters.productCategoryID" bindLabel="name" bindValue="id"
                  (change)="getFilters($event,'category')">
                </ng-select>
              </div>

              <div class="col-md-4 mb-2 gd">
                <ng-select id="productCategoryID" placeholder="Select SubCategory" [items]="dropDownSubCategoryList"
                  [(ngModel)]="this.filters.subCategoryID" bindLabel="subCategoryName" bindValue="id"
                  (change)="getFilters($event,'subCategory')">
                </ng-select>
              </div>

              <div class="col-md-4 mb-2 gd">
                <select id="drop" class="form-control period margin-responsive custom-css-select"   (change)="getFilters($event,'productType')">
                  <option selected [value]="0">Select Product Type</option>
                  <option [value]="1">Online&nbsp;products</option>
                  <option [value]="2">Offline&nbsp;products</option>
                </select>
                <div class="select-img">
                  <img src="assets/images/drop-down-arrow.png">              
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 mt-2 gd" style="padding-left: 0px;">
            <select class="btn btn-primary ml-2 custom-button  period margin-responsive" name="cars" id="period">
              <option selected value="true">Active&nbsp;List</option>
              <option value="false">Deactive&nbsp;List</option>
              <option value="">All</option>

            </select>

          </div> -->







          <div class="col-md-12">
            <div class="box1 row">
              <div class="col-md-3">
              <label class="mr-2">Step 1: Export Exisitng Inventory </label></div>
              <div class="col-md-6">

              <button (click)="downloadRecord()" type="submit" id="submitForm1" class="btn btn-success readonlyfield1">
                Export Now  </button></div>

            </div>
            <div class="box2 row">
              <div class="col-md-3"><div class="step">

                <label class="mr-2">Step 2:  Select file to upload Inventory </label>
                

              </div></div>
              <div class="col-md-6"> <div class="">
                <!-- <label>Upload Bulk Data File<span
                class="cstm-required">*</span></label> -->
                <div class="input-group">
                  <input type="file" (change)="selectUploadedFile($event)" class="form-control" id="bulkFile" accept=".xlsx">
                </div>
                <!-- <span class="text-danger" style="visibility:hidden;">Upload File</span> -->
              </div></div>
             
              <div class="col-md-12 inventory-button">
              <button type="submit" id="submitForm1" (click)="getTypeOfExcel('update')"
                  class="btn btn-success readonlyfield1" [disabled]="isDisabled1"> Submit </button></div>
            </div>
          </div>



        </div>

      </div>
    </div>
  </div>
</section>





<section class="content-header sub-head ">
  <h1>
    Add New Inventory
  </h1>
</section>


<section class="content sub-cat">
  <div class="box product-box">
    <div class="box-body2  ">
      <div class="">


        <!-- sarthak -->

        <div class="row">
          <div class="col-md-12">
            <div class="box1 row">
              <div class="col-md-3">
              <label class="mr-2">Step 1 : Export New Inventory</label></div>
              <div class="col-md-6">
              <button (click)="downloadSarthakFormat()" type="submit" id="submitForm1"
                class="btn btn-success readonlyfield1">  Export Now </button>
</div>
            </div>

          </div>

          <div class="col-md-12">
            <div class="box2 row">
              <div class="col-md-3">
              <div class="step">
                <label class="mr-2">Step 2: Select Product Images </label>
                <!-- <button type="submit" id="submitForm1"  class="btn btn-success readonlyfield1"> Upload Images </button> -->

              </div>
            </div>
            <div class="col-md-6">
              <div class="">
                <!-- <label>Upload Bulk Data File<span
                      class="cstm-required">*</span></label> -->
                <div class="input-group">
                  <input type="file" multiple (change)="uploadImages($event)" class="form-control" id="bulkImages">
                </div>
                <!-- <span class="text-danger" style="visibility:hidden;">Upload File</span> -->
              </div></div>
            </div>
            <div class="box2 row">
              <div class="col-md-3">
              <div class="step">
                <label class="mr-2">Step 3: Select file to upload Inventory</label>


              </div></div>
              <div class="col-md-6">
              <div class="">
                <!-- <label>Upload Bulk Data File<span
                      class="cstm-required">*</span></label> -->
                <div class="input-group">
                  <input type="file" (change)="selectUploadedFile2($event)" class="form-control" id="bulkFile2"  accept=".xlsx">
                </div>
                <!-- <span class="text-danger" style="visibility:hidden;">Upload File</span> -->
              </div>
</div>
            </div>

          </div>
          <div class="col-md-12">
            <div class="sub d-flex justify-content-end align-items-end">
              <div class=" text-end">
                <button type="submit" id="submitForm1" (click)="getTypeOfExcel('add')" class="btn btn-success readonlyfield1" [disabled]="isDisabled2">
                  Submit </button>
              </div>
            </div>
          </div>
        </div>



        <!-- sarthak -->

        <!-- <div class="row">
            <div class="col-md-12 mt-2 text-right custom-button">
               
            
                <button (click)="downloadRecord()" type="submit" id="submitForm1" class="btn btn-success readonlyfield1"> Export Bulk Record  </button>
               
            
            </div>
          </div> -->
      </div>
    </div>
  </div>
</section>