import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SeoManagementService } from './seo-management.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { resourceUsage } from 'process';
import { ThemeService } from 'ng2-charts';

@Component({
  selector: 'app-seo-management',
  templateUrl: './seo-management.component.html',
  styleUrls: ['./seo-management.component.css']
})
export class SeoManagementComponent implements OnInit{
  public seoForm:any;
  constructor(private fb:FormBuilder,
    private seoService:SeoManagementService,
    private toastr:ToastrService,
    private spinner:NgxSpinnerService,
    private route:ActivatedRoute,
    private router:Router){
    this.seoForm=fb.group({
      id: [0],
      slug:[null],
      type:[null],
      canonicalUrl: [null],
      metaTitle: [null,[Validators.required]],
      metaDescription: [null]
    });
  }

  ngOnInit(): void {

  }

  submitSEO() {
    if (this.seoForm.valid) {
      this.spinner.show();
      this.seoService.manageSEOMater(this.seoForm.value).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            // const action = this.seoForm.get('id').value > 0 ? 'added' : 'updated';
            // this.toastr.success(`Seo details ${action} successfully`);
            // this.resetSEO();
          } else {
            this.toastr.warning(response.message);
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 300);
        },
        error: (err) => {
          console.error('Error in ManageSEOMaster:', err);
          this.spinner.hide();
        }
      })

    } else { this.seoForm.markAllAsTouched() };
  }

  // onChangeInput(key:string,type:string){
  //   if(type==='META-TITLE'){
  //     this.seoDetail.Title=key;
  //   }
  //   else if(type==='META-DESCRIPTION'){
  //     this.seoDetail.Description=key;
  //   }
  // }

  isFormValid():boolean{
    return this.seoForm.valid;
  }

  bindSeoDetail(type:string,slug:string){
    this.spinner.show();
    this.seoService.getSEODetails(type,slug).subscribe({
      next:(response)=>{
        if(response.message==='Success'){
          this.seoForm.patchValue({
            id:response.result.id,
            metaDescription:response.result.metaDescription,
            metaTitle:response.result.metaTitle,
            canonicalUrl:response.result.canonicalUrl,
          });
        }else{
          console.warn('SEO Details Not Found');
        }
        setTimeout(() => {
          this.spinner.hide();
        },300);
      },
      error:(err)=>{
        this.spinner.hide();
        console.error('Error in GetSEODeailsByTypeAndSlug:',err);
      }
    })
  }

  resetSEO() {
    this.seoForm.reset();
    this.seoForm.patchValue({
      id: 0,
    });
  }


}
