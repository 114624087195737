
      <section class="content-header sub-head">
        <h1>
          Manage Application Settings
         
        </h1>
        
      </section>
    
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)"  >
              <div class="box-body">
                
                <div class="form-group col-md-10">
                  <input type="hidden" formControlName="ID" class="form-control">
                  <label for="exampleInputEmail1">Setting Name</label>
                  <input formControlName="SettingName" placeholder="Enter Setting Name"
                  id="SettingName" type="text" class="form-control SettingName" >
              <div class="validation-error" style="color: red;"
                  *ngIf="(form.get('SettingName').touched && form.get('SettingName').invalid && form.get('SettingName').hasError('required')) ">
                  This field is required</div>
                </div>
            
            
                <div class="form-group col-md-10">
                  <label for="email-b-f">  Setting Value</label>
      
                  <input type="file" multiple (change)="onFileChanged($event)" accept="image/*"
                      formControlName="SettingValue" id="SettingValue" class="form-control">
      
              </div>

              
              <div class="form-group col-md-10">
              <div class="image-preview mb-3" *ngIf="previewUrl">
                <img [src]="  previewUrl" height="300" />
            </div> 
          </div>
              </div>
              <!-- /.box-body -->
        
             <button type="submit" class="btn btn-success" id="btnsubmit">Submit</button>
                <button type="button" class="btn btn-secondary" id="">Cancel</button>
             
            </form>
          </div>
        </div>
      </div>

    
  

  <div class="box">
    <div class="box-header">
      <h3 class="box-title">Application Setting List</h3>
    </div>
    <!-- /.box-header -->
    <div class="box-body">
      <table id="example1" class="table table-bordered table-striped">
        <thead>
        <tr>
            <th style="text-align: center;">S.NO</th>
            <th style="text-align: center;">Setting Name</th>
            <th style="text-align: center;">Setting Value</th>
           
            
            <th style="text-align: center;">Edit</th>            
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bloglist of listApplicationSettingContent;let i=index">
          <td style="text-align: center;">{{i+1}}</td>
          <td style="text-align: center;">{{bloglist.SettingName}}</td>
                          
        
        
        
          <td style="width: 100px; text-align: center;"><img height="100px" [src]="bloglist.SettingValue"></td>
         
        <td style="text-align: center;">
            <button (click)="onEdit(bloglist)"  class="btn btn-icon" aria-label="Product details">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
      </td>
        </tr>
         </tbody>
      
      </table>
    </div>
    <!-- /.box-body -->
  </div>
