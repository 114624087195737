<div class="bb">
  <div class="row my-logi">
    <div class="col-xl-6 col-md-7 session">
      <div class="ses">
        <div class="row ">
          <div class="col-md-6 logo-cla" >
            <div class="left">
              <img src="assets/images/logo.png" alt="">
              
            </div>
          </div>
          <div class="col-md-6 log-boxis">
            <div  class="log-in" > 
              <div class="row">
                <div class="col-md-12"> <h4 class="text-center">Sign In</h4></div>
              </div>
          
             
              <form [formGroup]="form" (ngSubmit)="OnSubmit(form.value)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group has-feedback">
                      <input type="email" class="form-control" placeholder="Email" formControlName="email">
                      <span class="form-control-feedback"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group has-feedback" >
                      <input type="password" class="form-control password" placeholder="Password" formControlName="password" id="myInput"  >
                  
                      <span class="form-control-feedback" data-pwd="false"  >  <i class="fa fa-eye" aria-hidden="true" ></i></span>
                    </div>
                  </div>
                </div>
              
               
                <div class="row forgot-password">
                 
                  <div class="col-md-6">
                    <div class="checkbox icheck" style="padding-left: 25px;">
                      <label>
                        <input type="checkbox"> Remember Me
                      </label>
                    </div>
                  </div> 
                   <div class="col-md-6 forgot-class">
                    
                      <label> <a data-toggle="modal" data-target="#forgot"> Forgot Password</a>
                      </label>
                   
                  </div>
                  <div class="col-md-12 " >
                    <button type="submit" class="btn btn-primary btn-block btn-flat login button">Sign In</button>
                  </div>
              </div>
              
              </form>
          
             
            </div>
          </div>
        </div>
     
       
      </div>
    </div>
  </div>
 
</div>
<!-- <div id="forgot" class="modal fade" role="dialog">
  <div class="modal-dialog">


    <form [formGroup]="ResetPasswordForm">
    <div class="modal-content forgot-popup">
      <div class="modal-header">
          <h4 class="modal-title">Forgot Password</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
    
      </div>
      <div class="modal-body">
       
        <div class="forgot-field">
          <div class="form-group has-feedback">
            <input type="email" class="form-control" placeholder="Email" formControlName="email">
            <span class="form-control-feedback"><i class="fa fa-envelope" aria-hidden="true"></i></span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
       <div class="button-forgot">
          <div  class="col-md-12 mt-2 text-right custom-button">
            <button  type="submit" id="form1submit" class="btn btn-success readonlyfield1"> Send OTP </button>
            <button  id="" class="btn btn-secondary marginright15"  data-dismiss="modal">Cancel</button>
           
          </div>
      </div>
    </div>



  
  </form></div>
</div> -->
<div class="modal fade" tabindex="-1" id="forgot" aria-hidden="true"  data-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content forgot-popup">
      <div class="modal-header">
        <h4 class="modal-title">Forgot Password</h4>
      <button type="button" class="close" data-dismiss="modal" (click)="ResetFormValue()">&times;</button>
  
    </div>
   
       
      <div class="modal-body">
        <form [formGroup]="ResetPasswordForm" class="p-0">
          <div class="forgot-field">
            <div class="form-group row m-2" *ngIf="!IsEmailVerify">
              <!-- <label class="col-lg-12">Email</label> -->
              <div class="col-lg-12">
                <input type="text"  class="form-control" placeholder="Enter Your Registered Email" name="email" formControlName="email">
                <span *ngIf="f.email.touched" class="text-danger">
                  <span *ngIf="f.email?.errors?.required"> Please Enter Email</span>
                  <span *ngIf="f.email?.errors?.pattern "> Please Enter Valid Email</span>
                </span>
              </div>
             
            </div>
          </div>
  
            <div class="form-group row m-2" *ngIf="IsEmailVerify && !IsOtpVerify">
              <!-- <label class="col-lg-12">OTP</label> -->
              <div class="col-lg-12">
                <div class="opt-box">
                <ng-otp-input  [formCtrl]="ResetPasswordForm.get('otp')" name="otp" id="otp" [config]="{length:6 ,allowNumbersOnly:true}"></ng-otp-input>
              </div>
             
                <span *ngIf="f.otp.touched" class="text-danger">
                  <span *ngIf="f.otp?.errors?.required"> Please Enter OTP</span>
                </span>
               
              </div>
  
            </div>
  
            <div class="form-group row m-2" *ngIf="IsOtpVerify">
              
              <div class="col-lg-12 admin-eye">
                <label >New Password</label>
                <input  type="password" class="form-control" placeholder="Enter New Password" name="newpassword" formControlName="newpassword">
                <span data-pwdd1="false" (click)="togglePasswordVisibility()" class="pass-eye">
                  <span class="fa fa-eye"></span>
              </span>
                <span *ngIf="f.newpassword.touched" class="text-danger">
                  <span *ngIf="f.newpassword?.errors?.required">Please Enter Password</span>
                </span>
              </div>
             
            </div>
  
            <div class="form-group row m-2" *ngIf="IsOtpVerify">
            
              <div class="col-lg-12 admin-eye">
                <label >Confirm Password</label>
                <input type="password" class="form-control" placeholder="Enter Confirm Password" name="newconfirmpassword" formControlName="newconfirmpassword" >
                <span data-pwdd2="false" (click)="togglePasswordConfirm()" class="pass-eye">
                  <span class="fa fa-eye"></span>
                </span>
                <span *ngIf="f.newconfirmpassword.touched" class="text-danger">
                  <span *ngIf="f.newconfirmpassword?.errors?.passwordMismatch">Passwords do not match.</span>
                </span>
              </div>
             
            </div>
        
         

        
       
        </form>
      </div>
    


    <div class="modal-footer">
       
        <div class="button-forgot">
          <div class="col-md-12 mt-2 text-right custom-button">
            <button *ngIf="!IsEmailVerify" type="button" class="btn btn-success readonlyfield1" (click)="SendOTP()">Send OTP</button>
            <button *ngIf="IsEmailVerify && !IsOtpVerify" type="button" class="btn btn-success readonlyfield1" (click)="VerifyOtp()">Verify OTP</button>
            <button *ngIf="IsOtpVerify" type="button" class="btn btn-success readonlyfield1" (click)="ResetPassword()">Reset Password</button>
            <button type="reset" (click)="ResetFormValue()"  class="btn btn-secondary marginright15" data-dismiss="modal" (reset)="ResetPasswordForm"  >Cancel</button>
          </div>
        </div>
        
     
    </div>


    </div>
  </div>
</div>