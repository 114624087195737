import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { DashboardService } from './dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartConfiguration, ChartData, ChartEvent, ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { OrderhistoryService } from '../orderhistory/orderhistory.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,AfterViewInit {

  // Pie
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };

  public pieChartDatasets: any[] = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public chartType: string = 'pie';
  public encPassword = 'neha@hexa';
  public Counts: any;
  public TopProduct:any[]=[];
  public RecentOrderList:any[]=[];
  public YearList:any[]=[];
 public PaginationConfig={
  itemsPerPage: 5,
  currentPage: 1,
  totalItems:0,
 };

  public Year=new Date().getFullYear();

  public RootURL=environment.baseUrl.slice(0,-3);
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min:1,
      },
    },
    plugins: {
      legend: {
        display: true,
      },

    },
  };

  public barChartType: ChartType = 'bar';
  public barChartData: ChartData<'bar'>;
  public data1:any[];
  public data2:any[];




  constructor(public router: Router, public dashboardService: DashboardService,private spinner:NgxSpinnerService, private toastr: ToastrService,private orderhistoryservice:OrderhistoryService) {


    // Define background colors for each segment
    const backgroundColors = [
      'rgba(243,93,93)',   // Green for the second segment
      'rgba(17,202,109)',   // Red for the first segment
      'rgba(252,198,68)'    // Blue for the third segment
    ];

    const hoverBackgroundColors = [
      'rgba(243,93,93)',   // Green for the second segment
      'rgba(17,202,109)',   // Red for the first segment
      'rgba(252,198,68)'    // Blue for the third segment
    ];

    const hoverBorderColors = [
      'rgba(243,93,93)',   // Green for the second segment
      'rgba(17,202,109)',   // Red for the first segment
      'rgba(252,198,68)'    // Blue for the third segment
    ];

    this.pieChartDatasets = [{
      data: [300, 500, 100],
      backgroundColor: backgroundColors,
      hoverBackgroundColor: hoverBackgroundColors,
      hoverBorderColor: hoverBorderColors,  // Set initial border color
    }];

  }


  ngOnInit(): void {
    this.GetCounts();
    this.GetTopProductList();
    this.GetRecentOrderList();
    this.GetChartData();
    this.Getyears();
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.barChartData = {
        labels: ['January','February','March','April','May','June','July','August','September','October','November','December'],
        datasets: [
          { data: this.data1, label: 'Orders' },
          { data: this.data2, label: 'Sales' },
        ],
      };
    },500);





  }


  GetCounts() {
    this.spinner.show();
    this.dashboardService.gettotalcount().subscribe(data => {
      if (data.message === "Success") {
        this.Counts = data.result;
      };
      this.spinner.hide();
    });
  };


  GetTopProductList(){
    this.spinner.show();
    this.dashboardService.getToproducts().subscribe(data=>{
      if(data.message==='Success'){
        this.TopProduct=data.result;
      };
      this.spinner.hide();
    })
  };

  GetRecentOrderList(){
    this.spinner.show();
    this.dashboardService.getRecentOrders().subscribe(data=>{
      if(data.message==='Success'){
        this.RecentOrderList=data.result;
        this.PaginationConfig.totalItems=this.RecentOrderList.length;
      };
    this.spinner.hide();
    });

  }

  GetChartData(){
    this.dashboardService.GetChartData(2024).subscribe({
      next:(response)=>{
        if(response.message==='Success'){
          const Orders=response.result.order;
          const Sales=response.result.sale;
          this.data1= this.HandleData(Orders);
          this.data2=this.HandleData(Sales);
         }
      },
      error:(err)=>{
        this.toastr.error(err)
      }
    })

  }


  onTableDataChange(e:any){
    this.PaginationConfig.currentPage=e;
    this.GetRecentOrderList();
  }


  HandleData(data:any[]):any[]{
    const MyArr=[0,0,0,0,0,0,0,0,0,0,0,0];
    data.map((item:{month:number,count:number})=>{
      const Month=item.month;
      switch (Month){
        case 1:{
          MyArr[0]=item.count;
          break;
        }
        case 2:{
          MyArr[1]=item.count;
          break;
        }
        case 3:{
          MyArr[2]=item.count;
          break;
        }
        case 4:{
          MyArr[3]=item.count;
          break;
        }
        case 5:{
          MyArr[4]=item.count;
          break;
        }
        case 6:{
          MyArr[5]=item.count;
          break;
        }
        case 7:{
          MyArr[6]=item.count;
          break;
        }
        case 8:{
          MyArr[7]=item.count;
          break;
        }
        case 9:{
          MyArr[8]=item.count;
          break;
        }
        case 10:{
          MyArr[9]=item.count;
          break;
        }
        case 11:{
          MyArr[10]=item.count;
          break;
        }
        case 12:{
          MyArr[11]=item.count;
          break;
        }
      }
    });
    return MyArr;

  }




  Getyears():void{
    let d=new Date().getFullYear();
    for(let i=0;i<10;i++){
      this.YearList.push(d);
      d--;
    };
  }




  // events
  public chartClicked({

    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

goto(path,q1){
  if(path==='/orders'){
    const val= CryptoJS.AES.encrypt(q1,'neha@hexa').toString();
    this.router.navigate([path],{queryParams:{type:val}})
  }
  else if(path==='/view-order'){
    this.router.navigate([path],{queryParams:{type:q1}});

  }

}
}
