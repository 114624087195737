<section class="content-header sub-head ">
  <h1>Category List</h1>
</section>
<section class="">
  <div class="box">
    <div class="box-header">
      <div class="col-md-5">
        <form class="d-flex my-search" method="post">
          <input #searchCatgory id="SearchID" class="form-control me-2" type="search" placeholder="Search" aria-label="Search" (change)="Filter(searchCatgory.value,'BY-SEARCH')"/>
          
          <button (click)="Filter(searchCatgory.value,'BY-SEARCH')" class="btn btn-outline-success" type="submit"><i class="fa fa-search"></i></button>
             
        </form>
      </div>
     
     <div class="col-md-7 text-right">

      <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')"  class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
        <option  value="true" selected>Active&nbsp;List</option>
        <option value="false">Deactive&nbsp;List</option>
        <option value="">All</option>
        
      </select>
        <button class="btn btn-primary custom-button custom-button-margin"  aria-label="Product details" data-toggle="modal" data-target='#manage-category'>
          <i class="fa fa-plus" style="margin-right: 5px;"></i> Add Category
        </button>
      </div>

</div>


<div class="attri-button adding" >
      
    
  <div class="box-body">
    <div class="row nomargin">
      <div class="col-md-12" style="margin-top: 12px;">
        <div class="table-responsive">
          <table id="example1" class="table text-center table-bordered table-striped">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Image</th>
                <th>Category</th>
                <th>Search&nbsp;Tag</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let menu of MainMenuList| paginate
              : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count
                };let i=index">
                <td>{{i+1+tableSize*(page-1)}}</td>
                <td  width="150">
                  <img [src]="rootUrl+menu.categoryImage"  (error)="setDefaultImage($event)">
                </td>
                <td>{{menu.categoryName}}</td>
                <td>{{menu.searchTag}}</td>
      
        
                <td>
                  <div class="d-flex">
                    <button  class="btn btn-icon m-1 p-1 mr-2">
                      <input type="checkbox" id="checkBoxAinA{{menu.id}}" value="IsActive" name="IsActive" (change)="MenuActivedeactive(menu.id,menu.isActive)" [checked]="menu.isActive">
                    </button>
                    <button type="button" (click)="onEdit(menu)" class="btn btn-icon" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </div>
                 
                </td>
              </tr>
            </tbody>
    
          </table>
          <div *ngIf="MainMenuList.length>0;else elseblock">

          </div>
          </div>

          <div class="row mt-3"style="margin-right:0;" *ngIf="MainMenuList.length>tableSize">
            <div class="col-md-3 ">
              <select (change)="onTableSizeChange($event.target.value)" class="form-control w-auto">
                <option  *ngFor="let tblsize of tableSizes" 
                [ngValue]="tblsize">{{tblsize}}</option>
              </select>
            </div>
            <div class="col-md-9 text-right">
              <pagination-controls 
              previousLabel="Prev"
              nextLabel="Next"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
            
            </div>
           
          </div>
      </div>
    </div>
    
  </div>


</div>
</div>

<ng-template #elseblock>
  <div class="no-data">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <h4>No Data Found</h4>
  </div>
</ng-template>

  






  <div class="modal fade" id="manage-category" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-lg-custom">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Manage Category</h4>
          <button type="button" class="close" (click)="resetform()">&times;</button>

        </div>
        <div class="modal-body">
          <form [formGroup]="CategoryForm">
          <div class="row">
          
              <div class="form-group col-md-12 ">
               <div>
                <label for="categoryName">Category <span style="color: rgb(247, 25, 25);">*</span></label>
                <input (change)="onChangeInput($event.target.value)" formControlName="categoryName" id="categoryName" class="form-control validation-field" placeholder="Enter Category Name" type="text">
                
               </div>
               <span *ngIf="f.categoryName.touched" class="validation-error">
                <span *ngIf="f.categoryName.errors?.required">This Field is required </span>
              </span>
              </div>
    
    
              <div class="form-group col-md-12">
                <div>
                 <label for="searchTag">Search Tag <span style="color: rgb(247, 25, 25);">*</span></label>
                 <input formControlName="searchTag" id="searchTag" class="form-control validation-field" placeholder="Enter Search Tag" type="text">
                 
                </div>
                <span *ngIf="f.searchTag.touched" class="validation-error">
                  <span *ngIf="f.searchTag.errors?.required">This Field is required</span>
                </span>
               </div>
    
               
              <div class="form-group col-md-12">
                <div>
                 <label for="searchTag">Category Image <span id="filevalidation" style="color: rgb(247, 25, 25);">*</span></label>
                 <input (change)="OnChangeFile($event)"  id="categoryImage" name="categoryImage"  class="form-control validation-field"  type="file">
                 
                </div>
               </div>
             </div>

            

          

             


      
            </form>
            <app-seo-management></app-seo-management>
          </div>
          
          <div class="attrib-button">
            <div class="col-md-12">
              <button (click)="onSubmit()"  class="btn btn-success marginright15" type="submit"
                id="btnsubmit">
                Submit
              </button>
              <button class="btn btn-secondary marginright15"   (click)="resetform()">
                Cancel
              </button>
              
            </div>
          </div>
        </div>

      </div>

    </div>
 






  </section>
