<section class="content-header sub-head ">
    <h1>
     Update Inventory 
  
    </h1>
  
  </section>
  <section class="content sub-cat">
    <div class="box product-box">
      <div class="box-body2  ">
        <div class="" >
          <div class="row">
            
            <!-- <div class="col-md-6 col-xxl-6  col-md-12 ana ">
              <div class="col-md-6 mt-2 gd" style="padding-left: 0px;">
                <ng-select  id="productCategoryID" placeholder="Select Category"
                            [items]="DropdownCategoryList" [(ngModel)]="selectCat" bindLabel="categoryName" bindValue="id"
                         (change)="SubCategoryList()"  ></ng-select>
              </div>
        
                  <div class="col-md-6 mt-2 gd" style="padding-right: 0px;">
                    <ng-select  id="productCategoryID" placeholder="Select Category"
                    [items]="DropdownSubCategoryList" [(ngModel)]="selectSubCat" bindLabel="subCategoryName" bindValue="id"
                 ></ng-select>
                  </div>
            </div> -->
      
      
                <!-- <div class="col-md-6 mt-2 gd" style="padding-left: 0px;">
              <select id="drop"  class="btn btn-primary custom-button  period margin-responsive">
                <option selected [value]="0">Select</option>
                <option [value]="1" >Online&nbsp;products</option>
                <option [value]="2" >Offline&nbsp;products</option>
              </select>
  
  </div>
  <div class="col-md-6 mt-2 gd" style="padding-left: 0px;">
              <select 
                class="btn btn-primary ml-2 custom-button  period margin-responsive" name="cars" id="period">
                <option selected value="true">Active&nbsp;List</option>
                <option value="false">Deactive&nbsp;List</option>
                <option value="">All</option>
            
              </select>
      
      </div> -->

     
      
      
     
      

      <div class="col-md-12">
        <div class="box1">
          <label class="mr-2">Step 1</label>
          <button (click)="downloadRecord()" type="submit" id="submitForm1" class="btn btn-success readonlyfield1"> Export Existing Inventory Sheet </button>
        
        </div>
        <div class="box2">
          <div class="step">
            <label class="mr-2">Step 2</label>
          <button type="submit" id="submitForm1" (click)="UploadProductDetail()" class="btn btn-success readonlyfield1"> Import Updated Inventory Sheet </button>
          
          </div>
          <div class="">
            <!-- <label>Upload Bulk Data File<span
                class="cstm-required">*</span></label> -->
            <div class="input-group">
                <input type="file" (change)="selectUploadedFile($event)" class="form-control" id="bulkFile">
            </div>
            <!-- <span class="text-danger" style="visibility:hidden;">Upload File</span> -->
        </div>
        </div>
      </div>



      </div>
      <!-- <div class="row">
        <div class="col-md-12 mt-2 text-right custom-button">
           
        
            <button (click)="downloadRecord()" type="submit" id="submitForm1" class="btn btn-success readonlyfield1"> Export Bulk Record  </button>
           
        
        </div>
      </div> -->
      </div>
      </div>
      </div>
      </section>

       
  
  
  