<section class="content-header">
  <h1>
    {{Title}}
  </h1>

</section>
<section class="content">
  <div class="row">

    <div class="col-md-12 margin-left-np ">
      <div class="box box-danger">
        <div class="radioboxmain beforeafternone">
          <div class="box-header">

            <div class="col-md-6">
              <form class="d-flex my-search" method="post">
                <input (keyup)="Filter($event.target.value,'BY-SEARCH')" id="SearchID" class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-success" type="submit"><i class="fa fa-search"></i></button>
              </form>
            </div>
            <div class="col-md-6 text-right">
              <select (change)="Filter($event.target.value,'BY-DATE')" class="btn btn-primary custom-button mr-2" name="cars" id="period">
                <option value="" selected>Filter</option>
                <option value="ONEWEEK">One Week</option>
                <option value="ONEMONTH">One Month</option>
                <option value="THREEMONTHS">3 Months</option>

              </select>
              <div class="dropdown user user-menu">
                <a class="btn btn-primary" data-toggle="dropdown">
                  <span class="">Export&nbsp;&nbsp;&nbsp;<i class="fa fa-angle-down"></i></span>
                </a>
                <ul class="dropdown-menu dropy">
                  <!-- <li class="user-footer">
                    <div class="">
                      <a><img src="assets/images/pdf.png" alt="" class="icon">&nbsp;&nbsp;&nbsp; Pdf</a>
                    </div>
                  </li> -->
                  <li class="user-footer second-butt"  (click)="ExportDataInExcel()">
                    <div class="">
                      <a><img src="assets/images/xls.png" alt="" class="icon">&nbsp;&nbsp;&nbsp;Excel</a>
                    </div>
                  </li>
                </ul>
              </div>


            </div>

          </div>
        </div>
        <div class="box-body nomargin ">
          <div  class="col-md-12" style="margin-top: 12px;">
              <div  class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Image</th>
                  <th>Item&nbsp;Order&nbsp;#</th>
                  <th>Order&nbsp;Date</th>
                  <th>Product Details</th>
                  <th>Total&nbsp;Price&nbsp;(AUD)</th>
                  <th>Quantity</th>
                  <th *ngIf="type == 'Cancelled'">Reason</th>
                  <th>Status</th>


                </tr>
              </thead>
              <tbody>
               


                <tr *ngFor="let item of OrderList|search:'Orders-Two':SearchString|paginate:PaginationConfig let i=index">
                  <td>{{i+((PaginationConfig.currentPage-1)*PaginationConfig.itemsPerPage)+1}}</td>

                  <td width="150"  style="color: blue;text-decoration: underline;"
                    data-toggle="modal" data-target="#myOrderModal">
                    <img [src]="RootURL+item.imagePath" (error)="setDefaultImage($event)" [alt]="item.productName" />
                  </td>
                  <td>{{item.itemOrderID}}</td>
                  <td >{{item.orderDate|date:"dd-MMM-yyyy"}}</td>
                  <td >  <div class="report-text">
                    <span> <b>{{item.productName}}</b> </span>
                    <span> Color : {{item.color}}</span>
                    <span> Size : {{item.size}}</span>
                  </div></td>
                  <td>{{(item.discountPrecentage>0?(item.price-(item.price*item.discountPrecentage/100))*item.quantity:(item.price*item.quantity))|number:'1.2-2'}}</td>
                  <td>{{item.quantity}}</td>

                  <td *ngIf="type == 'Cancelled'" class="Reasonn">
                    <span #spanElement (click)="openModal(item.reason)">{{ truncateReason(item.reason) }}</span> 
                    
                  </td>
                  <td class="birde"> 
                   <div class="badge-comnon badge {{item.status.trim()==='Preparing order'? 'bg-green':item.status.trim()==='Picking order'?'bg-light-blue':item.status.trim()==='Preparing delivery'?'bg-orange':item.status.trim()==='On its way'?'bg-yellow':item.status.trim()==='Delivered'?'bg-green':'bg-red'}} ">
                    <span>{{item.status}}</span></div>
                  </td>
                </tr>

              </tbody>
            </table>
            <div class="modal fade" id="reason" role="dialog">
              <div class="modal-dialog">
              
                <!-- Modal content-->
                <div class="modal-content">
                  <div class="modal-header">
                
                    <h4 class="modal-title">Cancellation Reason</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div class="modal-body">
                    <p>{{ selectedReason }}</p>
                  </div>
                 
                </div>
                
              </div>
            </div>
            
          
          
            <div class="no-data" *ngIf="OrderList.length==0">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <h4>No Data Found</h4>
            </div>
          </div>
          </div>

          <div class="row mt-3 view-user-page"  *ngIf="OrderList.length>PaginationConfig.itemsPerPage">
              
            <div class="col-sm-3 ">
              
                <select (change)="OnTableSizeChange($event.target.value)"  class="form-control w-auto">
                  <option *ngFor="let item of tableSizes" value="{{item}}">{{item}}</option>
                </select>
        
            </div>
            <div class="col-sm-9 text-right" >
              <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next" (pageChange)="OnPageChange($event)">
              </pagination-controls>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>