<section class="content-header sub-head ">
  <h1>
    Add Discount
  </h1>
</section>

<section class="">

  <div class="row">

    <div class="col-md-12 ">
      <div class="box box-danger ">
        <form [formGroup]="form">
          <div class="box-body nomargin">

            <div class=" row">

              <div class="col-lg-4 col-md-12 col-12 mb-2">
                <label for="exampleInputEmail1">Discount(%)<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="discountPercentage" name="discountPercentage"
                  placeholder="Enter Discount Percent" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.discountPercentage.touched">
                  <span *ngIf="input.discountPercentage.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-lg-4 col-md-12 col-12 mb-2">
                <label for="exampleInputEmail1">Title (For e.g : New Year Day)<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="discountTitle" name="discountTitle"
                  placeholder="Enter Title" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.discountTitle.touched">
                  <span *ngIf="input.discountTitle.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-lg-4 col-md-12 col-12 mb-2">
                <label for="exampleInputEmail1">Description<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="discountDescription" name="discountDescription"
                  placeholder="Enter Description" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.discountDescription.touched">
                  <span *ngIf="input.discountDescription.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-lg-4 col-md-12 col-12 mb-2">
                <label for="exampleInputEmail1">Start Date<span class="text-danger">*</span></label>
                <input type="text" id="Discount-fromdate" formControlName="startDate" name="Discount-fromdate"
                  placeholder="Select Date" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.startDate.touched">
                  <span *ngIf="input.startDate.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-lg-4 col-md-12 col-12 mb-2">
                <label for="exampleInputEmail1">End Date<span class="text-danger">*</span></label>
                <input readonly type="text" id="Discount-todate" formControlName="endDate" name="Discount-todate"
                  placeholder="Select Date" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.endDate.touched">
                  <span *ngIf="input.endDate.errors?.required">This Field Is Required</span>
                </span>
              </div>

              <div class="col-md-12  col-12">
                <div class="d-flex justify-content-end">
                  <a class="btn btn-success" type="submit" (click)="AddDiscount()">
                    Submit
                  </a>
                  <button class="btn btn-secondary" type="reset">
                    Cancel
                  </button>
                </div>

              </div>


            </div>
          </div>
        </form>










      </div>
    </div>
  </div>

  <section class="content-header sub-head ">
    <h1>
      Discount List
    </h1>
  </section>

  <div class="box">
    <div class="box-header">
      <div class="search-box-new">
        <div class="row">
          <div class="col-md-12 text-right">
             

        <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')"  class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
  
          <option  value="true" selected>Active&nbsp;List</option>
          <option value="false">Deactive&nbsp;List</option>
          <option value="">All</option>
          
        </select>
          </div>
        </div>
      </div>
     
    </div>
   

    <div class="box-body ">
      <div class=" nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table class="table table-striped table-bordered nomargin">

              <thead>
                <tr>
                  <th class="text-center">S.NO</th>
                  <th class="text-center">Discount(%)</th>
                  <th class="text-center">Title</th>
                  <th class="text-center">Description</th>
                  <th class="text-center">Start&nbsp;Date</th>
                  <th class="text-center">End&nbsp;Date</th>

                  <th class="text-center">Action</th>


                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of discountlist| paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };,let i=index ">

                  <td class="text-center">{{i+1+ tableSize*(page-1)}}</td>
                  <td class="text-center">{{item.discountPercentage}}%</td>
                  <td class="text-center">{{item.discountTitle}}</td>
                  <td class="text-center">{{item.discountDescription}}</td>
                  <td class="text-center">{{item.startDate | date:"dd/MMM/yy"}}</td>
                  <td class="text-center">{{item.endDate| date:"dd/MMM/yy"}}</td>

                  <td class="text-center">
                    <button class="btn btn-icon m-1 p-1 mr-2">
                      <input type="checkbox" id="checkBoxAinA{{item.id}}" name="isActive" class="marginright10 "
                        (change)="ActivedeactiveDiscount(item.id,item.isActive)" [checked]="item.isActive">
                    </button>
                    <button (click)="onEdit(item)" class="btn btn-icon" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </td>

                </tr>

              </tbody>
            </table>
            <div *ngIf="discountlist.length>0;else elseblock">

            </div>
          </div>
          <div class="row mt-3 view-user-page" *ngIf="discountlist.length>tableSize">
            <div class="col-sm-12">
              <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next"
                (pageChange)="OnTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

</section>


<ng-template #elseblock>
  <div class="no-data">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <h4>No Data Found</h4>
  </div>
</ng-template>