  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
     Manage Vehicle
      
    </h1>

  </section>
	 <!-- Order status start -->
    <section class="content">
	<div class="col-md-12">
      <div class="row box box-danger">
        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" >
        <!-- left column -->
        <div class="col-md-6 ">
        
		<div class="box-body ">
                <div class="form-group">
                  <label for="First">Vehicle Name</label>
                  <input type="title" formControlName="VehicleTitle" id="VehicleTitle" class="form-control" placeholder="Vehicle Name">
                  <div class="validation-error" style="color: red;"
                  *ngIf="(form.get('VehicleTitle').touched && form.get('VehicleTitle').invalid && form.get('VehicleTitle').hasError('required')) ">
                  This field is required</div>
                </div>
				  <div class="form-group">
                  <label for="First">Vehicle Permit</label>
                 <select class="form-control" formControlName="VehiclePermit" id="VehiclePermit">
                  <option value=''>-Select-</option>
                    <option value='All UK '>All UK  </option>
                    <option value='Northen East ' >Northen East </option>
                  <option value='South West '>South West  </option>
                  </select>
                  <div class="validation-error" style="color: red;"
                  *ngIf="(form.get('VehiclePermit').touched && form.get('VehiclePermit').invalid && form.get('VehiclePermit').hasError('required')) ">
                  This field is required</div>
                </div>
                <div class="form-group">
                  <label for="First"> Vehicle Rate (Per Miles)</label>
                  <input type="title" formControlName="RatePerMiles" (keypress)="keyPressNumbers1($event)" id="RatePerMiles" class="form-control" placeholder="Vehicle Rate">
                  <div class="validation-error" style="color: red;"
                  *ngIf="(form.get('RatePerMiles').touched && form.get('RatePerMiles').invalid && form.get('RatePerMiles').hasError('required')) ">
                  This field is required</div>
                </div>
             
                <div class="form-group">
                  <label for="First">Vehicle Image</label>
                  <input (change)="onFileChanged($event)" accept="image/*" [formControl]="Image" id="Image" type="file" class="form-control" placeholder="Vehicle Name">
                </div>
                <div class="image-preview mb-3" *ngIf="previewUrl">
                    <img [src]="  previewUrl" height="100" />
                </div> 
               
				   
				  </div>
          
				  
				  
              </div>
   
	   <div class="col-md-6 ">
		<div class="box-body ">
                <div class="form-group">
                  <label for="First">Vehicle No</label>
                  <input type="title" formControlName="RegNumber" id="RegNumber" class="form-control" placeholder="Vehicle No">
                  <div class="validation-error" style="color: red;"
                   *ngIf="(form.get('RegNumber').touched && form.get('RegNumber').invalid && form.get('RegNumber').hasError('required')) ">
                   This field is required</div>
                </div>
				 <div class="form-group">
                  <label for="First">Vehicle Total Capacity (Tonnes)</label>
                   <input type="title" (keypress)="keyPressNumbers($event)" formControlName="CapacityInTonnes" id="CapacityInTonnes"  class="form-control" placeholder="Vehicle capacity">
                   <div class="validation-error" style="color: red;"
                   *ngIf="(form.get('CapacityInTonnes').touched && form.get('CapacityInTonnes').invalid && form.get('CapacityInTonnes').hasError('required')) ">
                   This field is required</div>
                </div>
                <div class="form-group">
                  <label for="First">Vehicle Model</label>
                   <input type="title" formControlName="VehicleModel" id="VehicleModel" class="form-control" placeholder="Vehicle Model">
                   <div class="validation-error" style="color: red;"
                   *ngIf="(form.get('VehicleModel').touched && form.get('VehicleModel').invalid && form.get('VehicleModel').hasError('required')) ">
                   This field is required</div>
                </div>
              
                <div class="form-group col-md-6" style="float: right;"  >
                  <button  type="submit" id="btnsubmit" style="float: right;"  class="btn btn-success">Add Vehicle </button>
                  </div>
				  </div>
              </div>
              
        </form>
       
       
      
	</div>
      <!-- order status end  -->
	   <!-- Order  recieve start   --> 
	   </div>
	  <div class="row">
	   <section class="content-header">
      <h1>
 Vehicle List
       </h1>
	   </section>
	      <div class="col-md-12 ">
		<div class="box box-danger">
        <table class="table table-striped">
                <tbody>
				
				<tr>
	<th>S.No</th>
                  <th > Vehicle Name</th>
				    <th>Vehicle Image </th>
				    <th>Vehicle No </th>
					<th> Vehicle Permit</th>
					<th> Vehicle Model</th>
				<th> Vehicle capacity</th>
				<th>Vehicle Rate(Per miles)</th>
					<th> Active  </th>
					  <th>Edit    </th>
                 	  
                </tr>
                <tr *ngFor="let vehiclelist of listVehicleContent;let i=index">
                  <td>{{i+1}}</td>
                 <td> {{vehiclelist.VehicleTitle}}</td>
                 <td style="width: 100px;"><img height="100px" [src]="vehiclelist.Image"></td>
                 <td> {{vehiclelist.RegNumber}}</td>
                 <td>{{vehiclelist.VehiclePermit}} </td>
                 <td>{{vehiclelist.VehicleModel}}</td>
                 <td>{{vehiclelist.CapacityInTonnes}}</td>
                 <td>{{vehiclelist.RatePerMiles}}</td>
              
                 <td>
                  <input type="checkbox" value="IsActive" name="IsActive"(change)="VehicleActivedeactive(vehiclelist.ID,vehiclelist.IsActive)" [checked]="vehiclelist.IsActive" >
                    
              </td>
              <td>
                  <button (click)="onEdit(vehiclelist)"  class="btn btn-icon" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  </button>
            </td>
                </tr>
			
				
				
				 </tbody></table>
		</div>
		</div>
	
	   
     </div>
	
	  
	   <!-- Order  recieve start-->
	   
	   
    </section>
    <!-- /.content -->

