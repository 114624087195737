
<div class="row">
    <!-- left column -->
    <div class="col-md-12">
    <div class="box box-primary">
      <section class="content-header">
        <h1>
          Manage Vehicle Package
         
        </h1>
     
      </section>
      <!-- /.box-header -->
      <!-- form start -->
      <form (ngSubmit)="onSubmit(form.value)">
        <div class="box-body">
          <div class="form-group col-md-8">
              <label for="exampleInputEmail1">Select Vehicle</label>
              
              <select  formControlName="VehicleID"   id="VehicleID" class="form-control" id="VehicleID" (change)="getvehicleDetail($event.target.value)">
                <option value=''>-Select-</option>
                <option  *ngFor="let vehicles of listvehicles" [value]="vehicles.ID">{{vehicles.VehicleTitle}}
              
                </option>
            </select>
          </div>
            
          <div class="form-group col-md-8">
            <label for="exampleInputEmail1">Enter Capacity (in Tonnes)</label>
            <input (keypress)="keyUpCapacity($event)"  formControlName="ForCapacity" id="ForCapacity"  placeholder="Enter Capacity"
             type="text" class="form-control" >
        
          </div>
          <div class="form-group col-md-8">
            <label for="exampleInputEmail1">Package Price (in £)</label>
            <input (keypress)="keyPressNumbers($event)"  placeholder="Enter Price" formControlName="Price" 
            id="CapacityInTonnes" type="text" class="form-control" >
        
          </div>
          
        </div>
        <!-- /.box-body -->
  
        <div class="box-footer">
          <button type="submit" class="btn btn-danger" id="btnsubmit">Submit</button>
        </div>
      </form>
    </div>
  </div>
  </div>
  <div class="box">
    <div class="box-header">
      <h3 class="box-title">Vehicle Package List</h3>
    </div>
    <!-- /.box-header -->
    <div class="box-body">
      <table id="example1" class="table table-bordered table-striped">
        <thead>
        <tr>
          <th>S.No</th>
      
          <th>Vehicle</th>
          <th>Capacity (in Tonnes)</th>
          <th>Price (in £)</th>
          <th>IsActive</th>
          <th>Edit</th>
        </tr>
        </thead>
        <tbody>
        <tr  *ngFor="let vehiclelist of listVehiclePackages;let i=index">
            <td>{{i+1}}</td>
          <td>{{vehiclelist.VehicleName}}</td>
          <td>{{vehiclelist.Capacity}}</td>
          <td>{{vehiclelist.Price}}</td>
          <td>
            <input type="checkbox" value="IsActive" name="IsActive" (change)="VehicleListActivedeactive(vehiclelist.ID,vehiclelist.IsActive)"
            [checked]="vehiclelist.IsActive">
              
        </td>
        <td>
          <button type="button" (click)="onEdit(vehiclelist)"   class="btn btn-icon"  aria-label="Product details">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
  
          </button>
      </td>
        </tr>
         </tbody>
      
      </table>
    </div>
    </div>
    <!-- /.box-body -->

  