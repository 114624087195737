import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from "@angular/common";
import { FormBuilder, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from './users.service';

import { AppService } from 'src/app/app.service';
import { UserPermissionService } from '../user-permission/user-permission.service';
import Swal from 'sweetalert2';
import { debug } from 'console';
import { uid } from '@ckeditor/ckeditor5-utils';
declare var $: any;


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {
  private MenuList: any[] = [];
  form: any;
  ProfileImgPreview: any;
  SelectedFile: File;
  formdata = new FormData();
  SelectMaxDate: Date;
  UserTypeList: any;

  flatpickrOptions = {
    altInput: true,
    altFormat: 'd/m/y',
    dateFormat: 'Y-m-d',
  };


  constructor(private acticatedRoute: ActivatedRoute, public router: Router, private fb: FormBuilder, public usersService: UsersService,
    private spinner: NgxSpinnerService, private toastr: ToastrService, private appservice: AppService,
    private datepipe: DatePipe,
    private userpermissionservice: UserPermissionService) {
    this.form = fb.group({
      id: [0],
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      dob: [null],
      mobile: ['', [Validators.required, Validators.minLength(10)]],
      gender: [null],
      profileImageName: [null],
      profileImagePath: [null],
      loginUserID: [0],
      userTypeID: [null, Validators.required],
      doj: ['', [Validators.required]],
    });

    let Today = new Date();
    this.SelectMaxDate = new Date(Today.getFullYear() - 18, Today.getMonth(), Today.getDate());
  }

  ngOnInit(): void {
    this.GetUserTypes();
    this.acticatedRoute.queryParamMap.subscribe(params => {
      const encuid = params.get('UserID');
      if (encuid) {
        const UID=parseInt(this.appservice.decryptValue(encuid));
        $('#email').prop('readonly', true);
        this.usersService.userDetailByID(UID).subscribe(data => {
          if (data.message == "Success") {
            var date = data.result.doj;
            let date1 = this.datepipe.transform(date, 'dd/MMM/yyyy');
            this.form.patchValue({
              'id': UID,
              'firstName': data.result.firstName,
              'lastName': data.result.lastName,
              'email': data.result.email,
              'dob': data.result.dob,
              'mobile': data.result.mobile,
              'gender': data.result.gender,
              'profileImageName': data.result.profileImageName,
              'profileImagePath': data.result.profileImagePath,
              'userTypeID': data.result.userTypeID,
              'doj': date1,
            });
          };
        });
      }
    });

  }


  ngAfterViewInit(): void {
    $('#doj').flatpickr({
      minDate: new Date(),
      dateFormat: 'd/M/Y'

    })
  }
  GetUserTypes() {
    this.usersService.GetallUserType().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const typelist = response.result.filter(x => x.id !== 4 && x.isActive);
          this.UserTypeList = typelist;
        } else { this.toastr.error(response.message) };
      },
      error: (err) => {
        this.toastr.error(err)
      }
    })
  }


  GetMenuByUserTypeID(ID: number) {
    this.userpermissionservice.getUserTypePermissionByUserTypeID(ID).subscribe({
      next: (response) => {
        if (response.message === 'ok') {
          this.MenuList = response.result;
        } else { console.error(response.message) };
      },
      error: (err) => {
        this.toastr.error(err)
      }
    })
  }

  get Input() {
    return this.form.controls;
  }


  onFileChanged(event: any) {
    this.SelectedFile = event.target.files[0];
    if (this.SelectedFile) {
      if (this.SelectedFile.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.ProfileImgPreview = e.target.result;
        };
        reader.readAsDataURL(this.SelectedFile);
      }
      if (this.formdata.has('Files')) {
        this.formdata = new FormData();
      }
      this.formdata.append('Files', this.SelectedFile);
      this.formdata.append('Type', 'User');
      this.formdata.append('LoginUserID', '0');
      let formdt = this.formdata.getAll('Files')
    }
    else {
      this.toastr.error('', 'Please select an image file');
    }
  }






  onSubmit(): void {
    if (this.form.valid) {
      this.spinner.show();
      const doj = this.datepipe.transform(this.form.get('doj').value, 'yyyy-MM-dd');
      this.form.patchValue({
        doj: doj
      });
      const isEdit = this.form.get('id').value !== 0;
      this.usersService.AddEditUsers(this.form.value).subscribe(data => {
        if (data.message === 'Success') {
          const ID = data.result.id;
          this.userpermissionservice.AssignPermissionToUser(ID, this.MenuList).subscribe({
            next: (response) => {
              if (response.message === 'ok') {
                this.spinner.hide();
                Swal.fire({
                  text: isEdit ? "User has been updated successfully." : "User has been created successfully. Login credentials have been emailed to the provided address.",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.router.navigate(['viewusers']);
                  }
                });
              }
            },
            error: (err) => {
              this.spinner.hide();
              this.toastr.error(err)
            }
          });
          this.form.reset();
          this.form.patchValue({
            id: 0
          });
        } else {
          this.spinner.hide();
          this.toastr.error(data.message);
        };
      }, err => {
        this.spinner.hide();
        this.toastr.error('', 'Not Responding')
      });
    } else {
      this.spinner.hide();
      this.form.markAllAsTouched();
    }
  }





  keyPressNumbers1(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  getCurrentDate(): string {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
  }

}

