import { Component, OnInit } from '@angular/core';
import { ViewInvoiceService } from '../view-invoice/view-invoice.service';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-pos-report',
  templateUrl: './pos-report.component.html',
  styleUrls: ['./pos-report.component.css']
})
export class PosReportComponent  implements OnInit{
  public IsPartial: any = '';
  public refundIsPartial_P1: boolean = false;
  public InvoiceList: any[] = [];

  private RootURL: string = environment.baseUrl.slice(0, -3);
  private fromdate: string = '';
  private todate: string = '';
  public PagincationConfig = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  public pickupstatus: any = '';
  public searchString: string;


  constructor(private viewinvoiceservice: ViewInvoiceService,private spinner:
    NgxSpinnerService, private toastr: ToastrService,
    private fb: FormBuilder, private datepipe: DatePipe) {

  }

ngOnInit(): void {
  this.GetInvoiceList();
  $(".date-range [name='Date-Filter']").flatpickr({
    dateFormat: 'd/M/Y',
    maxDate: 'today'
  })
}



Filter(val: any, type: string) {
  let date = new Date();
  if (type === 'payment-type') {
    val = val === 'true' ? true : false;
    this.IsPartial = val;
    this.GetInvoiceList();
  }
  if (type === 'search-invoice') {
    this.searchString = val;
  }
  if (type === 'from-date') {
    if (this.todate === '') {
      this.todate = this.datepipe.transform(date, 'yyyy-MM-dd');
    };
    this.fromdate = this.datepipe.transform(val, 'yyyy-MM-dd');
    this.GetInvoiceList();

  }
  if (type === 'to-date') {
    if (this.fromdate === '') {
      this.fromdate = this.datepipe.transform(date, 'yyyy-MM-dd');
    };
    this.todate = this.datepipe.transform(val, 'yyyy-MM-dd');
    this.GetInvoiceList();
  }
  if (type === 'pickup-status') {
    this.pickupstatus = val;
    this.GetInvoiceList();
  }
}


DownLoadInvoice(InvoiceNumber: string, InvoiceCount: number, IsPartial: boolean) {
  const Folder = IsPartial === true ? 'Partial_Invoice' : 'Full_Invoice';
  const Path = `Content/Invoice/${Folder}/`;
  const downloadlink = `${this.RootURL}${Path}${InvoiceNumber}.pdf`;
  saveAs(downloadlink, `invoice.pdf`);
}


GetInvoiceList() {
  this.spinner.show();
  this.viewinvoiceservice.GetInvoiceList(this.IsPartial, this.fromdate, this.todate, this.pickupstatus, 'other-invoice').subscribe({
    next: (response) => {
      if (response.message === 'Success') {
        this.InvoiceList = response.result.filter(x=>x.isPartialPaymentComplete);
      } else {
        console.error(response.message);
        this.InvoiceList = [];
      };
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    },
    error: (err) => {
      this.toastr.error(err)
      this.spinner.hide();
    }
  })
}

}
