
<div class=" areas">
    <section class="content sub-cat">
        <div class="box">
            <div class="box-header">
                <div class="row">
                    <div class="col-md-6">
                        <div class="search">
                            <form novalidate="" method="post"
                                class="d-flex my-search ng-untouched ng-pristine ng-valid">
                                <input #search_string (keyup)="Filter(search_string.value,'search-invoice')"
                                    id="SearchID" type="search" placeholder="Search" aria-label="Search"
                                    class="form-control me-2">
                                <button type="submit" class="btn btn-outline-success">
                                    <i class="fa fa-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="menu-filter">
                            <div class="full-half date-range">
                                <select (change)="Filter($event.target.value,'payment-type')"
                                    class="form-control form-select form-select-solid fw-bolder mr-2 ng-untouched ng-pristine ng-valid"
                                    id="Pickup-Status11" ng-reflect-is-disabled="false" ng-reflect-model="2">
                                    <option disabled selected>Select Payment Type</option>
                                    <option [value]="true">Partial Payment</option>
                                    <option [value]="false">Full Payment</option>
                                </select>
                            </div>
                            <!-- <div class="full-half">
                                <select (change)="Filter($event.target.value,'pickup-status')"
                                    class="form-control form-select form-select-so lid fw-bolder mr-2 ng-untouched ng-pristine ng-valid"
                                    id="Pickup-Status11">
                                    <option disabled selected>Select Delivery Status</option>
                                    <option [value]="0">Delivered</option>
                                    <option [value]="1">Pending for pickup</option>
                                    <option [value]="2">Picked up</option>
                                </select>
                            </div> -->
                            <div class="date-range full-half">
                                <input (change)="Filter($event.target.value,'from-date')" placeholder="From Date"
                                    name="Date-Filter" class="form-control" placeholder="Date from">
                            </div>
                            <div class="full-half m-o">
                                <input (change)="Filter($event.target.value,'to-date')" placeholder="To Date"
                                    name="Date-Filter" class="form-control" placeholder="Date to">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="box-body">

            
            <div class="row " style="margin: 0px;" >
                <div class="col-md-12 mt-4">
                    <div class="inner-pad" #dataToExport>
                            <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Invoice&nbsp;Number</th>
                                    <!-- <th> Order&nbsp;No</th> -->
                                    <th>Customer&nbsp;Name</th>
                                    <th>Invoice&nbsp;Date</th>
                                    <th> Invoice&nbsp;Amount&nbsp;(AUD) </th>
                                    <th>Balance&nbsp;(AUD) </th>
                                    <th>Payment&nbsp;Status</th>
                                    <th>Delivery&nbsp;Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                           
                            <tr *ngFor="let item of InvoiceList|search:'INVOICE':searchString|paginate:PagincationConfig"
                                style="position: relative;">
                                <!-- <td><span style="cursor: pointer;">{{item.invoiceNumber}}</span></td> -->
                                <td>{{item.orderNumber}}</td>
                                <td [routerLink]="item.isGuestUser!==true?'/viewusers':null ">
                                    {{item.customerName===null?'-':item.customerName}}</td>
                                <td>{{item.invoiceDate|date:'dd/MMM/yyyy'}}</td>
                                <td>{{item.invoiceTotalAmount}}</td>
                                <td>{{item.balance}}</td>
                                <td>
                                    <div class="pay-status">

                                        <span>{{item.ispartialPayment?(item.isPartialPaymentComplete?'Partial(Completed)':'Partial'):'Complete'}}</span>
                                        <a *ngIf=" !item.isPartialPaymentComplete"
                                            (click)="GetInvoiceDetailByID(item.id)" data-toggle="tooltip"
                                            data-placement="bottom" title="Click to receive balance payment"
                                            class="color-sel"><i class="fa fa-plus-circle"></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <select id="Pickup-Status{{item.id}}" (change)="OnChangePickup(item.id)"
                                        *ngIf="item.pickupStatus>0"
                                        class="form-control form-select form-select-solid fw-bolder "
                                        [(ngModel)]="item.pickupStatus">
                                        <option [disabled]="item.pickupStatus>1" [value]="1">Pending for pickup</option>
                                        <option [value]="2">Picked up</option>
                                    </select>
                                    <span *ngIf="item.pickupStatus===0">Delivered</span>
                                </td>
                                <td>
                                    <div class="d-flex action-view">
                                        <button
                                            (click)="DownLoadInvoice(item.invoiceNumber,item.balance>0?1:2,item.ispartialPayment)"
                                            class="btn btn-icon mr-2" type="submit" data-toggle="tooltip"
                                            data-placement="bottom" title="View Invoice">
                                            <i class="fa fa-eye" aria-hidden="true"> </i>
                                        </button>
                                        <!-- <button (click)="Send(item.id,'EMAIL')" class="btn btn-icon mr-2" type="submit"
                                            data-toggle="tooltip" data-placement="bottom" title="Send Mail">
                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                        </button> -->

                                        <!-- <button
                                            (click)="getInvoiceItemListByInvoiceID(item.id,item.ispartialPayment,item.isPartialPaymentComplete,item.orderNumber)"
                                            class="btn btn-icon mr-2" type="submit" data-toggle="tooltip"
                                            data-placement="bottom" title="Cancel Invoice" data-toggle="modal"
                                            data-target="#CancelInvoice">
                                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                                        </button> -->
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                        </table>
                        <div *ngIf="InvoiceList.length>0;else elseblock;">

                        </div>
                     </div>
                    </div>
                </div>
                <div class="area-cust" *ngIf="InvoiceList.length>10">
                    
                    <div class="col-md-12 text-right">
                        <pagination-controls previousLabel="Prev" nextLabel="Next"
                            (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>

            <ng-template #elseblock>
                <div class="no-data">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <h4>No Data Found</h4>
                </div>
            </ng-template>


        </div>
    </section>
</div>
