import { Component, OnInit } from '@angular/core';
import { ProductlistService } from '../productlist/productlist.service';
import { InventoryService } from './inventory.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
declare const $:any;

@Component({
  selector: 'app-lowinventory',
  templateUrl: './lowinventory.component.html',
  styleUrls: ['./lowinventory.component.css']
})
export class LowinventoryComponent  implements OnInit{
  public LowinventoryProductList:any[]=[];
  public form:any;
  public readonly rootURL:string=environment.baseUrl.slice(0,-3);
  public paginationconfig = {
    itemsPerPage:10,
    currentPage: 1,
    pageIndex :0
  };
  constructor(private inventoryservice:InventoryService,private fb:FormBuilder,private spinner:NgxSpinnerService,private toastr:ToastrService){
    this.form=fb.group({
      ProductDetailID:['',Validators.required],
      ProductStock:['',Validators.required],
    });

  }

  ngOnInit(): void {
    this.GetLowInventoryProduct();
  }

  get f(){
    return this.form.controls;
  }

  GetLowInventoryProduct(){
    this.spinner.show();
    this.inventoryservice.GetLowInventroyProducts().subscribe({
      next:(response)=>{
        if(response.message==='Success'){
          this.LowinventoryProductList=response.result;
        };
        this.spinner.hide();
      },
      error:(err)=>{
        this.toastr.error(err)
        this.spinner.hide();
      }
    });
  }

  OnTableDataChange(pageNumber:number){
    this.paginationconfig.currentPage=pageNumber;
  }

  AddStock(){
    if(this.form.valid){
      this.spinner.show();
      this.inventoryservice.AddProductStock(this.form.value).subscribe({
        next:(response)=>{
          if(response.message==='Success'){
            this.toastr.success('Product stock has been added successfully.');
          }else{this.toastr.error(response.message)};
          this.GetLowInventoryProduct();
          $('#StockModal').modal('hide');
          this.form.reset();
          this.spinner.hide();
        },
        error:(err)=>{
          this.toastr.error(err)
          this.spinner.hide();
        }
      })
    }else{this.form.markAllAsTouched()}

  }


  OpenModal(Id:number):void{
    this.form.patchValue({
      ProductDetailID:Id
    });
    $('#StockModal').modal('show');
  }
}
