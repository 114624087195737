import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InvoiceTypeService } from './invoice-type.service';
import { SplitInterpolation } from '@angular/compiler';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-master-invoicetype',
  templateUrl: './master-invoicetype.component.html',
  styleUrls: ['./master-invoicetype.component.css']
})
export class MasterInvoicetypeComponent implements OnInit{
  public InvoiceTypeForm:any;
  public InvoiceTypeList:any[]=[];
  public PagincationConfig = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  constructor(private fb:FormBuilder,private invoicetypeservice:InvoiceTypeService,private spinner:NgxSpinnerService,private toastr:ToastrService){
    this.InvoiceTypeForm=fb.group({
      id:[0],
      name:[null,[Validators.required]],
    });
  }

  ngOnInit(): void {
    this.GetInvoiceTypeList();
  }
  get InvoiceFormControls(){
    return this.InvoiceTypeForm.controls;
  }
  OnTableDataChange(pagenumber:number){
    this.PagincationConfig.currentPage=pagenumber;
  }
  GetInvoiceTypeList(){
    this.spinner.show();
    this.invoicetypeservice.GetInvoiceTypeList().subscribe({
      next:(response)=>{
        if(response.message=='Success'){
          console.log('invoce',response)
          this.InvoiceTypeList=response.result;
        }else{console.error(response.message)};
        setTimeout(() => {
          this.spinner.hide();
        },500);
      },
      error:(err)=>{
        this.toastr.error(err)
        this.spinner.hide();
      }
    })
  }
ManageInvoiceType(){
  if(this.InvoiceTypeForm.valid){

  }
  this.spinner.show();
this.invoicetypeservice.ManageInvoiceType(this.InvoiceTypeForm.value).subscribe({
  next:(response)=>{
    if(response.message==='Success'){
      this.toastr.success(response.activity);
      this.GetInvoiceTypeList();
      this.reset();

    }else{
      this.toastr.warning(response.message);
    };
    setTimeout(() => {
      this.spinner.hide();
    },500);
  },
  error:(err)=>{
    this.toastr.error(err)
    this.spinner.hide();
  }
})

}

onEdit(item:any){
  this.InvoiceTypeForm.patchValue({
    id:item.id,
    name:item.invoiceType,
  });
}

reset(){
  this.InvoiceTypeForm.reset();
  this.InvoiceTypeForm.patchValue({
    id:0,
  });
}
}
