import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class VehiclepackageService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http: HttpClient) { }
  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localdata.userToken
  });
  getVehicles() {
    return this.http.get<any>(this.rootUrl + '/Vehicle/getVehicles', {headers: this.reqHeader});
  }
  getVehiclePackages() {
    debugger;
    return this.http.get<any>(this.rootUrl + '/Vehicle/ListofVehiclePackages', {headers: this.reqHeader});
   }
   AddEditVehiclePackage(vehicle) {
    const reqHeader = new HttpHeaders({
      // 'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.localdata.userToken
    });
    
    return this.http.post<any>(this.rootUrl + '/Vehicle/AddEditVehiclePackage', vehicle, {headers: reqHeader} );
  }
  getActiveDeactive(active) {
    return this.http.post<any>(this.rootUrl + '/Vehicle/ActiveInactiveVehiclePackage', active, {headers: this.reqHeader});
   }
  
}
