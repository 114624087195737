import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, FormControl, AbstractControl, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
//import { CustomValidators } from 'ng2-validation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from "src/app/login/login.service";
import * as CryptoJS from 'crypto-js';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  encPassword = 'neha@hexa';
  form: UntypedFormGroup;
  public UserInfo = {
    'refreshToken':null,
    'userToken': null,
    'userId': null,
    'userTypeId': null,
    'userDetail': null,
    'userName': null,
    'Name': null,
    'companyId': null
  };
  public ResetPasswordForm:any;
  IsOtpVerify:boolean=false;
  IsEmailVerify: boolean = false;
  constructor(private spinner: NgxSpinnerService, fb: UntypedFormBuilder, private router: Router, private toastr: ToastrService,
    public loginService: LoginService) {
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(4)])]
    });



    this.ResetPasswordForm=fb.group({
      email:['',[Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      otp:['',Validators.required],
      newpassword: ['',Validators.required],
      newconfirmpassword:['',Validators.required]

    },{validators:this.PasswordMatchValidator})

  }


  ngOnInit(): void {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('menuM');


$("[data-pwdd1]").click(function () {
  let $this = $("[data-pwdd1]");
  if ($this.attr("data-pwdd1") == "false") {
    $this.siblings("input").attr('type', 'text');
    $this.attr("data-pwdd1", "false").addClass('show-password');
  } else {
    $this.siblings("input").attr('type', 'password');
    $this.attr("data-pwdd1", "false").removeClass('show-password');
  }
});
$("[data-pwdd2]").click(function () {
  let $this = $("[data-pwdd2]");
  if ($this.attr("data-pwdd2") == "false") {
    $this.siblings("input").attr('type', 'text');
    $this.attr("data-pwdd2", "true").addClass('show-password');
  } else {
    $this.siblings("input").attr('type', 'password');
    $this.attr("data-pwdd2", "false").removeClass('show-password');
  }
});

    $("[data-pwd]").click(function () {
      let $this = $("[data-pwd]");
      if ($this.attr("data-pwd") == "false") {
        $this.siblings("input").attr('type', 'text');
        $this.attr("data-pwd", "true").addClass('show-password');
      } else {
        $this.siblings("input").attr('type', 'password');
        $this.attr("data-pwd", "false").removeClass('show-password');
      }
    });


  }



  togglePasswordVisibility() {
    const input = document.querySelector('input[name="newpassword"]') as HTMLInputElement;
    const eyeIcon = document.querySelector('.pass-eye');
    if (input.type === 'password') {
        input.type = 'text';
        eyeIcon.classList.add('show-password');
    } else {
        input.type = 'password';
        eyeIcon.classList.remove('show-password');
    }
}

togglePasswordConfirm() {
  const input = document.querySelector('input[name="newconfirmpassword"]') as HTMLInputElement;
  const eyeIcon = document.querySelector('.pass-eye');
  if (input.type === 'password') {
      input.type = 'text';
      eyeIcon.classList.add('show-password');
  } else {
      input.type = 'password';
      eyeIcon.classList.remove('show-password');
  }
}
  OnSubmit(UserData) {
    if(this.form.valid){
      this.spinner.show();
      this.loginService.userAuthentication(UserData)
        .subscribe(data => {
          if (data.message === 'ok') {
            const TypeID = data.result.userTypeID;
            if (TypeID !== 4) {
              this.UserInfo.userId = CryptoJS.AES.encrypt(data.result.id.toString(), this.encPassword.trim()).toString();
              this.UserInfo.userTypeId = CryptoJS.AES.encrypt(data.result.userTypeID.toString(), this.encPassword.trim()).toString();
              this.UserInfo.userName = CryptoJS.AES.encrypt(data.result.userName.toString(), this.encPassword.trim()).toString();
              this.UserInfo.userToken = data.result.accessToken;
              this.UserInfo.refreshToken=data.result.refreshToken;
              this.UserInfo.Name = CryptoJS.AES.encrypt(data.result.FirstName, this.encPassword.trim()).toString();
              
              localStorage.setItem('userInfo', JSON.stringify(this.UserInfo));
              setTimeout(() => {
                this.spinner.hide();
                this.router.navigate(['/dashboard']);
              }, 200)
  
            } else {
              this.spinner.hide();
              this.toastr.error('', 'You are not able to login');
            }
  
          } else { this.toastr.error(data.message) };
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          if (err.status === 400) {
            this.toastr.error('', err.error.error_description);
          } else {
            this.toastr.error('', 'Not Responding');
          }
        }
        );
    }
    else{
      this.form.markAllAsTouched();
    }

  }


  SendOTP(){
    debugger
    if( this.ResetPasswordForm.get('email').valid){
      let Email= this.ResetPasswordForm.get('email').value;
      this.loginService.ForgetPassword(Email, "backend").subscribe(data=>{
        if(data.message=="Success"){
          this.IsEmailVerify=true;

        }
        else{
          this.toastr.warning('',data.message)
        }
      })
    }
    this.ResetPasswordForm.markAllAsTouched;
  }


  PasswordMatchValidator(formgroup:FormGroup){
    
    const PasswordControl=formgroup.get('newpassword');
    const ConfirmPasswordControl=formgroup.get('newconfirmpassword');
    if(PasswordControl?.value===ConfirmPasswordControl?.value){
      ConfirmPasswordControl?.setErrors(null);
    }
    else{
      ConfirmPasswordControl?.setErrors({passwordMismatch:true});
    }

  }

  VerifyOtp(){
    debugger
    if(this.ResetPasswordForm.get('otp').valid){
      let OTP=this.ResetPasswordForm.get('otp').value;
      let Email=this.ResetPasswordForm.get('email').value;
      let BO={
        otp:OTP,
        email:Email
      };
      this.loginService.VerifyOTP(BO).subscribe(data=>{
        if(data.message=="Success"){
          this.IsOtpVerify=true;
        }
        else{
          this.toastr.warning('',data.message);
  
        }
      })
     
  
    }
  
  }




  ResetPassword(){
    if(this.ResetPasswordForm.get('newpassword').valid && this.ResetPasswordForm.get('newconfirmpassword').valid){
      let Email=this.ResetPasswordForm.get('email').value;
      let Password=this.ResetPasswordForm.get('newpassword').value;
      let BO={
        email:Email,
        newpassword:Password,
        Type : 'backend'
        
      }
      this.loginService.ResetPassword(BO).subscribe(data=>{
        if(data.message=="Success"){
          this.ResetFormValue();
          this.toastr.success('',data.activity);
         
        }
        else{
          this.toastr.warning('',data.message);
        }
      })
  
  
    }
    else{
      this.ResetPasswordForm.markAllAsTouched();
    }
  }



  ResetFormValue(){
    this.ResetPasswordForm.reset();
    this.IsEmailVerify=false;
    this.IsOtpVerify=false;
    $('#forgot').modal('hide');
  }


  
  get f(){
    return this.ResetPasswordForm.controls;
  
  }

}
