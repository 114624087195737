import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MastertypeService {

  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}
  getMasterTypeList():Observable<any>
  {
    return this.http.get<any>(this.rootUrl +'/Common/GetMasterTypelist')
  }
  ManageMasterType(bo:any):Observable<any>
  {
    return this.http.post<any>(this.rootUrl +'/Common/ManageMasterType',bo)
  }
  ActiveInactiveType(id:number):Observable<any>
  {
    return this.http.get<any>(this.rootUrl +'/Common/ActiveInactiveType',{params:{id}})
  }
}
