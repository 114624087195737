import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductlistService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http:HttpClient) { }
  productDetailList(CategoryID:number,SubCategoryID:number,Active:boolean|string,SearchString:string,OnlineOffline:number,Currentpage:number,Pagesize:number):Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Product/GetProductListAdmin',{params:{CategoryID,SubCategoryID,Active,SearchString,OnlineOffline,Currentpage,Pagesize}});
  }
  ActiveInactiveProduct(id:number,isactive:boolean,type:string,UserID:number):Observable<any> {

    return this.http.get<any>(this.rootUrl + '/Product/ActiveInactiveProduct',  {params:{id,isactive,type,UserID}});
  }
  getProductDetailbyId(id):Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Product/GetProductByID', {params:{id}});
   }

   AddProductInFeatured(productID:any):Observable<any>{
    return  this.http.get<any>(this.rootUrl+'/Product/AddProductInFeatured',{params:{productID}});
   }


   GetItemCode(productID:any,colourID:any):Observable<any>{
    return  this.http.get<any>(this.rootUrl+'/Product/GetItemcodeOfProduct',{params:{productID,colourID}});
   }

   AddNewArrivalProduct(id:number):Observable<any>{
    return this.http.get<any>(this.rootUrl+'/Product/AddNewArrivalProduct',{params:{id}});
   }

   
}
