import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalesreportService } from './salesreport.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../../app.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import domToImage from 'dom-to-image';
import { ToastrService } from 'ngx-toastr';
declare const $: any;


@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent implements OnInit {
  public orderList: any[] = [];
  public Rooturl: string = environment.baseUrl.slice(0, -3);
  @ViewChild('dataToExport') private dataToExport: ElementRef;
  @ViewChild('date_filter') private dateFilter:ElementRef;
  public paginationconfig = {
    itemsPerPage:10,
    currentPage: 1,
    totalItems :1
  };
  public fromDate:string;
  public toDate:string;
  public reportType:number=0;
  constructor(private salesservice: SalesreportService, private spinner: NgxSpinnerService, private appservice: AppService, private datepipe: DatePipe,
    private toastr: ToastrService) { }

    

  ngOnInit(): void {
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    this.fromDate = this.datepipe.transform(oneWeekAgo, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    setTimeout(() => {
      this.dateFilter.nativeElement.flatpickr({
        mode: 'range',
        dateFormat: 'd/M/y',
        defaultDate: [oneWeekAgo, 'today']
      });
    }, 300);
    this.loadGridData();
  }
  
    ngAfterViewInit(): void {

    }

  Filter(key:string,value: any) {
    if(key==='by-date'){
      if (!value.includes('to')) return;
      const dates = value.split('to');
      this.fromDate = this.datepipe.transform(dates[0], 'yyyy-MM-dd');
      this.toDate = this.datepipe.transform(dates[1], 'yyyy-MM-dd');
    }
    else if(key==='by-pagination'){
      this.paginationconfig.currentPage=value;
    }
    this.loadGridData();
  }

  
  async loadGridData() {
    try {
      const data = await this.GetSalesOrderList('sales-report');
      this.orderList = data.result??[];
      this.paginationconfig.totalItems = data.count;
    } catch (error) {
      this.toastr.error('Error loading grid data.');
    }
  }
     
  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }
  
  GetSalesOrderList(type:string): Promise<any> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.salesservice.GetSalesOrderList(this.fromDate,this.toDate,this.paginationconfig.currentPage,this.paginationconfig.itemsPerPage,type,this.reportType)
        .subscribe({
          next: (data) => {
            resolve(data); 
            setTimeout(() => this.spinner.hide(), 300);
          },
          error: (err) => {
            this.spinner.hide();
            console.error('Error in fetching orders', err);
            reject(err);
          },
        });
    });
  }
  
  async ExportDataInExcel() {
    try {
      const data = await this.GetSalesOrderList('sales-report-xl');
      if (!data || !data.result || data.result.length === 0) {
        this.toastr.warning('No data available for export.');
        return;
      }
  
      const json = data.result.map((item: {
        productName: string,
        color: string,
        size: string,
        quantity: number,
        discount: any,
        price: number,
        orderDate: string,
        status: string,
        orderNo: string
      }) => {
        return {
          ItemOrder: item.orderNo,
          ProductName: item.productName,
          Color: item.color,
          Size: item.size,
          Quantity: item.quantity,
          Discount: item.discount,
          Price: item.price,
          OrderDate: this.datepipe.transform(item.orderDate, 'dd/MMM/yyyy'),
          Status: item.status,
        };
      });
  
      this.appservice.exportAsXLSXWithStyle(json, 'Sales Report');
      this.toastr.success('Sales report exported successfully.');
    } catch (error) {
      this.toastr.error('Error exporting sales report.');
      console.error('Export error:', error);
    }
  }

}
