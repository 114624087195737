import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DiscountService } from './discount.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
declare var $: any

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css']
})
export class DiscountComponent implements OnInit ,AfterViewInit{
  form: any;
  discountlist: any[]=[];
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];
  activedeaccolor: any;
  active:any=true;
  constructor(private fb: FormBuilder, private service: DiscountService, private toastr: ToastrService, private spinner: NgxSpinnerService,private datepipe:DatePipe) {
    this.form = fb.group({
      id: [0],
      discountPercentage: ["", Validators.required],
      discountTitle: ["", Validators.required],
      discountDescription: ["", Validators.required],
      // discountCouponName: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["",Validators.required],
      isActive: [true],
    })
  }
  ngOnInit(): void {
    this.getDiscountList()
  }

ngAfterViewInit(): void {
  setTimeout(() => {
    $('#Discount-fromdate').flatpickr({
      onChange:(selectedDate)=>{
        this.OnChangeStartDate(selectedDate[0])
      },
      minDate:'today',
      dateFormat:'d/M/Y',
    });
    
  },300);
}

OnChangeStartDate(date:any){
  $('#Discount-todate').val('');
  $('#Discount-todate').flatpickr({
    minDate:date,
    dateFormat:'d/M/Y'
  });
}

Filter(key:any,Type:string){
  if(Type==='ACTIVE-INACTIVE'){
    this.active=key;
  }
  this.getDiscountList();
}

getDiscountList() {
    this.spinner.show();
    this.service.getDiscountlist(this.active).subscribe(data => {
      if (data.message === "Success") {
        this.discountlist = data.result;
      }else{this.discountlist=[]};
      setTimeout(() => {
        this.spinner.hide();
      },300);
    })
  }
  get input() {
    return this.form.controls;
  }
  AddDiscount() {
    debugger
    if (this.form.valid) {
      this.spinner.show();
      let startDate=new Date(this.form.get('startDate').value);
      let endDate=new Date(this.form.get('endDate').value);
      this.form.patchValue({
        id:this.form.value.id==null?0:this.form.value.id,
        startDate:this.datepipe.transform(startDate,'yyyy-MM-dd'),
        endDate:this.datepipe.transform(endDate,'yyyy-MM-dd'),
      });
      this.service.ManageDiscount(this.form.value).subscribe(data => {
        if (data.message === "Success") {
          this.toastr.success(data.activity);
          this.getDiscountList();
          this.form.reset();
        }
        this.spinner.hide();
      })
    }
    else {
      this.form.markAllAsTouched();
    }

  }
  OnTableDataChange(PageNumber: number) {
    this.page = PageNumber;
  }

  ActivedeactiveDiscount(id: number, IsActive: boolean) {
    debugger
    let action='deactivated';
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the discount? " : "Are you sure you want to activate the discount?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if(!IsActive) action ='activated'
        this.service.ActiveInactiveDiscount(id).subscribe(res => {
          this.getDiscountList();
          this.toastr.success(`Discount has been ${action} Successfully.`);
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })

  }



  onEdit(discount: any) {
    $('#Discount-todate').val('');
    let MinDate=this.datepipe.transform(discount.startDate,'dd/MMM/yyyy');
    $('#Discount-todate').flatpickr({
      dateFormat:'d/M/Y',
      minDate:MinDate,
    });
   
    this.form.patchValue({
      id: discount.id,
      discountPercentage: discount.discountPercentage,
      discountTitle: discount.discountTitle,
      discountDescription: discount.discountDescription,
      // discountCouponName: discount.discountCoupon,
      endDate: this.datepipe.transform(discount.endDate,'dd/MMM/yyyy'),
      startDate:this.datepipe.transform(discount.startDate,'dd/MMM/yyyy'),
    });



  }
}
