<section class="content-header sub-head">
  <h1>
    {{Title}}
  </h1>
</section>

<div class="box view-user-box">
  <div class="box-header">
    <div class="search-box-new">
      <div class="row">
        <div class="col-md-6 col-xl-4 mt-2 mb-2">
          <form class="d-flex my-search  p-0 " method="post">
            <input #search class="form-control me-2" type="search" placeholder="Search" aria-label="Search"
              (keyup)="Filter(search.value,'by-search')">
            <button class="btn btn-outline-success" type="submit"><i class="fa fa-search"></i></button>
          </form>
        </div>

        <div class="col-md-6 col-xl-4 mt-2 mb-2">
          <ng-select [ngModel]="UserTypeID" (change)="Filter($event?.id,'by-usertypes')" placeholder="Select User Type"
            [items]="UserTypeList" bindLabel="name" bindValue="id" class=" my-search  p-0 "></ng-select>
        </div>
        <div class="col-md-12 col-xl-4 text-right mt-2 mb-2">

          <select (change)="Filter($event.target.value,'active-inactive')"
            class="btn btn-primary custom-button mr-2 period" name="cars" id="period">

            <option value="true">Active&nbsp;List</option>
            <option value="false">Deactive&nbsp;List</option>
            <option value="">All</option>

          </select>

          <button routerLink="/users" class="btn btn-primary  custom-button" aria-label="Product details">
            <i class="fa fa-plus"></i> &nbsp;Add&nbsp;New&nbsp;User
          </button>
        </div>
      </div>
    </div>


  </div>
  <!-- /.box-header -->
  <div class="box-body nomargin">
    <div class="">
      <div class="col-md-12" style="margin-top: 12px;">
        <div class="table-responsive">
          <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Registration</th>
                <th>User Type</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Orders</th>
                <th>User Registration</th>

                <th>Action</th>

              </tr>
            </thead>
            <tbody>


              <tr *ngFor="let item of UserList|search:Type:SearchString| paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
                let i=index">
                <td>{{i+1+ tableSize*(page-1)}}</td>
                <td>{{item.createdDate|date:'dd/MMM/yyyy'}}</td>
                <td>{{item.userTypeName}}</td>
                <td>{{item.firstName}}</td>
                <td>{{item.email}}</td>
                <td>{{item.mobile!==null?item.mobile:'-'}}</td>
                <td>
                  <button (click)="GoTo('/orders',item.id)" class="btn btn-icon m-1 p-1 mr-2">
                    {{item.orders}}
                  </button>
                </td>
                <td><span class="badge"
                    [ngClass]="{' badge-success': item.isOnlineRegistration,'badge-secondary': !item.isOnlineRegistration && !item.isGuestUser && item.userTypeID===4,'badge-warning': !item.isOnlineRegistration && item.isGuestUser && item.userTypeID===4,'badge-info':item.userTypeID!==4}">{{item.isOnlineRegistration?'Online':item.isGuestUser?'Guest POS':item.userTypeID!==4?'Staff':'POS'}}</span></td>

                <td style="text-align: left;">
                  <div class="d-flex ">
                    <button class="btn btn-icon m-1 p-1 mr-2">
                      <input id="checkBoxAinA{{item.id}}" type="checkbox" [checked]="item.active"
                        (change)="ActiveInactiveUser(item.id,item.active)">
                    </button>
                    <button data-target="largeModal" data-toggle="modal" class="btn btn-icon mr-2"
                      aria-label="User view" data-toggle="modal" data-target="#largeModal" (click)="onview(item.id)">
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                    <button [ngStyle]="{'display':item.isOnlineRegistration || item.isGuestUser?'none':'block'}"
                      [disabled]="item.isOnlineRegistration || item.isGuestUser" type="button" (click)="onEdit(item)"
                      class="btn btn-icon myButton">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>


              </tr>
            </tbody>

          </table>
          <div *ngIf="UserList.length>0 && (UserList|search:Type:SearchString).length>0;else elseblock">

          </div>
        </div>

        <div class="row mt-3 view-user-page" *ngIf="UserList.length>tableSize">

          <div class="col-sm-3 ">

            <select (change)="OnTableSizeChange($event.target.value)" class="form-control w-auto">
              <option *ngFor="let item of tableSizes" value="{{item}}">{{item}}</option>
            </select>

          </div>
          <div class="col-sm-9 text-right">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="OnTableDataChange($event)">
            </pagination-controls>
          </div>

        </div>
      </div>
    </div>


    <div>

    </div>
  </div>

  <ng-template #elseblock>
    <div class="no-data">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <h4>No Data Found</h4>
    </div>
  </ng-template>
  <!-- /.box-body -->
</div>



<!-- ***************modal**************** -->
<div class="modal fade" id="largeModal" tabindex="-1">
  <div class="modal-dialog user-detial">
    <div class="modal-content">
      <div class="modal-header">

        <h3 class="modal-title user-tittle">User Detail</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="row my-list">
              <div class="col-md-12">
                <p><strong>Name:</strong><span>{{UserDetails.name}} </span></p>
              </div>
              <div class="col-md-12">
                <p><strong>Email:</strong><span>{{UserDetails.email}}</span></p>
              </div>


              <div class="col-md-12">
                <p><strong>Mobile:</strong><span>{{UserDetails.phoneNumber}}</span></p>
              </div>
              <div class="col-md-12">
                <p><strong>Create Date:</strong><span>{{UserDetails!=undefined?(UserDetails.createdDate |
                    date:'dd-MMM-yyyy'):null}}</span></p>
              </div>
            </div>

          </div>
        </div>

        <div class="row mt-3" *ngIf="UserDetails.addressList && UserDetails.addressList.length>1">
          <div class="col-md-12">
            <div class="row mb-2">
              <div class="col-md-12">
                <h4><strong>Billing Address:</strong></h4>
                <div class="bill">
                  <p>{{UserDetails.addressList[0].address}}, {{UserDetails.addressList[0].cityName}},
                    {{UserDetails.addressList[0].stateName}}, {{UserDetails.addressList[0].countryName}},
                    {{UserDetails.addressList[0].pinCode}}</p>
                </div>
                <hr>
              </div>
            </div>
          </div>
          <hr>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <h4><strong>Shipping Address:</strong></h4>
                <div class="bill">
                  <p>{{UserDetails.addressList[1].address}}, {{UserDetails.addressList[1].cityName}},
                    {{UserDetails.addressList[1].stateName}}, {{UserDetails.addressList[1].countryName}},
                    {{UserDetails.addressList[1].pinCode}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Ok</button>
          <!-- <button type="button" class="btn btn-danger">Edit</button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- ******************************modalEnd********** -->