<section class="content-header sub-head ">
  <h1>
    Received Orders
  </h1>

</section>
<section class="content sub-cat">




  <div class="box box-danger ">




    <div class="radioboxmain beforeafternone">
      <div class="box-header">

        <div class="col-md-6">
          <form class="d-flex my-search" method="post">
            <input (keyup)="SearchOrder($event.target.value)" id="SearchID" class="form-control me-2" type="search"
              placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success" type="submit"><i class="fa fa-search"></i></button>
          </form>
        </div>
        <div class="col-md-6 text-right">

          <!-- <button class="btn btn-primary custom-button mr-2"  aria-label="Product details">  </button>  -->

          <!-- <img src="assets/images/tune.png" class="tune">-->
          <select (change)="ReceivedOrderList($event.target.value)" class="btn btn-primary custom-button mr-2"
            name="cars" id="period">

            <option value="ONEWEEK" selected>One Week</option>
            <option value="ONEMONTH">One Month</option>
            <option value="THREEMONTHS">3 Months</option>

          </select>
          <div class="dropdown user user-menu">
            <a class="btn btn-primary" data-toggle="dropdown">
              <span class="">Export&nbsp;&nbsp;&nbsp;<i class="fa fa-angle-down"></i></span>
            </a>
            <ul class="dropdown-menu dropy">
              <li class="user-footer second-butt">
                <div class="">
                  <a (click)="ExportDataInExcel()"><img src="assets/images/xls.png" alt=""
                      class="icon">&nbsp;&nbsp;&nbsp;Excel</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="box-body nomargin">
      <div class=" nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive" #dataToExport>
            <table id="orders" class="table table-striped table-bordered nomargin">
              <thead>
                <tr>
                  <th style="text-align: center;">S.NO</th>
                  <th>Item Order #</th>
                  <th>Order Date </th>
                  <th>Total&nbsp;Price&nbsp;(AUD)</th>
                  <th> Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderlist|search:'Orders':searchstring| paginate : { itemsPerPage: tableSize, currentPage: page, totalItems: count } let i=index">
                  <td style="text-align: center;">{{i+1+tableSize*(page-1)}}</td>
                  <td (click)="showOrderDetails(item.id)" data-toggle="modal" data-target="#myOrderModal">
                    {{item.itemID}}</td>
                  <td>{{item.orderDate | date: 'dd-MMM-yyyy'}}</td>
                  <td>{{item.totalPrice|number:'1.2-2'}}</td>
                  <td>
                    <div
                      class="badge-comnon badge {{item.status.trim()==='Received Order'?'bg-purple':item.status.trim()==='Preparing order'? 'bg-green':item.status.trim()==='Picking order'?'bg-light-blue':item.status.trim()==='Preparing delivery'?'bg-orange':item.status.trim()==='On its way'?'bg-yellow':item.status.trim()==='Delivered'?'bg-green':'bg-red'}} ">
                      <span>{{item.status}}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <button type="button" (click)="showOrderDetails(item.id)" class="btn btn-icon" aria-label="Edit">
                      <i class="fa fa-eye" aria-hidden="true"> </i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="orderlist.length;else elseBlock">
            </div>
          </div>
          <div class="row mt-3" *ngIf="orderlist.length>tableSize">
            <div class="col-md-3 ">
              >
            </div>
            <div class="col-md-9 text-right">
              <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
              </pagination-controls>

            </div>

          </div>


        </div>
      </div>


    </div>
    <ng-template #elseBlock>
      <div class="no-data">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <h4>No Data Found</h4>
      </div>
    </ng-template>

  </div>




</section>