import { Component, OnInit } from '@angular/core';
import { ViewhiredvehiclesService } from './viewhiredvehicles.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { FormGroup, FormControl, ReactiveFormsModule, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-viewhiredvehicles',
  templateUrl: './viewhiredvehicles.component.html',
  styleUrls: ['./viewhiredvehicles.component.css']
})
export class ViewhiredvehiclesComponent implements OnInit {
  menuType: string;
  encPassword = 'neha@hexa';
  public listHiredvehicles: Array<string> = [];
  constructor(private route: Router, private router: ActivatedRoute, fb: UntypedFormBuilder, private toastr: ToastrService, public hiredvehicleservice: ViewhiredvehiclesService, private spinner: NgxSpinnerService) {
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    if (currentUser !== null) {
      if (CryptoJS.AES.decrypt(currentUser.userTypeId.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8) === '1') {
      } else {
        this.route.navigate(['/notfound']);
      }
    } else {
      this.route.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    debugger;
    this.router.queryParams.subscribe(params => {
      this.menuType = params['Type'];
    });
    this.getHomePageSection(this.menuType);
  }
  getHomePageSection(type) {
    debugger;
    this.spinner.show();
    this.hiredvehicleservice.ListOfHiredVehicleStatus(type).subscribe(data => {
      this.listHiredvehicles = data.Result;
      console.log("vehiclelist", this.listHiredvehicles);

      setTimeout(() => {
        this.spinner.hide();



      }, 100);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });

  }
}
