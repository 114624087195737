import { Component, OnInit } from '@angular/core';
import { CancelledordersService } from './cancelledorders.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-cancelledorders',
  templateUrl: './cancelledorders.component.html',
  styleUrls: ['./cancelledorders.component.css']
})
export class CancelledordersComponent implements OnInit {
  public listCancelledOrdersDetail: Array<string> = [];
  constructor(private router: Router, private toastr: ToastrService, public cancelorderservice: CancelledordersService, private spinner: NgxSpinnerService) { }
  public OrderProductList: Array<any> = [];
  ngOnInit(): void {
    this.ListCancelledOrderDetail();
  }
  ListCancelledOrderDetail() {
    this.spinner.show();
    this.cancelorderservice.GetCancelledOrders().subscribe(data => {
      this.listCancelledOrdersDetail = data.Result;
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    });
  }
  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }
  showOrderDetails(orderid) {
    this.spinner.show();
    this.cancelorderservice.GetOrderByID(orderid).subscribe(data => {
      this.OrderProductList = data.Result;

      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    })

  }
}
