<ngx-spinner bdColor="rgba(51,51,51,0.8)"
             size="large"
             color="#fff"
             type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


             <!-- Modal -->
            

<router-outlet></router-outlet>

