import { Component, OnInit } from '@angular/core';
import { ViewusersService } from './viewusers.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare  var $:any;

import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import * as CryptoJS from 'crypto-js';
import { UsersService } from '../users/users.service';
@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrls: ['./viewusers.component.css']
})
export class ViewusersComponent implements OnInit {
  UserList:any=[];
  UserDetails:any={};
  UserProfileImg:any;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10,15,20];
  Type='User';
  Title:string="View Customer";
  SearchString:any;
  UserTypeList:any[]=[];
  UserTypeID:any=4;
  items: any;
  totalItems: any;
  Active:any=true;
  constructor(public router: Router,
    public ViewService: ViewusersService ,
     private spinner: NgxSpinnerService,
     private toastr: ToastrService,
     private userserviece:UsersService,
    private appservice:AppService) {
  }


  ngOnInit(): void {
    localStorage.removeItem('newuserID');
    this.GetUsers();
    this.GetUserTypes();
  }

  GetUsers() {
    this.spinner.show();
    this.ViewService.getUserList(this.UserTypeID,this.Active).subscribe({
      next:(response)=>{
        if(response.message=='Success'){
          this.UserList = response.result;
        }else{ this.UserList=[]; }
        setTimeout(() => {
          this.spinner.hide();
        },300);
      },
      error:(err)=>{
        this.spinner.hide();
        console.error('Error in getUserList :',err);
      }

    });

  }

  fetchItems(): void {
    this.items = this.UserList
    this.totalItems = this.items.length;
  }

  Filter(key: any, type: string) {
    if (type === 'by-search') {
      this.SearchString = key;
      this.page = 1;
      return;
    }
    if (type === 'active-inactive') {
      this.Active = key;
    };
    if (type === 'by-usertypes') {
      const Text = this.UserTypeList.find(x => x.id === key)?.name;
      if (Text && key) {
        this.UserTypeID = key;
        this.Title = `View ${Text}`
      } else {
        this.UserTypeID = key;
        this.Title = `View Users`
      }
    }
    this.page = 1;
    this.GetUsers();
  }



  GetUserTypes(){
    this.spinner.show();
    this.userserviece.GetallUserType().subscribe({
      next:(response)=>{
        if(response.message==='Success'){
          const UserTypeList:any[]=response.result;
          UserTypeList.unshift({id:'',name:'All'});
          this.UserTypeList=UserTypeList;
        }else{console.error(response.message)};
        this.spinner.hide();
      },
      error:(err)=>{
        this.spinner.hide();
        this.toastr.error(err)
      }
    })
  }
  OnTableDataChange(PageNumber:number){
    this.page=PageNumber;
  }

  OnTableSizeChange(SizeNumber:number){
    this.tableSize=SizeNumber;
  }


  onEdit(User: any) {
    if(!User.active){
      Swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: 'Please Activate User First!',
    });
    return;
    }
    $("[data-toggle='tooltip']").tooltip('hide');
    const UserID=this.appservice.encryptValue(User.id.toString());
    const url=this.appservice.encryptValue('viewusers');
    if(User.userTypeID===4){
      this.router.navigate(['/edit-user'],{queryParams:{UserID:UserID,URL:url}});
    }else{
      this.router.navigate(['/users'],{queryParams:{UserID:UserID}});
    }
  }


  onview(UserID:number){
    this.ViewService.GetUserByID(UserID).subscribe({
      next: (response) => {
        if (response.message == "Success") {
          this.UserDetails = response.result;
          if (this.UserDetails.addressList.length > 0) {
            const addresslist: {
              address: string, cityID: number, cityName: string, countryID: number,
              countryName: string, id: number, isBillingAddress: boolean, phoneCode: number, pinCode: string,
              stateID: number, stateName: string
            }[] = this.UserDetails.addressList;

            addresslist.sort((a, b) => {
              return (a.isBillingAddress === b.isBillingAddress) ? 0 : a.isBillingAddress ? -1 : 1;
            });
            this.UserDetails.addressList=addresslist;
            console.log('add',addresslist)

          }



        }
        console.log("Userdetails",this.UserDetails)
      },
      error:(err)=>{
        console.error('Error in GetUserByID:',err);
      }
    })
  }


  ActiveInactiveUser(UserID:number,IsActive:boolean){
    var action = 'activated';
    Swal.fire({
      // title: 'Are you sure?',
      title:IsActive?"Are you sure you want to deactivate the user? ":"Are you sure you want to activate the user? ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive?"Confirm":"Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
           if (IsActive === true) {
      action = 'deactivated';
    }
    this.ViewService.ActiveInactiveUsers(UserID).subscribe(res => {
      this.GetUsers();
      this.toastr.success('User has been ' + action + ' successfully.');
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else{
        function check(){
          $('#checkBoxAinA'+UserID).prop("checked",true)
        };
        function uncheck(){
          $('#checkBoxAinA'+UserID).prop("checked",false)
        }

        IsActive?check():uncheck();
      }
    })
  }

  GoTo(q1:any,q2:any){
    let Uid=q2.toString();
    const UserID=CryptoJS.AES.encrypt(Uid,"neha@hexa")
    this.router.navigate([q1],{queryParams:{'UserID':UserID}})
  }
}
