<section class="content-header sub-head ">
  <h1>
    Category Level 1
  </h1>

</section>
<section >

  <div class="box product-box">

      <div class="box-header">
        <div class="" style="width: 100%;">
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <form class="d-flex my-search pb-0 mb-2" method="post">
                <input #search id="SearchID" class="form-control me-2 background-input" type="search" placeholder="Search" aria-label="Search" (keyup)="Filter(search.value,'BY-SEARCH')">
                <button (click)="Filter(search.value,'BY-SEARCH')" class="btn btn-outline-success" type="submit"><i class="fa fa-search"></i></button>
              </form>
            </div>
            <div class="col-xl-4 col-md-6">
              <ng-select [items]="listmainmenus" placeholder="Select Category" bindLabel="categoryName" bindValue="id" (change)="Filter($event?.id,'BY-CATEGORY')" class="my-search pb-0 mb-2" ></ng-select>
            </div>
    
    
           <div class="col-xl-5 col-md-12 text-right">

            <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')"  class="btn btn-primary custom-button  mt-3 mb-2"  name="cars" id="period"> 
             
              <option  value="true" selected>Active&nbsp;List</option>
              <option value="false">Deactive&nbsp;List</option>
              <option value="">All</option>
               
            </select>
              <button class="btn btn-primary custom-button custom-button-margin mt-3 mb-2 ml-2"  aria-label="Product details" data-toggle="modal" data-target='#manage-subcategory'>
                <i class="fa fa-plus" style="margin-right: 5px;"></i> Add Category Level 1
              </button>
            </div>
          </div>
        </div>
       
  
  </div>
 

  
 
    <div class="box-body">
      <div class="row nomargin" >
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table id="example1" class="table text-center table-bordered table-striped">
              <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Category </th>
                <th>Category Level 1</th>
                <th>Search Tag</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let subcat of SubCategoriesList| paginate
              : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count
                };let i=index">
                <td>{{i+1+tableSize*(page-1)}}</td>
                <td width="150"> <img  src="{{RootURL}}{{subcat.imagePath}}"/></td>
                <td>{{subcat.category}}</td>
                <td>{{subcat.subCategory}}</td>
                <td>{{subcat.searchTag}}</td>

              <td>
                  <button  class="btn btn-icon m-1 p-1 mr-2">
                    <input type="checkbox" id="checkBoxAinA{{subcat.id}}" value="IsActive" name="IsActive" (change)="MenuActivedeactive(subcat.id,subcat.isActive)"
                    [checked]="subcat.isActive">
      
                  </button>
                <button type="button"  (click)="onEdit(subcat)" class="btn btn-icon"  aria-label="Product details">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        
                </button>
            </td>
              </tr>
               </tbody>
            
            </table>
            <div *ngIf="SubCategoriesList.length>0;else elseblock">

            </div>
          </div>


            <div class="row mt-3"style="margin-right:0;" *ngIf="SubCategoriesList.length>tableSize">
             
              <div class="col-md-3 ">
                <select (change)="onTableSizeChange($event.target.value)" class="form-control w-auto">
                  <option  *ngFor="let tblsize of tableSizes" 
                  [ngValue]="tblsize">{{tblsize}}</option>
                </select>
              </div>
              <div class="col-md-9 text-right">
                <pagination-controls
                previousLabel="Prev"
                nextLabel="Next"
                (pageChange)="onTableDataChange($event)"
              >
              </pagination-controls>
              
              </div>
            </div>

        </div>
      </div>
     
    </div>
    <!-- /.box-body -->



    <ng-template #elseblock>
      <div class="no-data">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <h4>No Data Found</h4>
      </div>
    </ng-template>



  <div class="modal fade" id="manage-subcategory" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-lg-custom">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Manage Category Level 1</h4>
          <button type="button" class="close" (click)="resetForm()">&times;</button>

        </div>
        <div class="modal-body">
          <form [formGroup]="SubCategoryForm" (ngSubmit)="onSubmit()"class=" p-0">
            <div class="box-body">
              <div class="row">
                <div class=" col-md-6 mb-2">
                  <label for="productCategoryID">Category <span style="color: rgb(247, 25, 25);">*</span></label>
                  <ng-select (change)="onChangeCategory($event.id)" placeholder="Select Category" formControlName="productCategoryID"  id="productCategoryID" [items]="listmainmenus"  bindLabel="categoryName" bindValue="id" ></ng-select>
                  
                <span *ngIf="input.productCategoryID.touched" class="text-danger validation-error">
                  <span *ngIf="input.productCategoryID.errors?.required">This field is required</span>
                </span>
                </div>
      
              <div class=" col-md-6 mb-2">
              <div>
                <label for="subCategoryName">Category Level 1 <span style="color: rgb(247, 25, 25);">*</span></label>
                <input (change)="onChangeInput($event.target.value)" formControlName="subCategoryName" placeholder="Enter Category Level 1"
                id="subCategoryName" type="text" class="form-control">
              </div>
                <span *ngIf="input.subCategoryName.touched" class="text-danger validation-error">
                  <span *ngIf="input.subCategoryName.errors?.required">This field is required</span>
                </span>
        
                
              </div>
        
        
              
              </div>
              <div class="row">
                <div class=" col-md-6 mb-2">
                  <div>
                    <label for="searchTag">Search Tag <span style="color: rgb(247, 25, 25);">*</span></label>
                    <input formControlName="searchTag" placeholder="Enter Search Tag"
                    id="searchTag" type="text" class="form-control">
                  </div>
                    <span *ngIf="input.searchTag.touched" class="text-danger validation-error">
                      <span *ngIf="input.searchTag.errors?.required">This field is required</span>
                    </span>
                  </div>
        
        
                  <div class=" col-md-6 mb-2">
                    <label for="productCategoryID">Category Level 1 Image<span id="filevalidation" style="color: rgb(247, 25, 25);">*</span></label>
                    <input (change)="OnChangeFile($event)"   id="Image" name="Image"  class="form-control validation-field"  type="file">
                  </div>
              </div>

            </div>
            
          </form>
          <app-seo-management></app-seo-management>
          </div>
          
         

          <div class="attrib-button">
            <div class="col-md-12">
              <button (click)="onSubmit()"  class="btn btn-success marginright15" type="submit"
                id="btnsubmit">
                Submit
              </button>
              <button class="btn btn-secondary marginright15"   (click)="resetForm()">
                Cancel
              </button>
              
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>







</section>

