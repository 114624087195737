import { MenusComponent } from './../menus/menus.component';
import { Component, OnInit } from '@angular/core';
import { BulkmanagementService } from './bulkmanagement.service';
import { SeleniumServer } from 'selenium-webdriver/remote';
import { ToastrService } from 'ngx-toastr';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { AddproductService } from '../pages/addproduct/addproduct.service';
import { AppService } from '../app.service';
declare var $: any;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-bulk-management',
  templateUrl: './bulk-management.component.html',
  styleUrls: ['./bulk-management.component.css']
})
export class BulkManagementComponent implements OnInit {
  private UploadedFile: any;
  private category:any;
  private Discount: any;
  private LoginUserID: any;

  constructor( private appservice:AppService, private buldmanageservice:BulkmanagementService,private toastr:ToastrService,private spinner:NgxSpinnerService,private addproductservices:AddproductService){}
  ngOnInit(): void {
this.getAllCategoryLevelList();
this.LoginUserID=this.appservice.getUserID();
  }

downloadRecord(){
  this.spinner.show()
  this.buldmanageservice.downloadReportInExcel().subscribe({
    next:(response)=>{
      if(response.message==='Success'){
          const byteCharacters = atob(response.result);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob,  'BulkProductFormat.xlsx');
          this.spinner.hide()

      }else{
        this.toastr.warning(response.message);
      }
      setTimeout(() => {
        this.spinner.hide();
      },300);
    }
  })
}
selectUploadedFile(event: any) {
  this.UploadedFile = event.target.files[0];
}

UploadProductDetail() {
  try {
    this.spinner.show();
      const files = this.UploadedFile;
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
          const arrayBuffer: any = event.target.result;
          const data = new Uint8Array(arrayBuffer);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          const bstr = arr.join('');
          const workbook = XLSX.read(bstr, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const ProductData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          let obj: any[] = [];

          ProductData.forEach(element => {
              const data: any = element;
              const itemCodeParts = String(data.ItemCode).split('|');
              const id = itemCodeParts.length > 1 ? parseInt(itemCodeParts[itemCodeParts.length-2], 10) : 0;
              const attributeId=itemCodeParts.length > 1? parseInt(itemCodeParts[itemCodeParts.length-1], 10):0;
              const itemCodePartsss = data.Discount?data.Discount.split('|'):null;
              this.Discount=itemCodePartsss==null?0:itemCodePartsss[itemCodePartsss.length - 1];
              const isOfflineProduct=data['Offline Product'];
              const isOnlineProduct=data['Online Product'];
              const [category,categoryLevel1,categoryLevel2]=this.getCategoryIdFromCategoryNames(data['Category/SubCategory/SubTwoCategory']);
            const myObject = {
              id: id,
              itemCode:String(itemCodeParts.length>0?itemCodeParts[0]:itemCodeParts),
              productName: data.ProductName,
              description: data.Description,
              minStock: data.MinStock,
              price: data.Price,
              loginUserID: this.LoginUserID,
              stock: data.Stock,
              discountID: parseInt(this.Discount),
              attributeID: attributeId,
              isOnlineProduct: isOnlineProduct,
              isOfflineProduct: isOfflineProduct,
              categoryID: category,
              categoryLevel1ID: categoryLevel1,
              categoryLevel2ID: categoryLevel2,
            };
              obj.push(myObject);
          });

          const bulkProductUploadBO = { obj };
          if (ProductData.length !== 0) {
              this.buldmanageservice
                .UploadProductDetail(bulkProductUploadBO)
                .subscribe(
                  (data) => {
                    if (data.message === 'Ok') {
                      Swal.fire({
                        icon: 'success',
                        title: '<h3>Success!</h3>',
                        text: 'Bulk product detail saved successfully.',
                      });
                      $('#bulkFile').val('');
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: '<h3>Error!</h3>',
                        text: 'Error saving product data.',
                      });
                    }
                    this.spinner.hide();
                  },
                  (error) => {
                    console.error('Error reading Excel file:', error);
                    this.spinner.hide();
                  }
                );
          } else {
              Swal.fire({
                  icon: 'warning',
                  title: '<h3>Warning!</h3>',
                  text: 'Add product manually.'
              });
              this.spinner.hide();
          }
      };

      fileReader.readAsArrayBuffer(files);
  } catch (e) {
      Swal.fire({
          icon: 'error',
          title: '<h3>Error!</h3>',
          text: 'The uploaded file appears to be empty.'
      });
      this.spinner.hide();
  }
}

getCategoryIdFromCategoryNames(category: string): [number, number, number] {
  const categories = category.split('/');
  const categoryId = this.getCategoryIdFromList(categories[0],1);
  const categoryLevel1Id = this.getCategoryIdFromList(categories[1],2);
  const categoryLevel2Id = this.getCategoryIdFromList(categories[2],3);
  return [categoryId, categoryLevel1Id, categoryLevel2Id];
}


getCategoryIdFromList(categoryName: string, level: number): number {
  let categoryId: number = 0;
  switch (level) {
    case 1:
      const category = this.category.categoryList.find(c => c.name.trim() === categoryName.trim());
      categoryId = category ? category.id : 0;
      break;

    case 2:
      const categoryLevel1 = this.category.categoryLevel1List.find(c => c.name.trim() === categoryName.trim());
      categoryId = categoryLevel1 ? categoryLevel1.id : 0;
      break;

    case 3:
      const categoryLevel2 = this.category.categoryLevel2List.find(c => c.name.trim() === categoryName.trim());
      categoryId = categoryLevel2 ? categoryLevel2.id : 0;
      break;

    default:
      categoryId = 0;
  }

  return categoryId;
}

getAllCategoryLevelList(){
  this.spinner.show();
  this.buldmanageservice.getAllCategoryLevelList().subscribe({
    next:(response:{message:string,result:{categoryList:any[],categoryLevel1List:any[],categoryLevel2List:any[]}})=>{
      if(response.message==='Success'){
        this.category=response.result;
      };
      setTimeout(() => {
        this.spinner.hide();
      }, 300);
    }
  })
}



}
