import { MenusComponent } from './../menus/menus.component';
import { Component, OnInit } from '@angular/core';
import { BulkmanagementService } from './bulkmanagement.service';
import { SeleniumServer } from 'selenium-webdriver/remote';
import { ToastrService } from 'ngx-toastr';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { AddproductService } from '../pages/addproduct/addproduct.service';
import { AppService } from '../app.service';
import { MastercolorService } from '../pages/mastercolor/mastercolor.service';
import { MastersizeService } from '../pages/mastersize/mastersize.service';
import { DiscountService } from '../pages/discount/discount.service';
declare var $: any;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-bulk-management',
  templateUrl: './bulk-management.component.html',
  styleUrls: ['./bulk-management.component.css']
})
export class BulkManagementComponent implements OnInit {
  private UploadedFile: any;
  private category: any;
  private Discount: any;
  private LoginUserID: any;
  private color: any;
  private size: any;
  private UploadedImages: any;
  private productNames: any;
  public isDisabled1: boolean = true;
  public isDisabled2: boolean = true;
  //sarthak
  validRows: any[] = [];
  validUpdatedRows: any[] = [];
  invalidRows: any[] = [];
  invalidUpdatedRows: any[] = [];
  colorList: any[] = [];
  sizelist: any[] = [];
  discountlist: any[] = [];
  itemCodeList: any[] = [];
  dropDownCategoryList: any[] = [];
  dropDownSubCategoryList: any[] = [];
  filters = {
    productCategoryID: null,
    subCategoryID: null,
    productType: 0,
  };
  private updatedExcelFile: any;

  constructor(private appservice: AppService, private buldmanageservice: BulkmanagementService, private toastr: ToastrService, private spinner: NgxSpinnerService, private addproductservices: AddproductService, private colorservice: MastercolorService,
    private sizeservice: MastersizeService,
    private discountservice: DiscountService,
    private productService: AddproductService) { }

  ngOnInit(): void {
    this.getAllCategoryLevelList();
    this.getmastercolor();
    this.getmasterSize();
    this.getdiscount();
    this.getAllItemBarCode();
    this.LoginUserID = this.appservice.getUserID();
    this.getAllProductNames();
  }
  getCategoryIdFromCategoryNames(category: string): [number, number, number] {
    const categories = category.split('/');
    const categoryId = this.getCategoryIdFromList(categories[0], 1);
    const categoryLevel1Id = this.getCategoryIdFromList(categories[1], 2);
    const categoryLevel2Id = this.getCategoryIdFromList(categories[2], 3);
    return [categoryId, categoryLevel1Id, categoryLevel2Id];
  }
  getCategoryIdFromList(categoryName: string, level: number): number {
    let categoryId: number = 0;
    switch (level) {
      case 1:
        const category = this.category.categoryList.find(c => c.name.trim() === categoryName.trim());
        categoryId = category ? category.id : 0;
        break;

      case 2:
        const categoryLevel1 = this.category.categoryLevel1List.find(c => c.name.trim() === categoryName.trim());
        categoryId = categoryLevel1 ? categoryLevel1.id : 0;
        break;

      case 3:
        const categoryLevel2 = this.category.categoryLevel2List.find(c => c.name.trim() === categoryName.trim());
        categoryId = categoryLevel2 ? categoryLevel2.id : 0;
        break;

      default:
        categoryId = 0;
    }

    return categoryId;
  }


  //Common Methods...
  getAllCategoryLevelList() {
    this.spinner.show();
    this.buldmanageservice.getAllCategoryLevelList().subscribe({
      next: (response: { message: string, result: { categoryList: any[], categoryLevel1List: any[], categoryLevel2List: any[] } }) => {
        if (response.message === 'Success') {
          this.category = response.result;

          this.dropDownCategoryList = this.category.categoryList;
        };
        setTimeout(() => {
          this.spinner.hide();
        }, 300);
      }
    })
  }
  isCategoryvalid(category: any, level: number): boolean {

    if (level == 1) {
      return this.category.categoryList.some(c => c.name.replace(/[^a-zA-Z0-9]/g, '').trim() === category.trim())
    } else if (level == 2) {
      if (category === null || category === '' || category === undefined) return true;
      return this.category.categoryLevel1List.some(c => c.name.replace(/[^a-zA-Z0-9]/g, '').trim() === category.trim())
    } else if (level == 3) {
      if (category === null || category === '' || category === undefined) return true;
      return this.category.categoryLevel2List.some(c => c.name.replace(/[^a-zA-Z0-9]/g, '').trim() === category.trim())
    }
  }
  getmastercolor() {
    this.colorservice.getMasterColorList(true).subscribe(data => {
      if (data.message == "Success") {
        this.colorList = data.result.filter(x => x.isActive == true)

      }
    })
  }
  getmasterSize() {
    this.sizeservice.getMasterSizeList(true).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.sizelist = response.result.filter(x => x.isActive);

        } else {
          console.error(response.message);
        }

      }
    });
  }
  getdiscount() {
    this.discountservice.getDiscountlist(true).subscribe(data => {
      if (data.message == "Success") {
        this.discountlist = data.result;

      }
    })
  }
  getAllItemBarCode() {
    this.addproductservices.GetAllItemBarCode().subscribe(data => {
      if (data.message == "Success") {
        this.itemCodeList = data.result;
      }
    })
  }
  getAllProductNames() {

    this.productService.getProductsName().subscribe(data => {
      if (data.message == "OK") {
        this.productNames = data.result;
        console.log('ProductNames',this.productNames)
      }
      else {
        console.error('ProductNames Error',data.message);
      }
    })
  }
  SarthakgetCategoryIdFromCategoryNames(category: string, level: number): number {
    if (level == 1) {
      const categoryId = this.SarthakgetCategoryIdFromList(category.trim()
        .replace(/^_+|_+$/g, '').replace(/[_\s]/g, ''), 1);
      return categoryId;
    }
    else if (level == 2) {
      const categoryLevel1Id = this.SarthakgetCategoryIdFromList(category.trim()
        .replace(/^_+|_+$/g, '').replace(/[_\s]/g, ''), 2);
      return categoryLevel1Id;
    }
    else if (level == 3) {
      const categoryLevel2Id = this.SarthakgetCategoryIdFromList(category.trim()
        .replace(/^_+|_+$/g, '').replace(/[_\s]/g, ''), 3);
      return categoryLevel2Id;
    }
  }
  SarthakgetCategoryIdFromList(categoryName: string, level: number): number {
    let categoryId: number = 0;
    switch (level) {
      case 1:
        const category = this.category.categoryList.find(c => c.name.replace(/[^a-zA-Z0-9]/g, '').trim() === categoryName.trim());
        categoryId = category ? category.id : 0;
        break;

      case 2:
        if (categoryName === null || categoryName === '' || categoryName === undefined) return 0;
        const categoryLevel1 = this.category.categoryLevel1List.find(c => c.name.replace(/[^a-zA-Z0-9]/g, '').trim() === categoryName.trim());
        categoryId = categoryLevel1 ? categoryLevel1.id : 0;
        break;

      case 3:
        if (categoryName === null || categoryName === '' || categoryName === undefined) return 0;
        const categoryLevel2 = this.category.categoryLevel2List.find(c => c.name.replace(/[^a-zA-Z0-9]/g, '').trim() === categoryName.trim());
        categoryId = categoryLevel2 ? categoryLevel2.id : 0;
        break;

      default:
        categoryId = 0;
    }

    return categoryId;
  }
  getFilters(event: any, type: string): void {
    switch (type) {
      case 'category':
        if (!event) {
          this.dropDownSubCategoryList = [];
          this.filters.productCategoryID = null;
          this.filters.subCategoryID = null;
        } else {
          this.filters.productCategoryID = event.id;
          this.filters.subCategoryID = null;
          const key = event.id;
          this.productService.GetSubCategoryList(key).subscribe({
            next: (response) => {
              this.dropDownSubCategoryList = response.message === "Success" ? response.result : [];
            },
            error: (err) => {
              this.toastr.error("Failed to fetch SubCategories.");
            }
          });
        }
        break;

      case 'subcategory':
        this.filters.subCategoryID = event?.id || null;
        break;

      case 'productType':
        this.filters.productType = event?.target?.value || 0;
        break;

      default:
        console.warn('Invalid filter type:', type);
        break;
    }
  }
  getTypeOfExcel(type: string) {
    if (type == 'update') {
      this.uploadExcelSheet('update');
    }
    else if (type == 'add') {
      this.uploadExcelSheet('add');
    }
    else {
      this.toastr.error("Invalid Excel");
    }
  }
  uploadExcelSheet(type: string) {
    this.spinner.show();

    let bulkProductUploadBO: any = null;
    if (type === 'update') {
      bulkProductUploadBO = { obj: this.validUpdatedRows };
    }
    else if (type === 'add') {
      bulkProductUploadBO = { obj: this.validRows };
    }
    if (bulkProductUploadBO && bulkProductUploadBO.obj.length > 0) {
      this.buldmanageservice.UploadSarthakDetail(bulkProductUploadBO).subscribe({
        next: (data) => {
          if (data.message === 'Ok') {
            Swal.fire({
              icon: 'success',
              title: '<h3>Success!</h3>',
              text: 'Bulk product detail saved successfully.',
            });
            (document.getElementById("bulkFile") as HTMLInputElement).value = "";
            (document.getElementById("bulkFile2") as HTMLInputElement).value = "";
            (document.getElementById("bulkImages") as HTMLInputElement).value = "";
          } else {
            Swal.fire({
              icon: 'error',
              title: '<h3>Error!</h3>',
              text: 'Error saving product data.',
            });
          }
        },
        error: (error) => {
          console.error('Error reading Excel file:', error);
          this.toastr.error("Failed to upload data");
        }, complete: () => {
          this.spinner.hide();
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: '<h3>Warning!</h3>',
        text: 'Add product manually.'
      });
      this.spinner.hide();
    }
  }

  //Updating Inventory...  

  //1)Download Excel Format.
  downloadRecord() {
    this.spinner.show()
    this.buldmanageservice.downloadReportInExcel(this.filters).subscribe({
      next: (response) => {
        if (response.message === 'Success' && response.result) {
          try {
            const byteCharacters = atob(response.result);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'BulkProductFormat.xlsx');

            Swal.fire({
              icon: 'success',
              title: 'Download Successful',
              html: `The Excel file has been downloaded successfully! Kindly read the instructions carefully before updating to avoid errors.<br><br>`,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            });
          } catch (error) {
            this.toastr.error('Failed to process the downloaded file. Please try again.');
          }
        }
        else {
          this.toastr.warning(response.message || 'Download failed. Please try again.');
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 300);
      }, error: (err) => {
        this.spinner.hide();
        this.toastr.error('An error occurred while downloading the file.');
      }
    });
  }

  //2)Select Excel File.
  selectUploadedFile(event: any) {
    const file = event.target.files?.[0];
    if (!file) {
      this.toastr.error("No file selected!");
      this.isDisabled1 = true;
      return;
    }
    const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"];
    if (!allowedTypes.includes(file.type)) {
      this.toastr.error("Invalid file format! Please upload an Excel file.");
      this.isDisabled1 = true;
      return;
    }
    this.updatedExcelFile = file;
    this.validateUpdateExcelRecords();
  }

  //Validating Excel Records.
  validateUpdateExcelRecords() {
    
    try {
      this.validUpdatedRows = [];
      const files = this.updatedExcelFile;
      const fileReader = new FileReader();
      const sheetName = 'Sheet1'

      fileReader.onload = async (event) => {
        const arrayBuffer: any = event.target.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });
        if (!workbook.SheetNames.includes(sheetName)) {
          this.toastr.error(`Sheet "${sheetName}" not found in the Excel file.`);
          return;
        }
        const worksheet = workbook.Sheets[sheetName];
        const ProductData = XLSX.utils.sheet_to_json(worksheet, { raw: true, defval: null });

        ProductData.forEach(async element => {
          const data: any = element;
          let errormessage: any[] = [];

          //Column Validations....
          //A)Category input check
          let categoryName = data['Category'].trim()
            .replace(/^_+|_+$/g, '').replace(/[^a-zA-Z0-9]/g, '');
          if (!this.isCategoryvalid(categoryName, 1)) {
            this.invalidUpdatedRows.push({ ...data, Error: `Invalid category at level 1:${categoryName}` });
            return;
          }
          //2)SubCategory input check
          let subcategoryName: any;
          if (data['SubCategory']?.trim()) {
            subcategoryName = data['SubCategory'].trim()
              .replace(/^_+|_+$/g, '').replace(/[^a-zA-Z0-9]/g, '');
            if (!this.isCategoryvalid(subcategoryName, 2)) {
              this.invalidUpdatedRows.push({ ...data, Error: `Invalid category at level 2:${subcategoryName}` });
              return;
            }
          }
          //3)Sub2Category input check
          let sub2categoryName: any;
          if (data['Sub2Category']?.trim()) {
            sub2categoryName = data['Sub2Category'].trim()
              .replace(/^_+|_+$/g, '').replace(/[^a-zA-Z0-9]/g, '');
            if (!this.isCategoryvalid(sub2categoryName, 3)) {
              this.invalidUpdatedRows.push({ ...data, Error: `Invalid category at level 3:${sub2categoryName}` });
              return;
            }
          }
          //4)numeric input check
          ['Price', 'ExistingStock', 'MinStock'].forEach((field) => {
            if (data[field] && isNaN(Number(data[field]))) {
              errormessage.push(field + ' must be a numeric value');
              this.invalidUpdatedRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          });
          //InQueue Check
          if (data["InQueue"] == null || data["InQueue"] == undefined || data["InQueue"] == '') {
            data["InQueue"] = false;
          }
          if (data['InQueue'] !== true && data['InQueue'] !== false) {
            errormessage.push("InQueue must be a TRUE or FALSE");
            this.invalidUpdatedRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }
          //5)ONLINE OFFLINE check
          ['Online Product', 'Offline Product'].forEach((field) => {
            if ((data[field] !== true && data[field] !== false)) {
              errormessage.push(field + ' must be a TRUE or FALSE');
              this.invalidUpdatedRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          });
          //6)DISCOUNT check
          if (data['Discount']) {
            const discountValue = data['Discount'].split('|')[0].trim();
            const isValidDiscount = this.discountlist.some(discount => discount.discountTitle.toLowerCase().trim() === discountValue.toLowerCase());
            if (!isValidDiscount) {
              errormessage.push("Enter valid Discount value.");
              this.invalidUpdatedRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          }
          if(data['NewStock']){
            const newStock = data['NewStock'];
            if(newStock<=0){
              errormessage.push("New stock should be greater than 0");
              this.invalidUpdatedRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          }

          const itemCodeParts = String(data.ItemCode).split('|');
          const id = itemCodeParts.length > 1 ? parseInt(itemCodeParts[itemCodeParts.length - 2], 10) : 0;
          const attributeId = itemCodeParts.length > 1 ? parseInt(itemCodeParts[itemCodeParts.length - 1], 10) : 0;
          const itemCodePartsss = data.Discount ? data.Discount.split('|') : null;
          this.Discount = itemCodePartsss == null ? 0 : itemCodePartsss[itemCodePartsss.length - 1];
          const isOfflineProduct = data['Offline Product'];
          const isOnlineProduct = data['Online Product'];
          const category = this.SarthakgetCategoryIdFromCategoryNames(categoryName, 1);
          if (subcategoryName !== null && subcategoryName !== undefined && subcategoryName !== '') {
            var categoryLevel1 = this.SarthakgetCategoryIdFromCategoryNames(subcategoryName, 2);
          }
          else {
            categoryLevel1 = 0;
          }
          if (sub2categoryName != null && sub2categoryName != undefined && sub2categoryName != '') {
            var categoryLevel2 = this.SarthakgetCategoryIdFromCategoryNames(sub2categoryName, 3);
          }
          else {
            categoryLevel2 = 0;
          }

          const myObject = {
            id: id,
            itemCode: String(itemCodeParts.length > 0 ? itemCodeParts[0] : itemCodeParts),
            productName: data.ProductName,
            description: data.Description,
            minStock: data.MinStock,
            price: data.Price,
            loginUserID: this.LoginUserID,
            stock: data.Stock,
            discountID: parseInt(this.Discount),
            attributeID: attributeId,
            isOnlineProduct: isOnlineProduct,
            isOfflineProduct: isOfflineProduct,
            categoryID: category,
            categoryLevel1ID: categoryLevel1,
            categoryLevel2ID: categoryLevel2,
            lot: {
              price: data.Price,
              quantity: data.NewStock,
              inQueue: data.InQueue,
              lotNumber:""
            }

          };
          //validRecords
          this.validUpdatedRows.push(myObject);
        });
        this.isDisabled1 = false;
      }

      fileReader.readAsArrayBuffer(files);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: '<h3>Error!</h3>',
        text: 'The uploaded file appears to be empty.'
      });
      this.spinner.hide();
    }
  }

  //Adding New Inventory...

  //1)Download Excel File...
  downloadSarthakFormat() {
    this.spinner.show();

    this.buldmanageservice.downloadSarthakFormat().subscribe({
      next: (response) => {
        if (response.message == "Success" && response.result) {
          try {
            const byteCharacters = atob(response.result);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'BulkUploadFormat.xlsx');

            Swal.fire({
              icon: 'success',
              title: 'Download Successful',
              text: 'The Excel file has been downloaded successfully! Kindly read the instructions carefully before starting to avoid errors.',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            });
          } catch (error) {
            this.toastr.error('Failed to process the downloaded file. Please try again.');
          }
        }
        else {
          this.toastr.warning(response.message || 'Download failed. Please try again.');
        }
      }, error: () => {
        this.toastr.error("An error occurred while downloading the file.");
      }, complete: () => {
        this.spinner.hide();
      }
    });
  }

  //2)Select Images.
  uploadImages(event: any) {
    
    if (!event.target.files || event.target.files.length === 0) {
      this.toastr.error("No files selected!");
      return;
    }
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file: any) =>
      allowedTypes.includes(file.type)
    );
    if (validFiles.length === 0) {
      this.toastr.error("Invalid file format! Only PNG, JPG, and JPEG are allowed.");
      event.target.value = '';
      return;
    }
    this.UploadedImages = validFiles;
    this.toastr.success(`${validFiles.length} image(s) selected successfully.`)
  }

  //3)Select Excel File.
  selectUploadedFile2(event: any) {
   
    const file = event.target.files?.[0];
    if (!file) {
      this.toastr.error("No file selected!");
      this.isDisabled2 = true;
      return;
    }
    const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"];
    if (!allowedTypes.includes(file.type)) {
      this.toastr.error("Invalid file format! Please upload an Excel file.");
      this.isDisabled2 = true;
      return;
    }
    if (!this.UploadedImages || this.UploadedImages.length === 0) {
      this.toastr.error("No images selected!");
      event.target.value = '';
      return;
    }
    this.UploadedFile = file;
    this.validateExcelRecords();
  }

  //a)Validating Excel Records.
  validateExcelRecords() {
    
    try {
      this.validRows = [];
      this.invalidRows = [];
      const files = this.UploadedFile;
      const fileReader = new FileReader();
      const sheetName = 'Sheet1'

      fileReader.onload = async (event) => {
        const arrayBuffer: any = event.target.result;
        const data = new Uint8Array(arrayBuffer);
        const arr = new Array();
        for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        const bstr = arr.join('');
        const workbook = XLSX.read(bstr, { type: 'binary' });

        if (!workbook.SheetNames.includes(sheetName)) {
          this.spinner.hide();
          this.toastr.error(`Sheet "${sheetName}" not found in the Excel file.`);
          return;
        }

        const worksheet = workbook.Sheets[sheetName];
        const ProductData = XLSX.utils.sheet_to_json(worksheet, { raw: true, defval: null });

        ProductData.forEach(async element => {
          const data: any = element;
          const requiredColumns = [
            'Category',
            'ItemCode',
            'ProductName',
            'Description',
            'Price',
            'MinStock',
            'Stock',
            'Online Product',
            'Offline Product',
            'smallDescription',
            'colour',
            'size',
            'Shipping Information',
            'Cover Image',
          ];

          let invalidColumns: any[] = [];
          let errormessage: any[] = [];

          //null columns check
          for (const column of requiredColumns) {
            if (data[column] === null || data[column] === undefined || data[column].toString().trim() === '') {
              invalidColumns.push(column);
            }
          }
          if (invalidColumns.length > 0) {
            errormessage.push(invalidColumns.join(', ') + ' is empty');
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }

          //Each Column VALIDATIONS...
          //1)Category input check
          const categoryName = data['Category'].trim()
            .replace(/^_+|_+$/g, '').replace(/[_\s]/g, '');
          if (!this.isCategoryvalid(categoryName, 1)) {
            this.invalidRows.push({ ...data, Error: `Invalid category at level 1:${categoryName}` });
            return;
          }

          //2)SubCategory input check
          if (data['SubCategory'] !== null && data['SubCategory'] !== undefined && data['SubCategory'] !== '') {
            const subcategoryName = data['SubCategory'].trim()
              .replace(/^_+|_+$/g, '').replace(/[_\s]/g, '');
            if (!this.isCategoryvalid(subcategoryName, 2)) {
              this.invalidRows.push({ ...data, Error: `Invalid category at level 2:${subcategoryName}` });
              return;
            }
          }

          //3)Sub2Category input check
          if (data['SubTwoCategory'] !== null && data['SubTwoCategory'] !== undefined && data['SubTwoCategory'] !== '') {
            const sub2categoryName = data['SubTwoCategory'].trim()
              .replace(/^_+|_+$/g, '').replace(/[_\s]/g, '');
            if (!this.isCategoryvalid(sub2categoryName, 3)) {
              this.invalidRows.push({ ...data, Error: `Invalid category at level 3:${sub2categoryName}` });
              return;
            }
          }

          //ItemCode Check
          const itemCode = data['ItemCode'].trim().replace(/\s+/g, '').toLowerCase();
          const isValidItemCode = this.itemCodeList.some(itemcode => itemcode.barCode.replace(/\s+/g, '').toLowerCase() === itemCode);
          if (isValidItemCode) {
            errormessage.push("ItemCode already exists.");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }
          //Product Name Check
          const ProductName = data['ProductName'].trim().replace(/\s+/g, '').toLowerCase();
          const isValidProductName = this.productNames.some(product => product.productName.trim().replace(/\s+/g, '').toLowerCase() === ProductName);
          if (isValidProductName) {
            errormessage.push("Product Name already exists.");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }

          //4)numeric input check
          ['Price', 'Stock', 'MinStock'].forEach((field) => {
            if (data[field] && isNaN(Number(data[field]))) {
              errormessage.push(field + ' must be a numeric value');
              this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          });

          //5)ONLINE OFFLINE check
          ['Online Product', 'Offline Product'].forEach((field) => {
            if ((data[field] !== true && data[field] !== false)) {
              errormessage.push(field + ' must be a TRUE or FALSE');
              this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          });
          if ((data['Online Product'] === false && data['Offline Product'] === false)) {
            errormessage.push("Both 'Online Product' and 'Offline Product' cannot be False.");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }

          //6)DISCOUNT check
          if (data['Discount']) {
            const discountValue = data['Discount'].split('|')[0].trim();
            const isValidDiscount = this.discountlist.some(discount => discount.discountTitle.toLowerCase().trim() === discountValue.toLowerCase());

            if (!isValidDiscount) {
              errormessage.push("Enter valid Discount value.");
              this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
          }


          //7)COLOUR check
          const colourValue = data['colour'].split('|')[0].trim();
          const isValidColour = this.colorList.some(colour => colour.name.toLowerCase() === colourValue.toLowerCase());
          if (!isValidColour) {
            errormessage.push("Colour value is not valid.");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }

          //8)SIZE check
          const sizeValue = data['size'].split('|')[0].trim();
          const isValidsize = this.sizelist.some(size => size.name.toLowerCase() === sizeValue.toLowerCase());
          if (!isValidsize) {
            errormessage.push("Size value is not valid.");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }

          //ShippingCharges
          const shippingCharges = data['Shipping Information'];
          if (typeof (shippingCharges) == 'number') {
            errormessage.push("Shipping Information cannot be only numeric value.");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }

          //9)Validate images
          const uploadedImagesSet = new Set(Array.from(this.UploadedImages as FileList).map(file => file.name.trim().replace(/\s+/g, '').toLowerCase()));
          const ProductImages: any[] = []

          //A)validate CoverImage
          const coverimageName = data['Cover Image'].trim().replace(/\s+/g, '').toLowerCase();
          if (!uploadedImagesSet.has(coverimageName)) {
            errormessage.push("Image " + coverimageName + " is missing or does not match");
            this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
            return;
          }
          else {
            ProductImages.push({ ImageName: coverimageName, isCover: true });
          }

          //B)validate Other Images  
          const otherimagesName = data['Other Images']; 
          if (otherimagesName) {
            const otherimagesList = otherimagesName.split(',').map((img: string) => img.trim().replace(/\s+/g, '').toLowerCase());
            const missingotherImages = otherimagesList.filter(imgName => !uploadedImagesSet.has(imgName));

            if (missingotherImages.length > 0) {
              this.spinner.hide();
              errormessage.push(`Other Images missing or not matching: ${missingotherImages.join(', ')}`);
              this.invalidRows.push({ ...data, Error: errormessage.join(', ') });
              return;
            }
            otherimagesList.forEach(imgName => {
              ProductImages.push({ ImageName: imgName, isCover: false });
            });
          }

          const formData = new FormData();
          ProductImages.forEach(img => {
            const file = Array.from(this.UploadedImages as FileList).find(f => f.name.trim().replace(/\s+/g, '').toLowerCase() === img.ImageName);
            if (file) {
              formData.append('files', file);
              formData.append('iscoverImage', img.isCover.toString());

            }
          });

          const itemCodeParts = String(data.ItemCode).split('|');
          const id = itemCodeParts.length > 1 ? parseInt(itemCodeParts[itemCodeParts.length - 2], 10) : 0;
          const attributeId = itemCodeParts.length > 1 ? parseInt(itemCodeParts[itemCodeParts.length - 1], 10) : 0;

          const itemCodePartsss = data.Discount ? data.Discount.split('|') : null;
          this.Discount = itemCodePartsss == null ? 0 : itemCodePartsss[itemCodePartsss.length - 1];
          const colorcode = data.colour ? data.colour.split('|') : null;
          this.color = colorcode == null ? 0 : colorcode[colorcode.length - 1];
          const sizecode = data.size ? data.size.split('|') : null;
          this.size = sizecode == null ? 0 : sizecode[sizecode.length - 1];
          const isOfflineProduct = data['Offline Product'];
          const isOnlineProduct = data['Online Product'];
          const additionalinformation = data['Shipping Information'];
          const category = this.SarthakgetCategoryIdFromCategoryNames(data['Category'], 1);

          const subCategory = data['SubCategory'];
          if (subCategory !== null && subCategory !== undefined && subCategory !== '') {
            var categoryLevel1 = this.SarthakgetCategoryIdFromCategoryNames(subCategory, 2);
          }
          else {
            categoryLevel1 = 0;
          }

          const subtwoCategory = data['SubTwoCategory'];
          if (subtwoCategory != null && subtwoCategory != undefined && subtwoCategory != '') {
            var categoryLevel2 = this.SarthakgetCategoryIdFromCategoryNames(subtwoCategory, 2);
          }
          else {
            categoryLevel2 = 0;
          }

          const myObject = {
            id: id,
            itemCode: String(itemCodeParts.length > 0 ? itemCodeParts[0] : itemCodeParts),
            productName: data.ProductName,
            description: data.Description,
            minStock: data.MinStock,
            price: data.Price,
            loginUserID: this.LoginUserID,
            stock: data.Stock,
            discountID: parseInt(this.Discount),
            colourID: parseInt(this.color),
            sizeID: parseInt(this.size),
            attributeID: attributeId,
            smalldescription: data.smallDescription,
            isOnlineProduct: isOnlineProduct,
            isOfflineProduct: isOfflineProduct,
            categoryID: category,
            categoryLevel1ID: categoryLevel1,
            categoryLevel2ID: categoryLevel2,
            additionalInformation: additionalinformation,
            lot: {
              price: data.Price,
              quantity: data.Stock,
              inQueue: false,
              lotNumber:""
            }
          };

          //uplaoding images on the server
          try {
            const fileResponse = await this.uploadProductImages(formData);
            if (fileResponse.message === 'Success') {
              myObject['productImages'] = fileResponse.result;
            }
          } catch (error) {
            console.error('Error during file upload:', error);
          }

          //validRecords        
          this.validRows.push(myObject);
        });

        //invalidRecordsSheet
        if (this.invalidRows.length > 0) {
          Swal.fire({
            icon: 'warning',
            title: '<h3>Invalid Records Detected</h3>',
            html: `Some invalid records were found in your file. A sheet with details of the errors has been downloaded for your reference.<br><br>You can proceed with uploading the valid records now by clicking <b>Submit</b>.`,
          });
          // console.log('InvalidRows',this.invalidRows)
          this.invalidRecordsSheet(this.invalidRows);
        }
      }
      this.isDisabled2 = false;
      fileReader.readAsArrayBuffer(files);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: '<h3>Error!</h3>',
        text: 'The uploaded file appears to be empty.'

      });
      this.spinner.hide();
    }
  }
  //b)Uploading Images.
  async uploadProductImages(fileData: FormData): Promise<any> {
    try {
      const response = await this.productService.UploadProductImages(fileData).toPromise();
      return response;
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  }
  //c)Invalid Records Sheet.
  invalidRecordsSheet(invalidRecords: any[]) {
    // console.log("HI",invalidRecords)
    this.buldmanageservice.getInvalidRecordsSheet(invalidRecords).subscribe((response => {
      if (response.message === "Success") {
        const byteCharacters = atob(response.result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'InvalidRecords.xlsx');
      }
    }))
  }
}
