<aside class="main-sidebar">
  <section class="sidebar">
    <div class="user-panel">
      <div class="pull-left image">
        <img src="assets/images/favicon.png" class="img-circle my-prof" alt="User Image">
      </div>
      <div class="pull-left info">
        <p>{{UserName}}</p>
        <a><i class="fa fa-circle text-success"></i> Online</a>
      </div>
    </div>

    <!-- dynamic menus start -->

    <ul class="sidebar-menu" >
    
      <li class="treeview" *ngFor="let item of BindUser" [id]="'menu'+item.id">
        <a *ngIf="item.isSelected"  (click)="item.isMaster? goto(item.menuUrl,null,''):null" >
          <i  class="{{item.icon}}"></i> <span>{{item.value}}</span>
          <span class="pull-right-container">
            <i class=" pull-left"></i>
          </span>
        </a>


        <ul class="treeview-menu" *ngIf="item.childList" >
          <li *ngFor="let itemChild of item.childList"  (click)="goto(itemChild.menuUrl,itemChild.type,'')" >
            
            <a *ngIf="itemChild.isSelected"  (click)="removeSidebarOpenClass()"  ><i class="fa fa-circle-o"></i>{{itemChild.value}}</a></li> 
        </ul>
        
      </li>
      </ul>


      <!-- end -->
  </section>
  <!-- /.sidebar -->
</aside>
