<section class="content-header">
    <h1> Edit User</h1>
    
  </section>
<section class="content">
    <div class="row">
        <div class="col-md-12  margin-left-np">
            <div class="box box-danger">
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-12">
                        <form [formGroup]="UserForm">
                            <div class="row">

                           
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">Name<span class="text-danger">*</span></label>
                                <input  formControlName="firstName" type="text" name="discountPercentage"
                                    placeholder="Enter Name" class="form-control ">
                                <span *ngIf="GetUserFormcontrols.firstName.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.firstName?.errors?.required">This Field Is
                                        Required</span>
                                </span>
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">Phone No.<span class="text-danger">*</span></label>
                                <input formControlName="mobile" (keypress)="keyPressNumbers1($event)" maxlength="10"
                                    type="text" name="discountPercentage" placeholder="Enter Phone No."
                                    class="form-control ">
                                <span *ngIf="GetUserFormcontrols.mobile.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.mobile?.errors?.required">This Field Is
                                        Required</span>
                                    <span *ngIf="GetUserFormcontrols.mobile?.errors?.minlength">Number Must Be At Least
                                        10
                                        Digits
                                        Long.</span>
                                </span>
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">Email<span class="text-danger">*</span></label>
                                <input  formControlName="email" type="text" name="discountPercentage"
                                    placeholder="Enter Email" class="form-control ">
                                <span *ngIf="GetUserFormcontrols.email.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.email?.errors?.required">This Field Is
                                        Required</span>
                                    <span *ngIf="GetUserFormcontrols.email?.errors?.pattern">Please Enter Valid
                                        Email</span>
                                </span>
                            </div>
                       
                       
        
                        <div class="col-md-4 mb-2">
                            <label for="exampleInputEmail1">ACN Number</label>
                            <input formControlName="acnNumber" type="text" name="discountPercentage" placeholder="Enter ACN Number"
                                class="form-control " maxlength="9">
                                <span *ngIf="GetUserFormcontrols.acnNumber.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.acnNumber?.errors?.pattern"> Invalid ACN. It must be a 9-digit number.</span>
                                </span>
                        </div>
                        <div class="col-md-4 mb-2">
                            <label for="exampleInputEmail1">ABN Number</label>
                            <input formControlName="abnNumber" type="text" name="discountPercentage" placeholder="Enter ABN Number"
                                class="form-control " maxlength="11">
                                <span *ngIf="GetUserFormcontrols.abnNumber.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.abnNumber?.errors?.pattern"> Invalid ABN. It must be a 11-digit number.</span>
                                </span>
                        </div>
                    </div>
                    </form>
                </div>
                    </div>
        
        
                    <div class="row">
                        <div class="col-md-12">
                            <fieldset>
                                <legend>Billing Address</legend>
                                <form [formGroup]="AddressForm1" style="    padding: 10px 3px;">
                                    <div class="row">
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select Country <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="country" placeholder="Select Country"
                                                [items]="CountryDropdownList" bindValue="id" bindLabel="name"
                                                (change)="OnChangeCounry($event?.id,'billing-address')"></ng-select>
                                            <span *ngIf="GetAddress1Formcontrols.country.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.country?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
                                        </div>
        
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select State <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="state" placeholder="Select State"
                                                [items]="StateDropdownList1" bindValue="id" bindLabel="name"
                                                (change)="OnChangeState($event?.id,'billing-address')"></ng-select>
                                            <span *ngIf="GetAddress1Formcontrols.state.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.state?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>
        
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select City <span
                                                    class="text-danger">*</span></label>
                                            <ng-select (change)="onChangeUserFields($event.id,'city')" formControlName="city" placeholder="Select City"
                                                [items]="CityDropdownList1" bindValue="id" bindLabel="name"></ng-select>
                                            <span *ngIf="GetAddress1Formcontrols.city.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.city?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>
        
        
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Pincode <span
                                                    class="text-danger">*</span></label>
                                            <input (keypress)="keyPressNumbers1($event)" (keyup)="onChangeUserFields($event.target.value,'pincode')" formControlName="pinCode" type="text" name="discountPercentage"
                                                placeholder="Enter Pincode" class="form-control " maxlength="4">
                                            <span *ngIf="GetAddress1Formcontrols.pinCode.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.pinCode?.errors?.required">This
                                                    Field Is Required</span>
                                                <span *ngIf="GetAddress1Formcontrols.pinCode?.errors?.pattern">Invalid pincode it
                                                    Must Be At Least 4 Digits Long.</span>
                                            </span>
                                        </div>
                                        <div class="col-md-12 mb-2">
                                            <label for="exampleInputEmail1"> Address <span
                                                    class="text-danger">*</span></label>
                                            <textarea  (keyup)="onChangeUserFields($event.target.value,'address')" formControlName="address1" class="form-control"
                                                id="exampleFormControlTextarea1" rows="2"
                                                placeholder="Enter Address"></textarea>
                                            <span *ngIf="GetAddress1Formcontrols.address1.touched" style="color: red;"
                                                class="my-fields">
                                                <span *ngIf="GetAddress1Formcontrols.address1?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
                                        </div>
        
                                    </div>
                                </form>
        
                            </fieldset>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <fieldset>
                                <legend>Delivery Address <span class="deli">(<input
                                            (change)="CommonAddress($event.target.checked)" type="checkbox"
                                            id="common-address" name="isActive" class="m-0 ">
                                        <i class="italic"  style="font-size: 18px;">Same as Billing Address</i>)</span></legend>
                                <form [formGroup]="AddressForm2" style="    padding: 10px 3px;">
                                    <div class="row">
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select Country <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="country" placeholder="Select Country"
                                                [items]="CountryDropdownList" bindValue="id" bindLabel="name"
                                                (change)="OnChangeCounry($event?.id,'shipping-address')"></ng-select>
                                            <span *ngIf="GetAddress2Formcontrols.country.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.country?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
        
                                        </div>
        
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select State <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="state" placeholder="Select State"
                                                [items]="StateDropdownList2" bindValue="id" bindLabel="name"
                                                (change)="OnChangeState($event?.id,'shipping-address')"></ng-select>
                                            <span *ngIf="GetAddress2Formcontrols.state.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.state?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>
        
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select City <span
                                                    class="text-danger">*</span></label>
                                            <ng-select  formControlName="city" placeholder="Select City"
                                                [items]="CityDropdownList2" bindValue="id" bindLabel="name"></ng-select>
                                            <span *ngIf="GetAddress2Formcontrols.city.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.city?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>
        
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Pincode <span
                                                    class="text-danger">*</span></label>
                                            <input formControlName="pinCode" (keypress)="keyPressNumbers1($event)" type="text" name="discountPercentage"
                                                placeholder="Enter Pincode" class="form-control " maxlength="4">
                                            <span *ngIf="GetAddress2Formcontrols.pinCode.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.pinCode?.errors?.required">This
                                                    Field Is Required</span>
                                                <span *ngIf="GetAddress2Formcontrols.pinCode?.errors?.pattern">Invalid pincode it
                                                    Must Be At Least 4 Digits Long.</span>
                                            </span>
                                        </div>
                                        <div class="col-md-12 mb-2">
                                            <label for="exampleInputEmail1"> Address <span class="text-danger">*</span>
                                            </label>
                                            <textarea formControlName="address1" class="form-control"
                                                id="exampleFormControlTextarea2" rows="2"
                                                placeholder="Enter  Address"></textarea>
                                            <span *ngIf="GetAddress2Formcontrols.address1.touched" style="color: red;"
                                                class="my-fields">
                                                <span *ngIf="GetAddress2Formcontrols.address1?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
                                        </div>
        
                                    </div>
                                </form>
        
                            </fieldset>
                        </div>
                    </div>
              
              
                    <div class="attrib-button">
                        <div class="col-md-12 mt-2" style="
                        display: flex;
                        justify-content: end;
                        padding-right: 35px;
                    ">
                            <button (click)="ManageUserDetails()" class="btn btn-success marginright15" type="submit"
                                id="btnsubmit">
                                Update
                            </button>
                            <button (click)="resetMasterForm()" class="btn btn-secondary ">
                                Cancel
                            </button>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
   
        
       
           
        
   



