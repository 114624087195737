import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ContentmanagementService } from './contentmanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, FormControl, ReactiveFormsModule, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { eventNames } from 'process';




declare var $: any;
@Component({
  selector: 'app-contentmanagement',
  templateUrl: './contentmanagement.component.html',
  styleUrls: ['./contentmanagement.component.css']
})
export class ContentmanagementComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
  };
  private Active:any=true;
  readonly ImageRootURL = environment.baseUrl.slice(0, -3);
  form: any;
  previewUrl = [];
  public selectedFile1: any;
  menuType: string;
  private navigationSubscription: any;
  constructor(private router: Router, private route: ActivatedRoute, fb: UntypedFormBuilder, private toastr: ToastrService, public ContentmanagementService: ContentmanagementService, private spinner: NgxSpinnerService) {
    this.form = fb.group({
      'ID': [0],
      'Section': ['', Validators.required],
      'Title1': ['', Validators.required],
      'Title2': [''],
      'Title3': [''],
      'Description': ['', Validators.required],
      'Image': [''],
      'Type': [''],
      'Author': ['']
    });


  }

  public listHomePageSection: Array<string> = [];
  public listHomePageSectionandType: Array<string> = [];
  ngOnInit(): void {
    let param;
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
         param = this.route.snapshot.queryParamMap.get('Type');
        if (param) {
          this.menuType=param;
          this.toggleSection(param);
          this.getHomePageSection(param);
        }
      }
    });

    param =this.route.snapshot.queryParamMap.get('Type');
    if (param) {
      this.menuType=param;
      this.toggleSection(param);
      this.getHomePageSection(param);
    }

  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

  }


  public onReady(editor: DecoupledEditor): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(
      editor.ui.view.toolbar.element!,
      element
    );
  }

  Filter(key:any,type:string){
    if(type==='ACTIVE-INACTIVE'){
      this.Active=key;
    };
    this.getHomePageSection(this.menuType);
  }


  toggleSection(menuType: any) {
    if (menuType !== 'FAQ') {
      $('#Section option:nth-child(n+4)').addClass('hide');
    }else{
      $('#Section option:nth-child(n+4)').removeClass('hide');
    }
  }
  getHomePageSection(type) {
    this.spinner.show();
    const uniqueSections = {};
    this.ContentmanagementService.GetHomePageSection(type).subscribe(data => {
      if (data.message === 'OK') {
        this.listHomePageSection = data.result.filter((item) => {
          if (!uniqueSections[item.section]) {
            uniqueSections[item.section] = true;
            return true;
          }
          return false;
        });
        this.getSectionListBySectionAndType();
      } else {
        this.listHomePageSection = [];
        this.listHomePageSectionandType = [];
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 200);

    });

  }

  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg') {
      const image = new Image();
      image.src = URL.createObjectURL(event.target.files[0]);
      image.onload = () => {
        // if (image.width >= 900 && image.height >= 500) {
        this.selectedFile1 = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event: any) => {
          this.previewUrl = _event.target.result;
        };
        // }
        // else{
        //   $('#Image').val('');
        //   this.toastr.warning('','Image dimensions should be exactly 900x500 pixels.')
        // }

      }

    } else {
      alert('Upload only image file'); event.srcElement.value = null;
    }
  }

  getSectionListBySectionAndType() {
    this.spinner.show();
    setTimeout(() => {
      let dropdowntext = $('#SectionT option:selected').text();
      let Type2 = 'Admin';
      this.ContentmanagementService.GetSectionListBySectionAndTypeName(this.menuType, dropdowntext, Type2,this.Active).subscribe(data => {
        if (data.message === 'OK') {
          this.listHomePageSectionandType = data.result;
        }else{
          this.listHomePageSectionandType=[];
        }
      });
      setTimeout(() => {
        this.spinner.hide();
      }, 200);

    }, 300);

  }



  onSubmit() {
    debugger;
    //AddContentManagementImage
    const formData = new FormData();
    console.log("data1", this.menuType)
    if (this.form.valid) {
      this.spinner.show();
      formData.append('Type', 'Home')
      formData.append('loginUserID', '0');
      if (this.selectedFile1 != null) {
        formData.append('Files', this.selectedFile1);
      }
      if (formData.has('Files')) {
        this.ContentmanagementService.AddHomePageImages(formData).subscribe(data => {
          if (data.message == "ok") {
            this.form.patchValue({
              Image: data.result[0].path + data.result[0].filename
            })
          }
        })
      }
      //AddContentManagement
      this.form.patchValue({
        'ID': this.form.value.ID != null ? this.form.value.ID : 0,
        'Type': this.menuType
      });

      setTimeout(() => {
        this.ContentmanagementService.manageContent(this.form.value).subscribe(data => {
          if (data.message == "OK") {
            this.spinner.hide();
            $('#Image').val('');
            if (this.form.value.ID > 0) {
              this.toastr.success('', "Data has been Updated Successfully");
              document.getElementById('btnsubmit').innerHTML = 'Submit';
            }
            else {
              this.toastr.success('', "Data has been Added Successfully");
            }
            window.scroll(0, 0);
            this.form.reset();
            this.getHomePageSection(this.menuType);
            this.getSectionListBySectionAndType();

            $('#btnsubmit').innerHTML = "Submit";
          } else { this.toastr.error(data.Message); }
        });

      }, 1000);

    }
    else {
      this.form.markAllAsTouched();
      this.spinner.hide();
    }
  }




  onEdit(footer: any) {
    debugger;
    // this.dropdownvalue = $('#Section option:selected').val();
    this.spinner.show();
    this.form.patchValue({
      Title1: footer.title1,
      Title2: footer.title2,
      Title3: footer.title3,
      Section: footer.section,
      Description: footer.description,
      ID: footer.id,
      Author: footer.author,
      Image: ''
    });
    document.getElementById('btnsubmit').innerHTML = 'Update';
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
    window.scroll(0, 1000);
  }


  CancelClick() {
    this.form.reset();
    window.scroll(0, 0);
  }





  ActiveInactive(ContentID: any, IsActive: any) {
    debugger

    var action = 'Activated';
    // ******SweetAlert************
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate this content?" : "Are you sure you want to activate this content?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084 ',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (IsActive === true) {
          action = 'Deactivated';
        }
        this.ContentmanagementService.ActiveInactiveContent(ContentID).subscribe(res => {
          this.getHomePageSection(this.menuType);
          this.toastr.success('Menu ' + action + ' successfully');
          setTimeout(() => {
            this.spinner.hide();
          }
          , 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        debugger
        function check() {
          $('#checkBoxAinA' + ContentID).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + ContentID).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })
  }


}





