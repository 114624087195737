<section class="content-header sub-head ">
  <h1>
    Product List

  </h1>

</section>
<section class="content sub-cat">
  <div class="box product-box">
    <div class="box-header  ">
      <div class="" style="width: 100%;">
        <div class="row">
          <div class="col-xxl-2 col-md-4 mt-2 mb-2">
            <form class="d-flex my-search p-0 " method="post">
              <input id="SearchID" #val  class="form-control me-2" type="search" placeholder="Search" aria-label="Search"
                (change)="Filter(val.value,'BY-SEARCH')">
              <button class="btn btn-outline-success" type="submit"><i class="fa fa-search"
                  (click)="Filter(val.value,'BY-SEARCH')"></i></button>
            </form>
          </div>
    
          <div class="col-md-8 col-xxl-5  ana ">
            <div class="col-md-6 mt-2 gd" style="padding-left: 0px;">
              <ng-select placeholder="Select Category" (change)="Filter($event?.id,'CATEGORY')" [items]="CategoryList"
                bindLabel="categoryName" bindValue="id"></ng-select>
            </div>
      
                <div class="col-md-6 mt-2 gd" style="padding-right: 0px;">
                  <ng-select placeholder="Select Subcategory Level1" id="subcategory"
                (change)="Filter($event?.id,'SUBCATEGORYLEVEL1')" [items]="SubCategoryLevel1List" bindLabel="subCategoryName"
                bindValue="id"></ng-select>
                </div>
          </div>
    
    
          <div class="col-xxl-5 col-md-12 text-right mt-2 mb-2  r-button">
          
            <select  (change)="Filter($event.target.value,'BY-ONLINE-OFFLINE')" class="btn btn-primary custom-button  period margin-responsive">
              <option selected [value]="0">Select</option>
              <option [value]="1" >Online&nbsp;products</option>
              <option [value]="2" >Offline&nbsp;products</option>
            </select>


            <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')"
              class="btn btn-primary ml-2 custom-button  period margin-responsive" name="cars" id="period">
              <option selected value="true">Active&nbsp;List</option>
              <option value="false">Deactive&nbsp;List</option>
              <option value="">All</option>
          
            </select>
    
    
    
    
    <button class="btn btn-primary custom-button custom-button-margin ml-2 margin-responsive" routerLink="/manageProduct"
      aria-label="Product details">
      <i class="fa fa-plus"></i> &nbsp;&nbsp;Add New Product
    </button>
    </div>
    </div>
    </div>
     



    </div>
    <!-- /.box-header -->

    <div class="box-body">
      <div class="col-md-12" style="margin-top: 12px;">
        <div class="table-responsive">



          <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="text-align: center;">S.NO</th>
                <th _ngcontent-ork-c17="" style="text-align: center;width: 290px;">Product&nbsp;Image</th>
                <th style="min-width: 240px;text-align: center;">Product&nbsp;Details</th>



                <th style="text-align: center;">Description</th>
                <th style="text-align: center;">New&nbsp;Arrival&nbsp;Product</th>

                <th style="text-align: center;">Featured&nbsp;Product</th>
                <th style="text-align: center;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of listproductdetail|search:this.searchString|paginate:paginationConfig; let i=index">

                <td style="text-align: center;">{{((paginationConfig.currentPage-1)*paginationConfig.itemsPerPage)+i+1}}</td>
                <td>
                  <div class="productimage" style="text-align: center;">


                    <img src="{{rootUrl}}{{item.productImage}}" alt="" width="150" />

                    <div class="product-detail-new">
                      <p class="product-idea"> {{item.productName}} </p>
                    </div>
                    <div class="product-detail-new">
                      <p class="item-code item-code{{item.id}}">Bar Code:-&nbsp;{{item.itemBarCode}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="productdetail">

                    <div>
                      <p><strong>Category:</strong><span>{{item.categoryName}}</span></p>
                      <p><strong>Category&nbsp;Level&nbsp;1:</strong><span>{{item.subcategoryLevel1}}</span></p>
                      <p><strong>Category&nbsp;Level&nbsp;2:</strong><span>{{item.subcategoryLevel2}}</span></p>
                      <p><strong>Available:</strong>
                        <span>
                          <ng-container *ngIf="item.isOfflineProduct || item.isOnlineProduct">
                            <ng-container *ngIf="item.isOfflineProduct">Offline</ng-container>
                            <ng-container *ngIf="item.isOfflineProduct && item.isOnlineProduct">, </ng-container>
                            <ng-container *ngIf="item.isOnlineProduct">Online</ng-container>
                          </ng-container>
                        </span>
                      </p>
                      <p><strong>Colour:</strong>
                        <span *ngFor="let color of item.colorList" (click)="selectColor(color.colorID, item)">
                          <span class="color-product" [id]="'Variation-'+item.id+'-'+color.colorID"
                            [ngStyle]="{'background-color': color.colorCode}"
                            >
                          </span>
                        </span>
                      </p>
                      <p><strong>Size:</strong> {{item.sizeList}}</p>

                    </div>
                  </div>
                </td>


                <td class="descrip-product">
                  <div [innerHTML]="item.description"></div>
                </td>

                <td class="descrip-product new-desc">
                  <button class="btn btn-icon m-1 p-1 mr-2">
                    <input [disabled]="!item.isActive || !item.isOnlineProduct" type="checkbox"
                      id="IsNewArrival{{item.id}}" name="isActive" class="marginright10 "
                      (change)="AddNewArrivalProduct(item.id,item.isNewArrival)" [checked]="item.isNewArrival"
                      [attr.data-toggle]="item.isOnlineProduct ? null : 'tooltip'"
                      title="{{ item.isOnlineProduct ? '' : 'Offline-Product' }}">
                  </button>
                </td>

                <td class="descrip-product new-desc">
                  <button class="btn btn-icon m-1 p-1 mr-2">
                    <input [disabled]="!item.isActive || !item.isOnlineProduct" type="checkbox"
                      id="checkBoxAinA{{item.id}}" name="isFeatured" class="marginright10 "
                      (change)="AddProductInFeatured(item.id,item.isFeatured)" [checked]="item.isFeatured"
                      [attr.data-toggle]="item.isOnlineProduct ? null : 'tooltip'"
                      title="{{ item.isOnlineProduct ? '' : 'Offline-Product' }}">
                  </button>
                </td>




                <td style="text-align: center;">
                  <div class="d-flex justify-content-center">
                    <button class="btn btn-icon m-1 p-1 mr-2">
                      <input type="checkbox" id="ActiveInactive{{item.id}}" name="isActive" class="marginright10 "
                        (change)="ActivedeactiveProduct(item.id,item.isActive)" [checked]="item.isActive">
                    </button>

                    <button (click)="onEdit(item)" class="btn btn-icon m-1 p-1 mr-1" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>
          <div class="" *ngIf="listproductdetail.length>0;else elseblock;">

          </div>


        </div>


        <div class="row mt-3" *ngIf="paginationConfig.totalItems>paginationConfig.itemsPerPage">
          <div class="col-md-3 ">
          </div>
          <div class="col-md-9 text-right">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
            </pagination-controls>

          </div>


        </div>


      </div>
    </div>
  </div>
</section>


<ng-template #elseblock>
  <div class="no-data">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <h4>No Data Found</h4>
  </div>
</ng-template>