<section class="content-header sub-head d-flex justify-content-between">
    <h1>
      Order #{{order?.itemID}}
    </h1>
    <a routerLink="/return-orders" class="btn btn-primary float-right"><i class="fa fa-backward back-button"></i>Back</a>
  </section>
  <div class="row "> <!-- left column -->
    <div class="col-md-12">
      <div class="box box-danger">

        <div class="box-body">

          <div class="row nomargin">

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 ">
                  <div class=" customerdetailmain">
                    <div class="col-md-12  nopadding">
                      <div class="box-header with-border">
                        <h3 class="box-title">Customer Details</h3>
                      </div>
                    </div>
                    <div class="col-md-12">

                      <div class="row">
                        <div class="col-md-12  ">
                          <div class="bilingbox">




                            <div class="billingcontent">
                              <p>Name: {{CustomerDetails?.firstName}}&nbsp;{{CustomerDetails?.lastName}}</p>
                              <p>Email:<span> {{CustomerDetails?.email}}</span></p>
                              <p>Phone-no:&nbsp;<span>{{CustomerDetails?.phoneNumber}}</span></p>

                            </div>

                          </div>
                        </div>

                      </div>

                    </div>


                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class=" customerdetailmain">
                    <div class="col-md-12  nopadding">
                      <div class="box-header with-border">
                        <h3 class="box-title">Billing Address</h3>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12  ">
                          <div class="bilingbox">




                            <div class="billingcontent">
                              <p>{{billingAddress?.address1}}</p>
                              <p>{{billingAddress?.country}} <span>, {{billingAddress?.state}}</span></p>
                              <p>{{billingAddress?.city}}&nbsp;<span>,{{billingAddress?.pinCode}}</span></p>

                            </div>

                          </div>
                        </div>

                      </div>

                    </div>


                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class=" customerdetailmain">
                    <div class="col-md-12  nopadding">
                      <div class="box-header with-border">
                        <h3 class="box-title">Delivery Address</h3>
                      </div>
                    </div>
                    <div class="col-md-12">


                      <div class="row">

                        <div class="col-md-12 ">
                          <div class="bilingbox">



                           
                            <div class="billingcontent">
                              <p>{{deliveryAddress?.address1}}</p>
                              <p>{{deliveryAddress?.country}} <span>, {{deliveryAddress?.state}}</span></p>
                              <p>{{deliveryAddress?.city}}&nbsp;<span>,{{deliveryAddress?.pinCode}}</span></p>

                            </div>

                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>





          </div>
        </div>
      </div>
    </div>
  </div>


  <section class="content-header sub-head ">
    <h1>
     Return  Item Details
    </h1>
  </section> <div class="box">
    <div class="box-body">
      <div class="row nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table class="table table-striped table-bordered nomargin">

              <thead>
                <tr>
                
                  <th>Delivered&nbsp;Item</th>
                  <th>Product Details</th>
                
                  
                  <th>Return&nbsp;Reason</th>
                  <th>Other Reason</th>
                  <th>Pickup Schedule</th>
                   
                  <th> Return&nbsp;Status </th>
                


                </tr>
              </thead>
              <tbody>

                <tr>
                        <td style=" width:200px;">
                    <div class="image-new">
                      <img src="{{rootUrl}}{{order?.returnImages}}" alt="Product Image">
                    </div>
                  </td>
                  <td>  <div class="report-text">
                    <span> <b>{{order.productName}}</b> </span>
                    <span> Color : {{order.color}}</span>
                    <span> Size : {{order.size}}</span>
                  </div></td>
                 
                 
                  <td>{{order?.reason}}</td>
                  <td><p>{{order?.otherReason}}</p></td>


                  <td>
                      <strong >{{ order?.pickupDate|date:'yyyy-MM-dd'}}</strong>
                      <strong >{{ order?.pickUpSchedule }}</strong>
                  </td>

                  
                  <td >
                    <select class="form-control" (change)="SelectStatus(orders?.id,$event.target.value)">
                      <option value="" disabled selected>Select</option>
                      <option *ngFor="let menu of orders?.cancelOrReturn" [value]="menu?.id" [selected]="menu.id === order?.statusID">
                        {{ menu.statusName.trim() }}
                      </option>
                    </select>

                  
                  </td>
                  </tr>
                  
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
