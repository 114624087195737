<section class="content-header sub-head ">
  <h1>
    Custom Orders
  </h1>

</section>
<section class="content sub-cat">

  <div class="row">

    <div class="col-md-12">
      <div class="box box-danger ">




        <div class="radioboxmain beforeafternone">
          <div class="box-header">

            <div class="col-md-6">
              <form class="d-flex my-search" method="post">
                <input id="SearchID" class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-success" type="submit"><i class="fa fa-search"></i></button>
              </form>
            </div>

            <div class="col-md-6 text-right">
              <button class="btn btn-primary custom-button custom-button-margin" routerLink="/manageProduct"
                aria-label="Product details">
                <i class="fa fa-plus"></i> &nbsp;&nbsp;Add New Product
              </button>
            </div>




          </div>
        </div>









        <div class="box-body nomargin" *ngIf="OrderList.length>0; else elseBlock; let i=index">
          <div class="row nomargin">
            <div class="col-md-12" style="margin-top: 12px;">
              <div class="table-responsive" #dataToExport>
                <table id="orders" class="table table-striped table-bordered nomargin">

                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Order-No</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngFor="let item of OrderList|paginate:PaginationConfig let i=index">
                      <td>{{item.orderDate|date:'dd-MMM-yyyy'}}</td>
                      <td>{{item.enquiryNumber}}</td>
                      <td>{{item.title}}</td>
                      <td data-target="#myCustomerDetailModal">{{item.description}}</td>
                      <td class="text-center">
                        <div class="action-recived-ordeer">
                          <div class="cont-bt">
                            <button (click)="OnView(item)" type="button" class="btn btn-icon eye-icon" aria-label="Edit">
                              <i class="fa fa-eye" aria-hidden="true"> </i>
                            </button>
                            <button (click)="OnReply(item.customerEmail)" type="button" class="btn btn-icon"
                              aria-label="reply">
                              <i class="fa fa-reply"></i>
                            </button>
                          </div>
                          
                          <button *ngIf="item.isApproved===null"  class="btn btn-icon m-1 p-1 mr-2" (click)="RejectOrder(item.id)">
                            <i class="fa fa-times" style="color: red;"></i>
                          </button>
                          <div *ngIf="item.isApproved===false">
                            <span style="color: red;">Rejected</span>
                          </div>
                          
                        </div>
                       

                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>

              <div class="row mt-3">
                <div class="col-md-3 ">
                  <select class="form-control w-auto">
                    <option *ngFor="let item of tableSizes">{{item}}</option>
                  </select>
                </div>
                <div class="col-md-9 text-right">
                  <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="OnPageChange($event)"></pagination-controls>
                </div>

              </div>
            </div>
          </div>
        </div>
        <ng-template #elseBlock>
          <h2>No Record Found</h2>
        </ng-template>


      </div>


    </div>
  </div>
</section>

<!-- Modal -->
<div id="reply" class="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Reply </h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>

      </div>
      <div class="modal-body">

        <form [formGroup]="ReplyForm">
          <div class-="reply-form">
            <div class="row">
              <div class="col-md-12">
                <div class="input-group">
                  <label> Email ID</label>
                  <input [readOnly]="true" formControlName="emailID" type="text" name="email"
                    class="form-control custom-color" placeholder="Enter Email Id" />
                </div>
                <div class="input-group">
                  <label>Subject <span style="color: red;">*</span></label>
                  <input formControlName="subject" type="text" name="email" class="form-control"
                    placeholder="Enter Subject" />
                  <span class="validation-error" *ngIf="replyformcontrols.subject?.touched">
                    <span *ngIf="replyformcontrols.subject?.errors?.required">This Field Is Required</span>
                  </span>
                </div>
                <div class="input-group">
                  <label> Message <span style="color: red;">*</span></label>
                  <textarea formControlName="message" id="Description" name="Message" rows="4" cols="50"
                    placeholder="Message">
                    </textarea>
                  <span class="validation-error" *ngIf="replyformcontrols.message?.touched">
                    <span *ngIf="replyformcontrols.message?.errors?.required">This Field Is Required</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </form>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel

        </button>
        <button (click)="SubmitReply()" type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>

  </div>
</div>
<div class="modal fade" id="largeModal" tabindex="-1" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog user-detial ">
    <div class="modal-content">
      <div class="modal-header">

        <h3 class="modal-title user-tittle">View Order</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="Col-md-12">
           
          </div>
          <div class="col-md-6">
              <h4 class="custom-order-title"> Order Details</h4>
            <div class="order-custom-info">
                <p><strong>Categorie: </strong><span> {{Order?.category}}</span></p>
                <p><strong>Sub Categories:</strong><span> {{Order?.subcategory}}</span></p>
                <p><strong>Description:</strong><span> {{Order?.description}}</span></p>
            </div>
            <h4 class="custom-order-title referce"> Customer Details</h4>
            <div class="order-custom-info">
                <p><strong>Name: </strong> <span>  {{Order?.customerName}}</span></p>
                <p><strong>Email: </strong> <span> {{Order?.customerEmail}}</span></p>
                <p><strong>Phone: </strong> <span> {{Order?.customerPhoneNumber}}</span></p>
            </div>
          </div>
          <div class="col-md-6">
                <div class="cust-img">
                    <h4 class="custom-order-title referce"> Reference Images</h4>
                    <div class="owl-carousel owl-theme" id="mygallary">
                    </div>
                </div>


          </div>
          
            
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>