import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PosService } from './pos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { settings } from 'cluster';
import { error } from 'console';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Toast, ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { totalmem } from 'os';
import { AppService } from 'src/app/app.service';
declare const $: any;

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.css'],
})
export class PosComponent implements OnInit, AfterViewInit, OnDestroy {
  public InvoiceTypeList: any[] = [];
  public InvoiceNumber: string;
  public IsFindCustomer: boolean = false;
  public AddressList: any[] = [];
  public UserDropdownList: any[] = [];
  public ProductDropdownList: any[] = [];
  public ProductItemForm: any;
  public ItemList: any[] = [];
  public UserForm: any;
  public GuestUserForm: any;
  public AddressForm1: any;
  public AddressForm2: any;
  public CountryDropdownList: any[] = [];
  public StateDropdownList1: any[] = [];
  public CityDropdownList1: any[] = [];
  public StateDropdownList2: any[] = [];
  public CityDropdownList2: any[] = [];
  public IsGuestUser: boolean = false;
  public ItemTotalPrice: number = 0;
  public GstTax: number = 0;
  public ItemTotalAmount: number = 0;
  public TotalShipping: number = 0;
  public InvoiceForm: any;
  public CustomerID: number = 0;
  public ReferedByID: number = 0;
  public Balance: number = 0;
  public IsPartialPayment: boolean = false;
  public TotalSavings: number = 0;
  // private StoreCredit: number = 0;
  public CustomerDropdownValue: any = null;
  public InvoiceTypeID: number = 1;
  private RootURL: string = environment.baseUrl.slice(0, -3);
  public Remarks: string;
  public IsCommonAddress: boolean = false;
  public IsEditableCustomer: boolean = false;
  public IsItemSubmited: boolean = false;
  public finalDiscount: number = 0;
  private enterKeyListener: () => void;
  @ViewChild('itemNgSelect', { static: false }) ngSelect: NgSelectComponent;
  @ViewChild('userDropdown', { static: false })
  userDropdown!: NgSelectComponent;
  @ViewChild('itemForm') itemForm!: ElementRef;

  public UserDetail = {
    ID: 0,
    Name: null,
    PhoneNumber: null,
    Phone_Code: null,
    Email: null,
    StoreCreadit: 0,
  };

  constructor(
    private posservice: PosService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private datepipe: DatePipe,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private appservice: AppService
  ) {
    this.ProductItemForm = fb.group({
      tempArrIndex: [null],
      ProductName: [null],
      itemID: [null, [Validators.required]],
      itemCode: [null],
      price: [null],
      quantity: [null, [Validators.required, Validators.min(1)]],
      discount: [null],
      ItemStock: [null],
      shipping: [null],
    });

    this.UserForm = fb.group({
      id: [0],
      userTypeID: [4],
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      firstName: [null, [Validators.required]],
      mobile: [null, [Validators.required, Validators.minLength(10)]],
      loginUserID: [0],
      isOnlineRegistration: [false],
      acnNumber: [null, [Validators.pattern(/^\d{9}$/)]],
      abnNumber: [null, [Validators.pattern(/^\d{11}$/)]],
    });

    this.AddressForm1 = fb.group({
      id: [0],
      userID: [0],
      address1: [null, [Validators.required]],
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pinCode: [null, [Validators.required, Validators.pattern(/^\d{4}$/)]],
      isShippingAddress: false,
      isBillingAddress: true,
    });

    this.AddressForm2 = fb.group({
      id: [0],
      userID: [0],
      address1: [null, [Validators.required]],
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pinCode: [null, [Validators.required, Validators.pattern(/^\d{4}$/)]],
      isShippingAddress: true,
      isBillingAddress: false,
    });

    this.InvoiceForm = this.fb.group({
      invoiceTypeID: [1, [Validators.required]],
      invoiceNumber: [''],
      invoiceDate: [''],
      customerID: [0],
      referedByID: [0],
      invoiceTotal: [0],
      shippingCharges: [0],
      isPartialPayment: false,
      cashPaid: [0],
      storeCreditPaid: [0],
      onlinePaid: [0],
      gstAmount: [0],
      shippingType: [1],
      isPaymentModeCash: [false],
      onlinePaymentMode: [null],
      remark: [null],
    });

    this.GuestUserForm = fb.group({
      id: [0],
      userTypeID: [4],
      email: [
        null,
        [
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
      name: [null],
      phoneNumber: [null, [Validators.minLength(10)]],
      loginUserID: [0],
    });
  }

  ngOnInit(): void {
    this.GetUserDropdownList();
    this.GetInvoiceTypeList();
    this.GenrateInvoiceNumber();
    this.GetProductDropdownList();
    this.GetCountryDropdownList();
  }

  ngAfterViewInit(): void {
    this.userDropdown.focus();
    $('#invoice-date').flatpickr({
      dateFormat: 'd/M/Y',
      defaultDate: 'today',
    });

    this.enterKeyListener = this.renderer.listen(
      'document',
      'keydown',
      (event) => {
        const isEnterOrTab =
          event.key === 'Enter' ||
          event.keyCode === 13 ||
          event.key === 'Tab' ||
          event.keyCode === 9;

        if (!isEnterOrTab) {
          return;
        }

        if (event.key === 'Tab' || event.keyCode === 9) {
          if (
            this.ProductItemForm.get('tempArrIndex').value === null &&
            this.ngSelect.viewPortItems.length > 0
          ) {
            const ItemID = this.ngSelect.viewPortItems[0].value.id;
            this.ProductItemForm.patchValue({ itemID: ItemID });
            this.fetchProductById(ItemID);
          }
          return;
        }

        if (
          (event.key === 'Enter' || event.keyCode === 13) &&
          this.ProductItemForm.get('tempArrIndex').value === null
        ) {
          this.ngSelect.viewPortItems = [];
          return;
        }

        this.onSubmitForm();
        event.preventDefault();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.enterKeyListener) {
      if (this.enterKeyListener) {
        this.enterKeyListener();
      }
    }
  }

  keyPressNumbers1(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  get GetItemFormcontrols() {
    return this.ProductItemForm.controls;
  }
  get GetUserFormcontrols() {
    return this.UserForm.controls;
  }
  get GetAddress1Formcontrols() {
    return this.AddressForm1.controls;
  }
  get GetAddress2Formcontrols() {
    return this.AddressForm2.controls;
  }
  get GetInvoiceFormControls() {
    return this.InvoiceForm.controls;
  }

  onBlur() {
    this.ngSelect.viewPortItems = [];
  }

  editUserDetails(userid: number, url: string) {
    const encid = this.appservice.encryptValue(userid.toString());
    const encurl = this.appservice.encryptValue(url);
    this.router.navigate(['edit-user'], {
      queryParams: { UserID: encid, URL: encurl },
    });
  }

  GetInvoiceTypeList() {
    this.spinner.show();
    this.posservice.GetInvoiceTypeList().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.InvoiceTypeList = response.result;
        } else {
          console.error(response.message);
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.toastr.error(err);
        this.spinner.hide();
      },
    });
  }

  onApplyFinalDiscount() {
    if (this.finalDiscount > this.ItemTotalAmount) {
      this.WarningOverAmount('over-amount', this.finalDiscount);
      this.finalDiscount = 0;
      return;
    };
    this.ItemTotalAmount = parseFloat(((this.ItemTotalPrice + this.GstTax) - this.finalDiscount).toFixed(2));
    this.finalDiscount = this.finalDiscount ?? 0;
    this.onChangeAmount('Cash-Paid');
  }

  OnChangePaymentType(IsPartial: boolean) {
    this.IsPartialPayment = IsPartial;
    this.InvoiceForm.patchValue({
      isPartialPayment: IsPartial,
    });
  }

  OnChangeOnlineMode(val: string) {
    this.InvoiceForm.patchValue({
      onlinePaymentMode: val,
    });
  }

  onChangeAmount(type: string) {
    const formGroup = this.InvoiceForm as FormGroup;
    const cash = formGroup.get('cashPaid').value ?? 0;
    const storeCreadit = formGroup.get('storeCreditPaid').value ?? 0;
    const online = formGroup.get('onlinePaid').value ?? 0;
    const amountTotal = parseFloat((cash + storeCreadit + online).toFixed(2));
    if (type === 'Cash-Paid') {
      if (amountTotal <= this.ItemTotalAmount) {
        formGroup.patchValue({
          cashPaid: cash,
        });
        this.Balance = this.ItemTotalAmount - amountTotal;
      } else {
        this.WarningOverAmount('over-amount', amountTotal);
        formGroup.patchValue({
          cashPaid: 0,
        });
        this.Balance = this.ItemTotalAmount - (storeCreadit + online);
      }
    };
    if (type === 'Storecredit-Paid') {
      if (storeCreadit <= this.UserDetail.StoreCreadit && amountTotal <= this.ItemTotalAmount) {
        this.Balance = this.ItemTotalAmount - amountTotal;
        formGroup.patchValue({
          storeCreditPaid: storeCreadit,
        });
      } else {
        if (storeCreadit > this.UserDetail.StoreCreadit) {
          this.WarningOverAmount('store-credit', storeCreadit);
        } else {
          this.WarningOverAmount('over-amount', amountTotal);
        };
        formGroup.patchValue({
          storeCreditPaid: 0,
        });
        this.Balance = this.ItemTotalAmount - (cash + online);
      }
    };
    if (type === 'Online-Paid') {
      if (amountTotal <= this.ItemTotalAmount) {
        this.Balance = this.ItemTotalAmount - amountTotal;
        formGroup.patchValue({
          onlinePaid: online,
        });
        if (online > 0) {
          formGroup.patchValue({
            onlinePaymentMode: 'Bank',
          });
          $('#online-payment-check1').prop('checked', true);
        } else {
          formGroup.patchValue({
            onlinePaymentMode: null,
          });
          $("[name='online-pyment']").prop('checked', false);
        }
      } else {
        this.WarningOverAmount('over-amount', amountTotal);
        formGroup.patchValue({
          onlinePaid: 0,
        });
        this.Balance = this.ItemTotalAmount - (cash + storeCreadit);
      }
    };


    this.Balance = parseFloat(this.Balance.toFixed(2));
    if (this.Balance === 0) {
      this.InvoiceForm.patchValue({
        isPartialPayment: false,
      });
      $('#inlineRadio2').prop('checked', true);
      $('#inlineRadio1').prop('disabled', true);
    } else {
      $('#inlineRadio1').prop('disabled', false);
    }
  }

  WarningOverAmount(type: string, value: number) {
    if (this.ItemTotalAmount > 0) {
      let text = "";
      if (type === 'store-credit' && value > this.UserDetail.StoreCreadit) {
        text = `Insufficient store credit: You only have ${this.UserDetail.StoreCreadit} available, but you're trying to use ${value}.`;

      } else {
        text = `Amount exceeds total: The entered amount (${value}) is more than your total amount of ${this.ItemTotalAmount}.`;

      }
      Swal.fire({
        title: "Warning",
        text: text,
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  GetUserDropdownList() {
    this.spinner.show();
    this.posservice.GetUserDropdownList(4, true).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const data = response.result;
          let list = [];
          data.map(
            (item: {
              id: number;
              firstName: string;
              email: string;
              mobile: string;
              isGuestUser: boolean;
            }) => {
              const text = `${item.id} | ${item.firstName} | ${item.mobile} | ${item.email}`;
              const value = item.id;
              list.push({ name: text, id: value });
            }
          );
          this.UserDropdownList = list;
        } else {
          console.error(response.message);
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.toastr.error(err);
        this.spinner.hide();
      },
    });
  }

  GetProductDropdownList() {
    this.posservice.GetProductDropdownList().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const list = new Array();
          response.result.map(
            (item: {
              productDetailID: number;
              itemCode: string;
              itemBarCode: string;
              productName: string;
              availableQuantity: number
            }) => {
              const text = `${item.productName} | ${item.itemBarCode}`;
              const value = item.productDetailID;
              list.push({ name: text, id: value });
            }
          );
          this.ProductDropdownList = list;
        } else {
          console.error(response.message);
        }
      },
      error: (err) => {
        this.toastr.error(err);
      },
    });
  }

  GenrateInvoiceNumber() {
    const timestamp = Date.now();
    const numberPart = String(timestamp).slice(5);
    const invoiceNumber = 'INV-' + numberPart;
    this.InvoiceNumber = invoiceNumber;
  }

  GetCountryDropdownList() {
    this.posservice.GetCoruntryDropdownList().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const ID = response.result.find(
            (x) => x.name.toUpperCase() === 'AUSTRALIA'
          ).id;
          this.OnChangeCounry(ID, 'billing-address');
          this.OnChangeCounry(ID, 'shipping-address');
          this.CountryDropdownList = response.result;
          setTimeout(() => {
            this.AddressForm1.patchValue({
              country: ID,
            });
            this.AddressForm2.patchValue({
              country: ID,
            });
          }, 500);
        } else {
          console.error(response.message);
        }
      },
      error: (err) => {
        this.toastr.error(err);
      },
    });
  }

  OnChangeCounry(Id: any, type: string) {
    if (type === 'billing-address') {
      this.StateDropdownList1 = [];
      this.CityDropdownList1 = [];
      this.AddressForm1.patchValue({
        state: null,
        city: null,
      });
      this.onChangeUserFields(Id, 'country');
      this.posservice.GetStateDropdownListByCountryID(Id).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.StateDropdownList1 = response.result;
          } else {
            console.error(response.message);
          }
        },
        error: (err) => {
          this.toastr.error(err);
        },
      });
    } else {
      this.StateDropdownList2 = [];
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        state: null,
        city: null,
      });
      this.posservice.GetStateDropdownListByCountryID(Id).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.StateDropdownList2 = response.result;
          } else {
            console.error(response.message);
          }
        },
        error: (err) => {
          this.toastr.error(err);
        },
      });
    }
  }

  OnChangeState(Id: any, type: string) {
    if (type === 'billing-address') {
      this.CityDropdownList1 = [];
      this.AddressForm1.patchValue({
        city: null,
      });
      this.onChangeUserFields(Id, 'state');
      this.posservice.GetCityDropdownListByStateID(Id).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.CityDropdownList1 = response.result;
          } else {
            console.error(response.message);
          }
        },
        error: (err) => {
          this.toastr.error(err);
        },
      });
    } else {
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        city: null,
      });
      this.posservice.GetCityDropdownListByStateID(Id).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.CityDropdownList2 = response.result;
          } else {
            console.error(response.message);
          }
        },
        error: (err) => {
          this.toastr.error(err);
        },
      });
    }
  }

  fetchProductById(Id: any) {
    if (Id > 0) {
      this.spinner.show();
      this.posservice.GetProductItemByID(Id).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            const data = response.result;
            const discountPercent = response.result.discountPercentage ?? 0;
            const discountedAmount =
              discountPercent > 0 ? (data.price * discountPercent) / 100 : 0;
            const subTotal = parseFloat(
              (data.price - discountedAmount).toFixed(2)
            );
            this.ProductItemForm.patchValue({
              itemCode: data.itemCode,
              ProductName: data.productName,
              salePrice: subTotal,
              price: data.price,
              discount: discountPercent,
              quantity: 1,
              ItemStock: data.stock,
              shipping: 0,
            });

            // Manage Item Summary
            this.onSubmitForm();
          } else {
            console.error(response.message);
            this.ProductItemForm.reset();
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        },
        error: (err) => {
          this.toastr.error(err);
          this.spinner.hide();
        },
      });
    } else {
      this.ProductItemForm.reset();
    }
  }

  onChangeItemQuantity() {
    const itemstock = this.ProductItemForm.get('ItemStock').value;
    const purchasequantity = this.ProductItemForm.get('quantity').value;
    if (itemstock < purchasequantity) {
      Swal.fire({
        icon: 'warning',
        title: 'Insufficient Stock',
        text: `Sorry, we only have ${itemstock} items in stock, but you tried to purchase ${purchasequantity}. Please adjust your order quantity.`,
      });
      this.ProductItemForm.patchValue({
        quantity: itemstock,
      });
    }
  }



  FindUser(Id: any) {
    if (Id > 0) {
      this.spinner.show();
      this.posservice.GetCustomer(Id).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.CustomerID = Id;
            const data = response.result;
            this.AddressList = data.addressList;
            this.UserDetail.ID = Id;
            this.UserDetail.Name = data.name;
            this.UserDetail.Email = data.email;
            this.UserDetail.PhoneNumber = data.phoneNumber;
            this.UserDetail.StoreCreadit = data.storeCredit ?? 0;
            this.UserDetail.Phone_Code = data.addressList[0]?.phoneCode;
            this.IsFindCustomer = true;
            $('#Add-Customer-Btn').css('pointer-events', 'none');
            if (response.result.isOnlineRegistration || response.result.isGuestUser) {
              this.IsEditableCustomer = false;
              if (response.result.isGuestUser) {
                this.OnChangeCash(true);
                $('#cashcheck').prop('checked', true);
                const guestUserFormGroup = this.GuestUserForm as FormGroup;
                if (data.name !== "" && data.name !== null) {
                  guestUserFormGroup.get('name').setValue(data.name);

                } if (data.phoneNumber !== "" && data.phoneNumber !== null) {
                  guestUserFormGroup.get('phoneNumber').setValue(data.phoneNumber);

                } if (data.email !== "" && data.email !== null) {
                  guestUserFormGroup.get('email').setValue(data.email);
                }

              };
            } else {
              this.IsEditableCustomer = true;
            }
          } else {
            this.ResetUserDetails();
            $('#Add-Customer-Btn-xyz').css('pointer-events', '');
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 300);
        },
        error: (err) => {
          this.toastr.error(err);
          this.spinner.hide();
        },
      });
    } else {
      this.ResetUserDetails();
      this.InvoiceForm.patchValue({
        storeCreditPaid: 0,
      });
      this.onChangeAmount('Storecredit-Paid');
      $('#Add-Customer-Btn').css('pointer-events', '');
    }
  }

  onSubmitForm() {
    this.IsItemSubmited = true;
  
    if (!this.ProductItemForm.valid) {
      this.ProductItemForm.markAllAsTouched();
      return;
    }
  
    this.IsItemSubmited = false;
    this.spinner.show();
  
    let item = { ...this.ProductItemForm.value };
    item.discount = Math.max(item.discount, 0);
    item.shipping = Math.max(item.shipping, 0);
    item.quantity = Math.max(item.quantity, 0);
  
    const discountPerItem = (item.price * item.discount) / 100;
    const saleAmount = (item.price - discountPerItem) * item.quantity + item.shipping;
    item.salePrice = parseFloat(saleAmount.toFixed(2));
  
    if (item.ItemStock < item.quantity) {
      this.warn(item.quantity, item.ItemStock);
      this.resetForm();
      return;
    }
  
    if (item.tempArrIndex !== null) {
      this.ItemList[item.tempArrIndex] = item;
    } else {
      let existingItem = this.ItemList.find((i) => i.itemID === item.itemID);
      if (existingItem) {
        const totalQuantity = existingItem.quantity + item.quantity;
        const newDiscount = (item.price * item.discount) / 100;
        const newPrice = item.shipping + (item.price - newDiscount) * totalQuantity;
  
        if (existingItem.ItemStock < totalQuantity) {
          this.warn(totalQuantity, existingItem.ItemStock);
          this.resetForm();
          return;
        }
  
        Object.assign(existingItem, {
          salePrice: parseFloat(newPrice.toFixed(2)),
          quantity: totalQuantity,
          discount: item.discount,
          price: item.price
        });
      } else {
        this.ItemList.push(item);
      }
    }
  
    this.resetForm();
    this.ManageItemsSummary();
  }
  
  resetForm() {
    this.ProductItemForm.reset();
    this.ngSelect.viewPortItems = [];
    this.ngSelect.focus();
    setTimeout(() => this.spinner.hide(), 500);
  }
  
  warn(purchasequantity: number, itemstock: any) {
    Swal.fire({
      icon: 'warning',
      title: 'Insufficient Stock',
      text: `Sorry, we only have ${itemstock} items in stock, but you tried to purchase ${purchasequantity}. Please adjust your order quantity.`,
    });
  }

  ResetUserDetails() {
    this.UserDetail.ID = 0;
    this.UserDetail.Phone_Code = null;
    this.UserDetail.Email = null;
    this.UserDetail.Name = null;
    this.UserDetail.PhoneNumber = null;
    this.UserDetail.StoreCreadit = 0;
    this.AddressList = [];
    this.IsFindCustomer = false;
  }

  resetPayment() {
    this.InvoiceForm.patchValue({
      cashPaid: 0,
      storeCreditPaid: 0,
      onlinePaid: 0,
    });
    this.Balance = this.ItemTotalAmount;
  }

  resetInvoice() {
    window.location.reload();
  }

  OnChangeCash(val: boolean) {
    if (val === true) {
      this.InvoiceForm.patchValue({
        isPaymentModeCash: true,
      });
      this.CustomerDropdownValue = null;
      this.IsGuestUser = true;
      this.IsFindCustomer = false;
      $('#Add-Customer-Btn').css('pointer-events', 'none');
      $('#Cash-Fileds').removeClass('hide');
    } else {
      this.InvoiceForm.patchValue({
        isPaymentModeCash: false,
      });
      this.GuestUserForm.reset();
      this.IsGuestUser = false;
      this.CustomerID = 0;
      $('#Add-Customer-Btn').css('pointer-events', '');
      $('#Cash-Fileds').addClass('hide');
    }
  }

  OnChangeShipping(type: string) {
    if (type === 'pickup') {
      this.TotalShipping = 0;
      this.InvoiceForm.patchValue({
        shippingType: 1,
      });
      $('#shipping-changes').prop('readonly', true);
      $('#shipping-changes').addClass('readonly-color');
      this.ManageItemsSummary();
    } else {
      this.InvoiceForm.patchValue({
        shippingType: 0,
      });
      $('#shipping-changes').prop('readonly', false);
      $('#shipping-changes').removeClass('readonly-color');
    }
  }

  OnChangeInvoiceType(ID: any) {
    if (ID) {
      this.InvoiceForm.patchValue({
        invoiceTypeID: ID,
      });
    }
  }

  async submitInvoice() {
    if (this.ValidateInvoice()) {
      if (this.IsGuestUser && this.CustomerID === 0) {
        //Manage Guest User
        if (this.GuestUserForm.valid && (this.GuestUserForm.get('email').value !== null || this.GuestUserForm.get('name').value !== null || this.GuestUserForm.get('phoneNumber').value !== null)
        ) {
          try {
            $('#refresh').modal('show');
            let response = await this.posservice
              .ManageGuestUser(this.GuestUserForm.value)
              .toPromise();
            if (response.message === 'Success') {
              this.CustomerID = response.result.id;
              this.GuestUserForm.reset();
            } else {
              this.toastr.warning(response.message);
              $('#refresh').modal('hide');
              return;
            }
          } catch (error) {
            this.toastr.error(error.message);
            $('#refresh').modal('hide');
            return;
          }
        } else if (this.GuestUserForm.invalid) {
          this.GuestUserForm.markAllAsTouched();
          return;
        }
      }

      try {
        $('#refresh').modal('show');
        const invoiceDateInput = $('#invoice-date').val();
        const currentDate = new Date();
        const currentTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS format
        const formattedDate = this.datepipe.transform(
          invoiceDateInput,
          'yyyy-MM-dd'
        );
        const fullDate = `${formattedDate}T${currentTime}`;

        const invoiceItemList = this.ItemList.map(
          (item: {
            itemID: number;
            salePrice: number;
            price: number;
            quantity: number;
            shipping: number;
          }) => item
        );

        // Patch invoice data
        const formatedData = {
          ...this.InvoiceForm.value,
          invoiceTypeID: this.InvoiceTypeID,
          invoiceNumber: this.InvoiceNumber,
          invoiceDate: fullDate,
          customerID: this.CustomerID,
          referedByID: this.ReferedByID,
          invoiceTotal: this.ItemTotalAmount,
          shippingCharges: this.TotalShipping,
          gstAmount: this.GstTax,
          remark: this.Remarks,
          finalDiscount: this.finalDiscount,
          invoiceItemList,
        };

        // Submit invoice
        const invoiceResponse = await this.posservice
          .ManageInvoiceMaster(formatedData)
          .toPromise();
        if (invoiceResponse.message === 'Success') {
          this.toastr.success('Invoice has been added successfully');

          // Download the file
          const { path, fileName } = invoiceResponse.result;
          console.log(invoiceResponse.result);
          const downloadLink = `${this.RootURL}${path}${fileName}`;
          saveAs(downloadLink);

          this.router.navigate(['/view-invoice']);
        }
      } catch (error) {
        this.toastr.error(error.message);
      } finally {
        $('#refresh').modal('hide');
      }
    } else {
      this.InvoiceForm.markAllAsTouched();
    }
  }

  ValidateInvoice(): boolean {
    if (this.InvoiceForm.invalid) {
      this.toastr.warning('Please fill out all required fields.');
      return false;
    }
    if (!this.IsFindCustomer && !this.IsGuestUser) {
      this.toastr.warning('Please add a user first.');
      return false;
    }
    if (this.ItemList.length < 1) {
      this.toastr.warning('Please add items first.');
      return false;
    }

    if (this.IsPartialPayment) {
      const partialValidAmount = this.ItemTotalAmount * 0.25;
      const partialPaidAmount = this.ItemTotalAmount - this.Balance;
      if (partialPaidAmount < partialValidAmount) {
        this.toastr.warning(
          `Please make a partial payment of at least ${partialValidAmount.toFixed(
            2
          )} of the total amount.`
        );
        return false;
      }
    }
    if (!this.IsPartialPayment && this.Balance !== 0) {
      this.toastr.warning('Please complete your payment.');
      return false;
    }
    return true;
  }

  ManageUserDetails() {
    if (
      this.UserForm.valid &&
      this.AddressForm1.valid &&
      this.AddressForm2.valid
    ) {
      this.spinner.show();
      this.posservice.ManageUserDetails(this.UserForm.value).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            const user = response.result;
            this.CustomerDropdownValue = user.id;
            const addresslist = new Array();
            this.AddressForm1.patchValue({
              userID: user.id,
            });
            this.AddressForm2.patchValue({
              userID: user.id,
            });

            addresslist.push(this.AddressForm1.value);
            addresslist.push(this.AddressForm2.value);
            this.posservice
              .ManageAddressMaster(addresslist, 'create-address')
              .subscribe({
                next: (response1) => {
                  if (response1.message === 'Success') {
                    this.AddressList = response1.result;
                    this.toastr.success(
                      'User details have been added successfully.'
                    );
                    $('#Add-customer-details').modal('hide');
                    $('#Add-Customer-Btn').css('pointer-events', 'none');
                    this.UserDetail.ID = user.id;
                    this.UserDetail.Name = user.firstName;
                    this.UserDetail.Email = user.email;
                    this.UserDetail.PhoneNumber = user.mobile;
                    this.UserDetail.StoreCreadit = user.storeCredit ?? 0;
                    this.IsFindCustomer = true;
                    this.GetUserDropdownList();
                    this.resetMasterForm();
                  }
                  setTimeout(() => {
                    this.CustomerID = user.id;
                    this.IsEditableCustomer = true;
                    this.spinner.hide();
                  }, 500);
                },
                error: (err) => {
                  this.toastr.error(err);
                  this.spinner.hide();
                },
              });
          } else {
            this.toastr.error(response.message);
            this.spinner.hide();
          }
        },
        error: (err) => {
          this.toastr.error(err);
          this.spinner.hide();
        },
      });
    } else {
      this.UserForm.markAllAsTouched();
      this.AddressForm1.markAllAsTouched();
      this.AddressForm2.markAllAsTouched();
    }
  }

  resetMasterForm() {
    const countryID = this.CountryDropdownList.find(
      (x) => x.name.toUpperCase() === 'AUSTRALIA'
    ).id;
    this.GetCountryDropdownList();
    this.CityDropdownList1 = [];
    this.CityDropdownList2 = [];
    this.UserForm.reset();
    this.AddressForm1.reset();
    this.AddressForm2.reset();
    this.UserForm.patchValue({
      id: 0,
      userTypeID: 4,
      loginUserID: 0,
      isOnlineRegistration: false,
    });

    this.AddressForm1.patchValue({
      id: 0,
      userID: 0,
      isShippingAddress: false,
      isBillingAddress: true,
      country: countryID,
    });

    this.AddressForm2.patchValue({
      id: 0,
      userID: 0,
      isShippingAddress: true,
      isBillingAddress: false,
      country: countryID,
    });
    $('#Add-customer-details').modal('hide');
    $('#common-address').prop('checked', false);
  }

  ManageItemsSummary() {
    const Total = this.ItemList.reduce(
      (acc, item) => {
        return {
          totalPrice: acc.totalPrice + item.salePrice,
          totalShipping: acc.totalShipping + item.shipping,
          totalsavings:
            acc.totalsavings +
            (item.price * item.quantity * item.discount) / 100,
        };
      },
      { totalPrice: 0, totalShipping: 0, totalsavings: 0 }
    );

    const TotalPrice = Total.totalPrice;
    const TotalShippingCharges = Total.totalShipping;
    const TotalSavings = Total.totalsavings;
    if (this.ItemList.length > 0) {
      this.GstTax = parseFloat((TotalPrice / 11).toFixed(2));
      this.ItemTotalPrice = parseFloat((TotalPrice - this.GstTax).toFixed(2));
      this.TotalShipping = parseFloat(TotalShippingCharges.toFixed(2));
      this.ItemTotalAmount = parseFloat(((this.ItemTotalPrice + this.GstTax) - this.finalDiscount).toFixed(2));
      this.Balance = parseFloat((this.ItemTotalPrice + this.GstTax).toFixed(2));
      this.TotalSavings = parseFloat(TotalSavings.toFixed(2));
    } else {
      this.ItemTotalAmount = 0;
      this.Balance = 0;
      this.GstTax = 0;
      this.TotalShipping = 0;
      this.ItemTotalPrice = 0;
      this.TotalSavings = 0;
    }

    this.onChangeAmount('Cash-Paid');
  }


  OnDeleteItem(index: number) {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to delete this item?',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    }).then((response) => {
      if (response.isConfirmed) {
        this.ItemList.splice(index, 1);
        this.ManageItemsSummary();
      }
    });
  }

  OnEditItem(index: number) {
    const ProductDetail = this.ItemList[index];
    this.ProductItemForm.patchValue({
      tempArrIndex: index,
      itemID: ProductDetail.itemID,
      price: ProductDetail.price,
      salePrice: ProductDetail.salePrice,
      quantity: ProductDetail.quantity,
      discount: ProductDetail.discount,
      ItemStock: ProductDetail.ItemStock,
      ProductName: ProductDetail.ProductName,
      itemCode: ProductDetail.itemCode,
      shipping: ProductDetail.shipping,
    });
    if (this.itemForm) {
      this.itemForm.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  CommonAddress(checked: boolean) {
    if (checked) {
      this.IsCommonAddress = true;
      this.spinner.show();
      const billingAddress = this.AddressForm1.value;
      this.posservice
        .GetStateDropdownListByCountryID(billingAddress.country)
        .subscribe({
          next: (response) => {
            if (response.message === 'Success') {
              this.StateDropdownList2 = response.result;
            } else {
              console.error(response.message);
              this.spinner.hide();
            }
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          },
          error: (err) => {
            this.toastr.error(err);
            this.spinner.hide();
          },
        });

      this.posservice
        .GetCityDropdownListByStateID(billingAddress.state)
        .subscribe({
          next: (response) => {
            if (response.message === 'Success') {
              this.CityDropdownList2 = response.result;
            } else {
              console.error(response.message);
              this.spinner.hide();
            }

            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          },
          error: (err) => {
            this.toastr.error(err);
            this.spinner.hide();
          },
        });
      this.AddressForm2.patchValue({
        address1: billingAddress.address1,
        country: billingAddress.country,
        state: billingAddress.state,
        city: billingAddress.city,
        pinCode: billingAddress.pinCode,
      });
    } else {
      this.IsCommonAddress = false;
      const ID = this.CountryDropdownList.find(
        (x) => x.name.toUpperCase() === 'AUSTRALIA'
      ).id;
      this.OnChangeCounry(ID, 'shipping-address');
      this.AddressForm2.reset();
      this.AddressForm2.patchValue({
        id: 0,
        userID: 0,
        isShippingAddress: true,
        isBillingAddress: false,
        country: ID,
      });
    }
  }

  onChangeUserFields(val: any, type: string) {
    if (!this.IsCommonAddress) return;
    if (type === 'address') {
      this.AddressForm2.patchValue({
        address1: val,
      });
    }
    if (type === 'country') {
      this.StateDropdownList2 = [];
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        country: val,
        state: null,
        city: null,
      });
      this.posservice.GetStateDropdownListByCountryID(val).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.StateDropdownList2 = response.result;
          } else {
            this.StateDropdownList2 = [];
          }
        },
        error: (err) => {
          console.error('Error in GetStateDropdownListByCountryID', err);
        },
      });
    }
    if (type === 'state') {
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        state: val,
        city: null,
      });
      this.posservice.GetCityDropdownListByStateID(val).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.CityDropdownList2 = response.result;
          } else {
            this.CityDropdownList2 = [];
          }
        },
        error: (err) => {
          console.error('Error in GetCityDropdownListByStateID', err);
        },
      });
    }
    if (type === 'city') {
      this.AddressForm2.patchValue({
        city: val,
      });
    }
    if (type === 'pincode') {
      this.AddressForm2.patchValue({
        pinCode: val,
      });
    }
  }

  onChangeUserField(value: string, type: string, type1: string) {
    if (value) {
      this.posservice.IsValidUser(value, type).subscribe({
        next: (response: boolean) => {
          let email;
          let mobile;
          if (type1 === 'guest-user') {
            email = this.GuestUserForm.get('email') as FormControl;
            mobile = this.GuestUserForm.get('phoneNumber') as FormControl;
          } else {
            email = this.UserForm.get('email') as FormControl;
            mobile = this.UserForm.get('mobile') as FormControl;
          }
          if (response) {
            switch (type) {
              case 'email': {
                email.setErrors({ duplicateEmail: true });
                break;
              }
              case 'phone-number': {
                mobile.setErrors({ duplicateMobile: true });
                break;
              }
            }
          } else {
            switch (type) {
              case 'email': {
                if (email.errors && email.errors['duplicateEmail']) {
                  delete email.errors['duplicateEmail'];
                  if (!Object.keys(email.errors).length) {
                    email.setErrors(null);
                  }
                }

                break;
              }
              case 'phone-number': {
                if (mobile.errors && mobile.errors['duplicateMobile']) {
                  delete mobile.errors['duplicateMobile'];
                  if (!Object.keys(mobile.errors).length) {
                    mobile.setErrors(null);
                  }
                }
                break;
              }
            }
          }
        },
      });
    }
  }
}





