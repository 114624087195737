import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ContentmanagementService {
  readonly rootUrl = environment.baseUrl;
  pagename:any="";
  private data=new BehaviorSubject<any>(null);
  public currentdata=this.data.asObservable();
  constructor(private http: HttpClient) { }


  GetHomePageSection(Type:any):Observable<any>
  {
    return this.http.get<any>(this.rootUrl + '/ContentMangement/getHomePageSectionBYTypeName',{params:{Type}}) 
}

GetSectionListBySectionAndTypeName(Type,SectionName,Type2,Active:any):Observable<any>
{
  return this.http.get<any>(this.rootUrl + '/ContentMangement/getContentBySectionandTypeName',{params:{Type,SectionName,Type2,Active}}) 

}
manageContentImages(blogContentBO) {
  return this.http.post<any>(this.rootUrl + '/ManageContent/manageBlogContentFile', blogContentBO);
}
manageContent(blogContentBO)
{
  
  return this.http.post<any>(this.rootUrl + '/ContentMangement/manageContent',  blogContentBO);
}

ActiveInactiveContent(id:number):Observable<any>{
  return this.http.get<any>(this.rootUrl+'/ContentMangement/ActiveInactiveContentManageMent',{params:{id}});

}


AddHomePageImages(formdata:any) {
  return this.http.post<any>(this.rootUrl + '/UploadMultipleFiles',formdata);
}

PassData(val:any){
  this.data.next(val);

}

}




