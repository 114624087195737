import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UntypedFormGroup, FormControl, ReactiveFormsModule, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleService } from './vehicle.service';
declare var $: any;
@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {
  encPassword = 'neha@hexa';
  public router: Router;
  public form: UntypedFormGroup;
  public ID: AbstractControl;
  public VehicleTitle: AbstractControl;
  public RegNumber: AbstractControl;
  public CapacityInTonnes: AbstractControl;
  public Image: AbstractControl;
  public VehiclePermit: AbstractControl;
  public VehicleModel: AbstractControl;
  public RatePerMiles: AbstractControl;
  previewUrl = [];
  public selectedFile1: any;
  constructor(router: Router, fb: UntypedFormBuilder, private toastr: ToastrService, public VehicleService: VehicleService, private spinner: NgxSpinnerService) {
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    if (currentUser !== null) {
      if (CryptoJS.AES.decrypt(currentUser.userTypeId.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8) === '1') {
      } else {
        this.router.navigate(['/notfound']);
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.form = fb.group({
      'ID': [''],
      'VehicleTitle': ['', Validators.required],
      'RegNumber': ['', Validators.required],
      'CapacityInTonnes': ['', Validators.required],
      'VehicleModel': ['', Validators.required],
      'VehiclePermit': ['', Validators.required],
      'RatePerMiles': ['', Validators.required],
      'Image': ['']
    });
    this.ID = this.form.controls['ID'];
    this.VehicleTitle = this.form.controls['VehicleTitle'];
    this.RegNumber = this.form.controls['RegNumber'];
    this.Image = this.form.controls['Image'];
    this.VehiclePermit = this.form.controls['VehiclePermit'];
    this.VehicleModel = this.form.controls['VehicleModel'];
    this.RatePerMiles = this.form.controls['RatePerMiles'];

  }
  public selectedFile: any;
  public listVehicleContent: Array<string> = [];
  public activedeacVehicles: any = {};
  ngOnInit(): void {

    this.getVehicleList();
  }
  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg') {
      this.selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event: any) => {
        this.previewUrl = _event.target.result;
        const image = new Image();
        image.src = _event.target.result;
        image.onload = () => {
          if (image.width <= 500 && image.height <= 500) {
            this.selectedFile = event.target.files[0];
            this.previewUrl = _event.target.result;
            return true;
          }
          else {
            this.toastr.warning('', 'Image dimentions should be maximum 500*500');
            event.srcElement.value = null;
            this.selectedFile = null;
            this.previewUrl = null;
            return false;

          }
        }
      };

    } else {
      alert('Upload only image file'); event.srcElement.value = null;
    }
  }
  getVehicleList() {
    this.spinner.show();
    this.VehicleService.getVehicleContent().subscribe(data => {
      this.listVehicleContent = data.Result;
      console.log("vehiclelist", this.listVehicleContent);
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
  }

  onEdit(footer: any) {

    this.spinner.show();
    this.form.setValue({
      VehicleTitle: footer.VehicleTitle,
      RegNumber: footer.RegNumber,
      ID: footer.ID,
      CapacityInTonnes: footer.CapacityInTonnes,
      VehicleModel: footer.VehicleModel,
      RatePerMiles: footer.RatePerMiles,
      VehiclePermit: footer.VehiclePermit,
      Image: ''

    });
    this.previewUrl = footer.Image;
    document.getElementById('btnsubmit').innerHTML = 'Update';
    setTimeout(() => {
      this.spinner.hide();
    }, 200);
    window.scroll(0, 0);
  }
  VehicleActivedeactive(id: number, IsActive: string) {
    this.spinner.show();
    var action = 'Active';
    this.activedeacVehicles.ID = id;
    this.activedeacVehicles.Action = IsActive;
    if (this.activedeacVehicles.Action === true) {
      action = 'Inactive';
    }
    this.VehicleService.ActiveInactiveVehicleContent(this.activedeacVehicles).subscribe(res => {
      this.getVehicleList();
      this.toastr.success('Main Menu ' + action + ' successfully');
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
  }
  onSubmit(vehicleData) {
    debugger;

    const formData = new FormData();
    if (this.form.valid) {
      this.spinner.show();
      if (this.selectedFile != null || vehicleData.ID > 0) {
        this.VehicleService.managevehicleContent(vehicleData).subscribe(data => {
          if (data.Message == "Success") {

            formData.append('Id', data.Result);


            if (this.selectedFile != null) {
              formData.append('Image', this.selectedFile, this.selectedFile.name);
              this.VehicleService.manageVehicleImages(formData).subscribe(data1 => {
                if (data1.Message === 'Success') {
                  this.selectedFile = null;
                  this.previewUrl = null;
                  console.log("image", this.previewUrl);
                  this.getVehicleList();

                }

                else { this.toastr.error(data1.Message); }

              });
            }
            if (vehicleData.ID > 0) {
              this.toastr.success('', "Data Updated Successfully");
              this.form.reset();
              this.selectedFile = null;
              this.previewUrl = null;
              console.log("image", this.previewUrl);
              this.getVehicleList();

              document.getElementById('btnsubmit').innerHTML = 'Submit';
            }
            else
            { this.toastr.success('', "Data Added Successfully"); }
            this.form.reset();
            this.selectedFile = null;
            this.previewUrl = null;
            console.log("image", this.previewUrl);
            this.getVehicleList();

            document.getElementById('btnsubmit').innerHTML = 'Submit';
          } else { this.toastr.error(data.Message); }
        });


      }
      else {

        this.toastr.warning('Select an Image');
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);


    }
    else {
      this.spinner.hide();
      this.form.markAllAsTouched();
    }
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressNumbers1(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
