import { Component, OnInit } from '@angular/core';
import { MastertypeService } from './mastertype.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare var $:any
@Component({
  selector: 'app-mastertype',
  templateUrl: './mastertype.component.html',
  styleUrls: ['./mastertype.component.css']
})
export class MastertypeComponent implements OnInit {
  form: any;
  colorlist: any;
  activedeaccolor: any = {};
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10,15,20];
  Typelist: any;
  constructor(private service:MastertypeService,private fb:FormBuilder,private toastr:ToastrService,private spinner:NgxSpinnerService){
    this.form=fb.group({
      id:[0],
      name:["",Validators.required],
      isActive:[true]
    })
  }
ngOnInit(): void {
  this.getTypeList();
}
get input()
{
 return this.form.controls;
}
getTypeList()
{
this.spinner.show()
  this.service.getMasterTypeList().subscribe(data=>{
    if(data.message=="Success")
    {
      this.Typelist=data.result;
  this.spinner.hide();

    }
    else{
  this.spinner.hide();

    }
  })
}


AddType()
{
  debugger
  this.spinner.show();
  if(this.form.valid)
  {
    var ids=this.form.value.id==null?0:this.form.value.id
    this.form.patchValue({
      id:ids
    })
  this.service.ManageMasterType(this.form.value).subscribe(data=>{
    if(data.message=="Success")
    {
  this.spinner.hide();
  this.form.reset();

  this.getTypeList();

this.toastr.success("Type Added Succesfully");

    }
    else{
  this.spinner.hide();

this.toastr.warning(data.message);

    }
  })
}
else{
  this.spinner.hide();

  this.form.markAllAsTouched();
}
  
}

ActivedeactiveProductType(id: number, IsActive: boolean) {
  debugger
  var action = 'Inactive';
  this.activedeaccolor.ID = id;
  this.activedeaccolor.Action = IsActive;
  Swal.fire({
    title: 'Are you sure?',
    text:IsActive?"It Will Inactivate your Product":"It Will Activate your Product",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: IsActive?"Inactive it":"Active it"
  }).then((result) => {
    if (result.isConfirmed) {
      if( this.activedeaccolor.Action==false)
      {
    action = 'Active';
      }
      else
      {
    action = 'Inactive';

      }

  this.service.ActiveInactiveType( this.activedeaccolor.ID ).subscribe(res => {

    this.getTypeList();
    this.toastr.success('Type  ' + action + ' successfully');
    setTimeout(() => {
      // this.spinner.hide();
    }, 200);
  }, err => {  this.toastr.error('', 'Not Responding'); });
    }
    else{
      function check(){
        $('#checkBoxAinA'+id).prop("checked",true)
      };
      function uncheck(){
        $('#checkBoxAinA'+id).prop("checked",false)
      }
      
      IsActive?check():uncheck();
    }
  })

}
OnTableDataChange(PageNumber:number){
  this.page=PageNumber;
}
}
