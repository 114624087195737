import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmailsettingService } from './emailsetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare let $: any;

@Component({
  selector: 'app-emailsetting',
  templateUrl: './emailsetting.component.html',
  styleUrls: ['./emailsetting.component.css']
})
export class EmailsettingComponent implements OnInit {
  // ******pagination********
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];

  // ************************


  public form:any;
  public SmtpList: any[] = [];


  constructor(public router: Router, fb: FormBuilder, public emailsettingService: EmailsettingService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    this.form = fb.group({
      'id': 0,
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'host': ['', Validators.required],
      'port': ['', Validators.required],
      'ssl': false,
    });


  }

  ngOnInit(): void {
    this.GetEmailSettings();
  }


  GetEmailSettings() {
    this.spinner.show();
    this.emailsettingService.GetEmailSMTPSettings().subscribe(data => {
      if (data.message == "Success") {
        this.SmtpList = data.result;
      } else { console.error(data.message) };
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    },err=>{
      this.toastr.error(err)
      this.spinner.hide();
    })


  }


  // ********pagination*************
  onTableDataChange(event: any) {
    this.page = event;
    this.GetEmailSettings();
  }
  onTableSizeChange(event: any): void {

    this.tableSize = event;
    this.page = 1;
    this.GetEmailSettings();
  }
  // *********************



  onSubmit(): void {
    if (this.form.valid) {
      this.form.patchValue({
        id: this.form.value.id == null ? 0 : this.form.value.id
      })
      this.emailsettingService.ManageSMTPSettings(this.form.value).subscribe(data => {
        if (data.message === 'OK') {
          if (this.form.get('id').value > 0) {
            this.spinner.hide();

            this.toastr.success("Data has been updated successfully");
          }
          else {

            this.toastr.success("Data has been added successfully");

          }
          document.getElementById('btnsubmit').innerHTML = 'Save';
          this.form.reset();
          this.GetEmailSettings();
        }
        else {

          this.toastr.error('', data.Message);
        }

        this.form.reset();
      });
      setTimeout(() => {
      }, 1000);
    }
    else {
      this.form.markAllAsTouched();
    }
  }



  onEdit(item: any) {
    this.spinner.show();
    this.form.patchValue({
      username: item.username,
      password: item.password,
      host: item.host,
      port: item.port,
      ssl: item.ssl,
      id: item.id

    });
    document.getElementById('btnsubmit').innerHTML = 'Update';
    window.scroll(0, 0);
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }


  ActiveInactiveSMTP(id: number, IsActive: boolean) {
    if (this.SmtpList.filter(x => x.isActive).length > 0 && !IsActive) {
      Swal.fire({
        // title: 'Warning',
        title: 'Please deactivate the other SMTP first.',
        icon: 'info',
      });
      $('#checkBoxAinA' + id).prop("checked", false);
      return;
    };
    var action = 'activated';
    // ******SweetAlert************
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the SMTP settings? " : "Are you sure you want to activate the SMTP settings?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (IsActive === true) {
          action = 'deactivated';
        }
        this.emailsettingService.ActiveInactiveSMTP(id).subscribe(res => {
          this.GetEmailSettings();
          this.toastr.success('SMTP settings has been ' + action + ' successfully.');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {

        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }
        IsActive ? check() : uncheck();
      }
    })
  }



  get f() {
    return this.form.controls;
  }



}
