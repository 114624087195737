import { Component, OnInit } from '@angular/core';
import { PosService } from '../pos/pos.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
declare var $: any;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  public UserForm: any;
  public AddressForm1: any;
  public AddressForm2: any;
  public CountryDropdownList: any[] = [];
  public StateDropdownList1: any[] = [];
  public CityDropdownList1: any[] = [];
  public StateDropdownList2: any[] = [];
  public CityDropdownList2: any[] = [];
  public IsCommonAddress: boolean = false;
  private redirectionurl: string;


  constructor(private posservice: PosService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appservice: AppService) {
    this.UserForm = fb.group({
      id: [0],
      userTypeID: [4],
      email: [null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      firstName: [null, [Validators.required]],
      mobile: [null, [Validators.required, Validators.minLength(10)]],
      loginUserID: [0],
      isOnlineRegistration: [false],
      acnNumber:[null,[Validators.pattern(/^\d{9}$/)]],
      abnNumber:[null,[Validators.pattern(/^\d{11}$/)]]
    });

    this.AddressForm1 = fb.group({
      id: [0],
      userID: [0],
      address1: [null, [Validators.required]],
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pinCode: [null, [Validators.required,Validators.pattern(/^\d{4}$/)]],
      isShippingAddress: false,
      isBillingAddress: true
    });

    this.AddressForm2 = fb.group({
      id: [0],
      userID: [0],
      address1: [null, [Validators.required]],
      country: [null, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      pinCode: [null, [Validators.required,Validators.pattern(/^\d{4}$/)]],
      isShippingAddress: true,
      isBillingAddress: false
    });

  }


  ngOnInit(): void {
    this.route.queryParamMap.subscribe(param => {
      const encuid = param.get('UserID');
      const encredirect = param.get('URL');
      if (encuid && encredirect) {
        const UID = parseInt(this.appservice.decryptValue(encuid));
        this.redirectionurl = this.appservice.decryptValue(encredirect);

        this.GetCountryDropdownList();
        this.getUserDetails(UID);
      }
    })
  }

  getUserDetails(userid: number) {
    this.spinner.show();
    this.posservice.GetCustomer(userid).subscribe({
      next: (response) => {

        if (response.message === 'Success') {
          this.UserForm.patchValue({
            id: userid,
            email: response.result.email,
            firstName: response.result.name,
            mobile: response.result.phoneNumber,
            acnNumber:response.result.acnNumber,
            abnNumber:response.result.abnNumber,
          });

          response.result.addressList.map((item: { address: string, cityID: number, countryID: number, isBillingAddress: boolean, phoneCode: number, pinCode: string, stateID: number }) => {
            const type: string = item.isBillingAddress === true ? 'billing-address' : 'shipping-address';
            this.OnChangeCounry(item.countryID, type);
            this.OnChangeState(item.stateID, type)

            setTimeout(() => {
              if (item.isBillingAddress) {
                this.AddressForm1.patchValue({
                  address1: item.address,
                  country: item.countryID,
                  state: item.stateID,
                  city: item.cityID,
                  pinCode: item.pinCode,
                  isShippingAddress: !item.isBillingAddress,
                  isBillingAddress: item.isBillingAddress
                })

              } else {
                this.AddressForm2.patchValue({
                  address1: item.address,
                  country: item.countryID,
                  state: item.stateID,
                  city: item.cityID,
                  pinCode: item.pinCode,
                  isShippingAddress: !item.isBillingAddress,
                  isBillingAddress: item.isBillingAddress
                });
              }

            }, 500);
          });
        } else {
          console.error(response.message);
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 300);
      },
      error: (err) => {
        console.error('Error in getUserDetails:', err);
        this.spinner.hide();
      }
    })

  }






  get GetUserFormcontrols() {
    return this.UserForm.controls;
  }
  keyPressNumbers1(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  get GetAddress1Formcontrols() {
    return this.AddressForm1.controls;
  }
  get GetAddress2Formcontrols() {
    return this.AddressForm2.controls;
  }


  resetMasterForm() {
    const countryID = this.CountryDropdownList.find(x => x.name.toUpperCase() === 'AUSTRALIA').id;
    this.GetCountryDropdownList();
    this.CityDropdownList1 = [];
    this.CityDropdownList2 = [];
    this.UserForm.reset();
    this.AddressForm1.reset();
    this.AddressForm2.reset();
    this.UserForm.patchValue({
      id: 0,
      userTypeID: 4,
      loginUserID: 0,
      isOnlineRegistration: false,
    });

    this.AddressForm1.patchValue({
      id: 0,
      userID: 0,
      isShippingAddress: false,
      isBillingAddress: true,
      country: countryID
    });

    this.AddressForm2.patchValue({
      id: 0,
      userID: 0,
      isShippingAddress: true,
      isBillingAddress: false,
      country: countryID,
    });
    $('#Add-customer-details').modal('hide');
    $('#common-address').prop('checked', false);
    this.router.navigate([this.redirectionurl]);
  }

  GetCountryDropdownList() {
    this.posservice.GetCoruntryDropdownList().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const ID = response.result.find(x => x.name.toUpperCase() === 'AUSTRALIA').id;
          this.OnChangeCounry(ID, 'billing-address');
          this.OnChangeCounry(ID, 'shipping-address');
          this.CountryDropdownList = response.result;
          setTimeout(() => {
            this.AddressForm1.patchValue({
              country: ID
            });
            this.AddressForm2.patchValue({
              country: ID
            });
          }, 500);
        } else { console.error(response.message) };
      },
      error: (err) => {
        this.toastr.error(err)
      }
    })
  };


  OnChangeCounry(Id: any, type: string) {
    if (type === 'billing-address') {
      this.StateDropdownList1 = [];
      this.CityDropdownList1 = [];
      this.AddressForm1.patchValue({
        state: null,
        city: null
      });
      this.onChangeUserFields(Id, 'country');
      this.posservice.GetStateDropdownListByCountryID(Id).subscribe({
        next: (response) => {
          if (response.message === "Success") {
            this.StateDropdownList1 = response.result;
          } else { console.error(response.message) };
        },
        error: (err) => {
          this.toastr.error(err)
        }
      })


    }
    else {
      this.StateDropdownList2 = [];
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        state: null,
        city: null
      });
      this.posservice.GetStateDropdownListByCountryID(Id).subscribe({
        next: (response) => {
          if (response.message === "Success") {
            this.StateDropdownList2 = response.result;
          } else { console.error(response.message) };
        },
        error: (err) => {
          this.toastr.error(err)
        }
      })
    }
  }


  OnChangeState(Id: any, type: string) {
    if (type === 'billing-address') {
      this.CityDropdownList1 = [];
      this.AddressForm1.patchValue({
        city: null,
      });
      this.onChangeUserFields(Id, 'state');
      this.posservice.GetCityDropdownListByStateID(Id).subscribe({
        next: (response) => {
          if (response.message === "Success") {
            this.CityDropdownList1 = response.result;
          } else { console.error(response.message) };
        },
        error: (err) => {
          this.toastr.error(err)
        }
      })


    }
    else {
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        city: null
      });
      this.posservice.GetCityDropdownListByStateID(Id).subscribe({
        next: (response) => {
          if (response.message === "Success") {
            this.CityDropdownList2 = response.result;
          } else { console.error(response.message) };
        },
        error: (err) => {
          this.toastr.error(err)
        }
      })
    }
  }


  onChangeUserFields(val: any, type: string) {
    if (!this.IsCommonAddress) return;
    if (type === 'address') {
      this.AddressForm2.patchValue({
        address1: val,
      });
    };
    if (type === 'country') {
      this.StateDropdownList2 = [];
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        country: val,
        state: null,
        city: null,
      });
      this.posservice.GetStateDropdownListByCountryID(val).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.StateDropdownList2 = response.result;
          } else { this.StateDropdownList2 = [] };
        },
        error: (err) => {
          console.error("Error in GetStateDropdownListByCountryID", err);
        }
      })


    }
    if (type === 'state') {
      this.CityDropdownList2 = [];
      this.AddressForm2.patchValue({
        state: val,
        city: null
      });
      this.posservice.GetCityDropdownListByStateID(val).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.CityDropdownList2 = response.result;
          } else { this.CityDropdownList2 = [] };
        },
        error: (err) => {
          console.error("Error in GetCityDropdownListByStateID", err);
        }
      })

    };
    if (type === 'city') {
      this.AddressForm2.patchValue({
        city: val,
      });

    };
    if (type === 'pincode') {
      this.AddressForm2.patchValue({
        pinCode: val,
      });
    }

  }


  CommonAddress(checked: boolean) {
    if (checked) {
      this.IsCommonAddress = true;
      this.spinner.show();
      const billingAddress = this.AddressForm1.value;
      this.posservice.GetStateDropdownListByCountryID(billingAddress.country).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.StateDropdownList2 = response.result;
          } else {
            console.error(response.message);
            this.spinner.hide();
          };
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        },
        error: (err) => {
          this.toastr.error(err)
          this.spinner.hide();
        }
      });


      this.posservice.GetCityDropdownListByStateID(billingAddress.state).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.CityDropdownList2 = response.result;
          } else {
            console.error(response.message);
            this.spinner.hide();
          };

          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        },
        error: (err) => {
          this.toastr.error(err)
          this.spinner.hide();

        }
      });
      this.AddressForm2.patchValue({
        address1: billingAddress.address1,
        country: billingAddress.country,
        state: billingAddress.state,
        city: billingAddress.city,
        pinCode: billingAddress.pinCode,
      });
    } else {
      this.IsCommonAddress = false;
      const ID = this.CountryDropdownList.find(x => x.name.toUpperCase() === 'AUSTRALIA').id;
      this.OnChangeCounry(ID, 'shipping-address');
      this.AddressForm2.reset();
      this.AddressForm2.patchValue({
        id: 0,
        userID: 0,
        isShippingAddress: true,
        isBillingAddress: false,
        country: ID
      });
    }
  }


  ManageUserDetails() {
    if ((this.UserForm.valid && this.AddressForm1.valid && this.AddressForm2.valid)) {
      this.spinner.show();
      this.posservice.ManageUserDetails(this.UserForm.value).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            const user = response.result;
            const addresslist = new Array();
            this.AddressForm1.patchValue({
              userID: user.id,
            });
            this.AddressForm2.patchValue({
              userID: user.id,
            });

            addresslist.push(this.AddressForm1.value);
            addresslist.push(this.AddressForm2.value);
            this.posservice.ManageAddressMaster(addresslist, 'update-adddress').subscribe({
              next: (response1) => {
                if (response1.message === 'Success') {
                  this.toastr.success(response.activity);
                  this.resetMasterForm();
                };
                setTimeout(() => {
                  this.spinner.hide();
                }, 500);
              },
              error: (err) => {
                this.toastr.error(err)
                this.spinner.hide();
              }
            })
          } else {
            this.toastr.error(response.message);
            this.spinner.hide();
          }
        },
        error: (err) => {
          this.toastr.error(err)
          this.spinner.hide();
        }
      })
    } else {
      this.UserForm.markAllAsTouched();
      this.AddressForm1.markAllAsTouched();
      this.AddressForm2.markAllAsTouched();
    }
  }



}
