import { Component, OnInit } from '@angular/core';
import { ProductlistService } from './productlist.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import * as CryptoJS from 'crypto-js';
import { on } from 'events';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AddproductService } from '../addproduct/addproduct.service';
import { error, timeStamp } from 'console';
import { Key } from 'protractor';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css']
})
export class ProductlistComponent implements OnInit {
  encPassword = 'neha@hexa';
  readonly rootUrl = environment.baseUrl.slice(0, -3);
  // ******pagination*******


  public paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems:0,

  }


  Type = 'Product';
  searchString: string = '';
  ProductStock: any;
  ProductID: any;
  ProductDetails: any;
  ProductCoverImg: any;   //cover Image

  ProductOtherImages = [];

  public listproductdetail: Array<any> = [];
  public listproductdetail1: Array<string> = [];
  private CategoryID: number = 0;
  private SubCategoryLevel1ID: number = 0;
  public CategoryList: Array<any> = [];
  public SubCategoryLevel1List: Array<any> = [];
  public activedeacSubCatTwo: any = {};
  public selectedItemColor: any;
  public ProductitemID: any;
  public ItemCode: any;
  public PID: any;
  public Active: boolean | string = true;
  private OnlineOffline:number=0;
private LoginUserID;
  constructor(private router: Router,
    private toastr: ToastrService,
    public productlistservices: ProductlistService,
    private spinner: NgxSpinnerService,
    private addproductservice: AddproductService,
    private appservice: AppService) {

  }

  ngOnInit(): void {
    this.getproductlist();
    this.GetCategoryList();

    this.LoginUserID=this.appservice.getUserID();
  }

  GetCategoryList() {
    this.addproductservice.GetCategoryList().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.CategoryList = response.result;
        }
      },
      error: (err) => { console.error(err) }
    })
  }


  getproductlist() {
    this.spinner.show();
    this.productlistservices.productDetailList(this.CategoryID, this.SubCategoryLevel1ID, this.Active, this.searchString,this.OnlineOffline,this.paginationConfig.currentPage,this.paginationConfig.itemsPerPage).subscribe(data => {
      if (data.message === "Success") {
        data.result.forEach(element => {
          element.sizeList = element.sizeList.join(" , ");
        });
        this.listproductdetail = data.result;
        this.paginationConfig.totalItems=data.count;
      } else {
         this.listproductdetail = [];
         this.paginationConfig.totalItems=data.count; };
      this.spinner.hide();
    }, err => {
      this.toastr.error('', "Not Responding");
      this.spinner.hide();
    });
  }

  selectColor(colorID: any, Item: any) {
    $('.color-product').removeClass('selected-color');
    $(`#Variation-${Item.id}-${colorID}`).addClass('selected-color');
    this.selectedItemColor = colorID;
    this.ProductitemID = Item.id;
    this.GetAttribute();
  }

  GetAttribute() {
    this.productlistservices.GetItemCode(this.ProductitemID, this.selectedItemColor).subscribe(data => {
      if (data.message === "Success") {
        $(".item-code" + this.ProductitemID).html("Bar Code:- " + data.result.itemCode)
        this.PID = data.result.pid;
      }
    })
  }


  Filter(key: any, type: string) {
    if (type === 'CATEGORY') {
      this.addproductservice.GetSubCategoryList(key).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.SubCategoryLevel1List = response.result;
          } else { this.SubCategoryLevel1List = [] }
        },
        error: (err) => {
          this.toastr.error(err)
        }
      });
      this.SubCategoryLevel1ID = 0;
      this.CategoryID = key > 0 ? key : 0;
    }
    if (type === 'SUBCATEGORYLEVEL1') {
      this.SubCategoryLevel1ID = key > 0 ? key : 0;
    }
    if (type === 'ACTIVE-INACTIVE') {
      this.Active = key;
    }
    if (type === 'BY-SEARCH') {
      this.searchString = key.trim();
    }
    if(type==='BY-ONLINE-OFFLINE'){
      this.OnlineOffline=key;
    }
    this.paginationConfig.currentPage = 1;
    this.getproductlist();
  }


  // ************pagination**********
  onTableDataChange(event: any) {
    this.paginationConfig.currentPage = event;
    this.getproductlist();
  }


  onview(id) {
    this.productlistservices.getProductDetailbyId(id).subscribe(data => {
      console.log("SingleProductDetails", data.result);
      var RootUrlImg = environment.baseUrl.slice(0, -3);
      this.ProductDetails = data.result;
      let ImageDetails = data.result.productImage;
      this.ProductOtherImages = [];
      for (let i = 0; i < ImageDetails.length; i++) {
        if (ImageDetails[i].isCoverImage) {
          let CoverImagePath = RootUrlImg + ImageDetails[i].imagePath + ImageDetails[i].coverImageName;
          this.ProductCoverImg = CoverImagePath
        }
        else {
          let OtherImg = RootUrlImg + ImageDetails[i].imagePath + ImageDetails[i].otherImageName;
          if (!this.ProductOtherImages.includes(OtherImg)) {
            this.ProductOtherImages.push(OtherImg);
          }

        }
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    })
  }


  onEdit(catgry: any, id: number) {
    this.router.navigate(['/manageProduct'], { queryParams: { ProductID: catgry.id, ProductName: catgry.productName } });
  }



  ActivedeactiveProduct(id: number, IsActive: boolean) {

    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "All product items under this will be deactivated. Are you sure you want to deactivate the product?" : "Are you sure you want to proceed with activating the product?",
      icon: 'question',
      showCancelButton: true,
      showDenyButton: !IsActive,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      denyButtonColor: '#65B741',
      confirmButtonText: IsActive ? "Confirm" : "Confirm",
      denyButtonText: 'Activate All Levels',
    }).then((result) => {
      if (result.isConfirmed || result.isDenied) {
        const type = result.isDenied && !IsActive ? 'All' : result.isConfirmed && IsActive ? 'All' : 'Main-Active';
        const Action = IsActive ? 'deactivated' : ' activated ';
        const Isactive = IsActive ? false : true;
        this.productlistservices.ActiveInactiveProduct(id, Isactive, type,this.LoginUserID).subscribe(res => {
          if (res.message === 'Success') {
            this.toastr.success('Product has been ' + Action + ' successfully.');
            this.getproductlist();
          };
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#ActiveInactive' + id).prop("checked", true)
        };
        function uncheck() {
          $('#ActiveInactive' + id).prop("checked", false)
        };

        IsActive ? check() : uncheck();
      }
    })

  }



  AddProductInFeatured(ProductID: number, IsActive: any) {

    let action = 'added to';
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to remove this item from the Featured Product? " : "Are you sure you want to add this item in the Featured Product?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (IsActive) { action = 'removed from' };
        this.productlistservices.AddProductInFeatured(ProductID).subscribe(res => {
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
          if (res.message == 'Success') {
            this.toastr.success('Product has been successfully  ' + action + '  Featured Products. ');
            this.getproductlist();
          }

        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#checkBoxAinA' + ProductID).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + ProductID).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })

  }


  AddNewArrivalProduct(Id: number, IsNewArrival: boolean) {
    // if (this.IsProductEligibleForNewArrival() || IsNewArrival === true) {
    let action = 'added to'
    Swal.fire({
      // title: 'Are you sure?',
      title: IsNewArrival ? "Are you sure you want to remove this item from the New Arrival Product?" : "Are you sure you want to add this item in the New Arrival Product?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        if (IsNewArrival === true) {
          action = 'removed from';
        }
        this.productlistservices.AddNewArrivalProduct(Id).subscribe(res => {
          if (res.message == 'Success') {
            this.listproductdetail;
            this.getproductlist();
            this.toastr.success('Product has been successfully  ' + action + '  New Arrival Product. ');
          } else if (res.message === 'maximum-limit-completed') {

            $('#IsNewArrival' + Id).prop("checked", false);
            Swal.fire({
              title: "A maximum of two 'New Arrivals' products is permitted. Please remove one existing product before adding a new one.",
              icon: "question"
            });

          };
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#IsNewArrival' + Id).prop("checked", true)
        };
        function uncheck() {
          $('#IsNewArrival' + Id).prop("checked", false)
        }

        IsNewArrival ? check() : uncheck();
      }
    })

    // }
    // else {
    //   $('#IsNewArrival' + Id).prop("checked", false);
    //   Swal.fire({
    //     // title: "Oops!!",
    //     title: "A maximum of two 'New Arrivals' products is permitted. Please remove one existing product before adding a new one.",
    //     icon: "question"
    //   });
    // }
  }


  IsProductEligibleForNewArrival(): boolean {
    let Newarrivalcount = this.listproductdetail.filter(item => {
      return (item.isNewArrival === true);
    });
    return (Newarrivalcount.length < 2)
  }

}
