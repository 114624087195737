import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PassThrough } from 'stream';

@Injectable({
  providedIn: 'root'
})
export class PosService {
  private readonly rootRURL=environment.baseUrl;
  constructor(private http:HttpClient) {  }


  GetInvoiceTypeList():Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Pos/GetInvoiceTypeList');
  }
  GetLastInvoiceID():Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Pos/GetLastInvoiceID');
  }
  GetCustomer(id:number){
    return  this.http.get<any>(this.rootRURL+'/Pos/GetUserdetailsByID',{params:{id}});
  }
  GetUserDropdownList(typeid:any,Active:boolean):Observable<any>{
    return this.http.get<any>(this.rootRURL+'/User/GetUserMasterAll',{params:{typeid,Active}});
  }
  GetProductDropdownList():Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Pos/GetProductDropdownList');
  }
  GetProductItemByID(ID:number):Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Pos/GetProductItemByID',{params:{ID}})
  }
  GetCoruntryDropdownList():Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Common/GetAllCountry');
  }
  GetStateDropdownListByCountryID(countryID:number):Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Common/GetStateByCountryID',{params:{countryID}});
  }
  GetCityDropdownListByStateID(stateID:number):Observable<any>{
    return this.http.get<any>(this.rootRURL+'/Common/GetCityByStateID',{params:{stateID}});
  }
  ManageUserDetails(userdetails:any):Observable<any>{
    return this.http.post<any>(this.rootRURL+'/User/ManageUserMaster',userdetails);
  }
  ManageAddressMaster(addresslist:any,Type:string):Observable<any>{
    return this.http.post<any>(this.rootRURL+'/Pos/ManageAddressMaster',addresslist,{params:{Type}});
  }
  ManageInvoiceMaster(invoicedata:any):Observable<any>{
    return this.http.post<any>(this.rootRURL+'/Pos/ManageInvoiceDetails',invoicedata)
  }
  ManageInvoiceItems(ItemList:any,InvoiceID:number,InvoiceNumber:string):Observable<any>{
    return this.http.post<any>(this.rootRURL+'/Pos/ManageInvoiceItems',ItemList,{params:{InvoiceID,InvoiceNumber}});
  }
  ManageGuestUser(GuestUser:any):Observable<any>{
    return this.http.post<any>(this.rootRURL+'/Pos/ManageGuestUser',GuestUser);
  }
  IsValidUser(value:string,type:string):Observable<any>{
    return this.http.get<any>(this.rootRURL+'/User/IsValidUser',{params:{value,type}});
  }
}
