import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AddproductService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http: HttpClient) { }
  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localdata.userToken
  });
  data1: any = {};
  val: any = {};
  objj: any = {};
  public data2: Array<any> = [];
  GetCategoryList() {
    return this.http.get<any>(this.rootUrl + '/MainMenu/dropdownCategory');
  }

  GetSubCategoryList(id:any) {
    return this.http.get<any>(this.rootUrl + '/MainMenu/dropdownSubcategory', { params: { id } });
  }

  GetSubTwoCategoryList(id: number) {
    return this.http.get<any>(this.rootUrl + '/MainMenu/dropdownSubcategoryTwo', { params: { id } });
  }

  GetProductByID(id: number): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Product/GetProductByIDAdmin', { params: { id } });
  }

  GetAllItemBarCode():Observable<any>{
return this.http.get<any>(this.rootUrl+'/Product/GetAllItemBarCode');
  }

  GetProductDetailsBYProductID(ID: number): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Product/GetProductDetailsByProductIDAdmin', { params: { ID } });
  }

  productDetailCount() {
    return this.http.get<any>(this.rootUrl + '/Product/getProductCount');
  }
  getProductTypes() {
    return this.http.get<any>(this.rootUrl + '/Product/getProductSizeswithID');
  }
  productDetailByID(productImage) {
    return this.http.get<any>(this.rootUrl + '/Product/GetProductImagesByID?id=' + productImage);
  }
  RemoveProductImagesByID(productImage) {
    return this.http.get<any>(this.rootUrl + '/Product/RemoveProductImagesByID?id=' + productImage);
  }

  AddEditproduct(MasterProduct: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/Product/AddEditProduct', MasterProduct);
  }

  AddEditProductDetail(productdetail: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/Product/AddEditProductDetail', productdetail);
  }

  ManageAssignCategories(assignCategories:any):Observable<any>{
    return this.http.post<any>(this.rootUrl+'/Product/AssginProductCategories',assignCategories)

  }

  UploadProductImages(formdata: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/Product/UploadProductImages', formdata);
  }

  AddEditproductWeight(productData) {
    productData.ID = productData.ID2;
    this.data2 = [];
    productData.forEach(e => {
      this.data2.push(e);
    });
    this.objj.data = this.data2;
    var objj = this.objj;
    const reqHeader = new HttpHeaders({
      // 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.localdata.userToken
    });


    return this.http.post<any>(this.rootUrl + '/Product/AddEditWeightAndPrice', objj, { headers: reqHeader });
  }
  RemoveWeightAndPriceById(productprice) {
    return this.http.get<any>(this.rootUrl + '/Product/RemoveWeightAndPriceById?id=' + productprice);
  }
  ActiveInactivePriceandSize(active) {

    return this.http.post<any>(this.rootUrl + '/Product/ActiveInactiveProductsSizeandPrice', active, { headers: this.reqHeader });
  }
  getProductsName() {
    return this.http.get<any>(this.rootUrl + '/Product/GetProductNames');
  }


  GetLastProductId() {
    return this.http.get<any>(this.rootUrl + '/Product/GetLastProductDetailID');
  }
  ActiveInactiveProductDetail(id:number,UserID:number): Observable<any> {
    return this.http.get<any>(this.rootUrl+'/Product/ActiveInactiveProductDetail',{params:{id,UserID}});
  }


}
