<section class="content-header sub-head">
  <h1> SMTP </h1>

</section>
<section>
  <div class="row">
    <!-- left column -->
    <div class="col-md-12">
      <div class="box box-danger">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="box-body">
            <div class="row mb-3 mt-2 smpt">
              <div class="col-md-3">
                <label for="Host" class="form-label">Host Address <span class="cstm-required"
                    style="color: red;">*</span>
                </label>
                <input type="text" id="Host" formControlName="host" name="host" placeholder="Enter SMTP Host Address"
                  class="form-control ng-pristine ng-invalid ng-touched">
                <span *ngIf="f.host.touched" class="validation-error">
                  <span *ngIf="f.host.errors?.required">This Field is required </span>
                </span>
              </div>


              <div class="col-md-3">
                <label for="Port" class="form-label">Port Number <span class="cstm-required"
                    style="color: red;">*</span>
                </label>
                <input type="text" id="Port" formControlName="port" name="port" placeholder="Enter SMTP Port Number"
                  class="form-control ng-pristine ng-invalid ng-touched">
                <span *ngIf="f.port.touched" class="validation-error">
                  <span *ngIf="f.port.errors?.required">This Field is required </span>
                </span>
              </div>


              <div class="col-md-3">
                <label for="Username" class="form-label">User&nbsp;Name <span class="cstm-required"
                    style="color: red;">*</span>
                </label>
                <input type="text" id="Username" formControlName="username" name="username" placeholder="User Name"
                  class="form-control ng-pristine ng-invalid ng-touched">
                <span *ngIf="f.username.touched" class="validation-error">
                  <span *ngIf="f.username.errors?.required">This Field is required </span>
                </span>
              </div>


              <div _ngcontent-hmh-c36="" class="col-md-3" style="margin-bottom: 3px !important; ">
                <label for="Password" class="form-label">E-mail Password <span class="cstm-required"
                    style="color: red;">*</span>
                </label>
                <input type="password" id="Password" formControlName="password" name="password"
                  placeholder="E-mail Password" class="form-control ng-pristine ng-invalid ng-touched">
                  
                <span *ngIf="f.password.touched" class="validation-error">
                  <span *ngIf="f.password.errors?.required">This Field is required </span>
                </span>
              </div>
            </div>


            <div class="row mb-3 mt-2 smpt-new">
              <div class="col-md-6 smpt-input">
                <span> <input _ngcontent-goy-c31="" type="checkbox" name="ssl" formControlName="ssl"
                    id="checkBoxAinA241">
                  Enable SSL</span>
              </div>
              <div class="col-md-6 smpt-button">
                <ul class="list-inline wizard mb-0 mt-5">
                  <li class="next ">
                    <button type="submit" class="btn btn-primary waves-effect waves-light mb-2 me-2 hlo"
                      id="btnsubmit">Save </button>
                    <button type="reset" class="btn btn-secondary waves-effect waves-light mb-2">Cancel</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>



  <section class="content-header sub-head ">
    <h1>
      SMTP List
    </h1>

  </section>
  <div class="box">
    <!-- <div class="box-header">
      <div class="search-box-new">
        <div class="row">
          <div class="col-md-12 text-right">

        <select  class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
       
          <option  value="ONEWEEK" selected>Active&nbsp;List</option>
          <option value="ONEMONTH">Deactive&nbsp;List</option>
          <option value="THREEMONTHS">All</option>
          
        </select>
          </div>
        </div>
      </div>
     
    </div> -->
    <div class="box-body">
      <div class="row nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table id="example1" class="table text-center table-bordered table-striped">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>User&nbsp;Name</th>
                  <th>Host&nbsp;Address</th>
                  <th>Port&nbsp;Number</th>
                  <th>SSL</th>
                
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SmtpList |  paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };let i=index">
                  <td>{{i+1+tableSize*(page-1)}}</td>
                  <td>{{item.username}}</td>
                  <td>{{item.host}}</td>
                  <td>{{item.port}}</td>
                  <td>{{item.ssl}}</td>
               
                  <td>
                    <button class="btn btn-icon m-1 p-1 mr-2">
                      <input type="checkbox" id="checkBoxAinA{{item.id}}" value="isActive" name="isActive"
                        (change)="ActiveInactiveSMTP(item.id,item.isActive)" [checked]="item.isActive">
                    </button>
                    <button type="button" class="btn btn-icon" (click)="onEdit(item)" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              </tbody>

            </table>
            <div *ngIf="SmtpList.length>0;else elseblock">

            </div>
          </div>
          <div class="row mt-3" style="margin-right:0;" *ngIf="SmtpList.length>10">
            <div class="col-md-3">
              <select (change)="onTableSizeChange($event.target.value)" class="form-control w-auto">
                <option *ngFor="let tblsize of tableSizes" [ngValue]="tblsize">{{tblsize}}</option>
              </select>


            </div>
            <div class="col-md-9 text-right">
              <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- /.box-body -->
  </div>
</section>
<ng-template #elseblock>
  <div class="no-data">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <h4>No Data Found</h4>
  </div>
</ng-template>