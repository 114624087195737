import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appendFile } from 'fs';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecivedreviewService {
  private rooturl=environment.baseUrl;
  

  constructor(private http:HttpClient) { }

  GetRecivedReviews(Approve:any):Observable<any>{
    return this.http.get<any>(this.rooturl+'/Product/GetRecivedReviews',{params:{Approve}});
  }

  ApproveRejectReview(Id,IsApprove):Observable<any>{
    return this.http.get<any>(this.rooturl+'/Product/ApproveRejectReview',{params:{Id,IsApprove}});

  }

}
