<!-- Content Header (Page header) -->
<div class="dash"></div>

<section class="content-header sub-head ">

  <h1>
    Dashboard

  </h1>
</section>
<div class=" areas">

  <section class="content sub-cat">
    <div class="box">
      <div class="box-body">
        <div class="dash-boad-inner">
          <div class="row" style="margin: 0px;">
            <div class="col-xxl-9 col-12" style="padding: 0px;">
              <div class="row" style="margin: 0px;">
                <div class="col-xl-4 col-md-6 col-12" (click)="goto('/orders','recentorders')">
                  <div class="dsh-int">
                    <div class="icon-dsh">
                      <i class="fa fa-cart-plus" aria-hidden="true"></i>
                    </div>
                    <div class="cntent-dsh">
                      <span> Recent Orders</span>
                      <h5 class="number-sale">{{Counts?.recentordrscount}}</h5>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 col-12" (click)="goto('/orders','inprocessorders')">
                  <div class="dsh-int">
                    <div class="icon-dsh">
                      <i class="fa fa-cart-plus" aria-hidden="true"></i>
                    </div>
                    <div class="cntent-dsh">
                      <span> Orders In-process </span>
                      <h5 class="number-sale">{{Counts?.inprocessorderscount}}</h5>
                    </div>
                  </div>
                </div>


                <div class="col-xl-4 col-md-6 col-12">
                  <div class="dsh-int sale">
                    <div class="icon-dsh">
                      <i class="fa fa-line-chart" aria-hidden="true"></i>
                    </div>
                    <div class="cntent-dsh">
                      <span> Total Sale</span>
                      <h5 class="number-sale">{{Counts?.totalsaleamount}}</h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 col-12" routerLink="/viewusers">
                  <div class="dsh-int user">
                    <div class="icon-dsh">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                    <div class="cntent-dsh">
                      <span> Total Registered Users</span>
                      <h5 class="number-sale">{{Counts?.totaluserscount}}</h5>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 col-12" routerLink="/low-inventory">
                  <div class="dsh-int low">
                    <div class="icon-dsh">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </div>
                    <div class="cntent-dsh">
                      <span> Low Inventory Products</span>
                      <h5 class="number-sale">{{Counts?.lowinventoryproductscount}}</h5>
                    </div>
                  </div>
                </div>
              </div>




              <div class="col-md-12">
                <div class="bar-char">
                  <div class="cht-info d-flex justify-content-between">
                    <span>Earning Revenue</span>
                    <div class="year-select">
                      <select (change)="onChangeYear()"  [(ngModel)]="selectedYear" name="Discount" id="year-get" class="form-control">
                        <option *ngFor="let item of YearList" [value]="item">{{item}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-item-center">
                    <canvas baseChart class="chart" [data]="barChartData" [options]="barChartOptions"
                      [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                    </canvas>
                  </div>
                </div>


              </div>

              <div class="col-md-12 mt-4" *ngIf="RecentOrderList.length">
                <div class="inner-pad">
                  <div class="cht-info">
                    <span> Recent Order </span>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <tr>
                        <th>S.NO</th>
                        <th> Image </th>
                        <th style="text-align: left !important;">Product </th>
                        <th>Order Date</th>
                        <th> Order Status </th>
                      </tr>
                      <tr *ngFor="let item of RecentOrderList|paginate:PaginationConfig;let i=index">
                        <td>{{((PaginationConfig.currentPage-1)*PaginationConfig.itemsPerPage)+i+1}}</td>
                        <td><img [src]="RootURL+item.productImage" (error)="setDefaultImage($event)" [alt]="item.productName"></td>
                        <td style="text-align: left !important;">{{item.productName}}</td>
                        <td>{{item.orderDate|date:'dd-MMM-yyyy'}}</td>
                        <td><span class="badge badge-primary"> {{item.status}}</span></td>
                      </tr>
                    </table>
                  </div>
              
                </div>
                <div class=" mr-5" style="  margin-top: 13px;  " *ngIf="PaginationConfig.totalItems>PaginationConfig.itemsPerPage">
                  <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
                </div>
              </div>




            </div>


            <div class="col-xxl-3 col-12">
              <div class="row f-row" style="margin: 0px;">

                <div class=" col-xxl-12 col-lg-6 col-12 top-products-new">
                  <div class="top-products">
                    <div class="heading">
                      <h4> Top Products </h4>
                    </div>
                    <ul>
                      <li *ngFor="let item of topProductList">
                        <div class="product-item">
                          <div class="product-image">
                            <img [src]="RootURL+item.productImage" (error)="setDefaultImage($event)" [alt]="item.productName">
                          </div>
                          <div class="item-info">
                            <h4> {{item.productName}}</h4>
                            <span>AUD&nbsp;{{item.price}}</span>
                            <div class="badg">
                              <p>Inventory Stock <span class="badge badge-secondary">{{item?.stock}}</span></p>
                            </div>

                          </div>

                        </div>

                      </li>


                    </ul>
                  </div>
                </div>
                <div class=" col-xxl-12 col-lg-6 col-12 mt-4">
                  <div class="other-info2">
                    <div class="cht-info text-center"><span>Recent Order</span></div>
                    <div class="d-flex justify-content-center align-item-center">
                      <canvas baseChart [type]="'pie'" [datasets]="pieChartDatasets" [options]="pieChartOptions"
                        [plugins]="pieChartPlugins" [legend]="pieChartLegend" class="new-pie-chart">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>




            </div>


          </div>




        </div>
      </div>
    </div>


  </section>


</div>