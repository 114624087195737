
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import domToImage   from 'dom-to-image';
import { ToastrService } from 'ngx-toastr';
import { SalesreportService } from 'src/app/pages/sales-report/salesreport.service';
import { AppService } from 'src/app/app.service';
import { totalmem } from 'os';
import { promise } from 'selenium-webdriver';
declare const $:any;

@Component({
  selector: 'app-orders-report',
  templateUrl: './orders-report.component.html',
  styleUrls: ['./orders-report.component.css']
})
export class OrdersReportComponent implements OnInit,AfterViewInit {
  public orderList: any[] = [];
  public Rooturl: string = environment.baseUrl.slice(0, -3);
  @ViewChild('dataToExport') private dataToExport: ElementRef;
  @ViewChild('date_filter') private dateFilter: ElementRef;
  public paginationconfig = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 1
  };
  public fromDate: string;
  public toDate: string;

  constructor(private salesservice: SalesreportService, private spinner: NgxSpinnerService, private appservice: AppService, private datepipe: DatePipe,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    let date = new Date();
    const d2date = new Date();
    d2date.setDate(date.getDate() - 7);
    this.fromDate = this.datepipe.transform(d2date, 'yyyy-MM-dd');
    this.toDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.loadGridData();
  }

  ngAfterViewInit(): void {
    const beforeOneWeekDate=new Date();
    beforeOneWeekDate.setDate(new Date().getDate()-7);
    this.dateFilter.nativeElement.flatpickr({
      mode: 'range',
      dateFormat: 'd/M/y',
      defaultDate:[beforeOneWeekDate,'today']
    });
  }


  Filter(key:string,value: any) {
    if(key==='by-date'){
      if (!value.includes('to')) return;
      const dates = value.split('to');
      this.fromDate = this.datepipe.transform(dates[0], 'yyyy-MM-dd');
      this.toDate = this.datepipe.transform(dates[1], 'yyyy-MM-dd');
    }
    else if(key==='by-pagination'){
      this.paginationconfig.currentPage=value;
    };
    this.loadGridData();
  }
  
  async loadGridData() {
    try {
      const data = await this.GetSalesOrderList('order-report');
      this.orderList = data.result??[];
      this.paginationconfig.totalItems = data.count;
    } catch (error) {
      this.toastr.error('Error loading grid data.');
    }
  }
     

  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }
  
  
  GetSalesOrderList(type:string): Promise<any> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.salesservice.GetSalesOrderList(this.fromDate,this.toDate,this.paginationconfig.currentPage,this.paginationconfig.itemsPerPage,type,1)
        .subscribe({
          next: (data) => {
            resolve(data); 
            setTimeout(() => this.spinner.hide(), 300);
          },
          error: (err) => {
            this.spinner.hide();
            console.error('Error in fetching orders', err);
            reject(err);
          },
        });
    });
  }
  

  onTableDataChange(nextpage: any) {
    this.paginationconfig.currentPage=nextpage;
    window.scroll({
      top:0,
      behavior:'smooth'
    });
  }


  
  async ExportDataInExcel() {
    try {
      const data = await this.GetSalesOrderList('order-report-xl');
      if (!data || !data.result || data.result.length === 0) {
        this.toastr.warning('No data available for export.');
        return;
      }
  
      const json = data.result.map((item: {
        productName: string,
        color: string,
        size: string,
        quantity: number,
        discount: any,
        price: number,
        orderDate: string,
        status: string,
        orderNo: string
      }) => {
        return {
          ItemOrder: item.orderNo,
          ProductName: item.productName,
          Color: item.color,
          Size: item.size,
          Quantity: item.quantity,
          Discount: item.discount,
          Price: item.price,
          OrderDate: this.datepipe.transform(item.orderDate, 'dd/MMM/yyyy'),
          Status: item.status,
        };
      });
  
      this.appservice.exportAsXLSXWithStyle(json, 'Sales Report');
      this.toastr.success('Sales report exported successfully.');
    } catch (error) {
      this.toastr.error('Error exporting sales report.');
      console.error('Export error:', error);
    }
  }
  
  


}
