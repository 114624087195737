import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OrdersService } from './orders.service';
import jsPDF from 'jspdf';
import domToImage from 'dom-to-image';
import * as CryptoJS from 'crypto-js';
declare let $: any;
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;


  public StatusID: AbstractControl;
  public orderlist: any[] = [];
  public OrderProductList: any;
  public UserID: string = '';
  public OrderStatus: any;
  public page: number = 1;
  public count: number = 0;
  public tableSize: number = 10;
  public tableSizes: any = [10, 15, 20];
  public OrderListstatus: any;
  public searchstring: string;
  private Type: string = '';
  public navigationSubscription: any;




  constructor(private appservice: AppService,
    public router: Router,
    public ordersService: OrdersService, private spinner: NgxSpinnerService, private toastr: ToastrService, private activatedRoute: ActivatedRoute,
    private activatedroute: ActivatedRoute) {


  }
  public getOrders: any;
  public StatusList: Array<any> = [];
  public CustomerProductList: Array<any> = [];
  public getOrderbyid: any = { OrderNo: '', OrderDate: '', OrderTotalAmount: '', OrderStatus: '', CustomerID: '', ShippingCharges: '' };


  ngOnInit(): void {
    let Param1 = this.activatedRoute.snapshot.queryParams['UserID'];
    let Param2 = this.activatedRoute.snapshot.queryParams['type'];
    this.navigationSubscription = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.Type="";
        this.UserID="";
        Param1 = this.activatedroute.snapshot.queryParams['UserID'];
        Param2 = this.activatedroute.snapshot.queryParams['type'];
        if (Param1 || Param2) {
          if (Param1) {
            this.UserID = this.appservice.decryptValue(Param1);
          };
          if (Param2) {
            this.Type = this.appservice.decryptValue(Param2);
          }
        };
        this.ReceivedOrderList('')
        window.scrollTo(0, 0);
      }
    });

    if (Param1 || Param2) {
      if (Param1) {
        this.UserID = this.appservice.decryptValue(Param1);
      };
      if (Param2) {
        this.Type = this.appservice.decryptValue(Param2);
      }
    }
    this.ReceivedOrderList('');
  }


  ngOnDestroy() {
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }
  SearchOrder(val: string) {
    this.searchstring = val;
  }
  onTableDataChange(nextpage: any) {
    this.page = nextpage;
  }

  // onTableSizeChange(TableSize: any) {
  //   this.tableSize = TableSize;
  // }
  showOrderDetails(orderid: number) {
    const encryt = CryptoJS.AES.encrypt(orderid.toString(), "neha@hexa").toString();
    setTimeout(() => {
      this.router.navigate(['/viewdetail'], { queryParams: { Eid: encryt } });
    }, 500);
  }

  ReceivedOrderList(filter: string) {
    this.spinner.show();
    let d = new Date();
    if (filter === 'ONEWEEK') {
      d.setDate(d.getDate() - 7);
    }
    else if (filter === 'ONEMONTH') {
      d.setMonth(d.getMonth() - 1);
    }
    else if (filter === 'THREEMONTHS') {
      d.setMonth(d.getMonth() - 3);
    }
    const D = filter !== '' ? d.toISOString().split('T')[0] : '';
    this.ordersService.ReceivedOrderList(this.UserID, D, this.Type).subscribe(data => {
      this.spinner.hide();
      if (data.message == "Success") {
        this.orderlist = data.result;
      } else { this.orderlist = [] }
    })
  }


  ExportDataInExcel() {
    var json = this.orderlist;
    json = json.map((rec: { itemID: any,  discountPrecentage: any, orderAmount: any, orderDate: string,quantity:number,status:string }) => {
      return {
        'Item Order#': rec.itemID,
        'Total Order Amount': (rec.discountPrecentage>0?(rec.orderAmount-(rec.orderAmount*rec.discountPrecentage/100))*rec.quantity:rec.orderAmount*rec.quantity).toFixed(2),
        'Discount (%)':rec.discountPrecentage>0?rec.discountPrecentage:0,
        'Quantity':rec.quantity,
        'Order Date': rec.orderDate,
        'Status':rec.status
      };
    });
    this.appservice.exportAsXLSX(json, 'Orders');
  };

  ExprortDataInPDF(): void {
    debugger
    const width = this.dataToExport.nativeElement.clientWidth;
    const height = this.dataToExport.nativeElement.clientHeight + 40;
    domToImage.toPng(this.dataToExport.nativeElement, {
      width: width,
      height: height
    }).then(result => {
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'pt',
        format: [width + 100, height + 200]
      });
      pdf.setFontSize(24);
      pdf.setTextColor('#131523');
      pdf.text('Report date: ' + moment().format('ll'), 25, 115);
      pdf.addImage(result, 'PNG', 25, 185, width, height);
      pdf.save('Orders' + '.pdf');
    });


  }

}
