import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }



  ManageCompanySetting(bo:any):Observable<any>
  {
    return this.http.post<any>(this.rootUrl +'/Common/MasterCompanySetting',bo)
  }



  GetCompanySetting():Observable<any>
  {
    return this.http.get<any>(this.rootUrl +'/Common/GetCompanySetting')
  }


  ActiveInactiveSetting(ID) {

    return this.http.get<any>(this.rootUrl + '/Common/GetActiveCompanySetting', {params:{ID}});
  }
}
