import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StatusService } from './status.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
declare let $: any;



@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {

  // ******pagination********
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];

  // ************************


  public form: UntypedFormGroup;
  activeStatus: any = {};
  constructor(public router: Router, fb: UntypedFormBuilder, private spinner: NgxSpinnerService, private service: StatusService, private toastr: ToastrService) {
    this.form = fb.group({
      'id': 0,
      'statusName': ['', Validators.required],
      'statusDescription': ["", Validators.required]
    });

  }
  public statusList: Array<any> = [];

  ngOnInit(): void {
    this.GetStatus();
  }



  GetStatus() {
    this.spinner.show();
    this.service.GetStatusList().subscribe(data => {
      if (data.message == "Success") {
        this.statusList = data.result;
      }else{console.error(data.message)}
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    })


  }

  onSubmit(): void {
    if (this.form.valid) {
      this.form.patchValue({
        id: this.form.value.id == null ? 0 : this.form.value.id
      })
      this.service.ManageStatusMaster(this.form.value).subscribe(data => {
        if (data.message == 'Success') {
          if (this.form.get('id').value > 0) {
            this.spinner.hide();

            this.toastr.success("Data updated successfully");
          }
          else {
            
            this.toastr.success("Data saved  successfully");

          }
          document.getElementById('btnsubmit').innerHTML = 'Save';
          this.form.reset();
          this.GetStatus();
        }
        else {

          this.toastr.error('', data.message);
        }

        this.form.reset();
      });
      setTimeout(() => {
      }, 1000);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  onEdit(item: any) {
    this.form.patchValue({
      statusName: item.statusName,
      id: item.id,
      statusDescription: item.statusDescription
    });
    document.getElementById('btnsubmit').innerHTML = 'Update';
    window.scroll(0, 0);

  }





  ActiveInactiveStatus(id: number, IsActive: boolean) {
    ;

    // this.spinner.show();
    var action = 'activated';
    this.activeStatus.ID = id;
    this.activeStatus.Action = IsActive;
    // ******SweetAlert************
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the status?" : "Are you sure you want to activate the status?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.activeStatus.Action === true) {
          action = 'deactivated';
        }
        this.service.ActiveInactiveStatus(this.activeStatus.ID).subscribe(res => {
          this.GetStatus();
          this.toastr.success('Status has been ' + action + ' successfully.');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        
        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })
  }





  // ********pagination*************
  onTableDataChange(event: any) {
    this.page = event;
    this.GetStatus();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event;
    this.page = 1;
    this.GetStatus();
  }
  // *********************


  get f() {
    return this.form.controls;
  }
}
