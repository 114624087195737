import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CancelledordersService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http:HttpClient) { }
  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localdata.userToken
  });
  GetCancelledOrders() {

    return this.http.get<any>(this.rootUrl + '/Order/GetCancelledOrders', { headers: this.reqHeader });
  }
  GetOrderByID(orderid) {

    return this.http.get<any>(this.rootUrl + '/Order/GetOrderByID?orderID=' + orderid);
  
  }
}
