import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { SubCategoryTwoService } from './sub-category-two.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AppService } from 'src/app/app.service';
import { SeoManagementComponent } from '../seo-management/seo-management.component';
declare let $: any;
@Component({
  selector: 'app-sub-categories-two',
  templateUrl: './sub-categories-two.component.html',
  styleUrls: ['./sub-categories-two.component.css']
})
export class SubCategoriesTwoComponent implements OnInit {
  encPassword = 'neha@hexa';
  // *******pagination************
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];
  // *******************
  public SubCategoryTwoForm: any;
  public listmainmenus: Array<string> = [];
  public listsubCategories: Array<any> = [];
  public SubCategoriesLTwoList: Array<any> = [];
  public activedeacSubCatTwo: any = {};
  public SearchString: string='';
  private categoryid: number = 0;
  private subcategoryid: number = 0;
  public listcategorylevel1: any;
  public listsubCategorie: Array<any> = [];
  public Active: boolean | string = true;
  private LoginUserID:number;

  @ViewChild(SeoManagementComponent) seocomponent:SeoManagementComponent

  constructor(public router: Router, fb: UntypedFormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService,
    public subCategoryTwoService: SubCategoryTwoService,
  private appservice:AppService) {
    this.SubCategoryTwoForm = fb.group({
      id: 0,
      categoryid: [null, Validators.required],
      subCategoryID: [null, Validators.required],
      sub2CategoryName: ['', Validators.required],
      searchTag: ['', Validators.required],
      loginUserID:[0],
    });

  }


  ngOnInit(): void {
    this.getsubcategoryTwoList();
    this.MainCategoryDropdown();
    this.LoginUserID=this.appservice.getUserID();
  }

  MainCategoryDropdown() {
    this.spinner.show();
    this.subCategoryTwoService.getMainCategoryDropDown().subscribe(data => {
      if (data.message == "Success") {
        this.listmainmenus = data.result;
      }
      else {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }, err => {
      setTimeout(() => {
        this.spinner.hide();

      }, 500);

    })
  }

  getsubcategoryTwoList() {
    this.spinner.show();
    this.subCategoryTwoService.getSubCategoryTwoList(this.categoryid, this.subcategoryid, this.Active,this.SearchString).subscribe(data => {
      if (data.message == "Success") {
        this.SubCategoriesLTwoList = data.result;
      }
      else {
        this.SubCategoriesLTwoList = [];
        console.error(data.message);
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 500);

    }, err => {
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    });
  }

  // ******pagination**************
  onTableDataChange(event: any) {
    this.page = event;
    this.getsubcategoryTwoList();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event;
    this.page = 1;
    this.getsubcategoryTwoList();
  }
  // ******paginationEnd**************


  Filter(key: any, type: string) {
    if (type === 'BY-SEARCH') {
      this.SearchString = key.trim();
      this.Active='';
    }
    if (type === 'BY-CATEGORY') {
      if (key > 0) {
        this.categoryid = key;
        this.subCategoryTwoService.getSubCategoryDropwnlist(key).subscribe({
          next: (response) => {
            if (response.message === 'Success') {
              this.listcategorylevel1 = response.result;
            } else { this.listcategorylevel1 = [] }
          }
        });
      } else {
        this.categoryid = 0;
        this.listcategorylevel1 = [];
      }
      this.subcategoryid = 0;
    }
    if (type === 'BY-CATEGORYLEVEL1') {
      this.subcategoryid = key > 0 ? key : 0;
    }
    if (type === 'ACTIVE-INACTIVE') {
      this.Active = key;
    }
    this.getsubcategoryTwoList();

  }


  onChangeCategaory(CategoryID: number) {
    this.spinner.show();
    this.SubCategoryTwoForm.patchValue({
      subCategoryID: null
    });
    this.subCategoryTwoService.getSubCategoryDropwnlist(CategoryID).subscribe(data => {
      if (data.message == "Success") {
        this.listsubCategories = data.result;
      }
      else {
        this.listsubCategories = [];
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 500);

    }, err => {
      setTimeout(() => {
        this.spinner.hide();
        this.toastr.error('', "Not Responding");
      }, 500);
    })
  }

  onSubmit(): void {
    if (this.SubCategoryTwoForm.valid && this.seocomponent.isFormValid()) {
      this.spinner.show();
      this.SubCategoryTwoForm.patchValue({
        loginUserID:this.LoginUserID,
      });
      this.subCategoryTwoService.AddEditSubcategoriesTwo(this.SubCategoryTwoForm.value).subscribe(data => {
        if (data.message === 'Success') {
          this.toastr.success(data.activity);
          this.getsubcategoryTwoList();

          //Manage Seo Details
          this.seocomponent.seoForm.patchValue({
            slug:data.result.slug,
            type:'category-level2'
          });
          this.seocomponent.submitSEO();
          this.resetForm();
        }
        else {
          this.toastr.error('', data.message);
        }
      });
      setTimeout(() => {
        this.spinner.hide();
      },300);
    }
    else {
      this.SubCategoryTwoForm.markAllAsTouched();
      this.seocomponent.seoForm.markAllAsTouched();
    }
  }


  resetForm() {
    document.getElementById('btnsubmit').innerHTML = 'Submit';
    this.SubCategoryTwoForm.reset();
    this.SubCategoryTwoForm.patchValue({
      id: 0,
      isOnline: true,
      isOffline: true,
    });
    this.listsubCategories = [];
    $('#manage-subcategoryTwo').modal('hide');
    setTimeout(() => {
      this.seocomponent.resetSEO();
    },500);


  }

  onEdit(catgry: any) {
    this.spinner.show();
    this.seocomponent.bindSeoDetail('category-level2',catgry.slug)
    this.subCategoryTwoService.getSubCategoryDropwnlist(catgry.categoryID).subscribe(data => {
      if (data.message == "Success") {
        this.listsubCategories = data.result;
      };
    }, err => {
      this.toastr.error('', 'Not Responding');
    })
    window.scroll(0, 0);
    document.getElementById('btnsubmit').innerHTML = 'Update';
    setTimeout(() => {
      this.SubCategoryTwoForm.patchValue({
        id: catgry.id,
        categoryid: catgry.categoryID,
        subCategoryID: catgry.categorySubOneID,
        searchTag: catgry.searchTag,
        sub2CategoryName: catgry.subCategoryTwo,
        isOnline: catgry.isOnline,
        isOffline: catgry.isOffline,
      });
      this.spinner.hide();
      $('#manage-subcategoryTwo').modal('show');
    }, 500);

  }

  onChangeInput(key:string){
    this.seocomponent.seoForm.patchValue({
      metaTitle:key,
    });
  }

  ActivedeactiveSubCatTwo(id: number, IsActive: string) {
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Deactivating this category will also deactivate associated products. Are you sure you want to continue?" : "Are you sure you want to proceed with activating the category?",
      icon: 'question',
      showCancelButton: true,
      showDenyButton: !IsActive,
      denyButtonColor: '#65B741',
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      denyButtonText: 'Activate All Levels',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed || result.isDenied) {
        const type = result.isDenied && !IsActive ? 'All' : result.isConfirmed && IsActive ? 'All' : 'Main-Active';
        const Action = IsActive ? 'deactivated' : ' activated';
        const Isactive = IsActive ? false : true;
        this.subCategoryTwoService.getActiveDeactiveSubCatTwo(id, Isactive, type,this.LoginUserID).subscribe(res => {
          this.getsubcategoryTwoList();
          this.toastr.success('Menu has been ' + Action + ' successfully.');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })
  }


  get input() {
    return this.SubCategoryTwoForm.controls;
  }


  toggleCheck(elementid: string) {
    const checkbox = document.getElementById(elementid) as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = !checkbox.checked; // Toggle the checked state
    }
  }



  ListofSubCategories() {
    this.subCategoryTwoService.ListofSubCategories().subscribe(data => {
      if (data.message == "Success") {
        this.listsubCategorie = data.result;
      };
    })
  }


  eligebleFromParent(id: number, type: string) {
    const category = this.listsubCategorie.find(x => x.id === id);
    return type === 'change-online' ? category?.isOnline : category?.isOffline;
  }

  onChangeCategoryProps(subCategoryTwoID: number, type: string, ischecked: boolean) {
    debugger

    const subCategoryTwo = this.SubCategoriesLTwoList.find(x => x.id === subCategoryTwoID);
    console.log('id', subCategoryTwo)
    if (!ischecked) {
      if (!subCategoryTwo.isOnline || !subCategoryTwo.isOffline) {
        Swal.fire({
          title: 'Warning',
          text: "You can't disable both online and offline options. Please select at least one option.",
          icon: 'warning',
        });
        type === 'change-online' ? this.toggleCheck('online-checkbox' + subCategoryTwoID) : this.toggleCheck('offline-checkbox' + subCategoryTwoID);
        return;
      }
    } else {
      if (!this.eligebleFromParent(subCategoryTwo.categorySubOneID, type)) {
        Swal.fire({
          title: 'Warning',
          text: type === 'change-online' ? "Please make the parent category online first!!!" : "Please make the parent category offline first!!!",
          icon: 'warning'
        })
        return
      };
    }

    if (type === 'change-online') {
      Swal.fire({
        title: 'Confirmation',
        text: ischecked ? 'Are you sure you want to change to online?' : 'All Products under this category will be removed from online.',
        icon: ischecked ? 'question' : 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.subCategoryTwoService.changeSubCategoryTwoProps(subCategoryTwoID, type).subscribe({
            next: (response) => {
              if (response.message === 'Success') {
                const message = ischecked ? 'SubCategoryTwo successfully added in online' : 'SubCategoryTwo successfully removed from online'
                this.toastr.success(message);
                this.getsubcategoryTwoList();

              } else { this.toastr.error(response.message) };
              setTimeout(() => {
                this.spinner.hide();
              }, 500);
            },
            error: (err) => {
              this.toastr.error(err)
              this.spinner.hide();
            }
          })

        } else {
          this.toggleCheck('online-checkbox' + subCategoryTwoID);
        }
      })

    } else {
      Swal.fire({
        title: 'Confirmation',
        text: ischecked ? 'Are you sure you want to change to offline?' : 'All sub-items under this category will be removed from offline.',
        icon: ischecked ? 'question' : 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.subCategoryTwoService.changeSubCategoryTwoProps(subCategoryTwoID, type).subscribe({
            next: (response) => {
              if (response.message === 'Success') {
                const message = ischecked ? 'SubCategoryTwo successfully added in offline' : 'SubCategoryTwo successfully removed from offline'
                this.toastr.success(message);
                this.getsubcategoryTwoList();

              } else { this.toastr.error(response.message) };
              setTimeout(() => {
                this.spinner.hide();
              }, 500);
            },
            error: (err) => {
              this.toastr.error(err)
              this.spinner.hide();
            }
          })
        } else {
          this.toggleCheck('offline-checkbox' + subCategoryTwoID);
        }
      })

    }

  }



}
