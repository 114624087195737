<section class="content-header sub-head ">

  <h1>
    Orders Report

  </h1>
</section>
<div class=" areas">

  <section class="content sub-cat">
    <div class="box">
      <div class="box-header">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <input id="fromdate" class="form-control" placeholder="Date Range" #date_filter>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary custom-button mr-2"
                (click)="Filter('by-date',date_filter.value)">Apply</button>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-right">


          <div class="dropdown user user-menu">
            <a class="btn btn-primary" data-toggle="dropdown">
              <span class="">Export&nbsp;&nbsp;&nbsp;<i class="fa fa-angle-down"></i></span>
            </a>
            <ul class="dropdown-menu dropy">

              <li class="user-footer second-butt">
                <div class="">
                  <a (click)="ExportDataInExcel()"><img src="assets/images/xls.png" alt=""
                      class="icon">&nbsp;&nbsp;&nbsp;Excel</a>
                </div>
              </li>
            </ul>
          </div>
        </div>



      </div>
      <!-- <div *ngIf="Orders.length>0" class="row " style="margin: 0px;"> -->
        <div class="box-body">
          <div  class="row " style="margin: 0px;">
            <div class="col-md-12 mt-4">
              <div class="inner-pad" #dataToExport>
                  <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Order&nbsp;No</th>
                    <th>Product&nbsp;Details</th>
                    <th>Order&nbsp;Date</th>
                    <th>Quantity</th>
                    <th>Price(AUD)</th>
                    <th>Order&nbsp;Status</th>
    
                  </tr></thead>
                  <tr *ngFor="let item of Orders|paginate:paginationconfig; let i=index">
                    <td>{{((paginationconfig.currentPage-1)*paginationconfig.itemsPerPage)+i+1}}</td>
                    <td>{{item.orderNo}}</td>
                    <td>
                      <div class="report-img">
                        <img src="{{Rooturl}}{{item.image}}">
                        <!-- <img src="assets/images/user4-128x128.jpg"> -->
                      </div>
                      <div class="report-text">
                        <span> <b>{{item.productName}}</b> </span>
                        <span> Color : {{item.color}}</span>
                        <span> Size : {{item.size}}</span>
                      </div>
                    </td>
                    <td>{{item.orderDate|date:'dd/MMM/yyyy'}}</td>
                    <td>{{item.quantity}}</td>
                    <td>{{ (item.price - (item.discount && item.discount > 0 ? (item.price * item.discount / 100) : 0)) | number:'1.2-2' }}</td>
                    <td><span class="badge badge-primary"> {{item.status}}</span></td>
    
                  </tr>
    
                </table>
                  </div>
                <div *ngIf="Orders.length>0;else elseblock">
    
                </div>
              </div>
            </div>
    
            <div class="row lst" *ngIf="paginationconfig.totalItems>10">
              <div class="col-md-3 ">
                <!-- <select (change)="Filter($event.target.value)" class="form-control w-auto">
                  <option *ngFor="let tblsize of tableSizes" [ngValue]="tblsize">{{tblsize}}</option>
                </select> -->
              </div>
              <div class="col-md-9 text-right">
                <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="Filter('by-pagination',$event)">
                </pagination-controls>
              </div>
            </div>
          </div>
    
        </div>
      

    </div>
  </section>
</div>
  
<ng-template #elseblock>
  <div class="no-data">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <h4>No Data Found</h4>
  </div>
</ng-template>