import { ApplicationRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UntypedFormGroup, FormControl, ReactiveFormsModule, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';

import * as CryptoJS from 'crypto-js';
import { ApplicationsettingsService } from './applicationsettings.service';
declare var $: any;
@Component({
  selector: 'app-applicationsettings',
  templateUrl: './applicationsettings.component.html',
  styleUrls: ['./applicationsettings.component.css']
})
export class ApplicationsettingsComponent implements OnInit {
  encPassword = 'neha@hexa';
  public router: Router;
  public form: UntypedFormGroup;
  public ID: AbstractControl;
  public SettingName: AbstractControl;

  public SettingValue: AbstractControl;

  previewUrl = [];
  public selectedFile1: any;
  constructor(router: Router, fb: UntypedFormBuilder, private toastr: ToastrService, public applicationservice: ApplicationsettingsService, private spinner: NgxSpinnerService) { 
    this.form = fb.group({
      'ID': [''],
      'SettingName': ['', Validators.required],
      'SettingValue': [''],
      
    });
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    if (currentUser !== null) {
      if (CryptoJS.AES.decrypt(currentUser.userTypeId.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8) === '1') {
      } else {
        this.router.navigate(['/notfound']);
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.ID = this.form.controls['ID'];
    this.SettingName = this.form.controls['SettingName'];
    this.SettingValue = this.form.controls['SettingValue'];
  
  }
  public listApplicationSettingContent: Array<string> = [];
  ngOnInit(): void {
    this.spinner.show();
    this.getApplicationSettingContent();
  }
  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg') {
      this.selectedFile1 = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event: any) => {
        this.previewUrl = _event.target.result;
        const image = new Image();
        image.src = _event.target.result;
        image.onload = () => {
          if (image.width <= 800 && image.height <= 800) {
            this.selectedFile1 = event.target.files[0];
            this.previewUrl = _event.target.result;
            return true;
          }
          else {
            this.toastr.warning('', 'Image dimentions should be minimum 800*800');
            event.srcElement.value = null;
            this.selectedFile1 = null;
            this.previewUrl = null;
            return false;

          }
        }
      };

    } else {
      alert('Upload only image file'); event.srcElement.value = null;
    }
  }
  onSubmit(blogData) {

    const formData = new FormData();
    if (this.form.valid) {
      formData.append('id', blogData.ID);
      formData.append('SettingName', blogData.SettingName);
 
      if (this.selectedFile1 != null) {
        formData.append('SettingValue', this.selectedFile1, this.selectedFile1.name);
      }
      this.spinner.show();
      if (this.selectedFile1 != null || blogData.ID > 0) {
        this.applicationservice.manageApplicationSetting(formData).subscribe(data => {
          if (data.Message == "Success") {

         


            if (blogData.ID > 0) {
            
              this.toastr.success('', "Data Updated Successfully");
              this.form.reset();
              $("#SettingName").attr("disabled", false);
              this.selectedFile1= null;
              this.previewUrl = null;
              this.getApplicationSettingContent();
              document.getElementById('btnsubmit').innerHTML = 'Submit';
            }
            else
            { this.toastr.success('', "Data Added Successfully"); }
            this.form.reset();
            $("#SettingName").attr("disabled", false);
            this.selectedFile1= null;
            this.previewUrl = null;
            this.getApplicationSettingContent();
            document.getElementById('btnsubmit').innerHTML = 'Submit';
          } else { this.toastr.error(data.Message); }
        });


      }
      else {

        this.toastr.warning('Select an Image');
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);


    }
    else {
      this.form.markAllAsTouched();
    }
  }

  getApplicationSettingContent() {
    this.spinner.show();
   
    this.applicationservice.GetApplicationSettings().subscribe(data => {
      this.listApplicationSettingContent = data.Result;

      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
  }
  onEdit(catgry: any) {
    this.spinner.show();
    $("#SettingName").attr("disabled", "disabled");
    
    this.form.setValue({
  
      SettingName: catgry.SettingName,
      ID: catgry.ID,
      SettingValue: ''
    });
    this.previewUrl = catgry.SettingValue;

    window.scroll(0, 0);
    document.getElementById('btnsubmit').innerHTML = 'Update';
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }
}
