import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { DashboardService } from './dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChartConfiguration, ChartData, ChartEvent, ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { OrderhistoryService } from '../orderhistory/orderhistory.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,AfterViewInit {
  // Pie
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };

  public pieChartDatasets: any[] = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public chartType: string = 'pie';
  public encPassword = 'neha@hexa';
  public Counts: any;
  public topProductList:any[]=[];
  public RecentOrderList:any[]=[];
  public YearList:any[]=[];
  public selectedYear=new Date().getFullYear();
 public PaginationConfig={
  itemsPerPage: 5,
  currentPage: 1,
  totalItems:0,
 };


  public RootURL=environment.baseUrl.slice(0,-3);
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  public barChartOptions: ChartConfiguration['options'] = {
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min:1,
      },
    },
    plugins: {
      legend: {
        display: true,
      },

    },
  };

  public barChartType: ChartType = 'bar';
  public barChartData: ChartData<'bar'>;




  constructor(public router: Router, public dashboardService: DashboardService,private spinner:NgxSpinnerService, private toastr: ToastrService,private orderhistoryservice:OrderhistoryService) {


    // Define background colors for each segment
    const backgroundColors = [
      'rgba(243,93,93)',   // Green for the second segment
      'rgba(17,202,109)',   // Red for the first segment
      'rgba(252,198,68)'    // Blue for the third segment
    ];

    const hoverBackgroundColors = [
      'rgba(243,93,93)',   // Green for the second segment
      'rgba(17,202,109)',   // Red for the first segment
      'rgba(252,198,68)'    // Blue for the third segment
    ];

    const hoverBorderColors = [
      'rgba(243,93,93)',   // Green for the second segment
      'rgba(17,202,109)',   // Red for the first segment
      'rgba(252,198,68)'    // Blue for the third segment
    ];

    this.pieChartDatasets = [{
      data: [300, 500, 100],
      backgroundColor: backgroundColors,
      hoverBackgroundColor: hoverBackgroundColors,
      hoverBorderColor: hoverBorderColors,  // Set initial border color
    }];

  }


  ngOnInit(): void {
    this.GetCounts();
    this.GetTopProductList();
    this.GetRecentOrderList();
    this.GetChartData();
    this.Getyears();
  }


  ngAfterViewInit(): void {

  }

  onChangeYear(){
    this.GetChartData();
  }


  GetCounts() {
    this.spinner.show();
    this.dashboardService.gettotalcount().subscribe(data => {
      if (data.message === "Success") {
        this.Counts = data.result;
      };
      this.spinner.hide();
    });
  };

   setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }
  

  GetTopProductList(){
    this.spinner.show();
    this.dashboardService.getToproducts().subscribe(data=>{
      if(data.message==='Success'){
        this.topProductList=data.result;
      };
      this.spinner.hide();
    })
  };

  GetRecentOrderList(){
    this.spinner.show();
    this.dashboardService.getRecentOrders().subscribe(data=>{
      if(data.message==='Success'){
        this.RecentOrderList=data.result;
        this.PaginationConfig.totalItems=this.RecentOrderList.length;
      };
    this.spinner.hide();
    });

  }

  GetChartData(){
    this.spinner.show();
    this.dashboardService.GetChartData(this.selectedYear).subscribe({
      next:(response:{message:string,result:{order:any[],sale:any[]}})=>{
        if(response.message==='Success'){
          const formatedOrderData= this.handleChartData(response.result.order);
          const formatedSaleData=this.handleChartData(response.result.sale);
          this.barChartData = {
            labels: ['January','February','March','April','May','June','July','August','September','October','November','December'],
            datasets: [
              { data: formatedOrderData, label: 'Orders' },
              { data:formatedSaleData, label: 'Sales' },
            ],
          };
         };
         setTimeout(() => {
          this.spinner.hide();
         }, 300);
      },
      error:(err)=>{
        this.spinner.hide();
        this.toastr.error(err)
      }
    })

  }


  onTableDataChange(e:any){
    this.PaginationConfig.currentPage=e;
    this.GetRecentOrderList();
  }


  handleChartData(data: any[]): any[] {
    const tempArr = Array(12).fill(0); 
    data.map((item: { month: number, count: number }) => {
      const Month = item.month;
      if (Month >= 1 && Month <= 12) {
        tempArr[Month - 1] = item.count; 
      }
    });
  
    return tempArr;
  }

  Getyears():void{
    let d=new Date().getFullYear();
    for(let i=0;i<10;i++){
      this.YearList.push(d);
      d--;
    };
  }

  // events
  public chartClicked({

    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

goto(path,q1){
  if(path==='/orders'){
    const val= CryptoJS.AES.encrypt(q1,'neha@hexa').toString();
    this.router.navigate([path],{queryParams:{type:val}})
  }
  else if(path==='/view-order'){
    this.router.navigate([path],{queryParams:{type:q1}});

  }

}
}
