import { Observable, observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root' 
})
export class UserPermissionService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http:HttpClient) { }


   


  getUserPermissionByUserID(UserID:any):Observable<any>{
    return this.http.get<any>(this.rootUrl+'/User/GetUserPermissionByUserID',{params:{UserID}});
  }
  AssignPermissionToUser(UserID: any,menus:any):Observable<any>{
   
    return this.http.post<any>(this.rootUrl + '/User/AssignPermissionToUser',menus, {params:{UserID}});
  }
  GetUserTypesList():Observable<any>{
    return this.http.get<any>(this.rootUrl + '/User/GetUserTypesList');
  }
  GetUserMaster():Observable<any>{
    return this.http.get<any>(this.rootUrl + '/User/GetUserMasterAll');
  }
  getUserTypePermissionByUserTypeID(UserID: any):Observable<any>{
    debugger
    return this.http.get<any>(this.rootUrl + '/User/GetUserTypePermissionByUserTypeID',{params:{UserID}});
  }
  
  AssignPermissionToUserType(UserTypeID: any,menus:any): Observable<any>{
  debugger
    return this.http.post(this.rootUrl + '/User/AssignPermissionToUserType',menus, {params:{UserTypeID}});
    
  }
}
