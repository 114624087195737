import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewInvoiceComponent } from '../view-invoice/view-invoice.component';
import { ViewInvoiceService } from '../view-invoice/view-invoice.service';
import Swal from 'sweetalert2';
import { identifierName } from '@angular/compiler';
import { Toast, ToastrService } from 'ngx-toastr';
import { resolve } from 'dns';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver'

declare const $: any;

@Component({
  selector: 'app-cancel-invoice',
  templateUrl: './cancel-invoice.component.html',
  styleUrls: ['./cancel-invoice.component.css']
})
export class CancelInvoiceComponent implements OnInit {
  public RefundInvoiceList: any[] = [];
  public CancelledInvoiceList: any[] = [];
  public searchString: string;
  public pickupstatus: number = 0;

  public PagincationConfig = {
    itemsPerPage: 5,
    currentPage: 1,
  };
  public PagincationConfig1 = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  private fromdate: string = '';
  private todate: string = '';
  tableSizes: any = [5, 10, 15];

  private RootURL=environment.baseUrl.slice(0,-3);

  constructor(private datepipe: DatePipe, private spinner: NgxSpinnerService, private viewinvoiceservice: ViewInvoiceService, private toastr: ToastrService) { }

  ngOnInit(): void {
    // this.GetRefundInvoiceList();
    this.GetCancelledInvoiceList();
    $(".date-range [name='Date-Filter']").flatpickr({
      dateFormat: 'd/M/Y',
      maxDate: 'today'
    })
  }
  // GetRefundInvoiceList() {
  //   this.spinner.show();
  //   this.viewinvoiceservice.GetInvoiceList('', '', '', '', 'refund-invoice').subscribe({
  //     next: (response) => {
  //       if (response.message === 'Success') {
  //         this.RefundInvoiceList = response.result;
  //       } else {
  //         this.RefundInvoiceList =[];
  //         console.error(response.message);
  //       };
  //       setTimeout(() => {
  //         this.spinner.hide();
  //       }, 500);
  //     },
  //     error: (err) => {
  //       this.toastr.error(err)
  //       this.spinner.hide();
  //     }
  //   })
  // }

  GetCancelledInvoiceList() {
    this.spinner.show();
    this.viewinvoiceservice.GetInvoiceList('', this.fromdate, this.todate, '', 'cancelled-invoice').subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.CancelledInvoiceList = response.result;
        } else {
          console.error(response.message);
          this.CancelledInvoiceList = [];
        };
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }
    })

  }

  onTableDataChange(pagenumber: number, type: string) {
    if (type === 'refund') {
      this.PagincationConfig.currentPage = pagenumber;
    } else if (type === 'cancel') {
      this.PagincationConfig1.currentPage = pagenumber;
    }

  }
  onTableSizeChange(val: number, type: string) {
    if (type === 'refund') {
      this.PagincationConfig.currentPage = 1;
      this.PagincationConfig.itemsPerPage = val;
    }
    else if (type === 'cancel') {
      this.PagincationConfig1.currentPage = 1,
        this.PagincationConfig1.itemsPerPage = val
    };
  }

  Filter(val: any, type: string) {
    let date = new Date();
    if (type === 'search-invoice') {
      this.searchString = val;
    }
    if (type === 'from-date') {
      if (this.todate === '') {
        this.todate = this.datepipe.transform(date, 'yyyy-MM-dd');
      };
      this.fromdate = this.datepipe.transform(val, 'yyyy-MM-dd');
      this.GetCancelledInvoiceList();

    }
    if (type === 'to-date') {
      if (this.fromdate === '') {
        this.fromdate = this.datepipe.transform(date, 'yyyy-MM-dd');
      };
      this.todate = this.datepipe.transform(val, 'yyyy-MM-dd');
      this.GetCancelledInvoiceList();
    }
    if (type === 'pickup-status') {
      this.pickupstatus = val;
      this.GetCancelledInvoiceList();
    }
  }

  // CancelInvoice(ID: number) {
  //   Swal.fire({
  //     icon: 'info',
  //     title: 'Confirmation',
  //     text: 'Are you sure to refund the invoice amount',

  //     showCancelButton: true,
  //     cancelButtonText: 'No',
  //     confirmButtonText: 'Yes'
  //   }).then((res) => {
  //     if (res.isConfirmed) {
  //       this.spinner.show();
  //       this.viewinvoiceservice.CancelInvoice(ID).subscribe({
  //         next: (response) => {
  //           if (response.message === 'Success') {
  //             this.toastr.success('Success');
  //             this.GetCancelledInvoiceList();
  //             this.GetRefundInvoiceList();
  //           } else { console.error(response.message) }
  //           setTimeout(() => {
  //             this.spinner.hide();
  //           }, 500);
  //         },
  //         error: (err) => {
  //           this.toastr.error(err)
  //           this.spinner.hide();
  //         }
  //       })

  //     }
  //   })
  // }

  // BackInvoice(ID: number, InvoiceNumber: string){
  //   Swal.fire({
  //     title: "Confirmation",
  //     icon: 'info',
  //     text: `Are you sure you want to back invoice no :${InvoiceNumber}.`,
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes'

  //   }).then((res) => {
  //     if (res.isConfirmed) {
  //       this.spinner.show();
  //       this.viewinvoiceservice.backToInvoice(ID).subscribe({
  //         next: (response) => {
  //           if (response.message === 'Success') {
  //             this.toastr.success('Success');
  //             this.GetRefundInvoiceList();
  //           } else { console.error(response.message) };
  //           setTimeout(() => {
  //             this.spinner.hide();
  //           }, 500);
  //         },
  //         error: (err) => {
  //           this.toastr.error(err)
  //         }
  //       })

  //     }
  //   })
  // }

  DownLoadInvoice(InvoiceNumber: string,IsPartial:boolean){
    const Folder =IsPartial === true ? 'Partial_Invoice' : 'Full_Invoice';
    const Path = `Content/Invoice/${Folder}/`;
    const downloadlink = `${this.RootURL}${Path}${InvoiceNumber}.pdf`
      saveAs(downloadlink, `invoice.pdf`);
    this.spinner.hide();

  }
}
