import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UserPermissionService } from './user-permission.service';
import { UsertypesService } from '../usertypes/usertypes.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { error } from 'console';
declare var $: any;

@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']
})
export class UserPermissionComponent implements OnInit {
  Permission: any;
  VerticalName: any;
  IsMenuPresent: any = false;
  StaffList: any;
  ParentChildchecklist: any = [];
  data: any;
  userList: any;
  usertypeList: any;

  constructor(private fb: FormBuilder, private service: UserPermissionService, private Service: UsertypesService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    {
      this.Permission = fb.group({
        UserID: [''],
        UserTypeID: [""]
      })
    }
  }
  ngOnInit(): void {
    this.SelectClientID();
    this.SelectClientID1();
  }
  treeview(obj: any) {
    this.data = {};
    this.data.isAllSelected = false;
    this.data.isAllCollapsed = false;
    this.data.ParentChildchecklist = obj;

  }
  parentCheck(parentObj: any) {
    for (var i = 0; i < parentObj.childList.length; i++) {
      parentObj.childList[i].isSelected = parentObj.isSelected;
      parentObj.childList[i].IsCustomized = parentObj.isSelected;
      for (var j = 0; j < parentObj.childList[i].nestedchildList.length; j++) {
        parentObj.childList[i].nestedchildList[j].isSelected = parentObj.childList[i].isSelected;
        parentObj.childList[i].nestedchildList[j].IsCustomized = parentObj.childList[i].isSelected;
      }
    }
    parentObj.IsCustomized = parentObj.isSelected;

  }

  //Click event on child checkbox  
  childCheck(parentObj: any, childObj: any, ID: any) {

    parentObj.isSelected = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })
    parentObj.IsCustomized = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })


    for (let i = 0; i < childObj.length; i++) {
      if (childObj[i].id == ID) {
        childObj[i].IsCustomized = childObj[i].isSelected;
      }

    }
    for (var i = 0; i < childObj.length; i++) {

      for (var j = 0; j < childObj[i].nestedchildList.length; j++) {
        childObj[i].nestedchildList[j].isSelected = childObj[i].isSelected;
        childObj[i].nestedchildList[j].IsCustomized = childObj[i].isSelected;
      }

    }
    childObj.IsCustomized = childObj.isSelected;


  }

  // click event on nested child
  nestedchildCheck(parentObj: any, childObj: any, ID: any) {

    parentObj.isSelected = childObj.some(function (itemChild: any) {
      if (itemChild.isSelected) { return itemChild.isSelected == true; }
      else {
        return parentObj.isSelected == true;
      }



    })
    parentObj.IsCustomized = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })


    for (let i = 0; i < childObj.length; i++) {
      if (childObj[i].id == ID) {
        childObj[i].IsCustomized = childObj[i].isSelected;
      }

    }



    if (parentObj.isSelected) {
      childObj.IsCustomized = childObj.isSelected;
    }


  }
  selectUnselectAll(event: any, obj: any) {
    debugger
    var isselected = event.target.checked;
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isSelected = isselected;
      for (var j = 0; j < obj.ParentChildchecklist[i].childList.length; j++) {
        obj.ParentChildchecklist[i].childList[j].isSelected = isselected;
      }
      if (obj.ParentChildchecklist[i].childList.length > 0) {

        for (var k = 0; k < obj.ParentChildchecklist[i].childList[j - 1].nestedchildList.length; k++) {
          obj.ParentChildchecklist[i].childList[j - 1].nestedchildList[k].nestedChild.isSelected = isselected;
        }
      }
    }
  }
  expandCollapse(obj: any) {
    obj.isClosed = !obj.isClosed;
  }

  BindUserPermission() {
    this.spinner.show();
    var UserID = $("#BindUser").val();
    this.service.getUserPermissionByUserID(UserID).subscribe(data => {
      if (data.message == "ok") {
        this.treeview(data.result);
        this.IsMenuPresent = true;
        // this.Permission.patchValue({
        //   UserTypeID: data.result,
        // });
      }
      setTimeout(() => {
        this.spinner.hide();
        
      },500);
    },err=>{
      this.spinner.hide();
    })

  }
  GetUserTypes() {

    this.Service.GetUserTypes().subscribe(data => {
      if (data.message == "ok") {
        this.StaffList = data.result;

      }
      else { this.toastr.warning(data.message); }

    });
  }
  AssignPermission() {
    var UserID = $("#BindUser").val();
    if (this.Permission.valid) {
      this.service.AssignPermissionToUser(UserID, this.data.ParentChildchecklist).subscribe(data => {
        if (data.message == "ok") {
          this.toastr.success("User Permission assigned successfully", 'Success');
          $("#BindUserTypePermission").val('');
        }
      })
    }
    else {
      this.Permission.markAllAsTouched();
    }
  }

  SelectClientID() {
    this.spinner.show();
    this.service.GetUserMaster().subscribe(data => {
      if (data.message == 'Success') {
        let userlist = data.result.filter(x => x.userTypeID!==4);
        this.userList = userlist;
      }
      else { this.userList = []; }
      setTimeout(() => {
        this.spinner.hide()
      }, 500);
    }, err => {
      this.spinner.hide();
    });
    //this.GetEmployees();
  }
  SelectClientID1() {
    this.spinner.show();
    this.service.GetUserTypesList().subscribe(data => {
      if (data.message == 'Success') {
        const typelist = data.result.filter(x => x.isActive);
        this.usertypeList = typelist;
      }
      else {
        this.userList = [];
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
    //this.GetEmployees();
  }
  BindUserTypePermission() {
    var UserID = $("#BindUserTypePermission").val();
    this.service.getUserTypePermissionByUserTypeID(UserID).subscribe(data => {
      if (data.message == "ok") {
        this.treeview(data.result);
        this.IsMenuPresent = true;
      }
    })
  }

}

