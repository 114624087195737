import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { rootCertificates } from 'tls';

@Injectable({
  providedIn: 'root'
})
export class ViewInvoiceService {
  private readonly RootURL:string=environment.baseUrl;
  constructor(private http:HttpClient) { }

  GetInvoiceList(IsPartialPayment:boolean|string,fromdate:string,todate:string,pickupstatus:number|string,type:string):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/GetInvoiceList',{params:{IsPartialPayment,fromdate,todate,pickupstatus,type}});
  }
  GetInvoiceItemListByInvoiceID(ID:number):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/GetInvoiceItemListByInvoiceID',{params:{ID}});
  }
  SendInvoiceOnEmail(InvoiceID:number):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/SendInvoiceOnEmail',{params:{InvoiceID}});
  }
  GetInvoiceDetailByID(InvoiceID:number):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/GetInvoiceDetailByID',{params:{InvoiceID}});
  }
  ChangePickupStatus(ID:number):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/ChanagePickupStatus',{params:{ID}});
  }
  backToInvoice(ID:number):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/BackToInvoice',{params:{ID}});
  }
  CancelInvoice(ID:number):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/CancelInvoice2',{params:{ID}});
  }
  CompleteInvoicePayment(Invoice:any):Observable<any>{
    return this.http.post<any>(this.RootURL+'/Pos/CompleteInvoicePayment',Invoice);
  }
  submitReason(refund:any):Observable<any>{
    return this.http.post<any>(this.RootURL+'/Pos/RefundInvoice',refund);
  }
  getRefundInvoiceItemList():Observable<any>{
    return this.http.get<any>(this.RootURL+'/Pos/GetRefundInvoiceItemList');
  }
  getSecretKey():Observable<any>
  {
    return this.http.get<any>(this.RootURL +'/Common/getSecretKey')
  }

}
