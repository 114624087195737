<section class="content-header sub-head ">
    <h1>
        Add Invoice Type
    </h1>

</section>
<section>

    <div class="row">

        <div class="col-md-12 ">
            <div class="box box-danger ">
                <form [formGroup]="InvoiceTypeForm">
                    <div class="box-body">
                        <div class="radioboxmain beforeafternone">
                            <div class="">

                                <div class="col-md-4 padd-left">
                                    <label for="exampleInputEmail1">Name<span class="text-danger">*</span></label>
                                    <input type="text" id="Host" formControlName="name" name="name"
                                        placeholder="Enter Invoice Type"
                                        class="form-control ng-pristine ng-invalid ng-touched">
                                    <span class="validation-error" *ngIf="InvoiceFormControls.name.touched">
                                        <span *ngIf="InvoiceFormControls.name.errors?.required">This Field Is
                                            Required</span>
                                    </span>
                                </div>
                                <div class="col-md-5 mt-5 padd-left buttons text-start">
                                    <a class="btn btn-success"  (click)="ManageInvoiceType()">
                                        Submit
                                    </a>
                                    <button (click)="reset()" class="btn btn-secondary" type="button">
                                        Cancel
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>

                </form>









            </div>
        </div>
    </div>


    <section class="content-header sub-head ">
        <h1>
            Invoice Type List
        </h1>

    </section>
    <div class="box">
        <div class="box-body ">
            <div class="row nomargin">
                <div class="col-md-12" style="margin-top: 12px;">
                    <table class="table table-striped table-bordered nomargin">

                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>Name</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let item of InvoiceTypeList| paginate
            :PagincationConfig,let i=index ">

                                <td>{{i+1+PagincationConfig.itemsPerPage*(PagincationConfig.currentPage-1)}}</td>
                                <td>{{item.invoiceType}}</td>
                                
                                <td style="text-align: center;">
                                    <div class="d-flex justify-content-center">
                                        <button class="btn btn-icon m-1 p-1 mr-2">
                                            <input type="checkbox" id="checkBoxAinA{{item.id}}" name="isActive"
                                                class="marginright10 "
                                                (change)="ActivedeactiveProduct(item.id,item.isActive)"
                                                [checked]="item.isActive">
                                        </button>
                                        <button  type="button" (click)="onEdit(item)" class="btn btn-icon" aria-label="Product details">
                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                          </button>
                                    </div>
                                </td>

                            </tr>

                        </tbody>
                    </table>
                    <div class="" *ngIf="InvoiceTypeList.length>0;else elseblock;">

                    </div>

                </div>

               

               
            </div>
            <div class="row  view-user-page ">
                <div class="col-sm-12 mt-5">
                    <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next"
                        (pageChange)="OnTableDataChange($event)">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </div>


</section>
<ng-template #elseblock>
    <div class="no-data">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <h4>No Data Found</h4>
    </div>
</ng-template>