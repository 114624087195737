import { Component, OnInit } from '@angular/core';
import { ViewInvoiceComponent } from '../view-invoice/view-invoice.component';
import { ViewInvoiceService } from '../view-invoice/view-invoice.service';
import { splitNsName } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-refund-invoice',
  templateUrl: './view-refund-invoice.component.html',
  styleUrls: ['./view-refund-invoice.component.css']
})
export class ViewRefundInvoiceComponent implements OnInit{
refundInvoiceList:any[]=[];
  constructor(private viewinvoiceservice:ViewInvoiceService,private spinner:NgxSpinnerService,private toastr:ToastrService){}
  ngOnInit(): void {
    this.getRefundItemList();
  }

  getRefundItemList(){
    this.spinner.show();
    this.viewinvoiceservice.getRefundInvoiceItemList().subscribe({
      next:(response)=>{
        if(response.message==='Success'){
          this.refundInvoiceList=response.result;
        }else {console.log(response.message)}
        setTimeout(() => {
          this.spinner.hide();
        },500);
      },
      error:(err)=>{
        this.toastr.error(err)
      }
    })
  }

}
