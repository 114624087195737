import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApplicationsettingsService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  manageApplicationSettingsFile(blogContentBO) {
    return this.http.post<any>(this.rootUrl + '/ApplicationSetting/manageApplicationSettingsFile', blogContentBO);
  }
  manageApplicationSetting(blogContentBO)
  {
    
    return this.http.post<any>(this.rootUrl + '/ApplicationSetting/manageApplicationSetting',  blogContentBO); }
    GetApplicationSettings() {
      
      return this.http.get<any>(this.rootUrl + '/ApplicationSetting/GetApplicationSettings') }
    
}
