import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehiclepackageService } from './vehiclepackage.service';
@Component({
  selector: 'app-vehiclepackage',
  templateUrl: './vehiclepackage.component.html',
  styleUrls: ['./vehiclepackage.component.css']
})
export class VehiclepackageComponent implements OnInit {
  encPassword = 'neha@hexa';
  public form: UntypedFormGroup;
  public VehicleID: AbstractControl;
  public ForCapacity: AbstractControl;
  public Price: AbstractControl;
  public ID: AbstractControl;
  constructor(public router: Router, fb: UntypedFormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService, public vehiclepackageservice: VehiclepackageService) {
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    if (currentUser !== null) {
      if (CryptoJS.AES.decrypt(currentUser.userTypeId.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8) === '1') {
      } else {
        this.router.navigate(['/notfound']);
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.form = fb.group({
      'ID': [''],
      'VehicleID': ['', Validators.required],
      'ForCapacity': ['', Validators.required],
      'Price': ['', Validators.required],
    });
    this.VehicleID = this.form.controls['VehicleID'];
    this.ForCapacity = this.form.controls['ForCapacity'];
    this.Price = this.form.controls['Price'];
    this.ID = this.form.controls['ID'];
  }
  public listvehicles: Array<string> = [];
  public listVehiclePackages: Array<any> = [];
  public activedeacSubCat: any = {};
  public Capacity: any = {};
  public CapacityInTonnes: any = {};
  ngOnInit(): void {
    debugger;

    this.GetVehicles();
    this.getVehiclePackageList();
  }

  GetVehicles() {
    debugger;
    this.spinner.show();
    this.vehiclepackageservice.getVehicles().subscribe(data => {
      this.listvehicles = data.Result;
      this.spinner.hide();
    })
  }
  getvehicleDetail(val) {
    debugger;


    this.Capacity = this.listvehicles.filter((product: any) => product.ID == val)
    this.CapacityInTonnes = this.Capacity[0].CapacityInTonnes;

  }

  getVehiclePackageList() {
    this.spinner.show();
    this.vehiclepackageservice.getVehiclePackages().subscribe(data => {
      this.listVehiclePackages = data.Result;

      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    });
  }
  onSubmit(vehicleData): void {
    debugger;
    const formData = new FormData();
    if (this.form.valid) {
      this.spinner.show();
      this.vehiclepackageservice.AddEditVehiclePackage(vehicleData).subscribe(data => {
        if (data.Message === 'Success') {
          if (vehicleData.ID > 0) {
            this.toastr.success("Data updated successfully");
          }
          else {

            this.toastr.success("Data saved  successfully");

          }
          document.getElementById('btnsubmit').innerHTML = 'Submit';
          this.form.reset();
          this.getVehiclePackageList();
        }
        else {

          this.toastr.error('', data.Message);
        }
        this.form.reset();
      });
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
    }
    else {
      this.spinner.hide();
      this.form.markAllAsTouched();
    }
  }

  onEdit(list: any) {
    this.spinner.show();
    this.form.setValue({
      VehicleID: list.VehicleID,
      ForCapacity: list.Capacity,
      ID: list.ID,
      Price: list.Price

    });

    window.scroll(0, 0);
    document.getElementById('btnsubmit').innerHTML = 'Update';
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }
  VehicleListActivedeactive(id: number, IsActive: string) {
    this.spinner.show();
    var action = 'Active';
    this.activedeacSubCat.ID = id;
    this.activedeacSubCat.Action = IsActive;
    if (this.activedeacSubCat.Action === true) {
      action = 'Inactive';
    } this.vehiclepackageservice.getActiveDeactive(this.activedeacSubCat).subscribe(res => {
      this.getVehiclePackageList();
      this.toastr.success('Category ' + action + ' successfully');
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
  }
  keyPressNumbers(event) {
    var Capacity = Number(event.target.value + event.key);
    if (Capacity > 1000000) { return false; }
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyUpCapacity(event) {

    var Capacity = Number(event.target.value + event.key);


    debugger;
    if (Capacity > this.CapacityInTonnes) {

      this.toastr.warning('Capacity should not greater than  ' + this.CapacityInTonnes);
      return false;
    }
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;

    } else {
      return true;
    }
  }
}
