

<section class="content-header sub-head ">

    <h1>
        Cancelled Invoice
    </h1>
</section>
<div class="box">
    <div class="box-header">
        <div class="row">
            <div class="col-md-6">
                <div class="search">
                    <form novalidate="" method="post" class="d-flex my-search ng-untouched ng-pristine ng-valid">
                        <input #search_string (keyup)="Filter(search_string.value,'search-invoice')" id="SearchID"
                            type="search" placeholder="Search" aria-label="Search" class="form-control me-2">
                        <button type="submit" class="btn btn-outline-success">
                            <i class="fa fa-search"></i>
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="menu-filter">

                    <div class="date-range">
                        <input (change)="Filter($event.target.value,'from-date')" placeholder="From Date"
                            name="Date-Filter" class="form-control" placeholder="Date from">
                    </div>
                    <div class="date-range">
                        <input (change)="Filter($event.target.value,'to-date')" placeholder="To Date" name="Date-Filter"
                            class="form-control" placeholder="Date to">
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="box-body">
        <div class="row " style="margin: 0px;">
            <div class="col-md-12 mt-4">
                <div class="inner-pad" #dataToExport>
                        <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Invoice&nbsp;Number</th>
                                <th> Order&nbsp;No</th>
                                <th>Customer&nbsp;Name</th>
                                <th>Invoice&nbsp;Date</th>
                                <th> Invoice&nbsp;Amount&nbsp;(AUD) </th>
                                <th> Reason</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of CancelledInvoiceList|search:'INVOICE':searchString|paginate:PagincationConfig1"
                                style="position: relative;">
                                <td (click)="ViewInvoiceItems(item.id)">{{item.invoiceNumber}}</td>
                                <td>{{item.orderNumber}}</td>
                                <td>{{item.customerName}}</td>
                                <td>{{item.invoiceDate|date:'dd/MMM/yyyy'}}</td>
                                <td>{{item.invoiceTotalAmount}}</td>
                                <td>{{item.cancelReason}}</td>
                                <td>
                                    <button
                                        (click)="DownLoadInvoice(item.invoiceNumber,item.ispartialPayment)"
                                        class="btn btn-icon mr-2" type="submit" data-toggle="tooltip"
                                        data-placement="bottom" title="View Invoice">
                                        <i class="fa fa-eye" aria-hidden="true"> </i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                        </div>
                    <div *ngIf="CancelledInvoiceList.length>0;else elseblock1;">

                    </div>
                </div>
            </div>
            <div class="area-cust" *ngIf="CancelledInvoiceList.length>10">
                <div class="col-md-3">


                </div>
                <div class="col-md-9 text-right">
                    <pagination-controls previousLabel="Prev" nextLabel="Next"
                        (pageChange)="onTableDataChange($event,'cancel')">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #elseblock>
    <div class="no-data">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <h4>No Data Found</h4>
    </div>
</ng-template>
<ng-template #elseblock1>
    <div class="no-data">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        <h4>No Data Found</h4>
    </div>
</ng-template>
