<section class="content-header sub-head ">

    <h1>
        View Refund Invoice
    </h1>
</section>
<div class="box">
    <div class="box-header">
        <div class="row">
            <div class="col-md-6">
                <div class="search">
                    <form novalidate="" method="post" class="d-flex my-search ng-untouched ng-pristine ng-valid">
                        <input id="SearchID" type="search" placeholder="Search" aria-label="Search"
                            class="form-control me-2">
                        <button type="submit" class="btn btn-outline-success">
                            <i class="fa fa-search"></i>
                        </button>
                    </form>
                </div>
            </div>

        </div>

    </div>
    <div class="box-body">
        <div class="row " style="margin: 0px;">
            <div class="col-md-12 mt-4">
                <div class="inner-pad">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Bar&nbsp;Code</th>
                                <th> Invoice&nbsp;No.</th>
                                <th>Customer&nbsp;Name</th>
                                <th>Invoice&nbsp;Date</th>
                                <th>Item</th>
                                <th>Refund Amount</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of refundInvoiceList">

                                <td>{{item.itemCode}}</td>
                                <td>{{item.invoiceNumber}}</td>
                                <td>{{item.customerName}}</td>
                                <td>{{item.refundDate|date:'dd-MMM-yyyy'}}</td>
                                <td>{{item.productName}}</td>
                                <td>( {{item.salePrice}} )</td>
                                <td>{{item.reason}}</td>
                                <!-- <td class="text-center">
                                    <button class="btn btn-icon mr-2" type="submit" data-toggle="tooltip"
                                        data-placement="bottom" title="View Invoice">
                                        <i class="fa fa-eye" aria-hidden="true"> </i>
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>
</div>