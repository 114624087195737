import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { OrdersService } from '../orders/orders.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import  * as CryptoJS from 'crypto-js'

@Component({
  selector: 'app-return-orders',
  templateUrl: './return-orders.component.html',
  styleUrls: ['./return-orders.component.css']
})
export class ReturnOrdersComponent  implements OnInit{
  @ViewChild('dataToExport', { static: false }) public dataToExport: ElementRef;


  public StatusID: AbstractControl;
  public orderlist: any[] = [];
  public OrderProductList: any;
  public UserID: string = '';
  public OrderStatus: any;
  public page: number = 1;
  public count: number = 0;
  public tableSize: number = 10;
  public tableSizes: any = [10, 15, 20];
  public OrderListstatus: any;
  public searchstring: string;
  private Type: string = '';
  public navigationSubscription: any;

  constructor(private appservice: AppService,
    public router: Router,
    public ordersService: OrdersService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private activatedroute: ActivatedRoute) {


  }
  public getOrders: any;
  public StatusList: Array<any> = [];
  public CustomerProductList: Array<any> = [];
  public getOrderbyid: any = { OrderNo: '', OrderDate: '', OrderTotalAmount: '', OrderStatus: '', CustomerID: '', ShippingCharges: '' };


  ngOnInit(): void {
    let Param1 = this.activatedroute.snapshot.queryParams['UserID'];
    let Param2 = this.activatedroute.snapshot.queryParams['type'];
    this.navigationSubscription = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.Type="";
        this.UserID="";
        Param1 = this.activatedroute.snapshot.queryParams['UserID'];
        Param2 = this.activatedroute.snapshot.queryParams['type'];
        if (Param1 || Param2) {
          if (Param1) {
            this.UserID = this.appservice.decryptValue(Param1);
          };
          if (Param2) {
            this.Type = this.appservice.decryptValue(Param2);
          }
        };
        this.ReceivedOrderList('')
        window.scrollTo(0, 0);
      }
    });

    if (Param1 || Param2) {
      if (Param1) {
        this.UserID = this.appservice.decryptValue(Param1);
      };
      if (Param2) {
        this.Type = this.appservice.decryptValue(Param2);
      }
    }
    this.ReceivedOrderList('');
  }


  ngOnDestroy() {
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }
  SearchOrder(val: string) {
    this.searchstring = val;
  }
  onTableDataChange(nextpage: any) {
    this.page = nextpage;
  }

  onTableSizeChange(TableSize: any) {
    this.tableSize = TableSize;

  }
  showOrderDetails(orderid: number) {
    debugger;
    const encryt = CryptoJS.AES.encrypt(orderid.toString(), "neha@hexa").toString();
    setTimeout(() => {
      this.router.navigate(['/view-return-order'], { queryParams: { Eid: encryt } });
    }, 500);
   
  }

  ReceivedOrderList(filter: string) {
    this.spinner.show();
    let d = new Date();
    if (filter === 'ONEWEEK') {
      d.setDate(d.getDate() - 7);
    }
    else if (filter === 'ONEMONTH') {
      d.setMonth(d.getMonth() - 1);
    }
    else if (filter === 'THREEMONTHS') {
      d.setMonth(d.getMonth() - 3);
    }
    const D = filter !== '' ? d.toISOString().split('T')[0] : '';
    this.ordersService.ReceivedOrderList(this.UserID, D, "return").subscribe(data => {
      this.spinner.hide();
      if (data.message == "Success") {
        this.orderlist = data.result;
       console.log('order',this.orderlist)
      } else { this.orderlist = [] }
    })
  }


  ExportDataInExcel() {
    var json = this.orderlist;
    json = json.map((rec: { itemID: string, discountPrecentage: any,  orderAmount: number, status: string, quantity:number,orderDate:any}) => {
      return {
        'Item Order#': rec.itemID,
        'Total Order Amount':(rec.discountPrecentage>0?(rec.orderAmount-(rec.orderAmount*rec.discountPrecentage/100))*rec.quantity:rec.orderAmount*rec.quantity).toFixed(2),
        'Discount (%)': rec.discountPrecentage>0?rec.discountPrecentage:0,
        'Order Date': rec.orderDate,
        'Status':rec.status
      };
    });
    this.appservice.exportAsXLSX(json, 'Orders');
  };

  // ExprortDataInPDF(): void {
  //   debugger
  //   const width = this.dataToExport.nativeElement.clientWidth;
  //   const height = this.dataToExport.nativeElement.clientHeight + 40;
  //   domToImage.toPng(this.dataToExport.nativeElement, {
  //     width: width,
  //     height: height
  //   }).then(result => {
  //     const pdf = new jsPDF({
  //       orientation: 'landscape',
  //       unit: 'pt',
  //       format: [width + 100, height + 200]
  //     });
  //     pdf.setFontSize(24);
  //     pdf.setTextColor('#131523');
  //     pdf.text('Report date: ' + moment().format('ll'), 25, 115);
  //     pdf.addImage(result, 'PNG', 25, 185, width, height);
  //     pdf.save('Orders' + '.pdf');
  //   });


  // }

}



