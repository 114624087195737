import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MastercolorService } from './mastercolor.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { settings } from 'cluster';
declare var $: any

@Component({
  selector: 'app-mastercolor',
  templateUrl: './mastercolor.component.html',
  styleUrls: ['./mastercolor.component.css']
})
export class MastercolorComponent implements OnInit {
  public form: any;
  public masterlist: any[]=[];
  public activedeaccolor: any = {};
  public page: number = 1;
  public count: number = 0;
  public tableSize: number = 10;
  public tableSizes: any = [10, 15, 20];
  private Active:any=true;
  constructor(private fb: FormBuilder, private service: MastercolorService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.form = fb.group({
      id: [0],
      name: [null,[ Validators.required]],
      colorCode: [null,[Validators.required]],
      isActive: true
    })
  }
  ngOnInit(): void {
    this.getMastercolorlist()
  }
  getMastercolorlist() {
    this.spinner.show();
    this.service.getMasterColorList(this.Active).subscribe({
      next:(response)=>{
          if (response.message === "Success") {
            this.masterlist = response.result;
          }else{
            this.masterlist=[];
          }
          setTimeout(() => {
            this.spinner.hide();
          },300);
      },
      error:(err)=>{
        console.error('Error in getMastercolorlist :',err);
        this.spinner.hide();
      }
    })
  }
  get input() {
    return this.form.controls;
  }

  Filter(key:any,type:string){
    if(type==='ACTIVE-INACTIVE'){
      this.Active=key;
    };
    this.page=1;
    this.getMastercolorlist();
  }

  ManageMasterColor() {
    if (this.form.valid) {
      this.spinner.show();
      this.service.ManageMasterColor(this.form.value).subscribe(data => {
        if (data.message == "Success") {
          const Action=this.form.get('id').value>0?'Color Updated Successfully':'Color Added Successfully';
          this.toastr.success(Action);
          this.getMastercolorlist()
          this.form.reset();
          this.form.patchValue({
            id: 0,
          });
        }
        else {
          this.toastr.warning(data.message)
        }
        setTimeout(() => {
          this.spinner.hide();
        },300);
      })
      this.spinner.hide();
    }
    else {
      this.form.markAllAsTouched();
    }
  }


  onEdit(item:any){
    this.form.patchValue({
      id:item.id,
      name:item.name,
      colorCode:item.colorCode,
    });
  }

  ActivedeactiveProduct(id: number, IsActive: boolean) {
    
    var action = 'Inactive';
    this.activedeaccolor.ID = id;
    this.activedeaccolor.Action = IsActive;
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the colour?" : "Are you sure you want to activate the colour?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.activedeaccolor.Action == false) {
          action = 'activated';
        }
        else {
          action = 'deactivated';

        }

        this.service.ActiveInactiveColor(this.activedeaccolor.ID).subscribe(res => {

          this.getMastercolorlist();
          this.toastr.success('Colour has been ' + action + ' successfully.');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })

  }
  OnTableDataChange(PageNumber: number) {
    this.page = PageNumber;
  }
}
