
  
<section class="content">
<div class="box">
   
    <section class="content-header">
      <h1>
        View Vehicle
       
      </h1>
    
    </section>
    <!-- /.box-header -->
    <div class="box-body">
      <table id="example11" class="table table-bordered table-striped">
        <thead>
        <tr>
            <th>S.No</th>
          <th>Vehicle Name</th>
         <th>Image</th>
         <th>Price (in £)</th>
          <th>Capacity</th>
          <th>Hired Date</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
    
      <tr *ngFor="let Vehiclelist of listHiredvehicles;let i=index">
        <td>{{i+1}}</td>
         <td >{{Vehiclelist.VehicleName}}</td>
         <td style="width: 100px;"><img height="100px" [src]="Vehiclelist.Image"></td>
         <td >{{Vehiclelist.Price}}</td>
        
         <td>{{Vehiclelist.Capacity}}   </td>
         <td>{{Vehiclelist.CreatedDate}} </td>
         <td>{{Vehiclelist.Status}}</td>
        </tr>
      </tbody>
       
    </table>
  </div>

</div>
</section>
          
     
  
   
      
   
   
       
        
       
      
     
        
             
