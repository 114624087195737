import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private readonly RootURL=environment.baseUrl;

  constructor(private http:HttpClient) { }

  GetLowInventroyProducts():Observable<any>{
    return this.http.get<any>(this.RootURL+'/Product/GetLowInventoryProducts');
  }

   AddProductStock(OBJ:any):Observable<any>{
    return this.http.get<any>(this.RootURL+'/Product/AddProductStock',{params:OBJ});

   }
}
