import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  private readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}
  
  getDiscountlist(active:any):Observable<any>{
    return this.http.get<any>(this.rootUrl +'/Common/getDiscountlist',{params:{active}});
  }

  ManageDiscount(Bo:any):Observable<any>
  {
    return this.http.post<any>(this.rootUrl +'/Common/ManageDiscount',Bo);
  }
  ActiveInactiveDiscount(id:number):Observable<any>{
    return this.http.get<any>(this.rootUrl +'/Common/ActiveInactiveDiscount',{params:{id}});
  }
 
}
