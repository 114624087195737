import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxStarRatingModule } from 'ngx-star-rating';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule  } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PagesComponent } from './pages/pages.component';

import { CategoriesComponent } from './pages/categories/categories.component';
import { SubcategoriesComponent } from './pages/subcategories/subcategories.component';
import { BlogcontentComponent } from './pages/blogcontent/blogcontent.component';

import { ContentmanagementComponent } from './pages/contentmanagement/contentmanagement.component';
import { MenusComponent } from './menus/menus.component';
import { AddproductComponent } from './pages/addproduct/addproduct.component';
import { ProductlistComponent } from './pages/productlist/productlist.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { EmailsettingComponent } from './pages/emailsetting/emailsetting.component';
import { UsertypesComponent } from './pages/usertypes/usertypes.component';
import { UsersComponent } from './pages/users/users.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { VehiclepackageComponent } from './pages/vehiclepackage/vehiclepackage.component';
import { ViewhiredvehiclesComponent } from './pages/viewhiredvehicles/viewhiredvehicles.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderhistoryComponent } from './pages/orderhistory/orderhistory.component';
import { CancelledordersComponent } from './pages/cancelledorders/cancelledorders.component';
import { ApplicationsettingsComponent } from './pages/applicationsettings/applicationsettings.component';
import { ViewusersComponent } from './pages/viewusers/viewusers.component';
import { SubCategoriesTwoComponent } from './pages/sub-categories-two/sub-categories-two.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlatpickrModule } from 'angularx-flatpickr';
// import { ProductDiscountComponent } from './pages/product-discount/product-discount.component';
import { UserPermissionComponent } from './pages/user-permission/user-permission.component';
import { RoleBasedPermissionComponent } from './pages/role-based-permission/role-based-permission.component';
// import { CookieService } from 'ngx-cookie-service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ViewdetailComponent } from './pages/viewdetail/viewdetail.component';

import { NgChartsModule } from 'ng2-charts';
import { SearchPipe } from './search.pipe';
// import { ReceivedOrdersPipe } from './received-orders.pipe';
import { StatusComponent } from './pages/status/status.component';
import { MastercolorComponent } from './pages/mastercolor/mastercolor.component';
import { MastersizeComponent } from './pages/mastersize/mastersize.component';
import { MastertypeComponent } from './pages/mastertype/mastertype.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { DatePipe } from '@angular/common';
import { RecivedreviewsComponent } from './pages/recivedreviews/recivedreviews.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import { OrdersReportComponent } from './pages/orders-report/orders-report.component';
import { LowinventoryComponent } from './pages/lowinventory/lowinventory.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { CustomOrdersComponent } from './pages/custom-orders/custom-orders.component';
import { PosComponent } from './pages/pos/pos.component';
import { ReturnOrdersComponent } from './pages/return-orders/return-orders.component';
import { ViewInvoiceComponent } from './pages/view-invoice/view-invoice.component';
import { PosSearchPipe } from './pos-search.pipe';
import { CancelInvoiceComponent } from './pages/cancel-invoice/cancel-invoice.component';
import { MasterInvoicetypeComponent } from './pages/master-invoicetype/master-invoicetype.component';
import { ViewRefundInvoiceComponent } from './pages/view-refund-invoice/view-refund-invoice.component';
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component';
import { PosReportComponent } from './pages/pos-report/pos-report.component';
import { ViewReturnOrderComponent } from './pages/view-return-order/view-return-order.component';
import { JwtInterceptor } from '../jwt.interceptor';
import { SeoManagementComponent } from './pages/seo-management/seo-management.component';
import {NgOtpInputModule} from 'ng-otp-input';
import { BulkManagementComponent } from './bulk-management/bulk-management.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component'




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenusComponent,
    DashboardComponent,
    LoginComponent,
    PagesComponent,
    CategoriesComponent,
    SubcategoriesComponent,
    SubCategoriesTwoComponent,
    BlogcontentComponent,
    ContentmanagementComponent,
    AddproductComponent,
    ProductlistComponent,
    NotfoundComponent,
    EmailsettingComponent,
    UsertypesComponent,
    UsersComponent,
    VehicleComponent,
    VehiclepackageComponent,
    ViewhiredvehiclesComponent,
    OrderhistoryComponent,
    CancelledordersComponent,
    VehiclepackageComponent,
    OrdersComponent,
    ApplicationsettingsComponent,
    ViewusersComponent,
    UserPermissionComponent,
    RoleBasedPermissionComponent,
    ViewdetailComponent,
    SearchPipe,
    StatusComponent,
    MastercolorComponent,
    MastersizeComponent,
    MastertypeComponent,
    MastersizeComponent,
    MastertypeComponent,
    DiscountComponent,
    RecivedreviewsComponent,
    SalesReportComponent,
    OrdersReportComponent,
    LowinventoryComponent,
    CustomOrdersComponent,
    PosComponent,
    ReturnOrdersComponent,
    ViewReturnOrderComponent,
    ViewInvoiceComponent,
    PosSearchPipe,
    CancelInvoiceComponent,
    MasterInvoicetypeComponent,
    ViewRefundInvoiceComponent,
    ApplicationsettingsComponent,
    CompanySettingsComponent,
    PosReportComponent,
    ViewReturnOrderComponent,
    SeoManagementComponent,
    BulkManagementComponent,
    EditUserComponent,
   
  ],
  imports: [
    BrowserModule,
    NgOtpInputModule,
    NgChartsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgxStarRatingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxPaginationModule,
    FlatpickrModule.forRoot(),
    CKEditorModule,
    NgImageSliderModule,
    CommonModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass:JwtInterceptor,
      multi: true,

    },
    DatePipe
    // CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
