<section class="content-header sub-head ">
  <h1>
    Add Colour
  </h1>

</section>
<section>

  <div class="row">

    <div class="col-md-12 ">
      <div class="box box-danger ">
        <form [formGroup]="form">
          <div class="box-body">
            <div class="radioboxmain beforeafternone">
                <div class="col-md-4 padd-left">
                  <label for="exampleInputEmail1">Name<span class="text-danger">*</span></label>
                  <input type="text" id="Host" formControlName="name" name="name" placeholder="Enter Colour Name"
                    class="form-control ng-pristine ng-invalid ng-touched">
                  <span class="validation-error" *ngIf="input.name.touched">
                    <span *ngIf="input.name.errors?.required">This Field Is Required</span>
                  </span>
                </div>

                <div class="col-md-3 padd-left">
                  <label for="exampleInputEmail1">Choose&nbsp;Colour<span class="text-danger">*</span></label>
                  <input type="color" id="Host" formControlName="colorCode" name="colorCode" placeholder="Enter Name"
                    class="form-control color-plate">

                    <span class="validation-error" *ngIf="input.colorCode.touched">
                      <span *ngIf="input.colorCode.errors?.required">This Field Is Required</span>
                    </span>
                </div>

                <div class="col-md-5 mt-5 padd-left buttons">
                  <a class="btn btn-success" type="submit" (click)="ManageMasterColor()">
                    Submit
                  </a>
                  <button class="btn btn-secondary" type="button">
                    Cancel
                  </button>
                </div>


            </div>
          </div>

        </form>









      </div>
    </div>
  </div>


  <section class="content-header sub-head ">
    <h1>
      Colour List
    </h1>

  </section>
  <div class="box">
    <div class="box-header">
      <div class="search-box-new">
        <div class="row">
          <div class="col-md-12 text-right">
    
        <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')"  class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
         
          <option  value="true" selected>Active&nbsp;List</option>
          <option value="false">Deactive&nbsp;List</option>
          <option value="">All</option>
          
        </select>
          </div>
        </div>
      </div>
     
    </div>
    <div class="box-body ">
      <div class="row nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table class="table table-striped table-bordered nomargin">

              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Name</th>
                  <th>Colour</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of masterlist| paginate
            : {
                itemsPerPage: tableSize,
                currentPage: page,
                totalItems: count
              };,let i=index ">

                  <td>{{i+1+ tableSize*(page-1)}}</td>
                  <td>{{item.name}}</td>
                  <td [style.color]="item.colorCode"><i class="fa fa-square"></i>
                  </td>
                  <td style="text-align: center;">
                    <div class="d-flex justify-content-center">
                      <button class="btn btn-icon m-1 p-1 mr-2">
                        <input type="checkbox" id="checkBoxAinA{{item.id}}" name="isActive" class="marginright10 "
                          (change)="ActivedeactiveProduct(item.id,item.isActive)" [checked]="item.isActive">
                      </button>

                      <button type="button" (click)="onEdit(item)" class="btn btn-icon" aria-label="Product details">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>

                </tr>

              </tbody>
            </table>

          </div>
          <div class="" *ngIf="masterlist.length>0;else elseblock;">
          </div>
        </div>
        <div class="row mt-3 view-user-page"   style="width: 100%;" *ngIf="masterlist.length>tableSize">
          <div class="col-sm-12 paging text-right">
            <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next"
              (pageChange)="OnTableDataChange($event)">
            </pagination-controls>
          </div>
        </div>

        <ng-template #elseblock>
          <div class="no-data">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <h4>No Data Found</h4>
          </div>
        </ng-template>
      </div>
    </div>

  </div>


</section>