import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubcategoriesService } from './subcategories.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as CryptoJS from 'crypto-js';
import { promise } from 'protractor';
import { AnyARecord } from 'dns';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { SeoManagementComponent } from '../seo-management/seo-management.component';
declare let $: any;

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.css']
})
export class SubcategoriesComponent implements OnInit {
  encPassword = 'neha@hexa';
  // *******pagination************
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];
  // *******************
  public SubCategoryForm:any;
  public RootURL:string=environment.baseUrl.slice(0,-3);
  public formdata = new FormData();
  public listmainmenus: Array<any> = [];
  public SubCategoriesList: Array<any> = [];
  public previewImage: any;
  public SearchString:string='';
  public CategoryID:number=0;
  public Active:boolean|string=true;
  private LoginUserID:number;

  @ViewChild(SeoManagementComponent) seocomponent:SeoManagementComponent;
  constructor(public router: Router, 
    fb: UntypedFormBuilder, 
    private toastr: ToastrService, 
    private spinner: NgxSpinnerService, 
    public subcategoryoneservice: SubcategoriesService,
    private appservice:AppService
  ) {
    this.SubCategoryForm = fb.group({
      id: [0],
      productCategoryID: [null, Validators.required],
      subCategoryName: ['', Validators.required],
      imagePath: [null],
      searchTag: ['', Validators.required],
      loginUserID:[0],
    });
  }

  ngOnInit(): void {
    this.MainMenusDropdown()
    this.getSubcategoriesOneList();
    this.LoginUserID=this.appservice.getUserID();
  }
  MainMenusDropdown() {
    this.spinner.show();
    this.subcategoryoneservice.getMainMenus().subscribe(data => {
      if (data.message == "Success") {
        this.listmainmenus = data.result;
      }
      setTimeout(() => {
        this.spinner.hide();
      },500);
    }, err => {
      this.spinner.hide();
    })
  }

  // *********pagination*********
  onTableDataChange(event: any) {
    this.page = event;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event;
    this.page = 1;
  }
  onChangeCategory(categoryID:number){
    const category=this.listmainmenus.find(x=>x.id===categoryID);
    this.SubCategoryForm.patchValue({
      isOnline:category.isOnline,
      isOffline:category.isOffline,
    });
  
  }


  Filter(key:any,type:string){
    if(type==='BY-SEARCH'){
      this.SearchString=key.trim();
      this.Active='';
    }
    if(type==='BY-CATEGORY'){
      this.CategoryID=key>0?key:0;
    }
    if(type==='ACTIVE-INACTIVE'){
      this.Active=key;
    }
    this.page=1;
    this.getSubcategoriesOneList();

  }

  // *********paginationEnd*********

  getSubcategoriesOneList() {
    this.spinner.show();
    this.subcategoryoneservice.getSubCategoriesList(this.CategoryID,this.Active,this.SearchString).subscribe(data => {
      if (data.message == "Success") {
        this.SubCategoriesList = data.result;
      }
      else {
        console.warn('No Data Found!');
        this.SubCategoriesList=[];
      }
      setTimeout(() => {
        this.spinner.hide();
      },500);
    }, err => {
        this.spinner.hide();
      this.toastr.error('', "Not Responding");
    });
  }


  OnChangeFile(event: any) {
    const file = event.target.files[0];
    if (file.type.startsWith('image/')) {
      this.formdata = new FormData();
      this.formdata.append('Files', file);
      this.formdata.append('Type', 'SubCategory');
      this.formdata.append('LoginUserID', '1');
    }
    else {
      this.toastr.warning('', 'Upload only image file'); event.srcElement.value = null;
    }
  }

  onChangeInput(key:string){
    this.seocomponent.seoForm.patchValue({
      metaTitle:key,
    });
  }

  async onSubmit() {
    if (this.SubCategoryForm.valid && this.seocomponent.isFormValid()) {
      if (this.formdata.has('Files') || this.SubCategoryForm.get('id').value > 0) {
        this.spinner.show();
        let imagePath=null
        if (this.formdata.has('Files')) {
          try {
            const res = await this.subcategoryoneservice.UploadImage(this.formdata).toPromise();
            if (res.message === 'ok') {
              imagePath=res.result[0].path+res.result[0].filename;
            };
          } catch (error) {console.error(error);this.spinner.hide()};
        };
        this.SubCategoryForm.patchValue({
          imagePath:imagePath,
          loginUserID:this.LoginUserID,
        });
        this.subcategoryoneservice.AddEditcategories(this.SubCategoryForm.value).subscribe(data => {
          if (data.message === 'Success') {
            this.toastr.success(data.activity);
            document.getElementById('btnsubmit').innerHTML = 'Submit';
            this.getSubcategoriesOneList();

            //Manage Seo Details
            this.seocomponent.seoForm.patchValue({
              slug:data.result.slug,
              type:'category-level1'
            });
            this.seocomponent.submitSEO();


            this.resetForm();
          }
          else { this.toastr.error('', data.message); }
        });
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      } else { this.toastr.warning('Please Select Image!!!') }
    }
    else {
      this.spinner.hide();
      this.SubCategoryForm.markAllAsTouched();
      this.seocomponent.seoForm.markAllAsTouched();
    }
  }


  onEdit(catgry: any) {
    this.seocomponent.bindSeoDetail('category-level1',catgry.slug);
    $('#filevalidation').addClass('hide');
    this.SubCategoryForm.patchValue({
      productCategoryID: catgry.productCategoryID,
      subCategoryName: catgry.subCategory,
      id: catgry.id,
      imagePath:catgry.imagePath,
      searchTag: catgry.searchTag,
      isOnline:catgry.isOnline,
      isOffline:catgry.isOffline,

    });
    window.scroll(0, 0);
    document.getElementById('btnsubmit').innerHTML = 'Update';
    $('#manage-subcategory').modal('show');
  }

  MenuActivedeactive(id: number, IsActive: boolean) {
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Deactivating this category will also deactivate associated category level 2. Are you sure you want to continue?" : "Are you sure you want to proceed with activating the category?",
      icon: 'question',
      showCancelButton: true,
      showDenyButton: !IsActive,
      denyButtonColor: '#65B741',
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm",
      denyButtonText: 'Activate All Levels'
    }).then((result) => {
      if (result.isConfirmed || result.isDenied) {
        const type = result.isDenied && !IsActive ? 'All' : result.isConfirmed && IsActive ? 'All' : 'Main-Active';
        const Action = IsActive ? 'deactivated' : ' activated ';
        const Isactive = IsActive ? false : true;

        this.subcategoryoneservice.getActiveDeactive(id, Isactive, type,this.LoginUserID).subscribe(res => {
          this.getSubcategoriesOneList();
          this.toastr.success('Menu has been ' + Action + ' successfully.');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })
  }



  resetForm(){
    this.SubCategoryForm.reset();
    $('#Image').val('');
    this.formdata=new FormData();
    this.SubCategoryForm.patchValue({
      id:0,
      isOnline:true,
      isOffline:true,
    });
    $('#filevalidation').removeClass('hide');
    $('#manage-subcategory').modal('hide');
    $('#btnsubmit').text('Submit');
    setTimeout(() => {
      this.seocomponent.resetSEO();
    },500);

  }

  get input() {
    return this.SubCategoryForm.controls;
  }

  toggleCheck(elementid: string) {
    const checkbox = document.getElementById(elementid) as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = !checkbox.checked; // Toggle the checked state
    }
  }

  eligebleFromParent(id: number, type: string) {
    const category = this.listmainmenus.find(x => x.id === id);
    return type === 'change-online' ? category?.isOnline : category?.isOffline;
  }

}
