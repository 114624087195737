<section class="content-header sub-head ">
    <h1>
        Received Reviews
    </h1>

</section>



<section class="content sub-cat">

    <div class="row">

        <div class="col-md-12">
            <div class="box box-danger ">
                <div class="box-header">
                    <div class="search-box-new">
                      <div class="row">
                        <div class="col-md-12 text-right">
                           
                      <select (change)="Filter($event.target.value,'APPROVE-REJECT')" class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
                        <option  value="true" selected>Approve&nbsp;List</option>
                        <option value="false">Reject&nbsp;List</option>
                        <option value="">All</option>
                      </select>

                        </div>
                      </div>
                    </div>
                   
                  </div>
                <div class="box-body nomargin" >
                    <div class="row nomargin">
                        <div class="col-md-12" style="margin-top: 12px;">
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered nomargin">

                                    <thead>
                                        <tr>
                                            <th style="text-align: center;">S.NO</th>
                                            <th>Customer&nbsp;Name</th>
                                            <th>Product&nbsp;Name</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Created&nbsp;Date</th>
                                            <th style=" text-align: center; ">Star&nbsp;Rating</th>
                                            <th>Action</th>


                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let item of RecivedReviewList| paginate
                                        : {
                                            itemsPerPage: tableSize,
                                            currentPage: page,
                                            totalItems: count
                                          } let i=index">

                                            <td>{{i+1+tableSize*(page-1)}}</td>
                                            <td>{{item.customerFirstName}} &nbsp; {{item.customerLastName}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.reviewTitle}}</td>
                                            <td>{{item.reviewDescription}}</td>
                                            <td>{{item.createdDate|date: 'dd-MMM-yyyy'}}</td>

                                                <!-- this is not responsive -->
                                            <td class="starred">
                                                <ngx-star-rating id="rating{{item.id}}" [ngModel]="item.starRating" class="form-stars"
                                                    [disabled]="true"></ngx-star-rating>
                                            </td>



                                            <td>
                                                <div class="switches-container">
                                                    <input (change)="ApproveRejectReview(item.id,true)" type="radio" id="Approve{{item.id}}" name="switchPlan{{item.id}}" value="Approve{{item.id}}" [checked]="item.isAproved" />
                                                    <input  (change)="ApproveRejectReview(item.id,false)" type="radio" id="Reject{{item.id}}" name="switchPlan{{item.id}}" value="Reject{{item.id}}"  [checked]="!item.isAproved" />
                                                    <label for="Approve{{item.id}}">Approve</label>
                                                    <label for="Reject{{item.id}}">Reject</label>
                                                    <div class="switch-wrapper">
                                                      <div class="switch">
                                                        <div>Approve</div>
                                                        <div>Reject</div>
                                                      </div>
                                                    </div>
                                                </div>
                                                
                                            </td>



                                        </tr>
                                    </tbody>
                                </table>
                            <div *ngIf="RecivedReviewList.length>0;else elseBlock">

                            </div>
                            </div>
                            <div class="row mt-3" *ngIf="RecivedReviewList?.length>10">
                                <div class="col-md-9">
                                    <pagination-controls previousLabel="Prev" nextLabel="Next"
                                        (pageChange)="onTableDataChange($event)">
                                    </pagination-controls>

                                </div>
                                <div class="col-md-3 text-right">
                                    <select (change)="onTableSizeChange($event.target.value)"
                                        class="form-control w-auto">
                                        <option *ngFor="let tblsize of tableSizes" [ngValue]="tblsize">{{tblsize}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <ng-template #elseBlock>
                    <!-- Content to display when condition is false -->
                    <div class="no-data">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                          <h4>No Data Found</h4>
                        </div>
                </ng-template>
            </div>

        </div>
    </div>
</section>