import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoriesService } from './categories.service';
import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { settings } from 'cluster';
import { AppService } from 'src/app/app.service';
import { SeoManagementComponent } from '../seo-management/seo-management.component';
import { ThemeService } from 'ng2-charts';
declare let $: any;
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  encPassword = 'neha@hexa';
  // ******pagination********
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];

  // ************************
  public PreviewCategoryImage: any;
  public SelectedFile: any;
  public SearchString: string = '';
  public Active: boolean | string = true;
  public CategoryForm: any;
  public formdata = new FormData();
  private LoginUserID:number;
@ViewChild(SeoManagementComponent) seocomponent:SeoManagementComponent;


  constructor(public router: Router,
     fb: UntypedFormBuilder,
      public categoryservice: CategoriesService, 
      private spinner: NgxSpinnerService, 
      private toastr: ToastrService,
      private appservice:AppService
    ) {
    this.CategoryForm = fb.group({
      id: 0,
      categoryName: ['', Validators.required],
      categoryImage: [null],
      searchTag: ['', Validators.required],
      loginUserID:[0]
    });
  }
  public MainMenuList: Array<any> = [];
  public activedeacMenu: any = {};
  public rootUrl = environment.baseUrl.slice(0, -3);
  ngOnInit(): void {
    this.getMainMenuList();
    this.LoginUserID=this.appservice.getUserID();
  }
  getMainMenuList() {
    this.spinner.show();
    this.categoryservice.getMainMenus(this.Active,this.SearchString).subscribe(data => {
      if (data.message == "Success") {
        this.MainMenuList = data.result;
      } else { console.error('No Data Found') }
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => {
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    });
  }



  // ********pagination*************
  onTableDataChange(event: any) {
    this.page = event;
    this.getMainMenuList();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event;
    this.page = 1;
    this.getMainMenuList();
  }
  // *********************


  async onSubmit() {
    if (this.CategoryForm.valid && this.seocomponent.isFormValid()) {
      if (this.formdata.has('Files') || this.CategoryForm.get('id').value > 0) {
        this.spinner.show();
        let categoryImage=null
        if (this.formdata.has('Files')) {
          try {
            let data = await this.categoryservice.AddCategoryImage(this.formdata).toPromise();
            if (data.message == 'ok') {
              categoryImage= data.result[0].path + data.result[0].filename;
            };
          } catch (error) { console.error(error); }
        };
        this.CategoryForm.patchValue({
          loginUserID:this.LoginUserID,
          categoryImage:categoryImage,
        });
        this.categoryservice.Mainmenus(this.CategoryForm.value).subscribe(data => {
          if (data.message === 'Success') {
            this.getMainMenuList();
            let CategoryID = this.CategoryForm.get('id').value
            this.toastr.success(`Category has been ${CategoryID > 0 ? 'Updated' : 'Added'} Successfully`);
            // Manage seo details
            this.seocomponent.seoForm.patchValue({
              slug:data.result.slug,
              type:'category'
            });
            this.seocomponent.submitSEO();
           
            this.resetform();
          }
          else { this.spinner.hide(); this.toastr.error('', data.message); }
          setTimeout(() => {
            this.spinner.hide();
          }, 300);
        });
      }
      else {
        this.toastr.warning('Select an Image');
      }
    }
    else {
      this.CategoryForm.markAllAsTouched();
      this.seocomponent.seoForm.markAllAsTouched();
    }
  }

  resetform() {
    document.getElementById('btnsubmit').innerHTML = 'Submit';
    $('#categoryImage').val(null);
    $('#filevalidation').removeClass('hide');
    $('#manage-category').modal('hide');
    this.CategoryForm.reset();
    this.formdata = new FormData();
    this.CategoryForm.patchValue({
      id: 0,
      isOnline: true,
      isOffline: true,
    });
    setTimeout(() => {
      this.seocomponent.resetSEO();
    },500);
   
  }

  onEdit(menu: any) {
    this.spinner.show();
    this.seocomponent.bindSeoDetail('category',menu.slug);
    this.CategoryForm.patchValue({
      id: menu.id,
      categoryName: menu.categoryName,
      categoryImage: menu.categoryImage,
      isOnline: menu.isOnline,
      isOffline: menu.isOffline,
      searchTag: menu.searchTag,
    });
    $('#filevalidation').addClass('hide');
    document.getElementById('btnsubmit').innerHTML = 'Update';
    $('#manage-category').modal('show');
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

onChangeInput(key:string){
  this.seocomponent.seoForm.patchValue({
    metaTitle:key,
  });
}

  OnChangeFile(event: any) {
    let file = event.target.files[0];
    if (file.type.startsWith('image/')) {

      this.formdata = new FormData();
      this.formdata.append('Files', file);
      this.formdata.append('Type', 'Category');
      this.formdata.append('LoginUserID', '1');
    }
    else {
      this.toastr.warning('', 'Upload only image file'); event.srcElement.value = null;
    }
  }



  MenuActivedeactive(id: number, IsActive: boolean) {
    const ActiveCategoryCount = this.MainMenuList.filter(x => x.isActive === true).length;
    if (ActiveCategoryCount > 9 && !IsActive) {
      $('#checkBoxAinA' + id).prop("checked", false)
      Swal.fire({
        // title: "Oops!!",
        title: "Inactive one existing from the  category then, you can active a  category.",
        icon: "question"
      });
    }

    else {
      Swal.fire({
        // title: 'Are you sure?',
        title: IsActive ? "Deactivating this category will also deactivate  associated category level 1 and category level 2. Are you sure you want to continue? " : "Are you sure you want to proceed with activating the category?",
        icon: 'question',
        showCancelButton: true,
        showDenyButton: !IsActive,
        confirmButtonColor: '#297084',
        cancelButtonColor: '#686767',
        denyButtonColor: '#65B741',
        confirmButtonText: IsActive ? "Confirm" : "Confirm",
        denyButtonText: " Activate All Levels ",
      }).then((result) => {
        if (result.isConfirmed || result.isDenied) {
          const type = result.isDenied && !IsActive ? 'All' : result.isConfirmed && IsActive ? 'All' : 'Main-Active';
          const Action = IsActive ? 'deactivated' : ' activated ';
          const Isactive = IsActive ? false : true;
          this.categoryservice.ActiveInactiveMainMenus(id, Isactive, type,this.LoginUserID).subscribe(res => {
            this.getMainMenuList();
            this.toastr.success(' Menu has been ' + Action + ' successfully.');
            setTimeout(() => {
              this.spinner.hide();
            }, 200);
          }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
        }
        else {
          function check() {
            $('#checkBoxAinA' + id).prop("checked", true)
          };
          function uncheck() {
            $('#checkBoxAinA' + id).prop("checked", false)
          }

          IsActive ? check() : uncheck();
        }
      })

    }

  }

  Filter(key: any, type: string) {
    if (type === 'BY-SEARCH'){
      this.SearchString = key.trim();
      this.Active='';
    }
    if(type==='ACTIVE-INACTIVE'){
      this.Active=key;
    }
    this.getMainMenuList()
  }

  OpenModal() {
    $('#manage-category').modal('show');
  }

  get f() {
    return this.CategoryForm.controls;
  }


}
