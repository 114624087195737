import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class EmailsettingService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  GetEmailSMTPSettings() {
    debugger;
    return this.http.get<any>(this.rootUrl + '/EmailSMTPSetting/GetEmailSMTPSettings');
  }
  ManageSMTPSettings(emailSMTPSettings) {
    debugger;
    return this.http.post<any>(this.rootUrl + '/EmailSMTPSetting/AddEditEmailSMTPSettings', emailSMTPSettings);
   
  }

  ActiveInactiveSMTP(ID) {

    return this.http.get<any>(this.rootUrl + '/EmailSMTPSetting/GetActiveEmailSMTPSettings', {params:{ID}});
  }
}
