import { Component, OnInit } from '@angular/core';
import { ViewInvoiceService } from './view-invoice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { identifierName } from '@angular/compiler';
import { DatePipe } from '@angular/common';
import { resolve } from 'dns';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { Router } from '@angular/router';
import { CompanySettingsService } from '../company-settings/company-settings.service';
import { settings } from 'cluster';
declare let $: any;

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {
  public IsPartial: any = '';
  public refundIsPartial_P1: boolean = false;

  public InvoiceList: any[] = [];
  public invoiceDetail: any;
  private RootURL: string = environment.baseUrl.slice(0, -3);
  public ReInvoicePaymentForm: any;
  private Balance: number = 0;
  private tempBalance: number = 0;
  public StoreCredit: number = 0;
  public searchString: string;
  public pickupstatus: any = '';
  public refundInvoiceForm: any;
  public refundAmount: number;
  public refundBalance: number;
  public refundableAmount_P1: number;
  public PagincationConfig = {
    itemsPerPage: 10,
    currentPage: 1,
  };
  public amountDetail1 = {
    cash: 0,
    online: 0,
    storeCredit: 0,
    TotalRefund: 0,
  };
  public amountDetail2 = {
    cash: 0,
    online: 0,
    storeCredit: 0
  };
  public secretKey:string;


  private fromdate: string = '';
  private todate: string = '';
  tableSizes: any = [10, 15, 20];
  constructor(private viewinvoiceservice: ViewInvoiceService, private spinner: NgxSpinnerService, private toastr: ToastrService, private fb: FormBuilder,
    private datepipe: DatePipe, private router: Router, private companysettingservice: CompanySettingsService) {
    this.ReInvoicePaymentForm = this.fb.group({
      id: [0],
      cashPaid: [0],
      storeCreditPaid: [0],
      onlinePaid: [0],
      Balance: [0]
    });

    this.refundInvoiceForm = fb.group({
      id: [0],
      itemID: [null],
      reason: [null, [Validators.required]],
      orderNO: [null],
      refundCash: [0],
      refundStoreCredit: [0],
      refundOnline: [0],
      refundCash1: [0],
      refundStoreCredit1: [0],
      refundOnline1: [0],
    });
  }

  ngOnInit(): void {
    this.GetInvoiceList();
    this.getSecretKey();
    $(".date-range [name='Date-Filter']").flatpickr({
      dateFormat: 'd/M/Y',
      maxDate: 'today'
    });
  }

  GetInvoiceList() {
    this.spinner.show();
    this.viewinvoiceservice.GetInvoiceList(this.IsPartial, this.fromdate, this.todate, this.pickupstatus, 'other-invoice').subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.InvoiceList = response.result;
        } else {
          console.error(response.message);
          this.InvoiceList = [];
        };
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.error(err)
      }
    })
  }
  onTableDataChange(pagenumber: number) {
    this.PagincationConfig.currentPage = pagenumber;
  }
  onTableSizeChange(val: number) {
    this.PagincationConfig.currentPage = 1;
    this.PagincationConfig.itemsPerPage = val;
  }
  Filter(val: any, type: string) {
    this.PagincationConfig.currentPage = 1;
    let date = new Date();
    if (type === 'payment-type') {
      val = val === 'true' ? true : false;
      this.IsPartial = val;
      this.GetInvoiceList();
    }
    if (type === 'search-invoice') {
      this.searchString = val;
    }
    if (type === 'from-date') {
      if (this.todate === '') {
        this.todate = this.datepipe.transform(date, 'yyyy-MM-dd');
      };
      this.fromdate = this.datepipe.transform(val, 'yyyy-MM-dd');
      this.GetInvoiceList();

    }
    if (type === 'to-date') {
      if (this.fromdate === '') {
        this.fromdate = this.datepipe.transform(date, 'yyyy-MM-dd');
      };
      this.todate = this.datepipe.transform(val, 'yyyy-MM-dd');
      this.GetInvoiceList();
    }
    if (type === 'pickup-status') {
      this.pickupstatus = val;
      this.GetInvoiceList();
    }
  }

  onChangePaymentMode(val: string) {
    this.refundInvoiceForm.patchValue({
      refundMode: val
    });
  }

  validateCancellation(val: boolean) {
    if (val) {
      let total = this.refundInvoiceForm.get('refundCash').value ?? 0;
      total += this.refundInvoiceForm.get('refundOnline').value ?? 0;
      total += this.refundInvoiceForm.get('refundStoreCredit').value ?? 0;

      total += this.refundInvoiceForm.get('refundCash1').value ?? 0;
      total += this.refundInvoiceForm.get('refundOnline1').value ?? 0;
      total += this.refundInvoiceForm.get('refundStoreCredit1').value ?? 0;

      $('#agreement').prop('checked', false);
      if (total === this.refundAmount || this.refundIsPartial_P1) {
        if (this.refundIsPartial_P1) {
          const itemID = this.refundInvoiceForm.get('itemID').value;
          let totalReceivedParitalAmount = this.amountDetail1.cash + this.amountDetail1.online + this.amountDetail1.storeCredit;
          if (total > totalReceivedParitalAmount) {
            Swal.fire({
              // title: 'Warning!',
              title: 'The entered amount is greater than the total received amount.',
              icon: 'warning'
            });
            return;
          }

          if (this.refundableAmount_P1 < total) {
            Swal.fire({
              title: 'You cannot refund this item because the entered amount is greater than the refundable amount.',
              // title: 'Warning!',
              icon: 'warning',
            });
            return;
          }
          if (total !== this.refundableAmount_P1 && itemID < 1) {
            Swal.fire({
              title: 'You are not able to refund this item because the entered amount is not equal to the refundable amount.',
              // title: 'Warning!',
              icon: 'warning',
            });
            return;
          }
        }
        Swal.fire({
          title: "Enter secret key",
          input: "password",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          customClass: {
            popup: 'my-popup-class'
          },
          preConfirm: (key) => {
            if (this.secretKey !== key) {
              Swal.showValidationMessage(`
                  Invalid key:
                `);
              return;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            $('#agreement').prop('checked', true);
            $('#refund-submit').css('pointer-events', '');
            $('#refund-submit').css('filter', 'none');
          };
        });
        $('.my-popup-class').css('width', '300px'); // Adjust width as needed
      } else {
        Swal.fire({
          // title: 'Warning!',
          title: 'The total amount received does not match the amount refunded.',
          icon: 'warning'
        });
      }
    } else {
      $('#refund-submit').css('pointer-events', 'none');
      $('#refund-submit').css('filter', 'contrast(0.5)');
    }
  }


  getSecretKey() {
    this.spinner.show();
    this.viewinvoiceservice.getSecretKey().subscribe({
      next: (response) => {
        if (response) {
          this.secretKey=response.toString();
        } else {
          console.error('Not found secret key:',response);
        };
        setTimeout(() => {
          this.spinner.hide();

        },500);
      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }
    })
  }

  OnChangePickup(ID: number) {
    Swal.fire({
      // title: 'Warning!',
      title: 'Are you sure you want to change the status?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.viewinvoiceservice.ChangePickupStatus(ID).subscribe({
          next: (response) => {
            if (response.message === 'Success') {
              this.toastr.success('Delivery status has been changed successfully.')
              this.GetInvoiceList();
            } else { console.error(response.message) };
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          },
          error: (err) => {
            this.toastr.error(err)
            this.spinner.hide();
          }
        })
      }
      else {
        this.InvoiceList = this.InvoiceList.map((item: any) => {
          if (item.id === ID) {
            item.pickupStatus = 1;
          };
          return item;
        });
      }
    })
  }



  Send(InvoiceID: number, Type: string) {
    if (Type === 'EMAIL') {
      Swal.fire({
        // title: 'Confirmation',
        title: 'Are you sure you want to send an email ?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        icon: 'question'
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.viewinvoiceservice.SendInvoiceOnEmail(InvoiceID).subscribe({
            next: (response) => {
              if (response.message === 'Success') {
                this.toastr.success('The invoice has been successfully sent to your email.');
              } else { this.toastr.error(response.message) };
              setTimeout(() => {
                this.spinner.hide();
              }, 500);
            },
            error: (err) => {
              this.toastr.error(err)
              this.spinner.hide();
            }
          });
        }
      });
    }

    else if (Type === 'SMS') {
      this.spinner.hide();
    }
  }

  manageRefund() {
    if (this.refundInvoiceForm.valid) {
      this.spinner.show();
      this.viewinvoiceservice.submitReason(this.refundInvoiceForm.value).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            if (this.refundInvoiceForm.get('itemID').value ?? 0 > 0) {
              $('#reason').modal('hide');
              $('#agreement').prop('checked', false);
              $('#refund-submit').css('pointer-events', 'none');
              $('#refund-submit').css('filter', 'contrast(0.5)');
              $('#refundable-amt').addClass('hide');
              this.refundInvoiceForm.reset();
              this.refundInvoiceForm.patchValue({
                refundMode: 'Cash',
                refundCash: 0,
                refundStoreCredit: 0,
                refundOnline: 0,
                refundCash1: 0,
                refundStoreCredit1: 0,
                refundOnline1: 0,
              });

              this.GetInvoiceList();
              this.coloseCancelInvoice();
              this.toastr.success('The invoice has been cancelled successfully.');
            } else {
              $('#CancelInvoice').modal('hide');
              $('#reason').modal('hide');
              this.router.navigateByUrl('/cancel-invoice');
            }

          } else {
            console.error(response.message);
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        },
        error: (err) => {
          this.toastr.error(err)
        }
      })
    } else {
      this.refundInvoiceForm.markAllAsTouched();
    }
  }

  resetReasonFormValue() {
    $('#reason').modal('hide');
    $('#agreement').prop('checked', false);
    $('#refund-submit').css('pointer-events', 'none');
    $('#refund-submit').css('filter', 'contrast(0.5)');
    $('#refundable-amt').addClass('hide');
    this.refundInvoiceForm.patchValue({
      itemID: null,
      reason: null,
      refundMode: 'Cash',
      refundCash: 0,
      refundStoreCredit: 0,
      refundOnline: 0,
      refundCash1: 0,
      refundStoreCredit1: 0,
      refundOnline1: 0,
    });
  }

  DownLoadInvoice(InvoiceNumber: string, InvoiceCount: number, IsPartial: boolean) {
    const Folder = IsPartial === true ? 'Partial_Invoice' : 'Full_Invoice';
    const Path = `Content/Invoice/${Folder}/`;
    const downloadlink = `${this.RootURL}${Path}${InvoiceNumber}.pdf`;
    saveAs(downloadlink, `invoice.pdf`);
  }



  GetInvoiceDetailByID(InvoiceID: number) {
    this.spinner.show();
    this.viewinvoiceservice.GetInvoiceDetailByID(InvoiceID).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const data = response.result;
          this.Balance = parseFloat(data.balance.toFixed(2));
          this.tempBalance = parseFloat(data.balance.toFixed(2));
          this.StoreCredit = data.customerStoreCredit ?? 0;
          this.ReInvoicePaymentForm.patchValue({
            id: InvoiceID,
            Balance: this.tempBalance
          });
          data.isPaymentModeCash ? $('#strorecredit-box').addClass('hide') : $('#strorecredit-box').removeClass('hide');
          $('#ReInvoiceModal').modal('show');
        } else { console.error(response.message) };
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }
    })
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  OnChaneCashType(type: string) {
    const Cash = this.ReInvoicePaymentForm.get('cashPaid').value ?? 0;
    const storecredit = this.ReInvoicePaymentForm.get('storeCreditPaid').value ?? 0;
    const Online = this.ReInvoicePaymentForm.get('onlinePaid').value ?? 0;
    const Total = Cash + storecredit + Online;
    if (type === 'Cash-Paid') {
      if (Total <= this.Balance) {
        this.ReInvoicePaymentForm.patchValue({
          cashPaid: Cash
        });
        this.tempBalance = this.Balance - Total;
      } else {
        this.WarningOverAmount('cash', 0);
        this.ReInvoicePaymentForm.patchValue({
          cashPaid: 0
        });
        this.tempBalance = this.Balance - (storecredit + Online);
      }
    }
    if (type === 'Storecredit-Paid') {
      if (storecredit <= this.StoreCredit && Total <= this.Balance) {
        this.ReInvoicePaymentForm.patchValue({
          storeCreditPaid: storecredit
        });
        this.tempBalance = this.Balance - Total;
      } else {
        this.WarningOverAmount('store-credit', storecredit);
        this.ReInvoicePaymentForm.patchValue({
          storeCreditPaid: 0
        });
        this.tempBalance = this.Balance - (Cash + Online);
      }
    }
    if (type === 'Online-Paid') {
      if (Total <= this.Balance) {
        this.tempBalance = this.Balance - Total;
        this.ReInvoicePaymentForm.patchValue({
          onlinePaid: Online
        });
      } else {
        this.WarningOverAmount('online', 0);
        this.ReInvoicePaymentForm.patchValue({
          onlinePaid: 0
        });
        this.tempBalance = this.Balance - (Cash + storecredit);
      }
    }
    this.tempBalance = parseFloat(this.tempBalance.toFixed(2));
    this.ReInvoicePaymentForm.patchValue({
      Balance: this.tempBalance
    })
  }


  WarningOverAmount(type: string, usercredit: number) {
    let Text;
    if (type === 'store-credit' && usercredit > this.StoreCredit) {
      Text = "Insufficient store credit amount";
    } else {
      Text = "You've entered an amount that exceeds the balance Amount.";
    };
    Swal.fire({
      // title: "Warining!",
      text: Text,
      icon: "warning",
      confirmButtonText: "OK"
    });
  }

  OnSubmit() {
    if (this.ValidateInvoice()) {
      this.spinner.show();
      this.viewinvoiceservice.CompleteInvoicePayment(this.ReInvoicePaymentForm.value).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.toastr.success('The payment has been completed successfully');

            this.ResetForm();
            this.GetInvoiceList();
          } else {
            this.toastr.error(response.message);
          };
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        },
        error: (err) => {
          this.toastr.error(err)
          this.spinner.hide();
        }
      })
    }
  }

  ValidateInvoice(): boolean {
    let Total = this.ReInvoicePaymentForm.get('cashPaid').value;
    Total += this.ReInvoicePaymentForm.get('storeCreditPaid').value;
    Total += this.ReInvoicePaymentForm.get('onlinePaid').value;
    if (this.ReInvoicePaymentForm.invalid) {
      return false;
    } else if (Total !== this.Balance) {
      this.toastr.warning('Please complete your payment.');
      return false;
    }
    return true;
  }

  ResetForm() {
    $('#ReInvoiceModal').modal('hide');
    this.ReInvoicePaymentForm.reset();
    this.tempBalance = 0;
    this.Balance = 0;
    this.StoreCredit = 0;
    this.ReInvoicePaymentForm.patchValue({
      id: 0,
      cashPaid: 0,
      storeCreditPaid: 0,
      onlinePaid: 0,
      Balance: 0
    })
  }

  getInvoiceItemListByInvoiceID(ID: number, isPartial: boolean, isPartialComplete: boolean, orderNO: string) {
    this.spinner.show();
    this.viewinvoiceservice.GetInvoiceItemListByInvoiceID(ID).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.invoiceDetail = response.result;
          // Bind Pay Amount Details
          const data = response.result.amountDetailList;

          this.refundIsPartial_P1 = isPartial && !isPartialComplete ? true : false;

          if (isPartial && isPartialComplete) {
            this.amountDetail1.cash = data[0].cashPay;
            this.amountDetail1.storeCredit = data[0].storecreditPay;
            this.amountDetail1.online = data[0].onlinePay;

            this.amountDetail2.cash = data[1].cashPay;
            this.amountDetail2.storeCredit = data[1].storecreditPay;
            this.amountDetail2.online = data[1].onlinePay;
          } else {
            this.amountDetail1.cash = data[0].cashPay;
            this.amountDetail1.storeCredit = data[0].storecreditPay;
            this.amountDetail1.online = data[0].onlinePay;
            this.amountDetail1.TotalRefund = data[0].refundCash ?? 0 + data[0].refundOnline ?? 0 + data[0].refudStoreCredit ?? 0;
          }
          this.refundInvoiceForm.patchValue({
            id: ID,
            orderNO: orderNO
          });
          $('#CancelInvoice').modal('show');
          this.manageTemplateElements(isPartial, isPartialComplete);
        } else { console.error(response.message) };
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }
    })
  }

  manageTemplateElements(isPartial: boolean, isPartialComplete: boolean) {
    if (isPartial) {
      $('#payment-title-1').text('Partial Payment 1');
      if (isPartialComplete) {
        $('#Payment-2').removeClass('hide');
      }
    } else {
      $('#payment-title-1').text('Complete Payment');
    }
  }

  viewRefundForm(type: string, refundAmount: number, Id: number) {
    //Full Invoice Cancel
    if (type === 'Full-Invoice') {
      let total = 0;
      this.invoiceDetail.itemList.map(item => {
        total += item.itemPrice;
      });
      total = parseFloat(total.toFixed(2));
      this.refundAmount = total;
      this.refundBalance = total;

      //Set Refundable Amount In case Partial P1
      if (this.refundIsPartial_P1) {
        $('#refundable-amt').removeClass('hide');
        this.refundableAmount_P1 = parseFloat(((this.amountDetail1.cash + this.amountDetail1.online + this.amountDetail1.storeCredit) - this.amountDetail1.TotalRefund).toFixed(2));
      };
      $("[name='payment-storecreadit']").removeClass('hide');
    }
    else {
      refundAmount = parseFloat(refundAmount.toFixed(2));
      this.refundAmount = refundAmount;//500
      this.refundBalance = refundAmount;
      // Single Item Cancel
      if (this.invoiceDetail?.itemList.length > 1) {
        $("[name='payment-storecreadit']").addClass('hide');
        if (this.refundIsPartial_P1) {
          $('#refundable-amt').removeClass('hide');
          let receivedPartialTotal = this.amountDetail1.cash + this.amountDetail1.online + this.amountDetail1.storeCredit;
          let totalItemAmount = 0;
          this.invoiceDetail.itemList.map(item => {
            totalItemAmount += item.itemPrice;
          });
          let tenPercentOfItem = (totalItemAmount - this.refundAmount) * 0.25;
          this.refundableAmount_P1 = parseFloat(((receivedPartialTotal - this.amountDetail1.TotalRefund) - tenPercentOfItem).toFixed(2));
        }
        this.refundInvoiceForm.patchValue({
          itemID: Id
        });
      }
      //Full Invoice Cancel
      else {
        this.refundInvoiceForm.patchValue({
          itemID: null
        });
        $("[name='payment-storecreadit']").removeClass('hide');
        //Set Refundable Amount In case Partial P1
        if (this.refundIsPartial_P1) {
          $('#refundable-amt').removeClass('hide');
          this.refundableAmount_P1 = parseFloat(((this.amountDetail1.cash + this.amountDetail1.online + this.amountDetail1.storeCredit) - this.amountDetail1.TotalRefund).toFixed(2));
        };


      }
    }
    setTimeout(() => {
      $('#reason').modal('show');
    }, 500);
  }

  OnChangeAmount(type: string) {
    //Partial  Payment 1
    const cash = this.refundInvoiceForm.get('refundCash').value ?? 0;
    const online = this.refundInvoiceForm.get('refundOnline').value ?? 0;
    const storeCredit = this.refundInvoiceForm.get('refundStoreCredit').value ?? 0;

    //Partial Payment 2
    const cash1 = this.refundInvoiceForm.get('refundCash1').value ?? 0;
    const online1 = this.refundInvoiceForm.get('refundOnline1').value ?? 0;
    const storeCredit1 = this.refundInvoiceForm.get('refundStoreCredit1').value ?? 0;
    $('#agreement').prop('checked', false);
    $('#refund-submit').css('pointer-events', 'none');
    $('#refund-submit').css('filter', 'contrast(0.5)');
    const total = cash + cash1 + online + online1 + storeCredit + storeCredit1;

    if (type === 'cash') {
      if (total > this.refundAmount) {
        this.warningMessage('Total exceeds the refund amount.');
        this.refundInvoiceForm.patchValue({
          refundCash: 0
        });
        this.refundBalance = this.refundAmount - (cash1 + online + storeCredit + online1 + storeCredit1);
      } else {
        this.refundInvoiceForm.patchValue({
          refundCash: cash
        });
        this.refundBalance = this.refundAmount - total;
      }
    } else if (type === 'cash1') {
      if (total > this.refundAmount) {
        this.warningMessage('Total exceeds the refund amount.');
        this.refundInvoiceForm.patchValue({
          refundCash1: 0
        });
        this.refundBalance = this.refundAmount - (cash + online + storeCredit + online1 + storeCredit1);
      } else {
        this.refundInvoiceForm.patchValue({
          refundCash1: cash1
        });
        this.refundBalance = this.refundAmount - total;
      }
    }


    else if (type === 'online') {
      if (total > this.refundAmount) {
        this.warningMessage('Total exceeds the refund amount.');
        this.refundInvoiceForm.patchValue({
          refundOnline: 0
        });
        this.refundBalance = this.refundAmount - (cash + cash1 + storeCredit + storeCredit1 + online1);
      } else {
        this.refundInvoiceForm.patchValue({
          refundOnline: online
        });
        this.refundBalance = this.refundAmount - total;
      }
    } else if (type === 'online1') {
      if (total > this.refundAmount) {
        this.warningMessage('Total exceeds the refund amount.');
        this.refundInvoiceForm.patchValue({
          refundOnline1: 0
        });
        this.refundBalance = this.refundAmount - (cash + cash1 + storeCredit + storeCredit1 + online);
      } else {
        this.refundInvoiceForm.patchValue({
          refundOnline1: online1
        });
        this.refundBalance = this.refundAmount - total;
      }
    }


    else if (type === 'storecredit') {
      if (total > this.refundAmount || storeCredit > this.amountDetail1.storeCredit) {
        total>this.refundAmount?this.warningMessage('Total exceeds the refund amount.'):this.warningMessage('Store credit exceeds the available store credit.')
        this.refundInvoiceForm.patchValue({
          refundStoreCredit: 0
        });
        this.refundBalance = this.refundAmount - (online + online1 + cash + cash1 + storeCredit1);;
      } else {
        this.refundInvoiceForm.patchValue({
          refundStoreCredit: storeCredit
        });
        this.refundBalance = this.refundAmount - total;
      }
    }
    else if (type === 'storecredit1') {
      if (total > this.refundAmount || storeCredit1 > this.amountDetail2.storeCredit) {
        total>this.refundAmount?this.warningMessage('Total exceeds the refund amount.'):this.warningMessage('Store credit exceeds the available store credit.')
        this.refundInvoiceForm.patchValue({
          refundStoreCredit1: 0
        });
        this.refundBalance = this.refundAmount - (online + online1 + cash + cash1 + storeCredit);;
      } else {
        this.refundInvoiceForm.patchValue({
          refundStoreCredit1: storeCredit1
        });
        this.refundBalance = this.refundAmount - total;
      }
    }
    this.refundBalance = parseFloat(this.refundBalance.toFixed(2));
  }


  warningMessage(text:string) {
    Swal.fire({
      icon: 'warning',
      // title: 'Warning!',
      title: text
    });
  }

  coloseCancelInvoice() {
    $('#Payment-2').addClass('hide');
    $('#CancelInvoice').modal('hide');
    this.refundIsPartial_P1 = false;
  }

}
