import { ViewRefundInvoiceComponent } from './pages/view-refund-invoice/view-refund-invoice.component';
import { MastertypeComponent } from './pages/mastertype/mastertype.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "src/app/login/login.component";
import { PagesComponent } from "src/app/pages/pages.component";
import { DashboardComponent } from "src/app/pages/dashboard/dashboard.component";
import { SubcategoriesComponent } from './pages/subcategories/subcategories.component';
import { BlogcontentComponent } from './pages/blogcontent/blogcontent.component';
import { ContentmanagementComponent } from './pages/contentmanagement/contentmanagement.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { EmailsettingComponent } from './pages/emailsetting/emailsetting.component';
import { ProductlistComponent } from './pages/productlist/productlist.component';
import { AddproductComponent } from './pages/addproduct/addproduct.component';
import { UsertypesComponent } from './pages/usertypes/usertypes.component';
import { UsersComponent } from './pages/users/users.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { VehiclepackageComponent } from './pages/vehiclepackage/vehiclepackage.component';
import { ViewhiredvehiclesComponent } from './pages/viewhiredvehicles/viewhiredvehicles.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ApplicationsettingsComponent } from './pages/applicationsettings/applicationsettings.component';
import { ViewusersComponent } from './pages/viewusers/viewusers.component';
import { SubCategoriesTwoComponent } from './pages/sub-categories-two/sub-categories-two.component';
import { UserPermissionComponent } from './pages/user-permission/user-permission.component';
import { RoleBasedPermissionComponent } from './pages/role-based-permission/role-based-permission.component';
import { ViewdetailComponent } from './pages/viewdetail/viewdetail.component';
import { StatusComponent } from './pages/status/status.component';
import { MastercolorComponent } from './pages/mastercolor/mastercolor.component';
import { MastersizeComponent } from './pages/mastersize/mastersize.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { RecivedreviewsComponent } from './pages/recivedreviews/recivedreviews.component';
import { SalesReportComponent } from './pages/sales-report/sales-report.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { OrdersReportComponent } from './pages/orders-report/orders-report.component';
import { AuthGuard } from './auth.guard';
import { LowinventoryComponent } from './pages/lowinventory/lowinventory.component';
import { OrderhistoryComponent } from './pages/orderhistory/orderhistory.component';
import { CustomOrdersComponent } from './pages/custom-orders/custom-orders.component';
import { PosComponent } from './pages/pos/pos.component';
import { ViewReturnOrderComponent } from './pages/view-return-order/view-return-order.component';
import { ReturnOrdersComponent } from './pages/return-orders/return-orders.component';
import { ViewInvoiceComponent } from './pages/view-invoice/view-invoice.component';
import { CancelInvoiceComponent } from './pages/cancel-invoice/cancel-invoice.component';
import { MasterInvoicetypeComponent } from './pages/master-invoicetype/master-invoicetype.component';
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component';
import { PosReportComponent } from './pages/pos-report/pos-report.component';
import { SeoManagementComponent } from './pages/seo-management/seo-management.component';
import { BulkManagementComponent } from './bulk-management/bulk-management.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';






const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },

  { path: '', component: PagesComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Dashboard' } },
      { path: 'categories', component: CategoriesComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Categories' } },
      { path: 'subcategories', component: SubcategoriesComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Subcategories' } },
      { path: 'subcategoriestwo', component: SubCategoriesTwoComponent, canActivate:[AuthGuard],data: { breadcrumb: 'Subcategoriestwo' } },
      { path: 'blogcontent', component: BlogcontentComponent, canActivate:[AuthGuard],data: { breadcrumb: 'BlogContent' } },
      { path: 'contentmanagement', component: ContentmanagementComponent, canActivate:[AuthGuard],data: { breadcrumb: 'Contentmanagement' } },
      { path: 'emailsettings', component: EmailsettingComponent,canActivate:[AuthGuard], data: { breadcrumb: 'EmailSettings' } },
      { path: 'productlist', component: ProductlistComponent,canActivate:[AuthGuard], data: { breadcrumb: 'ProductList' } },
      { path: 'manageProduct', component: AddproductComponent, canActivate:[AuthGuard],data: { breadcrumb: 'Add Product' } },
      { path: 'usertypes', component: UsertypesComponent,canActivate:[AuthGuard], data: { breadcrumb: 'User Type' } },
      { path: 'users', component: UsersComponent, canActivate:[AuthGuard],data: { breadcrumb: 'Users' } },
      { path: 'vehicle', component: VehicleComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Vehicle' } },
      { path: 'vehiclepackages', component: VehiclepackageComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Vehicle Package' } },
     {path:'posReport',component:PosReportComponent,canActivate:[AuthGuard],data: { breadcrumb: 'Pos Report' }},
      { path: 'viewhiredvehicles', component: ViewhiredvehiclesComponent, data: { breadcrumb: 'Vehicle Status' } },
      { path: 'orders', component: OrdersComponent, canActivate:[AuthGuard],data: { breadcrumb: 'Orders' } },
      { path: 'applicationsettings', component: ApplicationsettingsComponent, data: { breadcrumb: 'Application Settings' } },
      { path: 'viewusers', component: ViewusersComponent,canActivate:[AuthGuard], data: { breadcrumb: 'View Users ' } },
      { path: 'userpermissions', component: UserPermissionComponent,canActivate:[AuthGuard], data: { breadcrumb: 'User Permissions' } },
      { path: 'rolebasedpermissions', component: RoleBasedPermissionComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Role Based Permission' } },
      { path: 'viewdetail', component: ViewdetailComponent, canActivate:[AuthGuard],data: { breadcrumb: 'View Detail' } },
      { path: 'status', component: StatusComponent, canActivate:[AuthGuard],data: { breadcrumb: 'Status' } },
      { path: 'master-color', component: MastercolorComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Master Color' } },
      { path: 'master-size', component: MastersizeComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Master Size' } },
      { path: 'master-type', component: MastertypeComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Master Type' } },
      { path: 'master-discount', component: DiscountComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Master Discount' } },
      { path: 'received-review', component: RecivedreviewsComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Recived Reivew' } },
      {path:'sales-report', component:SalesReportComponent ,canActivate:[AuthGuard], data: { breadcrumb: 'Sales Report' }},
      {path:'order-report',component:OrdersReportComponent,canActivate:[AuthGuard], data: { breadcrumb: 'Order Report' }},
      {path:'low-inventory',component:LowinventoryComponent,canActivate:[AuthGuard], data: { breadcrumb: 'low-inventory' }},
      {path:'view-order',component:OrderhistoryComponent,canActivate:[AuthGuard], data: { breadcrumb: 'view-order' }},
      {path:'custom-orders',component:CustomOrdersComponent,canActivate:[AuthGuard], data: { breadcrumb: 'received-orders' }},
      {path:'pos',component:PosComponent,canActivate:[AuthGuard], data: { breadcrumb: 'point-of-sale' }},
      {path:'view-invoice',component:ViewInvoiceComponent,canActivate:[AuthGuard], data: { breadcrumb: 'view-invoice' }},
      {path:'return-orders',component:ReturnOrdersComponent,canActivate:[AuthGuard], data: { breadcrumb: 'return-orders' }},
      {path:'view-return-order',component:ViewReturnOrderComponent,canActivate:[AuthGuard], data: { breadcrumb: 'return-orders' }},
      {path:'cancel-invoice',component:CancelInvoiceComponent,canActivate:[AuthGuard], data: { breadcrumb: 'cancel-invoice' }},
      {path:'invoice-type',component:MasterInvoicetypeComponent,canActivate:[AuthGuard], data: { breadcrumb: 'invoice-type' }},

      {path:'company-setting',component:CompanySettingsComponent,canActivate:[AuthGuard], data: { breadcrumb: 'invoice-type' }},
      {path:'view-refund-invoice',component:ViewRefundInvoiceComponent,canActivate:[AuthGuard], data: { breadcrumb: 'invoice-type' }},
      {path:'seo-management',component:SeoManagementComponent,canActivate:[AuthGuard],data: { breadcrumb: 'seo-management' }},
      {path:'bulk-management',component:BulkManagementComponent,canActivate:[AuthGuard],data: { breadcrumb: 'bulk-management' }},
      {path:'edit-user',component:EditUserComponent,canActivate:[AuthGuard],data: { breadcrumb: 'edit-user' }},


    ]
  },{path:'**',component:NotfoundComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
