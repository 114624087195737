import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  GetOrderDeatilByOrderID(orderID: number): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Order/GetOrderDeatilByOrderID', { params: { orderID } });
  }
  getStatusList() {
    return this.http.get<any>(this.rootUrl + '/Order/getstatuslist');
  }

  ChangeStatusOfOrder(orderNO, statusID) {
    return this.http.get<any>(this.rootUrl + '/Order/ChangeOrderStatus', { params: { orderNO, statusID } });

  }
  ChangeDeleveryDate(ID, date): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Order/changeDeleveryDate', { params: { ID, date } })
  }

  ReceivedOrderList(UserID: any, date: any, type: string): Observable<any> {
    return this.http.get<any>(this.rootUrl + "/Order/getReceivedOderList", { params: { UserID, date, type } });
  }

  GetCustomizeOrderList(): Observable<any> {
    return this.http.get<any>(this.rootUrl + "/Order/GetCustomizeOrderList");
  }

  ReplyCustomizeOrder(data: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/Order/ReplyCustomizeOrder', data);

  }


  DownloadImage(imageurl: string): Observable<Blob> {
    return this.http.get('http://localhost:5251/Content/Customize/efbdb643-7.jpeg', { responseType: 'blob' })
  }

  RejectOrderEnquiry(ID: number): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Order/ApproveRejectEnq', { params: { ID } });

  }

}