import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalesreportService } from './salesreport.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../../app.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import domToImage from 'dom-to-image';
import { ToastrService } from 'ngx-toastr';
declare const $: any;


@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent implements OnInit {
  public Orders: any[] = [];
  public Rooturl: string = environment.baseUrl.slice(0, -3);
  @ViewChild('dataToExport') private dataToExport: ElementRef;
  @ViewChild('date_filter') private dateFilter:ElementRef;
  public paginationconfig = {
    itemsPerPage:10,
    currentPage: 1,
    totalItems :1
  };
  public fromDate:any;
  public toDate:any;
  constructor(private salesservice: SalesreportService, private spinner: NgxSpinnerService, private appservice: AppService, private datepipe: DatePipe,
    private toastr: ToastrService) { }

    

    ngOnInit(): void {

      let date=new Date();
      const d2date=new Date();
      d2date.setDate(date.getDate()-7);

      this.fromDate=this.datepipe.transform(d2date,'yyyy-MM-dd');
      this.toDate=this.datepipe.transform(date,'yyyy-MM-dd');
      this.GetSalesOrderList();
    }
  
    ngAfterViewInit(): void {
      const beforeOneWeekDate=new Date();
      beforeOneWeekDate.setDate(new Date().getDate()-7);
      this.dateFilter.nativeElement.flatpickr({
        mode: 'range',
        dateFormat: 'd/M/y',
        defaultDate:[beforeOneWeekDate,'today']
      });
    }

  Filter(key:string,value: any) {
    if(key==='by-date'){
      if (!value.includes('to')) return;
      const dates = value.split('to');
      this.fromDate = this.datepipe.transform(dates[0], 'yyyy-MM-dd');
      this.toDate = this.datepipe.transform(dates[1], 'yyyy-MM-dd');
    }
    else if(key==='by-pagination'){
      this.paginationconfig.currentPage=value;
    };
    this.GetSalesOrderList();
  }

  
  GetSalesOrderList(){
    this.spinner.show();
    this.salesservice.GetSalesOrderList(this.fromDate,this.toDate,this.paginationconfig.currentPage,this.paginationconfig.itemsPerPage,'sales-report').subscribe(data=>{
      if(data.message==='Success'){
        this.Orders=data.result;
        this.paginationconfig.totalItems=data.count;
      }
      else{
        this.Orders=[];
        console.error(data.message);
      };
      setTimeout(() => {
        this.spinner.hide();
      },300);
    })
  };
  ExportDataInExcel() {
    if (this.Orders.length < 1) {
      this.toastr.info('No Data Found!!!');
      return;
    }
    const json = this.Orders.map((item: {  productName: string, color: string, size: string, quantity: number, discount: any, price: number, orderDate: string, status: string, orderNo: string }) => {
      return {
        ItemOrder: item.orderNo,
        ProductName: item.productName,
        Color: item.color,
        Size: item.size,
        Quantity: item.quantity,
        Discount: item.discount,
        Price: item.price,
        OrderDate: this.datepipe.transform(item.orderDate, 'dd/MMM/yyyy'),
        Status: item.status,
        
      };
    }
    );
    this.appservice.exportAsXLSX(json, 'Sales Report');
  };

}
