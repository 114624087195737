import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UntypedFormGroup, FormControl, ReactiveFormsModule, AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { NgxSpinnerService } from 'ngx-spinner';
import { BlogcontentService } from './blogcontent.service';
import * as CryptoJS from 'crypto-js';
declare var $: any;
@Component({
  selector: 'app-blogcontent',
  templateUrl: './blogcontent.component.html',
  styleUrls: ['./blogcontent.component.css']
})
export class BlogcontentComponent implements OnInit {
  encPassword = 'neha@hexa';
  public router: Router;
  public form: UntypedFormGroup;
  public ID: AbstractControl;
  public Title: AbstractControl;
  public Description: AbstractControl;
  public Image: AbstractControl;
  public Author: AbstractControl;
  previewUrl = [];
  public selectedFile1: any;
  constructor(router: Router, fb: UntypedFormBuilder, private toastr: ToastrService, public blogcontent: BlogcontentService, private spinner: NgxSpinnerService) {
    this.form = fb.group({
      'ID': [''],
      'Title': ['', Validators.required],
      'Description': ['', Validators.required],
      'Image': [''],

      'Author': ['', Validators.required]
    });
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    if (currentUser !== null) {
      if (CryptoJS.AES.decrypt(currentUser.userTypeId.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8) === '1') {
      } else {
        this.router.navigate(['/notfound']);
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.ID = this.form.controls['ID'];
    this.Title = this.form.controls['Title'];
    this.Description = this.form.controls['Description'];
    this.Image = this.form.controls['Image'];

    this.Author = this.form.controls['Author'];


  }
  public listCategories: Array<string> = [];
  public listBlogContent: Array<string> = [];
  public activedeacBlogs: any = {};
  ngOnInit(): void {
    this.spinner.show();
    this.getBlogContent();
  }

  onFileChanged(event) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg') {
      this.selectedFile1 = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event: any) => {
        this.previewUrl = _event.target.result;
        const image = new Image();
        image.src = _event.target.result;
        image.onload = () => {
          if (image.width <= 800 && image.height <= 800) {
            this.selectedFile1 = event.target.files[0];
            this.previewUrl = _event.target.result;
            return true;
          }
          else {
            this.toastr.warning('', 'Image dimentions should be minimum 800*800');
            event.srcElement.value = null;
            this.selectedFile1 = null;
            this.previewUrl = null;
            return false;

          }
        }
      };

    } else {
      alert('Upload only image file'); event.srcElement.value = null;
    }
  }

  onSubmit(blogData) {

    const formData = new FormData();
    if (this.form.valid) {
      this.spinner.show();
      if (this.selectedFile1 != null || blogData.ID > 0) {
        this.blogcontent.manageBlogContent(blogData).subscribe(data => {
          if (data.Message == "Success") {

            formData.append('Id', data.Result);


            if (this.selectedFile1 != null) {
              formData.append('Image', this.selectedFile1, this.selectedFile1.name);
              this.blogcontent.manageBlogImages(formData).subscribe(data1 => {
                if (data1.Message === 'Success') {

                }

                else { this.toastr.error(data1.Message); }

              });
            }
            if (blogData.ID > 0) {
              this.toastr.success('', "Data Updated Successfully");
              this.form.reset();
              this.selectedFile1= null;
              this.previewUrl = null;
              this.getBlogContent();
              document.getElementById('btnsubmit').innerHTML = 'Submit';
            }
            else
            { this.toastr.success('', "Data Added Successfully"); }
            this.form.reset();
              this.selectedFile1= null;
              this.previewUrl = null;
            this.getBlogContent();
            document.getElementById('btnsubmit').innerHTML = 'Submit';
          } else { this.toastr.error(data.Message); }
        });


      }
      else {

        this.toastr.warning('Select an Image');
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);


    }
    else {
      this.form.markAllAsTouched();
    }
  }
  onEdit(footer: any) {
    this.spinner.show();
    this.form.setValue({
      Title: footer.Title,
      Description: footer.Description,
      ID: footer.ID,
      Author: footer.Author,
      Image: ''

    });
    document.getElementById('btnsubmit').innerHTML = 'Update';
    setTimeout(() => {
      this.spinner.hide();
    }, 200);
    window.scroll(0, 0);
  }
  getBlogContent() {
    this.spinner.show();
    debugger;
    this.blogcontent.getBlogContent().subscribe(data => {
      this.listBlogContent = data.Result;

      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
  }
  BlogsActivedeactive(id: number, IsActive: string) {
    debugger;
    this.spinner.show();
    var action = 'Active';
    this.activedeacBlogs.ID = id;
    this.activedeacBlogs.Action = IsActive;
    if (this.activedeacBlogs.Action === true) {
      action = 'Inactive';
    }
    this.blogcontent.ActiveInactiveBlogContent(this.activedeacBlogs).subscribe(res => {
      this.getBlogContent();
      this.toastr.success('Main Menu ' + action + ' successfully');
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
  }
}
