import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any, Type: any, SearchString: any): any[] {
    console.log('search',SearchString)
    if (SearchString == undefined || Type == undefined) return items
    const searchString = SearchString?.toLowerCase();
    if (Type == 'User') {
      return items.filter((item) => {
        const IsEmailMatch = item.email?.toLowerCase().includes(searchString);
        const IsNameMatch = item.firstName?.toLowerCase().includes(searchString);
        const IsMobileMatch = item.mobile?.toLowerCase().includes(searchString);
        return IsEmailMatch || IsNameMatch || IsMobileMatch === true ? true : false
      })
    }
    else if (Type == 'Product') {
      return items.filter((item) => {
        return item.productName?.toLowerCase().includes(searchString) ||
          item.searchTag?.toLowerCase().includes(searchString) ||
          item.categoryName.toLowerCase().includes(searchString) ||
          item.subcategoryLevel1.toLowerCase().includes(searchString) ||
          item.list.some(x => x.itemBarCode.toLowerCase().includes(searchString));
      }).map((filteredItem) => {
        if (filteredItem.list.some(x => x.itemBarCode.toLowerCase().includes(searchString))) {
          const object = filteredItem.list.find(x => x.itemBarCode.toLowerCase().includes(searchString));
          filteredItem.itemBarCode = object.itemBarCode;
          filteredItem.productImage = object.itemImage;
        }
        return filteredItem;
      })
    }
    else if (Type === 'Orders') {
      return items.filter((item: { itemID: string }) => {
        return item.itemID.toLowerCase().includes(searchString)
      });
    }
    else if (Type === 'Orders-Two') {
      return items.filter((item: { itemOrderID: string }) => {
        return item.itemOrderID.toLocaleLowerCase().includes(searchString);
      })
    }
    else if (Type === 'CATEGORY') {
      return items.filter((item: { categoryName: string }) => {
        return item.categoryName.toLocaleLowerCase().includes(searchString);
      });
    }
    else if (Type === 'CATEGORY-LEVEL1') {
      return items.filter((item: { subCategory: string }) => {
        return item.subCategory.toLocaleLowerCase().includes(searchString);
      });

    }
    else if (Type === 'CATEGORY-LEVEL2') {
      return items.filter((item: { subCategoryTwo: string }) => {
        return item.subCategoryTwo.toLocaleLowerCase().includes(searchString);
      });
    }
    else if (Type === 'INVOICE') {
      return items.filter((item: { customerName: string, invoiceNumber: string, orderNumber: string }) => {
        return (item.customerName.toLocaleLowerCase().includes(searchString) ||
          item.orderNumber.toLocaleLowerCase().includes(searchString) ||
          item.invoiceNumber.toLocaleLowerCase().includes(searchString)) === true ? true : false;
      })
    }


  }

}
