import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http: HttpClient) { }
  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localdata.userToken
  });
  getVehicleContent() {
      
    return this.http.get<any>(this.rootUrl + '/Vehicle/GetVehicleList') }
    ActiveInactiveVehicleContent(active) {
      return this.http.post<any>(this.rootUrl + '/Vehicle/ActiveInactiveVehicleContent', active);
     }
     manageVehicleImages(vehicleBO) {
      return this.http.post<any>(this.rootUrl + '/Vehicle/VehicleFile', vehicleBO);
    }
    managevehicleContent(vehicleBO)
    {
      debugger;
      return this.http.post<any>(this.rootUrl + '/Vehicle/manageVehicleContent',  vehicleBO); }
}
