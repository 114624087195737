import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router:Router
  ) {}
  canActivate(): boolean {
    try {
      const userdetail = JSON.parse(localStorage.getItem('userInfo'));
      if(!userdetail || !userdetail.userToken){
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }
}

