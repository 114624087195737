import { Component, OnInit } from '@angular/core';
import { MastersizeService } from './mastersize.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubCategoryTwoService } from '../sub-categories-two/sub-category-two.service';
import { AddproductService } from '../addproduct/addproduct.service';
declare var $: any
@Component({
  selector: 'app-mastersize',
  templateUrl: './mastersize.component.html',
  styleUrls: ['./mastersize.component.css']
})
export class MastersizeComponent implements OnInit {
  form: any;
  Sizelist: any[]=[];
  activedeaccolor: any = {};
  CategoryList:any[]=[];
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];
  private Active:any=true;
  constructor(private service: MastersizeService, private fb: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService, private Productservice:AddproductService) {
    this.form = fb.group({
      id: [0],
      name: ["", Validators.required],
    })
  }
  ngOnInit(): void {
    this.getSizeList();
    this.GetCategoryList();
  }
  get input() {
    return this.form.controls;
  }

  Filter(key:any,type:string){
    if(type==='ACTIVE-INACTIVE'){
      this.Active=key;
    }
    this.page=1;
    this.getSizeList();

  }

  getSizeList() {
    this.spinner.show()
    this.service.getMasterSizeList(this.Active).subscribe(data => {
      if (data.message === "Success") {
        this.Sizelist = data.result;
      }else{
        this.Sizelist=[];
      };
      setTimeout(() => {
        this.spinner.hide();
      },300);

    })
  }

  GetCategoryList(){
    this.spinner.show();
    this.Productservice.GetCategoryList().subscribe(data=>{
      if(data.message==='Success'){
        this.CategoryList=data.result;
      };
      this.spinner.hide();
    })
  }
  manageSize() {
    if (this.form.valid) {
      this.spinner.show();
      this.service.ManageMasterSize(this.form.value).subscribe(data => {
        if (data.message === "Success") {
          this.toastr.success(data.activity);
          this.getSizeList();
          this.form.reset();
          this.form.patchValue({
            id:0
          });
        }
        else {
          this.toastr.warning(data.message);
        };
        this.spinner.hide();
      });
    }
    else {
      this.spinner.hide();
      this.form.markAllAsTouched();
    }
  }

  onEdit(size:any){
    this.form.patchValue({
      id:size.id,
      name:size.name,
    });
  }

  ActivedeactiveProductSize(id: number, IsActive: boolean) {
    var action = 'Deactivated';
    this.activedeaccolor.ID = id;
    this.activedeaccolor.Action = IsActive;
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the size?" : "Are you sure you want to activate the size?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        if (this.activedeaccolor.Action == false) {
          action = 'activated';
        }
        else {
          action = 'deactivated';
        }
        this.service.ActiveInactiveSize(this.activedeaccolor.ID).subscribe(res => {
          this.getSizeList();
          this.toastr.success('Size has been ' + action + ' successfully.');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { 
          this.toastr.error('', 'Not Responding');
          this.spinner.hide();
         });
      }
      else {
        function check() {
          $('#checkBoxAinA' + id).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + id).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })

  }
  OnTableDataChange(PageNumber: number) {
    this.page = PageNumber;
  }
}
