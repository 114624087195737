import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OrderhistoryService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  GetOrders(type:string,date:any) {
    return this.http.get<any>(this.rootUrl + '/Order/GetOrders',{params:{type,date}});
  }

}
