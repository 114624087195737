import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OrderhistoryService } from './orderhistory.service';
import { environment } from 'src/environments/environment';
import { settings } from 'cluster';
import { AppService } from 'src/app/app.service';

declare var $: any
@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['./orderhistory.component.css']
})
export class OrderhistoryComponent implements OnInit {
  public Title: string = '';
  wordLimit: number = 22;
  showReadMore: boolean = false;
  elementRef: any;
  @ViewChild('spanElement', { static: false }) spanElementRef: ElementRef;
  id: any;
  selectedReason: string = '';

  constructor(public router: Router, _elementRef: ElementRef, fb: UntypedFormBuilder, public ordersService: OrderhistoryService, private spinner: NgxSpinnerService, private toastr: ToastrService, private activatedroute: ActivatedRoute, private appservice: AppService) { }
  public OrderList: any = [];
  public RootURL: string = environment.baseUrl.slice(0, -3);
  public SearchString: string;
  public tableSizes: any[] = [10, 15, 20];
  private navigationSubscription: any;
  public PaginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,

  }

  private date: any = '';
  public type: any;

  ngOnInit(): void {
    this.navigationSubscription = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        const param = this.activatedroute.snapshot.queryParamMap.get('Type');
        if (param) {
          this.Title = param === 'Completed' ? "Completed Orders" : 'Cancelled Orders';
          this.type = param;
          this.GetOrders();
        };
        window.scrollTo(0, 0);
      }
    });

    const param = this.activatedroute.snapshot.queryParamMap.get('Type');
    if (param) {
      this.Title = param === 'Completed' ? "Completed Orders" : 'Cancelled Orders';
      this.type = param;
      this.GetOrders();
    }

  }
  truncateReason(reason: string): string {
    const maxChars = 50; // Max characters to display in the table cell
    return reason?.length > maxChars ? reason.substring(0, maxChars) + '...' : reason;
  }
  openModal(item: any): void {
    debugger
    this.selectedReason = item;
    $('#reason').modal('show');
  }
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }


  ExportDataInExcel() {
    var json = this.OrderList;
    json = json.map((rec: { itemOrderID: string, discountPrecentage: any, price: number, status: string, quantity: number, orderDate: any }) => {
      return {
        'Item Order#': rec.itemOrderID,
        'Total Order Amount': (rec.discountPrecentage > 0 ? (rec.price - (rec.price * rec.discountPrecentage / 100)) * rec.quantity : rec.price * rec.quantity).toFixed(2),
        'Discount (%)': rec.discountPrecentage > 0 ? rec.discountPrecentage : 0,
        'Order Date': rec.orderDate,
        'Status': rec.status
      };
    });
    this.appservice.exportAsXLSXWithStyle(json, 'Orders');
  };
  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }
  
  GetOrders() {
    this.spinner.show();
    this.ordersService.GetOrders(this.type, this.date).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.OrderList = response.result;
        }
        else {
          this.OrderList = [];
        };
        setTimeout(() => {
          this.spinner.hide();
        }, 300);

      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }
    })
  }


  Filter(key: any, type: string) {
    if (type === 'BY-SEARCH') {
      this.SearchString = key;
      return;
    }
    if (type === 'BY-DATE') {
      let d = new Date();
      if (key === 'ONEWEEK') {
        d.setDate(d.getDate() - 7);
      }
      else if (key === 'ONEMONTH') {
        d.setMonth(d.getMonth() - 1);
      }
      else if (key === 'THREEMONTHS') {
        d.setMonth(d.getMonth() - 3);
      }
      this.date = key !== '' ? d.toISOString().split('T')[0] : '';
    }
    this.GetOrders();
  }
  OnTableSizeChange(val: number) {
    this.PaginationConfig.itemsPerPage = val;
  }

  OnPageChange(val: number) {
    this.PaginationConfig.currentPage = val;
  }
}
