import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { CompanySettingsService } from './company-settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ToastNoAnimation, ToastrService } from 'ngx-toastr';
declare let $: any;






@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit{
  form: any;

  Comapny: any[]=[];
  activeSMTP: any = {};


  constructor(private fb: FormBuilder, private service: CompanySettingsService, private spinner: NgxSpinnerService, private toastr : ToastrService) {
    this.form = fb.group({
      'id': [0],
      'acn': ['', [Validators.required]], 
      'abn': ['', [Validators.required]],
      'mobile': ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      'email' :[null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      'address' :['', Validators.required],
      'isActive': true
    })
  }

  

  ngOnInit(): void {
    setTimeout(() => {
      this.GetCompanySettingList();
      
    }, 500);
  }



  // ManageMasterColor() {
  //   debugger
    
  //   if (this.form.valid) {
  //     this.service.ManageCompanySetting(this.form.value).subscribe(data => {
  //       console.log("Company", data)
  //       if (data.message == "Success") {
  //         this.toastr.success(" Successfully Added");
  //         this.form.reset();

  //       }
  //       else {
  //         this.toastr.warning(data.message)

  //       }
  //     })
  //   }
  //   else {
  //     this.form.markAllAsTouched();
  //   }
  // }


  onSubmit(): void {
    debugger;
    if (this.form.valid) {
      this.form.patchValue({
        id:this.form.value.id==null?0:this.form.value.id
      })
      this.service.ManageCompanySetting(this.form.value).subscribe(data => {
        if (data.message == 'Success') {
          if (this.form.get('id').value > 0) {
        this.spinner.hide();

            this.toastr.success("Data has been updated successfully");
          }
          else {
debugger
            this.toastr.success("Data has been added successfully");

          }
          document.getElementById('btnsubmit').innerHTML = 'Save';
          this.form.reset();
          this.GetCompanySettingList();
        }
        else {

          this.toastr.error('', data.Message);
        }
        
        this.form.reset();
      });
      setTimeout(() => {
      }, 1000);
    }
    else {
      this.form.markAllAsTouched();
    }
  }


  GetCompanySettingList() {
    this.service.GetCompanySetting().subscribe(data => {
      if (data.message == "Success") {
      
        this.Comapny = data.result;
      }
    })
  }


  onKeyPress(event: KeyboardEvent) {
    // Check if the pressed key is an alphabetic character
    if ((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122)) {
      // Prevent default behavior for alphabetic characters
      event.preventDefault();
    }
  }


  formatACN(event: any) {
    
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(?=\d)/g, '$1 ');
    this.form.get('acn').setValue(value);
  }


  formatABN(event: any) {
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    value = value.replace(/^(.{2})(.{3})(.{3})(.{3})/, '$1 $2 $3 $4 ');
    this.form.get('abn').setValue(value);
  }

  onEdit(item: any) {
    debugger
   
    this.spinner.show();
    this.form.patchValue({
      abn: item.abn,
      acn:item.acn,
      mobile: item.mobile,
      email: item.email, 
      address:item.address,
      id: item.id
       
    });
    document.getElementById('btnsubmit').innerHTML = 'Update';
    window.scroll(0, 0);
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }



  ActiveInactiveCopanySetting(id: number, IsActive: boolean) {
    debugger;

    // this.spinner.show();
    var action = 'activated';
    this.activeSMTP.ID = id;
    this.activeSMTP.Action = IsActive;
    // ******SweetAlert************
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive?"Are you sure you want to deactivate the application setting?":"Are you sure you want to activate the application setting?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive?"Confirm":"Confirm" 
    }).then((result) => {
      if (result.isConfirmed) {
       if (this.activeSMTP.Action === true) {
      action = 'deactivated';
    }
    this.service.ActiveInactiveSetting(this.activeSMTP.ID).subscribe(res => {
      this.GetCompanySettingList();
      this.toastr.success('application setting has been ' + action + ' successfully');
      setTimeout(() => {
        this.spinner.hide();
      }, 200);
    }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else{
        debugger
          function check(){
            $('#checkBoxAinA'+id).prop("checked",true)
          };
          function uncheck(){
            $('#checkBoxAinA'+id).prop("checked",false)
          }
          
          IsActive?check():uncheck();
      }
    })
  }


  get f(){
    return this.form.controls;
  }
}
