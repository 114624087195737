
<section class="content-header sub-head ">
    <h1>
     Add Type
    </h1>
   
  </section>
  <section class="custom-section-margin">
  
    <div class="row">
      
      <div class="col-md-12 overflow-hidden">
          <div class="box box-danger row">
            <form [formGroup]="form">
            <div class="radioboxmain beforeafternone">
              <div class=" col-md-12"style="margin: 20px 0px;">
               
              <div class="col-md-4 padd-left">
                <label for="exampleInputEmail1">Name<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="name" name="name" placeholder="Enter Name" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.name.touched">
                  <span *ngIf="input.name.errors?.required">This Field Is Required</span>
                </span>
              </div>

                <div class="col-md-4 mt-5 padd-left">
                  <a class="btn btn-success" type="submit" (click)="AddType()">
                    Submit
                  </a>
                  <button class="btn btn-secondary" type="button">
                    Cancel
                  </button>
            </div>
               
        
              </div>
            </div>  
            </form>      
  
  
  
  
  
  
           
            <div class="box-body nomargin padd-custom">
              <div class="box-header">
                <h3 class="box-title">Type List</h3>
              </div>
              <table class="table table-striped table-bordered nomargin">
                 
                <thead>
                 <tr >
                  <th>S.NO</th>
                   <th>Name</th>
                   <th>Status</th>
  
               </tr>
                </thead>
                   <tbody>
  
                    <tr *ngFor="let item of Typelist| paginate
                    : {
                        itemsPerPage: tableSize,
                        currentPage: page,
                        totalItems: count
                      };,let i=index "
                     >
  
                     <td>{{i+1+ tableSize*(page-1)}}</td>
                       <td >{{item.name}}</td>
                       <td style="text-align: center;">
                        <div class="d-flex justify-content-center">
                          <button  class="btn btn-icon m-1 p-1 mr-2">
                          <input type="checkbox" id="checkBoxAinA{{item.id}}" name="isActive" class="marginright10 "
                        (change)="ActivedeactiveProductType(item.id,item.isActive)" [checked]="item.isActive">
                        </button>
                        </div>
                    </td>
                  
                   </tr>
                  
               </tbody>
           </table>
           <!-- <div class="row mt-3">
            <div class="col-md-9">
              <pagination-controls
              previousLabel="Prev"
              nextLabel="Next"
              (pageChange)="onTableDataChange($event)"
            >
            </pagination-controls>
            
            </div>
            <div class="col-md-3 text-right">
              <select (change)="onTableSizeChange($event.target.value)" class="form-control w-auto">
                <option  *ngFor="let tblsize of tableSizes" 
                [ngValue]="tblsize">{{tblsize}}</option>
              </select>
            </div>
          </div> -->
            </div>
  
            <div class="row mt-3 view-user-page">
              <div class="col-sm-12 mt-5">
                <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next" (pageChange)="OnTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
            
            
          </div>
      </div>
  </div>
  </section>

