import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
 private encPassword = 'neha@hexa';


  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  GetYear():number{
    return new Date().getFullYear();
  }

}
