import { Component, OnInit } from '@angular/core';
declare var $: any;
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../login/login.service';
import { ConfirmPassword } from 'src/app/confirm-password';
import { CookieService } from 'ngx-cookie-service';
import { HeaderService } from './header.service';
import { AppService } from '../app.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  encPassword = 'neha@hexa';
  passwordform: any;
  uID: number;
  UserId: any;
  constructor(private loginservice:LoginService,private fb:FormBuilder,private cookies:CookieService,
     private service :HeaderService,private appservice: AppService,private toastr: ToastrService,) { 
    this.passwordform=fb.group({
      id:[0],
      oldPassword:["",Validators.required],
      newPassword:["",Validators.required],
      confirmPassword:["",Validators.required],
    }, {
      validator: ConfirmPassword('newPassword', 'confirmPassword')
    });
  }
  public UserName: any;
  ngOnInit(): void {
    var currentUser = JSON.parse(localStorage.getItem('userInfo'));
    if (currentUser !== null) {
   this.UserName=CryptoJS.AES.decrypt(currentUser.userName.trim(), this.encPassword.trim()).toString(CryptoJS.enc.Utf8)  
    

   
  
   this.UserId = this.appservice.decryptValue(currentUser.userId);

  
  }

  document.addEventListener('DOMContentLoaded', function() {
    // Get the main-sidebar element
    const mainSidebar = document.querySelector<HTMLElement>('.main-sidebar');

    // Get all the links inside the main-sidebar
    const links = mainSidebar.querySelectorAll<HTMLAnchorElement>('ul li a');

    // Add click event listeners to each link
    links.forEach(function(link) {
        link.addEventListener('click', function() {
            // Remove the sidebar-open class from the body
            document.body.classList.remove('sidebar-open');
        });
    });
});
}

logOut(){
  this.loginservice.logOut();
};



ngAfterViewInit() {
  $("[data-pwd]").click(function () {
    let $this = $("[data-pwd]");
    if ($this.attr("data-pwd") == "false") {
      $this.siblings("input").attr('type', 'text');
      $this.attr("data-pwd", "true").addClass('show-password');
    } else {
      $this.siblings("input").attr('type', 'password');
      $this.attr("data-pwd", "false").removeClass('show-password');
    }
  });
  $("[data-pwdd1]").click(function () {
    let $this = $("[data-pwdd1]");
    if ($this.attr("data-pwdd1") == "false") {
      $this.siblings("input").attr('type', 'text');
      $this.attr("data-pwdd1", "true").addClass('show-password');
    } else {
      $this.siblings("input").attr('type', 'password');
      $this.attr("data-pwdd1", "false").removeClass('show-password');
    }
  });
  $("[data-pwdd2]").click(function () {
    let $this = $("[data-pwdd2]");
    if ($this.attr("data-pwdd2") == "false") {
      $this.siblings("input").attr('type', 'text');
      $this.attr("data-pwdd2", "true").addClass('show-password');
    } else {
      $this.siblings("input").attr('type', 'password');
      $this.attr("data-pwdd2", "false").removeClass('show-password');
    }
  });

}

changePassword()
{
  debugger
  if(this.passwordform.valid)
  {
  this.service.changePassword(this.passwordform.value.oldPassword,this.passwordform.value.newPassword,this.UserId).subscribe(data=>{
    if(data.message=="Success")
    {
      this.toastr.success("Password change successfully")
      this.passwordform.reset();
      $("#reset").modal("hide");
      // this.logOut();
    }
    else{
      this.toastr.warning(data.message)
    }
  })
}else{
  this.passwordform.markAllAsTouched();
}
}


get input() {

  return this.passwordform.controls;


}

}
