import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private loginc = new BehaviorSubject<any>(null);
  currentCookies = this.loginc.asObservable();
  RootURL=environment.baseUrl;
  constructor(private http:HttpClient) { }



  changePassword(oldPassword:string,newPassword:string,userID:number):Observable<any>
  {
   return this.http.get<any>(this.RootURL +"/Login/changePassword",{params:{oldPassword,newPassword,userID}})
  } 
}
