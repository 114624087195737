import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesreportService {
  private readonly Rooturl=environment.baseUrl;

  constructor(private http:HttpClient) { }
  
  GetSalesOrderList(fromdate:string,todate:string,pagenumber:number,pagesize:number,type:string):Observable<any>{
    return  this.http.get<any>(this.Rooturl+'/Order/GetSalesOrderList',{params:{fromdate,todate,pagenumber,pagesize,type}})
  }
}
