import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { OrdersService } from '../orders/orders.service';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-viewdetail',
  templateUrl: './viewdetail.component.html',
  styleUrls: ['./viewdetail.component.css']
})
export class ViewdetailComponent implements OnInit {
  order: any;
  form: any;
  // StatusList: any = [];
  rootUrl = environment.baseUrl.slice(0, -3);
  GetOrders: any;
  status: any;
  selectstatus: any;
  OrderID: any;
  OrderNO:string;
  billingAddress: any;
  deliveryAddress: any;
  CustomerDetails: any;


  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private appservice: AppService,
    private orderservice: OrdersService,
    private router: Router,
    private datepipe: DatePipe) {
    this.form = fb.group({
      id: [0],
      orderDate: [""],
      deliveryDate: [""],
      deliveryType: [""],
      email: [""],
      isTempOrderNumber: [true],
      mobile: [""],
      orderNo: [""],
      name: [""],
      OffscreenCanvasRenderingContext2D: [""],
      orderTotalAmount: [0],
      paymentMode: [""],
      remarks: [""],
      shippingCharges: [0],
      status: [''],
      tax1Percentage: [0],
      tax2Percentage: [0],
      tempOrderNumber: [""],
      totalDiscountAmount: [""],
      address1: [""],
      address2: [""],
      city: [""],
      country: [""],
      pinCode: [""],
      state: [""],
    })
  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params: any) => {
      let EID = params.get('Eid');
      if(EID){
        EID = this.appservice.decryptValue(EID);
        this.OrderID = EID;
        this.getReceviedOrderByID()
      }
    });
  }



  getReceviedOrderByID() {
    this.spinner.show();
    this.orderservice.GetOrderByID(this.OrderID).subscribe(data => {
      if (data.message === "Success") {
        this.order=data.result;
        this.billingAddress = data.result.billingAddress;
        this.deliveryAddress = data.result.deliveryAddress;
        this.CustomerDetails = data.result.customerDetails;
      };
      setTimeout(() => {
        $("[name='deleveryDate'").flatpickr({
          dateFormat: 'd-M-Y',
          minDate: new Date()
        });
        this.spinner.hide();
      }, 100);
    })
  }



  ChangeDeleveryDate(ID: number, date: Date,oldDate:string) {
    let deleveryDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    Swal.fire({
      // title: 'Are you sure?',
      title: 'Are you sure you want to change the delivery date of the order?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'
    }).then(res => {
      if (res.isConfirmed) {
        this.orderservice.ChangeDeleveryDate(ID, deleveryDate).subscribe(data => {
          if (data.message === 'Success') {
            this.toastr.success('', 'delivery date has been changed successfully.');
            this.getReceviedOrderByID();
          };
        })
      }
      else{
        const currentdate=new Date(oldDate);
          $("[name='deleveryDate'").flatpickr({
            dateFormat: 'd-M-Y',
            minDate: new Date(),
            defaultDate:currentdate
          });
      };
    });
  }




  ChangeStatus(OrderNO: any, StatusId: any, currentStatusID: number) {
    debugger
    Swal.fire({
      // title: 'Are you sure?',
      title: 'Are you sure you want to change the status of this order?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'

    }).then((result) => {
      if (result.value) {
        StatusId = parseInt(StatusId);
        this.orderservice.ChangeStatusOfOrder(OrderNO, StatusId).subscribe(res => {
          if (res.message === 'Success') {
            this.getReceviedOrderByID();
            this.toastr.success('Status has been changed successfully.');
            if (StatusId === 6) {
              const Type = 'Completed';
              this.router.navigate(['view-order'], { queryParams: { Type } });
            }
          } else {
            this.toastr.error(res.message);
          }
        }

        );

      }
      else {
        $(`#ddlStatusID${OrderNO}`).val(currentStatusID);
      }
    }
    );
  }



  // StatusDropDown() {
  //   this.spinner.show();
  //   this.orderservice.getStatusList().subscribe(data => {
  //     if(data.message==='Success'){
  //       this.StatusList = data.result;
  //     };
  //     this.spinner.hide();
  //   })
  // }
}


