<section class="content-header">
    <h1> Manage Role Based Permission</h1>
  
  </section>
  <!-- Main content -->
  <section class="content user-permission">
    <div class="row">
      <!-- left column -->
      <div class="col-md-12 margin-left-np">
        <div class="box box-danger">
          
          <!-- /.box-header -->
          <!-- form start -->
          
          <div class="row">
            <div class="col-12">
                <form [formGroup]="UserTypePermission" class="card">
                    <!-- <div class="headingmargin">
                        <span class="headingproperty">
                        <i class="mdi mdi-account-circle me-1"></i>
                        <span class="d-sm-inline">Role Based Permission</span>
                        </span>
                        </div> -->
                    <div class="card-body">
                        <div class="row mb-3 mt-2">
                            <div class="col-md-4">
                               <div class="">
                                <label for="simpleinput" class="form-label p_relative">Select Role <span
                                    class="cstm-required">*</span></label>
                <a routerLink="../user-role" class="btn add_btn top90" tooltip="Add User Roles"><i class="mdi mdi-plus-circle "></i></a>

                               </div>
                                        <select class="form-control form-select" id="BindUserTypePermission" formControlName="UserTypeID" (change)="BindUserTypePermission()">
                                            <option value="">-Select-</option>
                                            <option *ngFor="let edu of StaffList" value="{{edu.id}}">{{edu.name}}</option>
                                        </select>
                                        <span *ngIf="UserTypePermission.controls.UserTypeID.touched" class="text-danger">
                                          <span *ngIf="UserTypePermission.controls.UserTypeID.errors?.required">
                                              This field is required.
                                          </span>
                                      </span>
                            </div>
                          
                            <div class="col-md-4">
                                <ul class="list-inline wizard mb-0 mt-5">
                                    <li class="next ">
                                        <button type="button"  (click)="AssignConfirmation()"
                                            class="btn btn-primary waves-effect waves-light mb-2 me-2 hlo">Assign
                                            Permission</button>
                                        <button type="reset" (click)="Reset()"
                                            class="btn btn-secondary waves-effect waves-light mb-2">Cancel</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row user-permision"*ngIf="data">
                            <a href="javascript: void(0);" class="text-uppercase fw-bold user-selection"><i
                                class="mdi mdi-adjust me-1"></i>Select Permission</a>
                            <div class="col-lg-6 list-items">
                                <ul class="sitemap">
                                    <li class="first-childh">
                                        <ul>
                                         
                                       
                                            <li *ngFor="let item of data.ParentChildchecklist"class="second-childh">
                                                <a href="javascript: void(0);" class="d-flex">
                                                    <div class="form-check  form-check-success">
                                                        <input  class="form-check-input" type="checkbox"  [disabled]="item.IsActive" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.isSelected" name="list_name" value="{{item.id}}"
                                                        (ngModelChange)="parentCheck(item)" checked="item.isSelected==true?'checked':''" />
                                                      
                                                    </div> <span>{{item.value}}</span>
                                                </a>
                                                <ul>
                                                    <div [hidden]="item.isClosed">
                                                    <li *ngFor="let itemChild of item.childList" class="third-list"><a href="javascript: void(0);" class="d-flex">
                                                            <div class="form-check  form-check-success">
                                                                <input class="form-check-input" type="checkbox" [disabled]="itemChild.IsActive"  [ngModelOptions]="{standalone: true}" [(ngModel)]="itemChild.isSelected" name="list_name_child"
                                                                value="{{itemChild.id}}" (ngModelChange)="childCheck(item,item.childList,itemChild.id)"   checked="itemChild.isSelected==true?'checked':''">
                                                            </div> <span>{{itemChild.value}}</span>
                                                        </a>
                                                        <ul>
                                                            <div [hidden]="item.isClosed">
                                                            <li *ngFor="let nestedChild of
                                                            itemChild.nestedchildList"><a href="javascript: void(0);" class="d-flex">
                                                                    <div class="form-check  form-check-success">
                                                                        <input class="form-check-input" type="checkbox" [disabled]="nestedChild.IsActive"  [ngModelOptions]="{standalone: true}" [(ngModel)]="nestedChild.isSelected" name="list_name_child"
                                                                        value="{{nestedChild.id}}" (ngModelChange)="nestedchildCheck(itemChild,itemChild.nestedchildList,nestedChild.id)"  checked="{{nestedChild.isSelected?true:null}}">
                                                                    </div> <span>{{nestedChild.value}}</span>
                                                                </a></li>
                                                            </div>
                                                            
                                                        </ul>
                                                    
                                                    </li>
                                                    </div>
                                                    
                                                </ul>
                                            </li>
                                           
                                            <hr>
                                          
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>
    </div>
  </section>
