import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BulkmanagementService {
  private readonly rooturl:string =environment.baseUrl;

  constructor(private http:HttpClient) { }

  downloadReportInExcel(filters:any):Observable<any>{
    return this.http.post(this.rooturl+'/Product/DownloadBulkRecord',filters);
  }
  UploadProductDetail(manageProduct:any):Observable<any>
  {
    return this.http.post(this.rooturl +'/Product/UploadProductDetail',manageProduct)
  }
  uploadBulkRecord(formdata:any):Observable<any>{
    return this.http.post(this.rooturl+'/Product/UploadBulkRecord',formdata);
  }

getAllCategoryLevelList():Observable<any>{
  return this.http.get<any>(this.rooturl+'/MainMenu/getAllCategoryLevelList');
}
// sarthak
downloadSarthakFormat():Observable<any>{
  return this.http.get(this.rooturl + '/Product/click');
}

UploadSarthakDetail(manageProduct:any):Observable<any>
{
  return this.http.post(this.rooturl +'/Product/UploadSarthaktDetail',manageProduct)
}

getInvalidRecordsSheet(invalidRecords:any[]):Observable<any>{
  return this.http.post(this.rooturl+'/Product/downloadInvalidRecordsSheet',invalidRecords);
}
//
}
