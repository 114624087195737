import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  readonly rootUrl = environment.baseUrl;
  
  constructor(private http: HttpClient) { }


  GetStatusList() {
    debugger;
    return this.http.get<any>(this.rootUrl + '/Order/getstatuslist');
   
  }

  ManageStatusMaster(obj) {
    debugger;
    return this.http.post<any>(this.rootUrl + '/Order/ManageStatusMaster', obj);
   
  }

  ActiveInactiveStatus(ID) {

    return this.http.get<any>(this.rootUrl + '/Order/GetStatusActiveInActive', {params:{ID}});
  }
}
