<section class="content-header sub-head ">
    <h1>
        Point of Sale
    </h1>
</section>

<section class="">

    <div class="row">

        <div class="col-md-12 ">
            <div class="box box-danger ">
                <div class="box-body nomargin">
                    <fieldset class="mb-2">
                        <legend>Invoice Details</legend>
                        <div class=" row">
                            <div class="col-md-3 mb-2">
                                <label for="exampleInputEmail1">Invoice Type<span class="text-danger">*</span></label>
                                <ng-select [readonly]="true" [(ngModel)]="InvoiceTypeID" id="InvoiceType-Dropdown"
                                    [items]="InvoiceTypeList" bindLabel="invoiceType" bindValue="id"
                                    placeholder="Select Invoice" placeholder="Select Invoice Type"></ng-select>
                                <span *ngIf="GetInvoiceFormControls.invoiceTypeID.touched" style="color: red;">
                                    <span *ngIf="GetInvoiceFormControls.invoiceTypeID?.errors?.required">This Field Is
                                        Required</span>
                                </span>
                            </div>

                            <div class="col-md-3 mb-2">
                                <label for="exampleInputEmail1">Invoice Number<span class="text-danger">*</span></label>
                                <input readonly [(ngModel)]="InvoiceNumber" type="text" name="invoice-number"
                                    placeholder="Enter Invoice Number" class="form-control ">

                            </div>
                            <div class="col-md-3 mb-2">
                                <label for="exampleInputEmail1">Invoice Date<span class="text-danger">*</span></label>
                                <input  id="invoice-date" name="invoice-date" placeholder="Enter Date"
                                    class="form-control ">

                            </div>

                            <div class="col-md-3 mb-2">
                                <label for="exampleInputEmail1">Shipping:</label>
                                <div class="shipping-type">
                                    <div class="ship-1">
                                        <input id='shipping-radio-1' [disabled]="ItemList.length>0"  (change)="OnChangeShipping('delivery')" name="shipping-radio"
                                    type="radio">
                                <label for="shipping-radio-1">Delivery</label>
                                    </div>
                                    <div class="ship-1">
                                        <input checked id='shipping-radio-2' [disabled]="ItemList.length>0" (change)="OnChangeShipping('pickup')" name="shipping-radio" type="radio">
                                        <label for="shipping-radio-2">Pickup</label>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </fieldset>

                    <fieldset class="mb-2">
                        <legend>Customer Details</legend>
                        <div class="row">
                            <div class="col-xxl-5 col-md-12 mb-2 search-main">
                                <div class="seach-cus">
                                    <label for="exampleInputEmail1">Search Customer</label>
                                    <ng-select #userDropdown [readonly]="IsGuestUser" id="UserDropdown"
                                        [items]="UserDropdownList" bindLabel="name" bindValue="id"
                                        (change)="FindUser($event?.id)" placeholder="Search Customer"
                                        [(ngModel)]="CustomerDropdownValue">
                                    </ng-select>
                                </div>

                                <div class="customer-btn">
                                    <a id="Add-Customer-Btn" data-toggle="modal" data-target='#Add-customer-details'>
                                        <button class="btn btn-light m-1 p-1 mr-2">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                        </button>
                                    </a>
                                    <button class="btn btn-light m-1 p-1 mr-2">
                                        <div class="d-flex align-items-center gap-10">
                                            <input  #cash (change)="OnChangeCash(cash.checked)" type="checkbox"
                                                id="cashcheck" name="isActive" class="m-0 ">
                                            <span>Cash</span>
                                        </div>
                                    </button>
                                </div>

                            </div>
                            <div class="col-xxl-7 col-md-12 hide" id="Cash-Fileds">
                                <form [formGroup]="GuestUserForm" style="padding: 0px;">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Name</label>
                                            <input [readOnly]="IsGuestUser && CustomerID" formControlName="name" type="text" placeholder="Enter Name" class="form-control ">
                                        </div>
                                        <div class="col-md-4">
                                            <label>Email </label>
                                            <input [readOnly]="IsGuestUser && CustomerID" #email (change)="onChangeUserField(email.value,'email','guest-user')" formControlName="email" type="text" placeholder="Enter Email" class="form-control ">
                                            <span *ngIf="GuestUserForm.get('email').touched" style="color: red;">
                                                <span *ngIf="GuestUserForm.get('email').errors?.pattern">Please Enter Valid
                                                    Email</span>
                                                    <span *ngIf="GuestUserForm.get('email').errors?.duplicateEmail">This email is already taken.</span>
                                            </span>
                                        </div>
                                        <div class="col-md-4">
                                            <label>Mobile No. </label>
                                            <input [readOnly]="IsGuestUser && CustomerID" #mobile (change)="onChangeUserField(mobile.value,'phone-number','guest-user')" maxLength="10" (keypress)="keyPressNumbers($event)" formControlName="phoneNumber" type="text" placeholder="Enter Mobile" class="form-control ">
                                            <span *ngIf="GuestUserForm.get('phoneNumber').touched" style="color: red;">
                                            <span *ngIf="GuestUserForm.get('phoneNumber').errors?.minlength">Number Must Be At Least 10 Digits Long.</span>
                                            <span *ngIf="GuestUserForm.get('phoneNumber').errors?.duplicateMobile">This phone number is already taken.</span>
                                            </span>
                                        </div>

                                    </div>
                                </form>
                            </div>

                            <div class="col-xxl-7 col-md-12" *ngIf="IsFindCustomer">
                                <fieldset>
                                    <legend  style="color: #00a65a;">Store Credits: ${{UserDetail.StoreCreadit}}
                                    </legend>
                                    <a [ngStyle]="{ 'display': IsEditableCustomer ? 'block' : 'none' }"   (click)="editUserDetails(UserDetail.ID,'pos')"  class="edit-customer"><i class="fa fa-pencil-square-o mr-1"></i></a>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="col-md-12">
                                                <span class="title-customer">Name:</span>
                                                <span class="info-customer">{{UserDetail.Name}}</span>
                                            </div>
                                            <div class="col-md-12" >
                                                <span class="title-customer">Phone&nbsp;No:</span>
                                                <span class="info-customer">+{{UserDetail.Phone_Code}} &nbsp;{{UserDetail.PhoneNumber}}
                                                </span>
                                            </div>
                                            <div class="col-md-12">
                                                <span class="title-customer">Email:</span>
                                                <span class="info-customer">{{UserDetail.Email}}</span>
                                            </div>

                                        </div>
                                        <div class="col-md-6" style="    border-left: 1px dotted gray;">
                                            <div *ngFor="let item of AddressList">
                                                <div *ngIf="item.isBillingAddress" class="col-md-12 mb-2">
                                                    <span class="title-customer">Billing&nbsp;Address:</span><br>
                                                    <span
                                                        class="info-customer">{{item.address}},&nbsp;{{item.cityName}},&nbsp;{{item.stateName}},&nbsp;{{item.countryName}},&nbsp;{{item.pinCode}}</span>
                                                </div>

                                                <div *ngIf="!item.isBillingAddress" class="col-md-12">
                                                    <span class="title-customer">Delivery&nbsp;Address:</span><br>
                                                    <span
                                                        class="info-customer">{{item.address}},&nbsp;{{item.cityName}},&nbsp;{{item.stateName}},&nbsp;{{item.countryName}},&nbsp;{{item.pinCode}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                        </div>

                    </fieldset>
                    <fieldset>
                        <legend>Billing Details</legend>
                        <form #itemForm [formGroup]="ProductItemForm" style="padding: 0px;">
                            <div class=" row mb-2">


                                <div class="col-lg-5 col-md-12 col-sm-12">
                                    <label for="exampleInputEmail1">Item<span class="text-danger">*</span></label>
                                    <ng-select  (blur)="onBlur()" id="ngSelect1" #itemNgSelect    formControlName="itemID" [items]="ProductDropdownList" bindLabel="name"
                                        bindValue="id" (change)="FindItem($event?.id)" placeholder="Select Item">
                                    </ng-select>
                                    <span *ngIf="IsItemSubmited" style="color: red;">
                                        <span *ngIf="GetItemFormcontrols.itemID?.errors?.required">This Field Is
                                            Required</span>
                                    </span>

                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-12 custom-width-pos">


                                    <label for="exampleInputEmail1">Unit Price
                                        <!-- <i class="italic">(inclusive of tax)  </i> -->
                                    </label>
                                    <input readonly formControlName="price" type="number" name="discountPercentage"
                                        placeholder="Enter  Price" class="form-control readonly-color">
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-12 custom-width-pos">
                                  <label for="exampleInputEmail1">Quantity<span class="text-danger">*</span></label>
                                  <input (change)="onChangeItemQuantity()" formControlName="quantity" type="number" name="quantity"
                                    placeholder="Enter Quantity" class="form-control ">
                                    <span *ngIf="IsItemSubmited" style="color: red;">
                                      <span *ngIf="GetItemFormcontrols.quantity?.errors?.required">This Field Is
                                        Required</span>
                                      <span *ngIf="GetItemFormcontrols.quantity?.errors?.min">
                                        Quantity must be at least 1</span>
                                    </span>

                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-12 custom-width-pos">
                                    <label for="exampleInputEmail1">Discount&nbsp;(%)</label>
                                    <input max="99" type="number"
                                        formControlName="discount" name="discount" placeholder="Enter Discount"
                                        class="form-control ">
                                        <span *ngIf="GetItemFormcontrols.discount.touched" style="color: red;">
                                            <span *ngIf="GetItemFormcontrols.discount?.errors?.max">Discount cannot be more than 99</span>
                                        </span>
                                </div>

                                <div class="col-lg-1 col-md-1 col-sm-12 custom-width-pos">
                                    <label for="shipping-changes">Shipping </label>
                                    <input formControlName="shipping"  (keypress)="keyPressNumbers($event)" id="shipping-changes" type="number"
                                     name="Shipping-Charges" placeholder="Enter Shipping Charges" class="form-control readonly-color" readonly >

                                </div>


                                <div class="col-lg-3 col-md-3 col-sm-12 custom-width-pos add-reset p-0">
                                    <div class="d-flex justify-content-end" >
                                        <a [innerText]="ProductItemForm.get('IndexNO').value===null?'Add':'Update'" (click)="OnSubmitItem()" class="btn btn-success" type="submit"></a>
                                        <button class="btn btn-secondary" type="reset" >
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </form>
                        <div class="row "  *ngIf="ItemList.length>0">
                            <div class="col-md-12 " style="margin-top: 12px;">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered ">

                                        <thead>
                                            <tr>
                                                <th class="text-center">Item&nbsp;code</th>
                                                <th class="text-center">Item</th>
                                                <th class="text-center">Unit&nbsp;Price</th>
                                                <th class="text-center">Quantity</th>
                                                <th class="text-center">Discount&nbsp;(%)</th>
                                                <th class="text-center">Shipping</th>
                                                <th class="text-center">Net&nbsp;Price</th>

                                                <th class="action">Action</th>


                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let item of ItemList; let i=index">

                                                <td class="text-center">{{item.itemCode}}</td>
                                                <td class="text-center">{{item.ProductName}}</td>
                                                <td class="text-center">{{item.price}}</td>
                                                <td class="text-center">{{item.quantity}}</td>
                                                <td class="text-center">{{item.discount}}</td>
                                                <td class="text-center">{{item.shipping}}</td>
                                                <td class="text-center">{{item.salePrice}}</td>


                                                <td class="right-algin-buttons">
                                                    <div class="d-flex align-items-center justify-content-center">
                                                        <button (click)="OnEditItem(i)" class="btn btn-icon"
                                                            aria-label="Product details">
                                                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="OnDeleteItem(i)" class="btn btn-icon ml-2"
                                                            aria-label="Product details ">
                                                            <i class="fa  fa-trash-o" aria-hidden="true"></i>
                                                        </button>
                                                    </div>

                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                        <div class="row mt-2 pos-row" style="display: flex !important;">
                            <div class="col-xl-5  col-md-12  text-area-pos full-width">
                                <label>Remarks</label>
                                <textarea [(ngModel)]="Remarks" class="form-control text-area-pos" id="Remarks" placeholder="Enter Remarks here.." style="height: 80%;"></textarea>
                            </div>

                            <div class="col-xl-7 col-md-12  full-width">
                                <div class="invoice-amnt">
                                    <div class="d-flex sub">
                                        <label>Sub Total</label>
                                        <span>
                                            <input readonly name="discountPercentage" placeholder="Enter GST"
                                                class="form-control readonly-color" [value]="ItemTotalPrice">
                                        </span>
                                    </div>
                                    <div class="d-flex sub">
                                        <label>GST  <i _ngcontent-vgu-c47="" class="italic"></i></label>
                                        <span>
                                            <input readonly [value]="GstTax" type="number" name="discountPercentage"
                                                placeholder="Enter GST" class="form-control readonly-color">
                                        </span>
                                    </div>
                                    <div class="d-flex sub">
                                        <label>Customer Savings  <i _ngcontent-vgu-c47="" class="italic"></i></label>
                                        <span>
                                            <input readonly [value]="TotalSavings" type="number" name="discountPercentage"
                                                placeholder="Enter GST" class="form-control readonly-color">
                                        </span>
                                    </div>
                                    <div class="d-flex sub">
                                      <label>Write Off <i  class="italic"></i></label>
                                      <span>
                                          <input [(ngModel)]="finalDiscount" min="0" (keypress)="keyPressNumbers($event)" (change)="onApplyFinalDiscount()"  type="number"
                                              placeholder="Enter Write Off" class="form-control ">
                                      </span>
                                  </div>
                                    <div class="d-flex sub">
                                        <label>Payable&nbsp;(inclusive of tax)</label>
                                        <span>
                                            <input readonly type="number" name="discountPercentage"
                                                placeholder="Enter Sub Total" class="form-control readonly-color"
                                                [value]="ItemTotalAmount">
                                        </span>
                                    </div>
                                </div>






                            </div>
                        </div>
                        <div class="row pos-img-text" >
                            <div class="col-lg-5  col-md-4 col-sm-12  pl-0 imag-left media-hide">
                                <div class="loging">
                                    <img src="assets/images/pos-img.jpg">
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-8 col-sm-12 full-width">
                                <div class="process">
                                    <div class=" process-box">
                                        <div class="">
                                            <form [formGroup]="InvoiceForm" style="padding: 10px 0px;">
                                                <fieldset>
                                                    <legend>Payment&nbsp;Summary</legend>

                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="recevie-pay">
                                                                <!-- <label
                                                                    for="exampleInputEmail1">Receive&nbsp;Payment:</label> -->
                                                                <div class="form-check form-check-inline">
                                                                    <input (change)="OnChangePaymentType(true)"
                                                                        class="form-check-input" type="radio"
                                                                        name="inlineRadioOptions" id="inlineRadio1">
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio1">Partial&nbsp;Payment</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input (change)="OnChangePaymentType(false)" checked
                                                                        class="form-check-input" type="radio"
                                                                        name="inlineRadioOptions" id="inlineRadio2">
                                                                    <label class="form-check-label"
                                                                        for="inlineRadio2">Full&nbsp;Payment</label>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="col-md-12 new-box">

                                                            <div class="d-flex mb-2 pay-section">
                                                                <span>
                                                                    <label for="exampleInputEmail1">Cash&nbsp;:</label>
                                                                </span>
                                                               <span>
                                                                <input (change)="onChangeAmount('Cash-Paid')"
                                                                (keypress)="keyPressNumbers($event)"
                                                                formControlName="cashPaid" type="number"
                                                                name="discountPercentage" id="Cash-Paid"
                                                                placeholder="Enter Amount" class="form-control ">
                                                               </span>


                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 new-box">
                                                            <div class="d-flex mb-2 pay-section">
                                                                <span>

                                                                    <label for="inputState">Store&nbsp;Credit&nbsp;:</label>
                                                                </span>
                                                                <span class="d-flex justify-content-end">

                                                                    <input (change)="onChangeAmount('Storecredit-Paid')"
                                                                        (keypress)="keyPressNumbers($event)"
                                                                        formControlName="storeCreditPaid" type="number"
                                                                        name="discountPercentage"
                                                                        placeholder="Enter Store Credit"
                                                                        id="Storecredit-Paid" class="form-control recevie-amt">
                                                                       <span class="fixed-amt avl-score"><span>Bal: {{UserDetail.StoreCreadit}}</span></span>
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-12 new-box">
                                                            <div class="d-flex mb-2 pay-section">
                                                                <span class="pay-buttons">

                                                                    <label for="exampleInputEmail1">Online&nbsp;Payment&nbsp;:</label>
                                                                    <div  class="shipping-type">
                                                                        <div class="ship-1">
                                                                            <input [disabled]="!InvoiceForm.get('onlinePaid').value " id="online-payment-check1" (change)="OnChangeOnlineMode('Bank')"  name="online-pyment"
                                                                        type="radio">
                                                                    <label>Bank</label>
                                                                        </div>
                                                                        <div class="ship-1">
                                                                            <input [disabled]="!InvoiceForm.get('onlinePaid').value" (change)="OnChangeOnlineMode('Credit/Debit')"    name="online-pyment" type="radio">
                                                                            <label> Credit/Debit </label>
                                                                        </div>

                                                                    </div>
                                                                </span>

                                                                <span class="d-flex justify-content-end">

                                                                    <input (change)="onChangeAmount('Online-Paid')"
                                                                        (keypress)="keyPressNumbers($event)"
                                                                        formControlName="onlinePaid" type="number"
                                                                        name="discountPercentage" placeholder="Enter Amount"
                                                                        id="Online-Paid" class="form-control recevie-amt">
                                                                    <input [value]="Balance" type="number"
                                                                        name="discountPercentage" readonly
                                                                        placeholder="Enter Amount"
                                                                        class="form-control fixed-amt">
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-md-12 new-box">
                                                          <div class="d-flex mb-2 pay-section">
                                                              <span>

                                                                  <label for="exampleInputEmail1">Write&nbsp;Off</label>
                                                              </span>
                                                              <span>

                                                                  <input (change)="onChangeAmount('Final-Discount')" formControlName="finalDiscount" type="number"
                                                                      name="writeoff"
                                                                      placeholder="Write Off"
                                                                      class="form-control ">
                                                              </span>

                                                          </div>
                                                      </div> -->
                                                        <div class="col-md-12 new-box">
                                                            <div class="d-flex mb-2 pay-section">
                                                                <span>

                                                                    <label for="exampleInputEmail1">Balance&nbsp;</label>
                                                                </span>
                                                                <span>

                                                                    <input readonly [value]="Balance" type="number"
                                                                        name="discountPercentage"
                                                                        placeholder="Balance"
                                                                        class="form-control readonly-color">
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-12  ">
                                                            <div class="d-flex justify-content-between mt-2 mb-2">

                                                                <button class="btn btn-secondary" (click)="resetPayment()">
                                                                    Reset
                                                                </button>
                                                                <div class="">
                                                                    <a (click)="ManageInvoice()" class="btn btn-blue mr-2"
                                                                    type="submit">
                                                                    <i class="fa fa-floppy-o " aria-hidden="true"></i>Save&nbsp;&&nbsp;Print
                                                                </a>
                                                                <button class="btn btn-secondary" >
                                                                   Close
                                                                </button>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="reset-whole">
                                    <div class="d-flex">
                                        <button class="btn btn-pink" (click)="resetInvoice()">
                                            Reset Invoice
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>



                </div>
            </div>
        </div>
    </div>




    <div class="modal fade customer-modal" id="Add-customer-details" role="dialog" data-backdrop="static"
        data-keyboard="false">
        <div class="modal-dialog modal-lg-custom">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Customer</h4>
                    <button  (click)="resetMasterForm()" type="button" class="close" data-dismiss="modal">&times;</button>

                </div>
                <div class="modal-body popos">
                    <div class="row">
                        <form [formGroup]="UserForm">
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">Name/Company Name<span class="text-danger">*</span></label>
                                <input formControlName="firstName" type="text" name="discountPercentage"
                                    placeholder="Enter Name" class="form-control ">
                                <span *ngIf="GetUserFormcontrols.firstName.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.firstName?.errors?.required">This Field Is
                                        Required</span>
                                </span>
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">Phone No.<span class="text-danger">*</span></label>
                                <input #phone (change)="onChangeUserField(phone.value,'phone-number','pos-user')" formControlName="mobile" (keypress)="keyPressNumbers1($event)" maxlength="10"
                                    type="text" name="discountPercentage" placeholder="Enter Phone No."
                                    class="form-control ">
                                <span *ngIf="GetUserFormcontrols.mobile.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.mobile?.errors?.required">This Field Is
                                        Required</span>
                                    <span *ngIf="GetUserFormcontrols.mobile?.errors?.minlength">Number Must Be At Least
                                        10
                                        Digits
                                        Long.</span>
                                        <span *ngIf="GetUserFormcontrols.mobile.errors?.duplicateMobile">This phone number is already taken.</span>
                                </span>
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">Email<span class="text-danger">*</span></label>
                                <input  #email1 (change)="onChangeUserField(email1.value,'email','pos-user')"  formControlName="email" type="text" name="discountPercentage"
                                    placeholder="Enter Email" class="form-control ">
                                <span *ngIf="GetUserFormcontrols.email.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.email?.errors?.required">This Field Is
                                        Required</span>
                                    <span *ngIf="GetUserFormcontrols.email?.errors?.pattern">Please Enter Valid
                                        Email</span>
                                        <span *ngIf="GetUserFormcontrols.email.errors?.duplicateEmail">This phone number is already taken.</span>
                                </span>
                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">ACN Number</label>
                                <input formControlName="acnNumber" type="text" name="discountPercentage" placeholder="Enter ACN Number"
                                    class="form-control " maxlength="9">
                                <span *ngIf="GetUserFormcontrols.acnNumber.touched" style="color: red;">
                                    <span *ngIf="GetUserFormcontrols.acnNumber?.errors?.pattern"> Invalid ACN. It must be a 9-digit number.</span>

                                </span>


                            </div>
                            <div class="col-md-4 mb-2">
                                <label for="exampleInputEmail1">ABN Number</label>
                                <input formControlName="abnNumber" type="text" name="discountPercentage" placeholder="Enter ABN Number"
                                    class="form-control " maxlength="11">
                                    <span *ngIf="GetUserFormcontrols.abnNumber.touched" style="color: red;">
                                        <span *ngIf="GetUserFormcontrols.abnNumber?.errors?.pattern"> Invalid ABN. It must be a 11-digit number.</span>
                                    </span>
                            </div>
                        </form>




                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <fieldset>
                                <legend>Billing Address</legend>
                                <form [formGroup]="AddressForm1">
                                    <div class="row">
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select Country <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="country" placeholder="Select Country"
                                                [items]="CountryDropdownList" bindValue="id" bindLabel="name"
                                                (change)="OnChangeCounry($event?.id,'billing-address')"></ng-select>
                                            <span *ngIf="GetAddress1Formcontrols.country.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.country?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
                                        </div>

                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select State <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="state" placeholder="Select State"
                                                [items]="StateDropdownList1" bindValue="id" bindLabel="name"
                                                (change)="OnChangeState($event?.id,'billing-address')"></ng-select>
                                            <span *ngIf="GetAddress1Formcontrols.state.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.state?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>

                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select City <span
                                                    class="text-danger">*</span></label>
                                            <ng-select (change)="onChangeUserFields($event.id,'city')" formControlName="city" placeholder="Select City"
                                                [items]="CityDropdownList1" bindValue="id" bindLabel="name"></ng-select>
                                            <span *ngIf="GetAddress1Formcontrols.city.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.city?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>


                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Pincode <span
                                                    class="text-danger">*</span></label>
                                            <input (keypress)="keyPressNumbers1($event)" (keyup)="onChangeUserFields($event.target.value,'pincode')" formControlName="pinCode" type="text" name="discountPercentage"
                                                placeholder="Enter Pincode" class="form-control " maxlength="4">
                                            <span *ngIf="GetAddress1Formcontrols.pinCode.touched" style="color: red;">
                                                <span *ngIf="GetAddress1Formcontrols.pinCode?.errors?.required">This
                                                    Field Is Required</span>
                                                <span *ngIf="GetAddress1Formcontrols.pinCode?.errors?.pattern">Invalid pincode it
                                                    Must Be At Least 4 Digits Long.</span>
                                            </span>
                                        </div>
                                        <div class="col-md-12 mb-2">
                                            <label for="exampleInputEmail1"> Address <span
                                                    class="text-danger">*</span></label>
                                            <textarea  (keyup)="onChangeUserFields($event.target.value,'address')" formControlName="address1" class="form-control"
                                                id="exampleFormControlTextarea1" rows="2"
                                                placeholder="Enter Address"></textarea>
                                            <span *ngIf="GetAddress1Formcontrols.address1.touched" style="color: red;"
                                                class="my-fields">
                                                <span *ngIf="GetAddress1Formcontrols.address1?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
                                        </div>

                                    </div>
                                </form>

                            </fieldset>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <fieldset>
                                <legend>Delivery Address <span class="deli">(<input
                                            (change)="CommonAddress($event.target.checked)" type="checkbox"
                                            id="common-address" name="isActive" class="m-0 ">
                                        <i class="italic">Same as Billing Address</i>)</span></legend>
                                <form [formGroup]="AddressForm2">
                                    <div class="row">
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select Country <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="country" placeholder="Select Country"
                                                [items]="CountryDropdownList" bindValue="id" bindLabel="name"
                                                (change)="OnChangeCounry($event?.id,'shipping-address')"></ng-select>
                                            <span *ngIf="GetAddress2Formcontrols.country.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.country?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>

                                        </div>

                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select State <span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="state" placeholder="Select State"
                                                [items]="StateDropdownList2" bindValue="id" bindLabel="name"
                                                (change)="OnChangeState($event?.id,'shipping-address')"></ng-select>
                                            <span *ngIf="GetAddress2Formcontrols.state.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.state?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>

                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Select City <span
                                                    class="text-danger">*</span></label>
                                            <ng-select  formControlName="city" placeholder="Select City"
                                                [items]="CityDropdownList2" bindValue="id" bindLabel="name"></ng-select>
                                            <span *ngIf="GetAddress2Formcontrols.city.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.city?.errors?.required">This Field
                                                    Is Required</span>
                                            </span>

                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="exampleInputEmail1">Pincode <span
                                                    class="text-danger">*</span></label>
                                            <input formControlName="pinCode" (keypress)="keyPressNumbers1($event)" type="text" name="discountPercentage"
                                                placeholder="Enter Pincode" class="form-control " maxlength="4">
                                            <span *ngIf="GetAddress2Formcontrols.pinCode.touched" style="color: red;">
                                                <span *ngIf="GetAddress2Formcontrols.pinCode?.errors?.required">This
                                                    Field Is Required</span>
                                                <span *ngIf="GetAddress2Formcontrols.pinCode?.errors?.pattern">Invalid pincode it
                                                    Must Be At Least 4 Digits Long.</span>
                                            </span>
                                        </div>
                                        <div class="col-md-12 mb-2">
                                            <label for="exampleInputEmail1"> Address <span class="text-danger">*</span>
                                            </label>
                                            <textarea formControlName="address1" class="form-control"
                                                id="exampleFormControlTextarea2" rows="2"
                                                placeholder="Enter  Address"></textarea>
                                            <span *ngIf="GetAddress2Formcontrols.address1.touched" style="color: red;"
                                                class="my-fields">
                                                <span *ngIf="GetAddress2Formcontrols.address1?.errors?.required">This
                                                    Field Is Required</span>
                                            </span>
                                        </div>

                                    </div>
                                </form>

                            </fieldset>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="attrib-button">
                        <div class="col-md-12">
                            <button (click)="ManageUserDetails()" class="btn btn-success marginright15" type="submit"
                                id="btnsubmit">
                                Submit
                            </button>
                            <button (click)="resetMasterForm()" class="btn btn-secondary ">
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>




    <div class="modal fade customer-modal" id="Referance" role="dialog" >
        <div class="modal-dialog modal-lg-custom">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Referance</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <label for="exampleInputEmail1">Referred By</label>
                            <select id="referanceselect" class="form-control">
                                <option selected>Select Referance</option>
                                <option>Friends</option>
                                <option>Google</option>
                                <option>Social Media</option>

                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="exampleInputEmail1">Name</label>
                            <input type="text" name="discountPercentage" placeholder="Enter Name" class="form-control ">
                        </div>
                        <div class="col-md-4">
                            <label for="exampleInputEmail1">Phone No.</label>
                            <input type="text" name="discountPercentage" placeholder="Enter Phone No."
                                class="form-control ">
                        </div>

                    </div>

                </div>
                <div class="modal-footer">
                    <div class="attrib-button">
                        <div class="col-md-12">
                            <button class="btn btn-success marginright15" type="submit" id="btnsubmit2">
                                Add
                            </button>
                            <button class="btn btn-secondary ">
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>




    <div class="modal fade" id="refresh" role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">

          <!-- Modal content-->
          <div class="modal-content">

            <div class="modal-body refresh-pop">
              <div class="refresh-content">
                <img src="assets/images/icons8-loading.gif">
                <h4> Processing</h4>
                <p>Please do not refresh the page or click the "Back" or "Close" button on your browser, as this will halt the process. Once the invoice is generated, you will be redirected to view it. This may take a few seconds. </p>

              </div>
            </div>

          </div>

        </div>
      </div>
