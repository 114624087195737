import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MastersizeService {

  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}
  getMasterSizeList(Active:any):Observable<any>
  {
    return this.http.get<any>(this.rootUrl +'/Common/GetMasterSizelist',{params:{Active}});
  }
  ManageMasterSize(bo:any):Observable<any>
  {
    return this.http.post<any>(this.rootUrl +'/Common/ManageMasterSize',bo)
  }
  ActiveInactiveSize(id:number):Observable<any>
  {
    return this.http.get<any>(this.rootUrl +'/Common/ActiveInactiveSize',{params:{id}})
  }
}
