import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ProductlistService } from '../productlist/productlist.service';
import { InventoryService } from './inventory.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatepickerServiceInputs } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
declare const $: any;

@Component({
  selector: 'app-lowinventory',
  templateUrl: './lowinventory.component.html',
  styleUrls: ['./lowinventory.component.css']
})
export class LowinventoryComponent implements OnInit, AfterViewInit {
  public LowinventoryProductList: any[] = [];
  public dataForSubmit:any[]=[];
  public changesInItemDetails: { [key: number]: { quantity: number, price: number } } = {};
  public searchText:string='';
  public form: any;
  public readonly rootURL: string = environment.baseUrl.slice(0, -3);
  public paginationconfig = {
    itemsPerPage: 10,
    currentPage: 1,
    pageIndex: 0
  };
  constructor(private inventoryservice: InventoryService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private datePipe: DatePipe) {
    this.form = fb.group({
      productDetailId: ['', [Validators.required]],
      productQuantity: [0, [Validators.required, Validators.min(1)]],
      productPrice: [0, [Validators.required, Validators.min(1)]],
    });

  }

  ngOnInit(): void {
    this.GetLowInventoryProduct();
  }

  ngAfterViewInit(): void {
    $(`.date-input`).flatpickr({
      minDate: 'today',
      dateFormat: 'd/M/Y'
    });
  }

  get f() {
    return this.form.controls;
  }

  GetLowInventoryProduct() {
    this.spinner.show();
    this.inventoryservice.GetLowInventroyProducts().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.LowinventoryProductList = response.result;
        };
        this.spinner.hide();
      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }
    });
  }

  OnTableDataChange(pageNumber: number) {
    this.paginationconfig.currentPage = pageNumber;
  }

  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }

  appendQuantity() {debugger
    if (this.form.valid) {
      this.dataForSubmit=[...this.dataForSubmit.filter(x=>x.productDetailId!==this.form.get('productDetailId').value),this.form.value];
      this.changesInItemDetails[this.form.get('productDetailId').value]={ quantity:this.form.get('productQuantity').value , price: this.form.get('productPrice').value };;
      this.formReset();
    }else{
      this.form.markAllAsTouched();
    }
  }

  addQuantity() {
      this.spinner.show();
      this.inventoryservice.AddProductStock(this.dataForSubmit).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            Swal.fire({
              title: 'Inventory Updated!',
              text: `New lot (${response.result}) has been successfully created for the selected products.`,
              icon: 'success'
            });
            this.dataForSubmit=[];
            this.changesInItemDetails={};
            this.GetLowInventoryProduct();
          } else { this.toastr.error(response.message) };
          this.spinner.hide();
        },
        error: (err) => {
          this.toastr.error(err)
          this.spinner.hide();
        }
      })
  }


  formReset() {
    $('#add-stock-modal').modal('hide');
    this.form.reset({ productPrice: 0, productQuantity: 0 });
  }

  openModal(item:{itemPrice:number,id:number}): void {
    this.form.patchValue({
      productDetailId: item.id,
      productPrice:this.changesInItemDetails[item.id]?this.changesInItemDetails[item.id].price : item.itemPrice,
    });
    $('#add-stock-modal').modal('show');
  }
}
