<section class="content-header sub-head">
    <h1> Application Settings   </h1>
  </section>
  <div class="row">
    <div class="col-md-12">
      <div class="box box-danger">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="box-body">
            <div class="form-group col-md-6">
              <input type="hidden" formControlName="ID" class="form-control">
              <label for="exampleInputEmail1">ABN</label>
              <input formControlName="abn" placeholder="Enter ABN" (input)="formatABN($event)" name="abn" id="Setting" type="text" class="form-control SettingName"  >
              <span *ngIf="f.abn.touched" class="validation-error">
                <span *ngIf="f.abn.errors?.required">This Field is required </span>
                <span *ngIf="f.abn.errors?.pattern">Please entered 11 digit </span>

              </span>
            </div>
            <div class="form-group col-md-6">
                <input type="hidden" formControlName="ID" class="form-control">
                <label for="exampleInputEmail1">ACN</label>
                <input formControlName="acn" placeholder="Enter ACN" (input)="formatACN($event)" name="acn" id="SettingName"  type="text"  class="form-control SettingName"  >
                <span *ngIf="f.acn.touched" class="validation-error">
                  <span *ngIf="f.acn.errors?.required">This Field is required </span>
                <span *ngIf="f.acn.errors?.pattern">Please entered 9 digit </span>

                </span>
              </div>
              <div class="form-group col-md-6">
                <input type="hidden" formControlName="ID" class="form-control">
                <label for="exampleInputEmail1">Phone</label>
                <input formControlName="mobile"  placeholder="Enter Phone" (keypress)="onKeyPress($event)" name="mobile" id="SettingName"  type="text"  class="form-control SettingName"  >
                <span *ngIf="f.mobile.touched" class="validation-error">
                  <span *ngIf="f.mobile.errors?.required">This Field is required </span>
                <span *ngIf="f.mobile.errors?.pattern">Please entered 10 digit </span>

                </span>
              </div>
              <div class="form-group col-md-6">
                <input type="hidden" formControlName="ID" class="form-control">
                <label for="exampleInputEmail1">Email</label>
                <input formControlName="email" placeholder="Enter Email" name="email" id="SettingName"  type="text"  class="form-control SettingName"  >
                <span *ngIf="f.email.touched" class="validation-error">
                  <span *ngIf="f.email.errors?.required">This Field is required </span>
                  <span *ngIf="f.email.errors?.pattern">Please entered correct email </span>

                </span>
              </div>
              <div class="form-group col-md-12">
                <!-- <input type="hidden" formControlName="address" name="address"class="form-control" > -->
                <label for="exampleInputEmail1">Address</label>
                <div class="input-group w-100">
                  <textarea id="w3review" name="address"  formControlName="address" cols="50"placeholder="Enter Address" >
                  </textarea>

                </div>
                
                <span *ngIf="f.address.touched" class="validation-error">
                  <span *ngIf="f.address.errors?.required">This Field is required </span>
                </span>
         
                  
              </div>
              
              <div class="form-group col-md-12"style=" text-align: right; ">
             <button type ="submit" id="btnsubmit" class="btn btn-primary marginright15" >Submit </button >
                <button type="button" id="" class="btn btn-secondary">Cancel</button></div>
            </div>
          
        </form>
      </div>
    </div>
 </div> 

<div _ngcontent-xaa-c17="" class="box">
<div class="attri-button adding" >
      
    
  <div class="box-body">
    <div class="row nomargin">
      <div class="col-md-12" style="margin-top: 12px;">
        <div class="table-responsive">
          <table id="example1" class="table  table-bordered table-striped">
            <thead>
              <tr>
                <th>ABN</th>
                <th>ACN</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Address</th>
                <!-- <th>IsActive</th> -->
                <th>Action</th>


              </tr>
            </thead>
            <tbody >
              <tr *ngFor="let item of Comapny" >
                <td>{{item.abn}}</td>
                <td>{{item.acn}}</td>
                <td>{{item.mobile}}</td>
                <td>{{item.email}}</td>
                <td>{{item.address}}</td>
                <!-- <td>
                  <button  class="btn btn-icon m-1 p-1 mr-2">
                    <input type="checkbox" id="checkBoxAinA{{item.id}}" value="isActive" name="isActive"
                        (change)="ActiveInactiveCopanySetting(item.id,item.isActive)" [checked]="item.isActive">
                  </button>
    
                </td> -->
                <td>
                  <div class="d-flex justify-content-center">
                    <button  class="btn btn-icon m-1 p-1 mr-2">
                      <input type="checkbox" id="checkBoxAinA{{item.id}}" value="isActive" name="isActive"
                          (change)="ActiveInactiveCopanySetting(item.id,item.isActive)" [checked]="item.isActive">
                    </button>
                    <button type="button"  class="btn btn-icon" (click)="onEdit(item)" aria-label="Product details">
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                  </div>
                
                </td>
              </tr>
            </tbody>
    
          </table>
          <div >

          </div>
          </div>
          
      </div>
    </div>
    
  </div>


</div></div>