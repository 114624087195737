<section class="content">
    <div class="box">
      <section class="content-header">
        <h1>
       Cancel Order
         
        </h1>
     
      </section>
        <!-- /.box-header -->
        <div class="box-body">
          <table id="example11" class="table table-bordered table-striped">
            <thead>
            <tr>
                <th>S.No</th>
              <th>Order No</th>
             <th>Order Date</th>
             <th>Shipping Charges</th>
             <th>Order Amount</th>
             <th>Status</th>
             <th>View</th>
            </tr>
            </thead>
            <tbody>
        
          <tr *ngFor="let Orderlist of listCancelledOrdersDetail;let i=index">
            <td>{{i+1}}</td>
             <td >{{Orderlist.OrderNo}}</td>
        
             <td >{{Orderlist.OrderDate | date: 'dd-MMM-yyyy'}}</td>
            <td>{{Orderlist.ShippingCharges}}</td>
            <td>{{Orderlist.TotalAmount}}</td>
             <td>{{Orderlist.Status}}   </td>
             <td>
              <button type="button" (click)="showOrderDetails(Orderlist.ID)"  class="btn btn-icon" aria-label="View" data-toggle="modal" data-target="#myOrderModal" >
                <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
          </td>
            </tr>
            <tr *ngIf="listCancelledOrdersDetail.length==0">
              <td colspan="7">No Record Found</td>
          </tr>
          </tbody>
           
        </table>
      </div>
    
    </div>
    </section>
              
    <div class="container" >
 
    
      <div class="modal fade" id="myOrderModal" role="dialog">
        <div class="modal-dialog">
        
      
          <div class="modal-content">
            <div class="modal-header" style="background-color:#dd4b39;">
              <button type="button" class="close close1" data-dismiss="modal" style="color: white;">&times;</button>&nbsp;&nbsp;
              <h4 class="modal-title" style="float: right;color: white;">Order Date : {{OrderProductList[0]?.OrderDate | date: 'dd-MMM-yyyy'}}</h4>
              <h4 class="modal-title" style="color: white;display: contents;"><b>{{OrderProductList[0]?.OrderNo}}</b></h4>
             
            </div>
            <div class="modal-body">
              <div class="row" *ngFor="let item1 of OrderProductList;" >
                <div class="col-md-4">
                <div class="product-img">
                <img [src]="item1.Image"  class="image-product">
                </div>
                </div>
                   <div class="col-md-6">
                   <div class="product-detail">
               
                <ul class="details">
                 <li><b>Product Name</b>  :  <span class="product-info"> {{item1.ProductName}} </span></li>
                 <li><b>SKU CODE</b> :<span class="product-info"> {{item1.SKUCODE}} </span></li>
                 <li>Product Quantity<b></b>  :  <span class="product-info"> {{item1.Weight}} {{item1.Unit}} </span></li>
                 <li><b>Discount</b> :<span class="product-info"> {{item1.Discount}} </span></li>
                 <li><b>Shipping Address(in £)</b> :<span class="product-info"> {{item1.ShippingAddress}} </span></li>
                 <li><b>Total Amount (in £)</b> :<span class="product-info"> {{item1.Price}} </span></li>
                 <li><b>Ordered Date</b>:  <span class="product-info"> {{OrderProductList[0]?.OrderDate | date: 'dd-MMM-yyyy'}} </span></li>
                 
                </ul>
                   </div>
               
                       </div>
                </div>
          
          </div>
          <div class="modal-footer" style="background-color:#dd4b39;">
            <!-- <button type="button" style="float: left;color: black;" class="btn btn-default" data-dismiss="modal">Close</button> -->
            <h4 class="modal-title" style="float: right; color: white;">Status : {{OrderProductList[0]?.Status}}</h4>
          </div>
        </div>
          
        </div>
      </div>
      
    </div> 