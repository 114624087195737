<section class="content-header sub-head d-flex justify-content-between">
  <h1>
    Order #{{orderDetail.itemID}}
  </h1>
  <a routerLink="/orders" class="btn btn-primary float-right"><i class="fa fa-backward back-button"></i>Back</a>
</section>
<section class="">

  <div class="row "> <!-- left column -->
    <div class="col-md-12">
      <div class="box box-danger">

        <div class="box-body">

          <div class="row nomargin">

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 ">
                  <div class=" customerdetailmain">
                    <div class="col-md-12  nopadding">
                      <div class="box-header with-border">
                        <h3 class="box-title">Customer Details</h3>
                      </div>
                    </div>
                    <div class="col-md-12">

                      <div class="row">
                        <div class="col-md-12  ">
                          <div class="bilingbox">




                            <div class="billingcontent">
                              <p>Name: {{customerDetail.firstName}}&nbsp;{{customerDetail.lastName}}</p>
                              <p>Email:<span> {{customerDetail.email}}</span></p>
                              <p>Phone-no:&nbsp;<span>{{customerDetail.phoneNumber}}</span></p>

                            </div>

                          </div>
                        </div>

                      </div>

                    </div>


                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class=" customerdetailmain">
                    <div class="col-md-12  nopadding">
                      <div class="box-header with-border">
                        <h3 class="box-title">Billing Address</h3>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12  ">
                          <div class="bilingbox">




                            <div class="billingcontent">
                              <p>{{billingAddress?.address1}}</p>
                              <p>{{billingAddress?.country}} <span>, {{billingAddress?.state}}</span></p>
                              <p>{{billingAddress?.city}}&nbsp;<span>,{{billingAddress?.pinCode}}</span></p>

                            </div>

                          </div>
                        </div>

                      </div>

                    </div>


                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class=" customerdetailmain">
                    <div class="col-md-12  nopadding">
                      <div class="box-header with-border">
                        <h3 class="box-title">Delivery Address</h3>
                      </div>
                    </div>
                    <div class="col-md-12">


                      <div class="row">

                        <div class="col-md-12 ">
                          <div class="bilingbox">



                            <div class="billingcontent">
                              <p>{{deliveryAddress?.address1}}</p>
                              <p>{{deliveryAddress?.country}} <span>, {{deliveryAddress?.state}}</span></p>
                              <p>{{deliveryAddress?.city}}&nbsp;<span>,{{deliveryAddress?.pinCode}}</span></p>

                            </div>

                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>





          </div>
        </div>
      </div>
    </div>
  </div>



  <section class="content-header sub-head ">
    <h1>
      Product Details
    </h1>
  </section>

  <div class="box">
    <div class="box-body">
      <div class="row nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table class="table table-striped table-bordered nomargin">

              <thead>
                <tr>
                  <th>Item&nbsp;Order&nbsp;#</th>
                  <th>Image</th>
                  <th>Product Details</th>
                  <th>Unit&nbsp;Price&nbsp;(AUD)</th>
                  <th>Quantity</th>
                  <th>Discount&nbsp;(%)</th>
                  <th>Total&nbsp;Price (AUD)</th>
                  <th>Delivery&nbsp;Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>{{orderDetail.itemID}}</td>

                  <td>
                    <div class="image">
                      <img [src]="rootUrl+orderDetail.image" (error)="setDefaultImage($event)" [alt]="orderDetail.productName">
                    </div>
                  </td>
                  <td>
                    <div class="report-text">
                      <span> <b>{{orderDetail.productName}}</b> </span>
                      <span> Color : {{orderDetail.color}}</span>
                      <span> Size : {{orderDetail.size}}</span>
                    </div>
                  </td>
                  <td>{{orderDetail.unitPrice|number:'1.2-2'}}</td>
                  <td>{{orderDetail.quantity}}</td>
                  <td>{{orderDetail.discountPrecentage??'-'}}</td>
                  <td>{{orderDetail.totalPrice|number:'1.2-2'}}</td>

                  <td>
                    <input id="deleverydate{{orderDetail.id}}" class="form-control ng-pristine ng-invalid ng-touched"
                      (change)="ChangeDeleveryDate(orderDetail.id,$event.target.value,orderDetail.deleveryDate)"
                      name="deleveryDate" ngModel="{{orderDetail.deleveryDate|date:'dd-MMM-yyyy'}}" />
                  </td>

                  <td>
                    <select [ngModel]="orderDetail.lastUpdatedStatusID" class="form-control "
                      id="ddlStatusID{{orderDetail.id}}"
                      (change)="orderStatusChange(orderDetail.id,$event.target.value,orderDetail.lastUpdatedStatusID)">
                      <option
                        [disabled]="menus.id<=orderDetail.lastUpdatedStatusID || menus?.id>orderDetail.lastUpdatedStatusID+1 ||menus?.id===7 ? 'disabled': null"
                        *ngFor="let menus of orderDetail.statusList" value="{{menus?.id}}">
                        {{menus.statusName}}
                      </option>
                    </select>
                  </td>





                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>