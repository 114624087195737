<section class="content-header sub-head">
    <h1>
      Category Level 2
    </h1>
</section>
<section >

    <div class="box product-box">
      <div class="box-header">
        <div style="width: 100%;">
          <div class="row">
            <div class="col-xl-2 col-md-4">
              <form class="d-flex my-search pb-0 mb-2" method="post">
                <input #search (change)="Filter(search.value,'BY-SEARCH')" id="SearchID" class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                
                <button class="btn btn-outline-success" type="submit"><i class="fa fa-search"
                   ></i></button>
              </form>
            </div>
    
    
            <div class="col-xl-5 col-md-8" style="display: flex;">
              <ng-select [items]="listmainmenus" placeholder="Select Category" bindLabel="categoryName" bindValue="id" (change)="Filter($event?.id,'BY-CATEGORY')" class="my-search pb-0 mb-2" style="width: 50%;"></ng-select>
              <ng-select [items]="listcategorylevel1" placeholder="Select Category Level 1" bindLabel="subCategoryName" bindValue="id" (change)="Filter($event?.id,'BY-CATEGORYLEVEL1')" class="my-search ml-2 pb-0 mb-2" style="width: 50%;"></ng-select>
            </div>
    
           <div class="col-xl-5 col-md-12 text-right">

            <select (change)="Filter($event.target.value,'ACTIVE-INACTIVE')" class="btn btn-primary custom-button  mt-3 "  name="cars" id="period"> 
              <option  value="true" selected>Active&nbsp;List</option>
              <option value="false">Deactive&nbsp;List</option> 
              <option value="">All</option>
              
            </select>
              <button class="btn btn-primary custom-button ml-2 custom-button-margin mt-3 "  aria-label="Product details" data-toggle="modal" data-target='#manage-subcategoryTwo'>
                <i class="fa fa-plus" style="margin-right: 5px;"></i> Add Category Level 2
              </button>
            </div>
          </div>

        </div>
      
  
  </div>
      
    
 




    <div class="box-body">
      <div class="row nomargin">
        <div class="col-md-12" style="margin-top: 12px;">
          <div class="table-responsive">
            <table id="example1" class="table text-center table-bordered table-striped">
              <thead>
              <tr>
                <th>S.No</th>
                <th style="min-width: 150px;">Category</th>
                <th style="min-width: 150px;">Category&nbsp;Level&nbsp;1</th>
                <th>Category&nbsp;Level&nbsp;2</th>
                <th>Search&nbsp;Tag</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
               <tr *ngFor="let subcattwo of SubCategoriesLTwoList|  paginate
               : {
                   itemsPerPage: tableSize,
                   currentPage: page,
                   totalItems: count
                 };let i=index">
                 <td>{{i+1+tableSize*(page-1)}}</td>
                <td>{{subcattwo.categoryName}}</td>
                <td>{{subcattwo.categorySubOne}}</td>
                <td>{{subcattwo.subCategoryTwo}}</td>
                <td>{{subcattwo.searchTag}}</td>
               
              <td>
                <div class="d-flex">
                  <button  class="btn btn-icon m-1 p-1 mr-2">
                    <input type="checkbox" id="checkBoxAinA{{subcattwo.id}}" value="IsActive" name="IsActive" (change)="ActivedeactiveSubCatTwo(subcattwo.id,subcattwo.isActive)"
                  [checked]="subcattwo.isActive">
      
                  </button>
                <button type="button"  (click)="onEdit(subcattwo)" class="btn btn-icon"  aria-label="Product details">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        
                </button>
                </div>
                
            </td>
              </tr> 
               </tbody>
            
            </table>
            <div *ngIf="SubCategoriesLTwoList.length>0;else elseblock">

            </div>
            </div>


            <div class="row mt-3"style="margin-right:0;"  *ngIf="SubCategoriesLTwoList.length>tableSize">
              <div class="col-md-3 ">
                <select (change)="onTableSizeChange($event.target.value)" class="form-control w-auto">
                  <option  *ngFor="let tblsize of tableSizes" 
                  [ngValue]="tblsize">{{tblsize}}</option>
                </select>
              </div>
              <div class="col-md-9 text-right">
                <pagination-controls
                previousLabel="Prev"
                nextLabel="Next"
                (pageChange)="onTableDataChange($event)"
              >
              </pagination-controls>
              
              </div>
             
            </div>
        </div>
      </div>
    </div>
  

  <ng-template #elseblock>
    <div class="no-data">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <h4>No Data Found</h4>
    </div>
  </ng-template>


  <div class="modal fade" id="manage-subcategoryTwo" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-lg-custom">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Manage Category Level 2</h4>
          <button type="button" class="close" (click)="resetForm()">&times;</button>

        </div>
        <div class="modal-body">
          <form [formGroup]="SubCategoryTwoForm"  >
            <div class="box-body">
             <div class="row">
              <div class="form-group col-md-6">
                <label for="">Category <span style="color: rgb(247, 25, 25);">*</span></label>
                <ng-select placeholder="Select Category" [items]="listmainmenus" bindLabel="categoryName" bindValue="id" formControlName="categoryid" (change)="onChangeCategaory($event.id)"></ng-select>

              <span *ngIf="input.categoryid.touched" class="text-danger validation-error">
                <span *ngIf="input.categoryid.errors?.required">This field is required</span>
              </span>
              </div>
    
    
              <div class="form-group col-md-6">
                <label for="subCategoryID">Category Level 1 <span style="color: rgb(247, 25, 25);">*</span></label>

                <ng-select  placeholder="Select Category Level 1" [items]="listsubCategories" bindLabel="subCategoryName" bindValue="id" formControlName="subCategoryID"></ng-select>
              <span *ngIf="input.subCategoryID.touched" class="text-danger validation-error">
                <span *ngIf="input.subCategoryID.errors?.required">This field is required</span>
              </span>
              </div>
    
    
             </div>
             <div class="row">
              <div class="form-group col-md-6">
                <label for="sub2CategoryName">Category Level 2 <span style="color: rgb(247, 25, 25);">*</span></label>
                <input (change)="onChangeInput($event.target.value)" formControlName="sub2CategoryName" placeholder="Enter Category Level 2"
                id="sub2CategoryName" type="text" class="form-control" >
                <span *ngIf="input.sub2CategoryName.touched" class="text-danger validation-error">
                  <span *ngIf="input.sub2CategoryName.errors?.required">This field is required</span>
                </span>
              </div>
    
              
            
    
              <div class="form-group col-md-6">
                <label for="searchTag">Search Tag <span style="color: rgb(247, 25, 25);">*</span></label>
                <input formControlName="searchTag" placeholder="Enter Search Tag"
                id="searchTag" type="text" class="form-control">
                <span *ngIf="input.searchTag.touched" class="text-danger validation-error">
                  <span *ngIf="input.searchTag.errors?.required">This field is required</span>
                </span>
              </div> 
             </div>  
            </div>

          
         
          </form>
          <app-seo-management></app-seo-management>
          </div>
          
          <div class="attrib-button">
            <div class="col-md-12">
              <button (click)="onSubmit()"  class="btn btn-success marginright15" type="submit"
                id="btnsubmit">
                Submit
              </button>
              <button class="btn btn-secondary marginright15"   (click)="resetForm()">
                Cancel
              </button>
              
            </div>
          </div>
        </div>

      </div>

    </div>


  </div>


</section>