<section class="content-header sub-head ">

    <h1>
        Invoice-List

    </h1>
</section>
<div class=" areas">
    <section class="content sub-cat">
        <div class="box">
            <div class="box-header">
                <div class="row">
                    <div class="col-md-6">
                        <div class="search">
                            <form novalidate="" method="post"
                                class="d-flex my-search ng-untouched ng-pristine ng-valid">
                                <input #search_string (keyup)="Filter(search_string.value,'search-invoice')"
                                    id="SearchID" type="search" placeholder="Search" aria-label="Search"
                                    class="form-control me-2">
                                <button type="submit" class="btn btn-outline-success">
                                    <i class="fa fa-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="menu-filter">
                            <div class="full-half">
                                <select (change)="Filter($event.target.value,'payment-type')"
                                    class="form-control form-select form-select-solid fw-bolder mr-2 ng-untouched ng-pristine ng-valid"
                                    id="Pickup-Status11" ng-reflect-is-disabled="false" ng-reflect-model="2">
                                    <option disabled selected>Select Payment Type</option>
                                    <option [value]="true">Partial Payment</option>
                                    <option [value]="false">Full Payment</option>
                                </select>
                            </div>
                            <div class="full-half">
                                <select (change)="Filter($event.target.value,'pickup-status')"
                                    class="form-control form-select form-select-so lid fw-bolder mr-2 ng-untouched ng-pristine ng-valid"
                                    id="Pickup-Status11">
                                    <option disabled selected>Select Delivery Status</option>
                                    <option [value]="0">Delivered</option>
                                    <option [value]="1">Pending for pickup</option>
                                    <option [value]="2">Picked up</option>
                                </select>
                            </div>
                            <div class="date-range">
                                <input (change)="Filter($event.target.value,'from-date')" placeholder="From Date"
                                    name="Date-Filter" class="form-control" placeholder="Date from">
                            </div>
                            <div class="date-range">
                                <input (change)="Filter($event.target.value,'to-date')" placeholder="To Date"
                                    name="Date-Filter" class="form-control" placeholder="Date to">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row " style="margin: 0px;" *ngIf="InvoiceList.length>0;else elseblock;">
                <div class="col-md-12 mt-4">
                    <div class="inner-pad" #dataToExport>
                        <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Invoice&nbsp;Number</th>
                                    <th> Order&nbsp;No</th>
                                    <th>Customer&nbsp;Name</th>
                                    <th>Invoice&nbsp;Date</th>
                                    <th> Invoice&nbsp;Amount&nbsp;(AUD) </th>
                                    <th>Balance&nbsp;(AUD) </th>
                                    <th>Payment&nbsp;Status</th>
                                    <th>Delivery&nbsp;Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tr *ngFor="let item of InvoiceList|search:'INVOICE':searchString|paginate:PagincationConfig"
                                style="position: relative;">
                                <td><span style="cursor: pointer;">{{item.invoiceNumber}}</span></td>
                                <td>{{item.orderNumber}}</td>
                                <td [routerLink]="item.isGuestUser!==true?'/viewusers':null ">
                                    {{item.customerName===null?'-':item.customerName}}</td>
                                <td>{{item.invoiceDate|date:'dd/MMM/yyyy'}}</td>
                                <td>{{item.invoiceTotalAmount}}</td>
                                <td>{{item.balance}}</td>
                                <td>
                                    <div class="pay-status">

                                        <span>{{item.ispartialPayment?(item.isPartialPaymentComplete?'Partial(Completed)':'Partial'):'Complete'}}</span>
                                        <a *ngIf=" !item.isPartialPaymentComplete"
                                            (click)="GetInvoiceDetailByID(item.id)" data-toggle="tooltip"
                                            data-placement="bottom" title="Click to receive balance payment"
                                            class="color-sel"><i class="fa fa-plus-circle"></i>
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <select id="Pickup-Status{{item.id}}" (change)="OnChangePickup(item.id)"
                                        *ngIf="item.pickupStatus>0"
                                        class="form-control form-select form-select-solid fw-bolder "
                                        [(ngModel)]="item.pickupStatus">
                                        <option [disabled]="item.pickupStatus>1" [value]="1">Pending for pickup</option>
                                        <option [value]="2">Picked up</option>
                                    </select>
                                    <span *ngIf="item.pickupStatus===0">Delivered</span>
                                </td>
                                <td>
                                    <div class="d-flex action-view">
                                        <button
                                            (click)="DownLoadInvoice(item.invoiceNumber,item.balance>0?1:2,item.ispartialPayment)"
                                            class="btn btn-icon mr-2" type="submit" data-toggle="tooltip"
                                            data-placement="bottom" title="View Invoice">
                                            <i class="fa fa-eye" aria-hidden="true"> </i>
                                        </button>
                                        <button *ngIf="item.customerEmail" (click)="Send(item.id,'EMAIL')" class="btn btn-icon mr-2" type="submit"
                                            data-toggle="tooltip" data-placement="bottom" title="Send Mail">
                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                        </button>

                                        <button
                                            (click)="getInvoiceItemListByInvoiceID(item.id,item.ispartialPayment,item.isPartialPaymentComplete,item.orderNumber)"
                                            class="btn btn-icon mr-2" type="submit" data-toggle="tooltip"
                                            data-placement="bottom" title="Cancel Invoice" 
                                            >
                                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>

                                </td>

                            </tr>

                        </table>
                    </div></div>
                </div>
                <div class="area-cust" *ngIf="InvoiceList.length>10">
                    <div class="col-md-3">
                     

                    </div>
                    <div class="col-md-9 text-right">
                        <pagination-controls previousLabel="Prev" nextLabel="Next"
                            (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>

            <ng-template #elseblock>
                <div class="no-data">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <h4>No Data Found</h4>
                </div>
            </ng-template>


        </div>
    </section>
</div>



<div class="modal fade add-color" id="ReInvoiceModal" tabindex="-1" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog user-detial Complete-invoice-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-white">Complete Invoice Payment</h4>
                <button (click)="ResetForm()" type="button" class="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body addcolormain">
                <form [formGroup]="ReInvoicePaymentForm" class="p-0">
                    <div class="box-body">
                        <div class="row mt-2">

                            <div class="col-md-12 new-box">

                                <div class="d-flex mb-2 pay-section">
                                    <span>

                                        <label for="exampleInputEmail1">Cash:</label>
                                    </span>
                                    <span>
                                        <input (change)="OnChaneCashType('Cash-Paid')"
                                            (keypress)="keyPressNumbers($event)" formControlName="cashPaid"
                                            type="number" name="discountPercentage" id="Cash-Paid"
                                            placeholder="Enter Amount" class="form-control ">
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-12 new-box" id="strorecredit-box">
                                <div class="d-flex mb-2 pay-section">
                                    <span>

                                        <label for="inputState">Store&nbsp;Credit:</label>
                                    </span>
                                    <span class="d-flex justify-content-end">
                                        <input (change)="OnChaneCashType('Storecredit-Paid')"
                                            (keypress)="keyPressNumbers($event)" formControlName="storeCreditPaid"
                                            type="number" name="discountPercentage" placeholder="Enter Store Credit"
                                            id="Storecredit-Paid" class="form-control recevie-amt">
                                        <span class="fixed-amt avl-score"><span>Bal: {{StoreCredit}}</span></span>
                                    </span>


                                </div>
                            </div>
                            <div class="col-md-12 new-box">
                                <div class="d-flex mb-2 pay-section ">
                                    <span>

                                        <label for="exampleInputEmail1">Online&nbsp;Payment:</label>
                                    </span>
                                    <span class="d-flex justify-content-end">
                                        <input (change)="OnChaneCashType('Online-Paid')"
                                            (keypress)="keyPressNumbers($event)" formControlName="onlinePaid"
                                            type="number" name="discountPercentage" placeholder="Enter Amount"
                                            id="Online-Paid" class="form-control recevie-amt">
                                        <input type="number" name="discountPercentage" disabled readonly
                                            formControlName="Balance" class="form-control fixed-amt">
                                    </span>


                                </div>
                            </div>
                            <div class="col-md-12 new-box">
                                <div class="d-flex mb-2 pay-section">
                                    <span>

                                        <label for="exampleInputEmail1">Balance:</label>
                                    </span>
                                    <span>

                                        <input readonly type="number" name="discountPercentage"
                                            formControlName="Balance" placeholder="Enter Balance"
                                            class="form-control readonly-color">
                                    </span>

                                </div>
                            </div>
                            <div class="col-md-12  ">
                                <div class="d-flex justify-content-end mt-2">
                                    <a (click)="OnSubmit()" class="btn btn-blue mr-2" type="submit">
                                        Receive&nbsp;Payment
                                    </a>

                                    <button (click)="ResetForm()" class="btn btn-secondary">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>
</div>








<!-- Modal -->
<div class="modal fade" id="CancelInvoice" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl custom-refund">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header" style=" color: #fff; ">
                <h4 class="modal-title">Invoice Items</h4>
                <button type="button" class="close" (click)="coloseCancelInvoice()">&times;</button>

            </div>
            <div class="modal-body">
                <div class="row" style=" margin-bottom: 12px;">
                    <div class="col-md-8">
                        <div class="invoice-del">
                            <h4> <b>Invoice No:</b>&nbsp; {{invoiceDetail?.invoiceNumber}}</h4>
                            <h4> <b>Invoice Date:</b>&nbsp; {{invoiceDetail?.invoiceDate|date:'dd-MMM-yyyy'}}</h4>
                            <h4><b>Customer Name: </b>&nbsp; {{invoiceDetail?.customerName??'Cash'}}</h4>
                        </div>
                    </div>
                    <div class="col-md-4 all-cancel">
                        <div class="">
                            <button (click)="viewRefundForm('Full-Invoice',0,0)" type="button"
                                class="btn btn-primary waves-effect waves-light  me-2 ">Cancel Full Invoice</button>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-md-12 " style="margin-top: 12px;">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered ">

                                <thead>
                                    <tr>
                                        <th class="text-left">#</th>
                                        <th class="text-left">Item</th>
                                        <th class="text-center">Quantity</th>
                                        <th class="text-center">Discount (%)</th>
                                        <th class="text-center">Price (inclusive of tax)</th>
                                        <th class="text-center">Action</th>


                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let item of invoiceDetail?.itemList ; let i=index">

                                        <td class="text-center">{{item.itemCode}}</td>
                                        <td class="text-center">{{item.productName}}</td>
                                        <td class="text-center">{{item.itemQuantity}}</td>
                                        <td class="text-center">{{item.itemDiscount}}</td>
                                        <td class="text-center">{{item.itemPrice}}</td>

                                        <td>
                                            <button (click)="viewRefundForm('',item.itemPrice,item.invoiceItemID)"
                                                class="btn btn-icon mr-2" type="submit" title="Refund">
                                                <i class="fa fa-retweet" aria-hidden="true"></i>
                                            </button>
                                        </td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>

            </div>
            <div class="modal-footer">

                <!-- <button type="button" class="btn btn-primary waves-effect waves-light  me-2 ">Update Invoice</button> -->
                <button type="reset" class="btn btn-secondary waves-effect waves-light mb-2"
                    (click)="coloseCancelInvoice()">Cancel</button>

            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div id="reason" class="modal rea fade" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog   custom-payment-input">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header" style=" color: #fff; ">
                <h4 class="modal-title">Invoice cancellation </h4>
                <button type="button" class="close" (click)="resetReasonFormValue()">&times;</button>

            </div>
            <div class="modal-body">
                <div class="row" style=" margin-bottom: 12px;">
                    <div class="col-md-8">
                        <div class="invoice-del">
                            <h4> <b>Invoice No:</b>{{invoiceDetail?.invoiceNumber}}</h4>
                            <h4> <b>Invoice Date:</b>{{invoiceDetail?.invoiceDate|date:'dd-MMM-yyyy'}}</h4>
                            <h4><b>Customer Name: </b>{{invoiceDetail?.customerName??'Cash'}}</h4>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="Col-md-12">
                        <form [formGroup]="refundInvoiceForm">
                            <div class="reasons" style=" padding: 0px; ">
                                <div class="one">
                                    <label for="w3review">Reason for cancellation<span style="color: red;">*</span></label>

                                    <textarea formControlName="reason" id="w3review" name="w3review" rows="4" cols="80"
                                        placeholder="Enter Here">
                            </textarea>
                                    <div *ngIf="refundInvoiceForm.get('reason').touched" style="color: red;">
                                        <span *ngIf="refundInvoiceForm.get('reason')?.errors?.required">This field is
                                            required</span>
                                    </div>
                                </div>
                                <div class="two">
                                    <label for="w3review"> Payment Refund <strong>({{refundBalance}})<span id="refundable-amt" class="hide" >Refundable Amount &nbsp; ({{refundableAmount_P1}})</span></strong></label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <form [formGroup]="refundInvoiceForm">
                        <div class="col-md-6 d-flex">

                            <div class="info-data">
                                <H4 id="payment-title-1"> </H4>
                                <div class="cash">
                                    <ul>
                                        <li> Cash</li>
                                        <li>{{amountDetail1.cash}}</li>
                                        <li> <input formControlName="refundCash" (change)="OnChangeAmount('cash')"
                                                type="number" (keypress)="keyPressNumbers($event)" class="form-control">
                                        </li>
                                    </ul>
                                    <ul>
                                        <li> Online</li>
                                        <li>{{amountDetail1.online}}</li>
                                        <li> <input formControlName="refundOnline" (change)="OnChangeAmount('online')"
                                                type="number" (keypress)="keyPressNumbers($event)" class="form-control">
                                        </li>
                                    </ul>

                                    <ul class="hide" name="payment-storecreadit">
                                        <li> Store&nbsp;Credit</li>
                                        <li>{{amountDetail1.storeCredit}}</li>
                                        <li> <input formControlName="refundStoreCredit"
                                                (change)="OnChangeAmount('storecredit')" type="number" value=""
                                                (keypress)="keyPressNumbers($event)" class="form-control"></li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                        <div id="Payment-2" class="col-md-6  d-flex hide" style=" border-left: 1px dashed #bdd0d5; ">
                            <div class="info-data">
                                <H4> Partial Payment 2 </H4>
                                <div class="cash">
                                    <ul>
                                        <li> Cash</li>
                                        <li>{{amountDetail2.cash}}</li>
                                        <li> <input formControlName="refundCash1" (change)="OnChangeAmount('cash1')"
                                                (keypress)="keyPressNumbers($event)" type="number" class="form-control"></li>
                                    </ul>
                                    <ul>
                                        <li> Online</li>
                                        <li>{{amountDetail2.online}}</li>
                                        <li> <input formControlName="refundOnline1" (change)="OnChangeAmount('online1')"
                                                (keypress)="keyPressNumbers($event)" type="number" class="form-control"></li>
                                    </ul>
                        
                                    <ul class="hide" name="payment-storecreadit">
                                        <li> Store&nbsp;Credit</li>
                                        <li> {{amountDetail2.storeCredit}}</li>
                                        <li> <input formControlName="refundStoreCredit1" (change)="OnChangeAmount('storecredit1')"
                                                (keypress)="keyPressNumbers($event)" type="number" class="form-control"></li>
                                    </ul>
                        
                                </div>
                            </div>

                        </div>
                    </form>

                    <div class="modal-footer">
                        <div class="col-d-12">
                            <div class="iagrree">
                                <input (change)="validateCancellation($event.target.checked)" type="checkbox"
                                    id="agreement" name="vehicle1" value="agreement">
                                <label for="agreement"> I hereby acknowledge my agreement to all terms and conditions outlined and wish to cancel the invoice.</label><br>
                            </div>
                        </div>
                        <div class="col-md-12 btnss">
                            <button (click)="manageRefund()" id="refund-submit"
                                style="pointer-events: none;    filter: contrast(0.5);" type="button"
                                class="btn btn-primary waves-effect waves-light  me-2 ">Submit
                            </button>
                            <button type="reset" class="btn btn-secondary waves-effect waves-light mb-2"
                                (click)="resetReasonFormValue()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>