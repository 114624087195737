<section class="content-header">
  <h1>
    Manage Product

  </h1>

</section>




<section class="">
  <div class="row "> <!-- left column -->
    <div class="col-md-12">
      <div class="box box-danger">


        <div class="row">
          <div class="col-md-12">
            <div class="tabbs">

              <ul class="nav nav-tabs">
                <li id="productinfo" class="active"><a data-toggle="tab" href="#home"> Product Information</a></li>
                <li id="productitem" (click)="NextTab()"><a>Product Item</a></li>

              </ul>

              <div class="tab-content">
                <div id="home" class="tab-pane fade in active">
                 
                    <div class="box-body">

                      <form [formGroup]="form1">
                      <div class="row">
                        <!-- <div class="form-group col-md-6">
                          <button class="btn btn-info hide" id="EditBTN">Edit</button>
                        </div> -->

                        <div class="form-group col-md-6">
                          <label for="exampleInputEmail1">Product Name <span style="color: red;">*</span></label>
                          <input (change)="onChangeInput($event.target.value)" formControlName="productName" id="ProductName"
                            class="readonlyfield form-control validation-field " placeholder="Enter ProductName"
                            type="text" >

                          <span class="validation-error" *ngIf="form1controls.productName?.touched">
                            <span *ngIf="form1controls.productName?.errors?.required">This Field Is Required</span>
                            <span *ngIf="form1controls.productName?.errors?.duplicateProductName">Product Already Exists
                              With Same Name</span>
                          </span>

                        </div>
                        <div class="form-group col-md-6">
                          <label for="exampleInputEmail1">Select Category <span style="color: red;">*</span></label>
                          <ng-select  id="productCategoryID" placeholder="Select Category"
                            [items]="DropdownCategoryList" bindLabel="categoryName" bindValue="id"
                            formControlName="productCategoryID" (change)="OnChangeCategory($event.id,'Assignment-Single-Category')"></ng-select>
                          <span class="validation-error" *ngIf="form1controls.productCategoryID?.touched">
                            <span *ngIf="form1controls.productCategoryID?.errors?.required">This Field Is
                              Required</span>
                          </span>

                        </div>
                      </div>

                      <div class="row">

                        <div class="form-group col-md-6">
                          <label for="exampleInputEmail1">Select Category Level 1 <span
                              style="color: red;">*</span></label>
                          <ng-select  class="readonlyfield"
                            placeholder="Select Category Level1"
                            (change)="OnChangeSubCategory($event.id,'Assignment-Single-Category')" [items]="DropdownSubCategoryList"
                            bindLabel="subCategoryName" bindValue="id"
                            formControlName="productSubCategoryID"></ng-select>
                          <span class="validation-error" *ngIf="form1controls.productSubCategoryID?.touched">
                            <span *ngIf="form1controls.productSubCategoryID?.errors?.required">This Field Is
                              Required</span>
                          </span>

                        </div>
                        <div class="form-group col-md-6">
                          <label for="exampleInputEmail1">Select Category Level 2 </label>
                          <ng-select  class="readonlyfield"
                            placeholder="Select Category Level2" [items]="DropdownSubTwoCategoryList"
                            bindLabel="sub2CategoryName" bindValue="id"
                            formControlName="productSub2CategoryID"></ng-select>


                          <span class="validation-error" *ngIf="form1controls.productSub2CategoryID?.touched">
                            <span *ngIf="form1controls.productSub2CategoryID?.errors?.required">This Field Is
                              Required</span>
                          </span>

                        </div>
                      </div>

                      <div class="row">

                        <div class="form-group col-md-6 ">
                          <label for="relatedProducts">Related Products</label>
                          <ng-select placeholder="Select Products" formControlName="relatedProducts"
                            [items]="listProductDropdown" [multiple]="true" bindLabel="productName"
                            bindValue="productID" name="relatedProducts">
                          </ng-select>

                        </div>

                        <div class="form-group col-md-6">
                          <label for="searchTag">Search Tag <span style="color: red;">*</span></label>
                          <input formControlName="searchTag" id="searchTag"
                            class="form-control validation-field readonlyfield1" placeholder="Enter Search Tag"
                            type="text">
                          <span class="validation-error" *ngIf="form1controls.searchTag?.touched">
                            <span *ngIf="form1controls.searchTag.errors?.required">This Field Is Required</span>
                          </span>

                        </div>

                      </div>


                      <div class="row">


                        <div class="form-group col-md-6">
                          <label for="additionalInformation"> Shipping Charges <span
                              style="color: red;">*</span></label>
                          <!-- <textarea formControlName="additionalInformation" id="additionalInformation"
                            class="form-control readonlyfield1"
                            placeholder="Enter Product Additional Information"></textarea> -->
                          <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="additionalInformation">
                          </ckeditor>
                          <div>
                            <span class="validation-error" *ngIf="form1controls.additionalInformation?.touched">
                              <span *ngIf="form1controls.additionalInformation?.errors?.required">This Field Is
                                Required</span>
                            </span>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="email-b-f"> Description <span style="color: red;">*</span></label>

                          <!-- <textarea formControlName="description" id="description" class="form-control readonlyfield1"
                            placeholder="Enter Product Description"></textarea> -->
                          <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="description"></ckeditor>
                          <div>
                            <span class="validation-error" *ngIf="form1controls.description?.touched">
                              <span *ngIf="form1controls.description?.errors?.required">This Field Is Required</span>
                            </span>
                          </div>
                        </div>





                      </div>


                      <div class="row">
                        <div class="form-group col-md-6">
                          <label for="email-b-f">Small Description <span style="color: red;">*</span></label>

                          <textarea formControlName="smallDescription" id="smallDescription"
                            class="form-control readonlyfield1"
                            placeholder="Enter Product Small Description"></textarea>
                          <!-- <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="description"></ckeditor> -->
                          <div>
                            <span class="validation-error" *ngIf="form1controls.smallDescription?.touched">
                              <span *ngIf="form1controls.smallDescription?.errors?.required">This Field Is
                                Required</span>
                              <span *ngIf="form1controls.smallDescription?.errors?.maxlength">The description should not
                                exceed {{ form1controls.smallDescription?.errors.maxlength.requiredLength }}
                                characters.</span>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-6 d-flex align-items-center">
                          <div class="product-online">
                            <div class="shipping-type">
                              <div class="ship-1">
                                <input (change)="onChangeProductType($event.target.id)" formControlName="isOnlineProduct" type="checkbox" id="online" name="online">
                                <label for="online"> Is online</label><br>
                              </div>
                            </div>
                            <div class="shipping-type">
                              <div class="ship-1">
                                <input (change)="onChangeProductType($event.target.id)" formControlName="isOfflineProduct" type="checkbox" id="offline" name="online">
                                <label for="offline"> Is offline</label><br>
                              </div>
                            </div>
                          </div>
                         
                        </div>

                      </div>
                    </form>
                    
                    <app-seo-management></app-seo-management>

                     <form [formGroup]="assignmentCategoryForm" >
                      <div class="row" class="AssociateRow row">
                        <div class="col-md-12">
                          <div class="shipping-type">
                            <div class="ship-1" style="display: inline;">
                              <input (change)="onChangeAssignment($event.target.checked)"  type="checkbox" id="AssociateMultipleCategory" name="AssociateMultipleCategory" >
                              <label for="AssociateMultipleCategory" > Associate Multiple Category</label><br>
                            </div>
                          </div>
                        </div>
                        
                      </div>

                      <div class="row hide" id="toggleDiv" >
                        <div class="col-md-3">
                          <label for="exampleInputEmail1">Select Category <span style="color: red;">*</span></label>
                          <ng-select  id="productCategoryID" placeholder="Select Category"
                            [items]="DropdownCategoryList" bindLabel="categoryName" bindValue="id"
                            formControlName="categoryID" (change)="OnChangeCategory($event.id,'Assignment-Multiple-Category')">
                          </ng-select>
                        </div>
                        <div class="col-md-3">
                          <label for="exampleInputEmail1">Select Category Level 1 <span style="color: red;">*</span></label>
                          <ng-select  id="productCategoryID-Level1" placeholder="Select Category Level1"
                            [items]="AssignmentDropdwonSubCategoryList" bindLabel="subCategoryName" bindValue="id"
                            formControlName="subCategoryID" (change)="OnChangeSubCategory($event.id,'Assignment-Multiple-Category')">
                          </ng-select>
                        </div>
                        <div class="col-md-3">
                          <label for="exampleInputEmail1">Select Category Level 2 </label>
                          <ng-select  id="productCategoryID-Level2" placeholder="Select Category Level2"
                          [items]="AssignmentDropdwonSubTwoCategoryList" bindLabel="sub2CategoryName" bindValue="id"
                          formControlName="subTwoCategoryID">
                        </ng-select>
                        </div>
                        <div class="col-md-3" style="margin-top: 30px;">
                          <button class="btn btn-success readonlyfield1" type="submit" id="AddMultiply" (click)="assignMultipleCategories()"
                          >
                          Add
                        </button>
                          <button  class="btn btn-secondary marginright15" id="CancelMultiply">Cancel</button>
                         
                        </div>

                        <div class="col-md-12" style="margin-top: 25px;" *ngIf="assignGridList.length>0" >
                          <div class="table-responsive">
                            <table class="table text-center table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>Category</th>
                                  <th>Category&nbsp;Level&nbsp;1</th>
                                  <th>Category&nbsp;Level&nbsp;2</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of assignGridList; let i=index">
                                  <td>{{item.categoryName}}</td>
                                <td>{{item.subCategoryName}}</td>
                                <td>{{item.subTwoCategoryName}}</td>
                                <td>
                                  <button (click)="onRemoveAssignCategory(i)"  type="button"
                                  class="btn btn-icon " aria-label="Product details">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                </td>
                                </tr>
                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </form>
                    </div>
                  

                  <div class="row">

                    <div class="col-md-12 mt-2 text-right custom-button">
                      <button class="btn btn-success readonlyfield1" type="submit" id="submitForm1"
                        (click)="SubmitForm1()">
                        Submit
                      </button>
                      <button routerLink="/productlist" class="btn btn-secondary marginright15" id="">Cancel</button>
                      


                    </div>
                  </div>
             
                </div>





                <div id="item-menu" class="tab-pane fade ">


                  <div class="row">
                    <div class="col-md-12">
                      <div class="atri-detial">
                        <div class="table-responsive">
                          <table id="example1" class="table text-center table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>S.NO</th>
                                <th>Image</th>
                                <th>Item Bar Code</th>
                                <th>Colour</th>
                                <th>Size</th>
                                <th>Price&nbsp;(AUD)</th>
                                <th>Length</th>
                                <th>Width</th>
                                <th> Height</th>
                                <th>Unit</th>
                                <th>Quantity</th>
                                <th>Min-Quantity</th>
                                <th>Discount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr *ngFor="let item of tempVariationList let i=index">
                                <td>{{i+1}}</td>
                                <td _ngcontent-psk-c23="" style="width: 115px;">
                                  <img
                                    src="{{rootURL}}{{item.coverImage?.imagePath}}{{item.coverImage?.coverImageName}}"
                                    class="table-prodcut-img">
                                </td>
                                <td>{{item.productItemCode}}</td>
                                <td>
                                  <div class="color-box-main">
                                    <div class="color-box" [ngStyle]="{ 'background-color': item.colorCode }">
                                    </div>
                                  </div>

                                  <!-- {{item.colorCode}} -->
                                </td>
                                <td> {{item.sizename}}</td>
                                <td> {{item.price}}</td>
                                <td> {{item.length!==null?item.length:'-'}}</td>
                                <td>{{item.width!==null?item.width:'-'}}</td>
                                <td> {{item.height!==null?item.height:'-'}}</td>
                                <td> {{item.unit!==null?item.unit:'-'}}</td>
                                <td> {{item.stock}}</td>
                                <td> {{item.minStock}}</td>
                                <td>{{item?.discountTitle!==undefined?item.discountTitle:'-'}} </td>



                                <td>
                                  <button class="btn btn-icon m-1 p-1 mr-2">
                                    <input type="checkbox" id="ActiveInactive{{item.id}}" name="isActive"
                                      class="marginright10 "
                                      (change)="ActivedeactiveProductDetail(item.id,item.isActive)"
                                      [checked]="item.isActive">
                                  </button>
                                  
                                  <button (click)="EditVariation(item,i)" type="button"
                                    class="btn btn-icon " aria-label="Product details">
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                  </button>

                                

                                </td>

                              </tr>


                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row attri-button">
                    <div class="col-md-12">
                      <button class="btn btn-success marginright15" type="submit" id="" (click)="OpenVariationModal()">
                        <i class="fa fa-plus" style="margin-right: 5px;"></i> Add Item
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mt-2 text-right custom-button">
                      <button routerLink="/productlist" class="btn btn-success ">
                        Back To List
                      </button>

                    </div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>



















      </div>
    </div>


  </div>




</section>



<div class="modal fade add-color" id="largeModal" tabindex="-1">
  <div class="modal-dialog user-detial">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-white">Add Colour</h4>
        <button type="button" class="close" (click)="HideModel('color')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body addcolormain">
        <form [formGroup]="ColorForm">
          <div class="box-body">
            <div class="radioboxmain beforeafternone">
              <div class="">

                <div class="col-md-4 padd-left">
                  <label for="exampleInputEmail1">Name<span class="text-danger">*</span></label>
                  <input type="text" id="Host" formControlName="name" name="name" placeholder="Enter Colour Name"
                    class="form-control ng-pristine ng-invalid ng-touched">
                  <span class="validation-error" *ngIf="colorformcontrols.name.touched">
                    <span *ngIf="colorformcontrols.name.errors?.required">This Field Is Required</span>
                  </span>
                </div>

                <div class="col-md-3 padd-left">
                  <label for="exampleInputEmail1">Choose&nbsp;Colour</label>
                  <input type="color" id="Host" formControlName="colorCode" name="colorCode" placeholder="Enter Name"
                    class="form-control color-plate">
                  <span class="validation-error" *ngIf="colorformcontrols.colorCode.touched">
                    <span *ngIf="colorformcontrols.colorCode.errors?.required">This Field Is Required</span>
                  </span>

                </div>

                <div class="col-md-5 mt-5 padd-left buttons">
                  <a class="btn btn-success" type="submit" (click)="ManageMasterColor()">
                    Submit
                  </a>
                  <button (click)="HideModel('color')" class="btn btn-secondary" type="button">
                    Cancel
                  </button>
                </div>


              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>



<div class="modal fade add-color" id="largeModal1" tabindex="-1" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog user-detial">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-white">Add Size</h4>
        <button type="button" class="close" (click)="HideModel('size')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body addcolormain">
        <form [formGroup]="SizeForm" style="padding: 0px;">
          <div class="radioboxmain beforeafternone">
            <div class="row">
              <div class=" col-md-12">

                <div class="col-md-6 padd-left">
                  <label for="exampleInputEmail1">Name<span class="text-danger">*</span></label>
                  <input type="text" id="Host" formControlName="name" name="name" placeholder="Enter Name"
                    class="form-control ng-pristine ng-invalid ng-touched">
                  <span class="validation-error" *ngIf="sizeformcontrols.name.touched">
                    <span *ngIf="sizeformcontrols.name.errors?.required">This Field Is Required</span>
                  </span>
                </div>
                <div class="col-md-6 mt-5 padd-left">
                  <div class="mt-3">
                    <a class="btn btn-success" type="submit" (click)="manageSize()">
                      Submit
                    </a>
                    <button (click)="HideModel('size')" class="btn btn-secondary" type="button">
                      Cancel
                    </button>
                  </div>

                </div>


              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="largeModal3" tabindex="-1" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog user-detial">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-white">Add Discount</h4>
        <button type="button" class="close" (click)="HideModel('discount')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body addcolormain">
        <form [formGroup]="DiscountForm" class="p-0">
          <div class="box-body nomargin">

            <div class=" row">

              <div class="col-md-4 mb-2">
                <label for="exampleInputEmail1">Percentage<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="discountPercentage" name="discountPercentage"
                  placeholder="Enter Name" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.discountPercentage.touched">
                  <span *ngIf="input.discountPercentage.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-md-4 mb-2">
                <label for="exampleInputEmail1">Title<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="discountTitle" name="discountTitle"
                  placeholder="Enter Name" class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.discountTitle.touched">
                  <span *ngIf="input.discountTitle.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-md-4 mb-2">
                <label for="exampleInputEmail1">Description<span class="text-danger">*</span></label>
                <input type="text" id="Host" formControlName="discountDescription" name="discountDescription"
                  placeholder="Enter Name" class="form-control ng-pristine ng-invalid ng-touched">

                <span class="validation-error" *ngIf="input.discountDescription.touched">
                  <span *ngIf="input.discountDescription.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-md-4 mb-2">
                <label for="exampleInputEmail1">Start Date<span class="text-danger">*</span></label>
                <input (change)="OnChangeStartDate($event.target.value)" type="text" id="startdate"
                  formControlName="startDate" name="discountdate" placeholder="Enter Name"
                  class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.startDate.touched">
                  <span *ngIf="input.startDate.errors?.required">This Field Is Required</span>
                </span>
              </div>
              <div class="col-md-4 mb-2">
                <label for="exampleInputEmail1">End Date<span class="text-danger">*</span></label>
                <input type="text" id="enddate" formControlName="endDate" name="discountdate" placeholder="Enter Name"
                  class="form-control ng-pristine ng-invalid ng-touched">
                <span class="validation-error" *ngIf="input.endDate.touched">
                  <span *ngIf="input.endDate.errors?.required">This Field Is Required</span>
                </span>
              </div>

              <div class="col-md-12  ">
                <div class="d-flex justify-content-end">
                  <a class="btn btn-success" type="submit" (click)="AddDiscount()">
                    Submit
                  </a>
                  <button class="btn btn-secondary" (click)="ResetDiscountForm()" data-dismiss="modal">
                    Cancel
                  </button>


                </div>

              </div>


            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="productItemModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog  modal-lg-custom" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Item</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="form2">
            <div class="product-attributes">

              <div class="form-group col-md-6">

                <div class="row dimensionmain new-main">
                  <div class="col-md-4">
                    <label for="color"> Colour <span style="color: red;">*</span></label><a data-toggle="modal"
                      data-target="#largeModal" data-backdrop="static" data-keyboard="false" class="color-sel"><i
                        class="fa fa-plus-circle"></i></a>

                    <ng-select [items]="colorList" bindValue="id" bindLabel="name" formControlName="colorID"
                      placeholder="Select Colour">
                    </ng-select>

                    <span class="validation-error" *ngIf="form2controls.colorID?.touched">
                      <span *ngIf="form2controls.colorID?.errors?.required">This Field Is
                        Required</span>
                    </span>
                  </div>
                  <div class="col-md-4">
                    <label for="color"> Size <span style="color: red;">*</span></label><a (click)="OpenSizeModal()"
                      data-backdrop="static" data-keyboard="false" class="color-sel"><i
                        class="fa fa-plus-circle"></i></a>

                    <ng-select [items]="sizelist" bindValue="id" bindLabel="name" formControlName="sizeID"
                      placeholder="Select Size">
                    </ng-select>

                    <span class="validation-error" *ngIf="form2controls.sizeID?.touched">
                      <span *ngIf="form2controls.sizeID?.errors?.required">This Field Is
                        Required</span>
                    </span>

                  </div>
                  <div class="col-md-4">
                    <label for="price"> Price <span style="color: red;">*</span></label>
                    <input (keypress)="keyPressNumbers($event)" formControlName="price" id="price"
                      class="form-control validation-field" placeholder="Enter Product Price" type="text">
                    <span class="validation-error" *ngIf="form2controls.price?.touched">
                      <span *ngIf="form2controls.price?.errors?.required">This Field Is
                        Required</span>
                    </span>


                  </div>
                </div>




                <div _ngcontent-tvx-c23="" class="row">

                  <div class=" dimensionmain">

                    <div class="col-md-3">
                      <label>Unit</label>
                      <div class="">
                        <select formControlName="unit" class="form-control">
                          <option [value]="null" disabled> Unit</option>
                          <option>Centimeters</option>
                          <option>Inches</option>
                          <option>Meters</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <label>Length </label>
                      <input formControlName="length" name="length" type="number" id="searchTag" placeholder="Length"
                        class="form-control validation-field" />
                    </div>


                    <div class="col-md-3">
                      <label>Width </label>
                      <input formControlName="width" name="width" type="number" id="searchTag" placeholder="Width"
                        class="form-control validation-field" />
                    </div>


                    <div class="col-md-3">
                      <label>Height</label>
                      <input formControlName="height" name="height" type="number" id="searchTag" placeholder="Height"
                        class="form-control validation-field" />
                    </div>




                  </div>



                </div>

                <div class="row">

                  <div class="col-md-6 Quantityy">
                    <label for="additionalInformation">Quantity<span style="color: red;">*</span></label>
                    <input name="stock" (keypress)="keyPressNumbers($event)" formControlName="stock" id="stock"
                      class="form-control first" placeholder="Quantity">
                    <div>
                      <span class="validation-error"
                        *ngIf="form2controls.stock?.touched || form2controls.minStock?.touched">
                        <span
                          *ngIf="form2controls.stock?.errors?.required || form2controls.minStock?.errors?.required">This
                          Field Is Required</span>

                      </span>
                    </div>
                    <div class="min-Quantity">

                      <label for="additionalInformation">Min-Quantity<span style="color: red;">*</span></label>
                      <input name="stock" (keypress)="keyPressNumbers($event)" formControlName="minStock" id="min-stock"
                        class="form-control second" placeholder="Quantity">
                      <div>
                      </div>

                    </div>

                  </div>

                  <div class="col-md-6">

                    <label for="additionalInformation">Discount </label>

                    <select name="Discount" id="discount-area" class="form-control"
                      (change)="OnApplyDiscount($event.target.value)">
                      <option value="0">Not-applicable</option>
                      <option value="1">Applicable</option>

                    </select>
                  </div>


                  <div class="form-group col-md-6 hide type" id="HideShowDiscount" tyle="  margin-bottom: 0px;   ">
                    <label for="exampleInputEmail1"> Discount Type <span style="color: red;">*</span></label><a
                      data-toggle="modal" data-target="#largeModal3" data-backdrop="static" data-keyboard="false"
                      class="color-sel"><i class="fa fa-plus-circle"></i></a>

                    <ng-select [items]="discountlist" bindValue="id" bindLabel="discountTitle"
                      formControlName="discountID" placeholder="Select Discount">
                    </ng-select>

                    <span class="validation-error" *ngIf="form2controls.discountID?.touched">
                      <span *ngIf="form2controls.discountID?.errors?.required">This Field Is
                        Required</span>
                    </span>


                  </div>


                  <div class="col-md-6">
                    <label>Item Bar Code <span style="color: red;">*</span></label>
                    <input formControlName="itemBarCode" name="itemBarCode"
                      type="text" id="itemBarCode" placeholder="Enter Bar Code" class="form-control validation-field" />
                    <span class="validation-error" *ngIf="form2controls.itemBarCode?.touched">
                      <span *ngIf="form2controls.itemBarCode?.errors?.required">This Field Is
                        Required</span>
                      <span *ngIf="form2controls.itemBarCode?.errors?.duplicateBarCode">Item Barcode
                        Already Exists</span>

                    </span>
                  </div>






                </div>
              </div>
              <div class="col-md-6 img-pre-div">
                <div class="col-md-12">
                  <div class="form-group col-md-12 nopadding">
                    <label for="email-b-f"> Product Image <span style="color: red;">*</span></label>
                    <input type="file" multiple (change)="onFileChanged($event)" accept="image/*" id="largeImage"
                      class="form-control">
                    <span class="validation-error" *ngIf="form2controls.tempfilevalidation?.touched">
                      <span *ngIf="form2controls.tempfilevalidation.errors?.required">This Field Is
                        Required</span>
                    </span>
                  </div>
                  <div class="default-img">
                    <i for="largeImage" class="fa fa-picture-o" aria-hidden="true"></i>

                  </div>
                  <div class="col-md-12 nopadding img-preview ">
                    <div class="col-md-8 less-pad" *ngIf="CoverImagePreview">
                      <div class="image-preview mb-3 ">
                        <ul class="imagepreview nomargin nopadding fullwidthheight">
                          <li class="nomargin fullwidthheight">
                            <img [src]="CoverImagePreview" class="fullwidthheight" />
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-md-4 paddinglr5 img-preview ">
                      <div class="area-new">
                        <div class="main-aear" *ngFor="let image of previewUrls ;let i=index">
                          <label for="test{{i}}" class="bannerimagemain">
                            <div class="bannerimage">
                              <img [src]="image" class="fullwidth fullheight">
                            </div>
                            <div class="radiobox">

                              <input class="" (change)="OnSelectCoverImage($event.target.value)" id="test{{i}}"
                                type="radio" name="test" [value]="i" [checked]="i===tempCoverImageIndex">
                            </div>

                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


            </div>
          </form>

        </div>



        <div class="row attrib-button">
          <div class="col-md-12">
            <button class="btn btn-secondary marginright15" type="submit" id="" (click)="ResetForm2()">
              Cancel
            </button>
            <button (click)="SubmitForm2()" class="btn btn-success marginright15" type="submit" id="">
              Submit
            </button>
          </div>
        </div>




      </div>

    </div>
  </div>
</div>