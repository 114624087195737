import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { OrdersService } from '../orders/orders.service';
import { ViewReturnOrderService } from './view-return-order.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-view-return-order',
  templateUrl: './view-return-order.component.html',
  styleUrls: ['./view-return-order.component.css']
})
export class ViewReturnOrderComponent {
  readonly rootUrl = environment.baseUrl.slice(0, -3);
  OrderID: any;
  OrderNO:string;
  billingAddress: any;
  deliveryAddress: any;
  CustomerDetails: any;
  orders: any;
  return: any;
  pickUpSchedule: any;
  Status: any=[];
  order: any;

  public ReturStatusForm:any;
  returnOrderID: any;
  constructor(private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private appservice: AppService,
    private orderservice: OrdersService,
    private router: Router,private service : ViewReturnOrderService, fb: UntypedFormBuilder) {

      this.ReturStatusForm = fb.group({
        id:[0],
        orderDetailID: [''],
        statusID: ['', Validators.required],
        statusChangedBy: ['', Validators.required],
        
      });
    }


  ngOnInit(): void {
    this.GetStatus();
    this.route.queryParamMap.subscribe((params: any) => {
      let EID = params.get('Eid');
      if(EID){
        EID = this.appservice.decryptValue(EID);
        this.OrderID = EID;
        this.getReceviedOrderByID();
        setTimeout(() => {
          this.getOrderByID();
        }, 500);
        
      }
    });
  }



  getReceviedOrderByID() {
    debugger
    this.spinner.show();
    this.orderservice.GetOrderDeatilByOrderID(this.OrderID).subscribe(data => {
      if (data.message === "Success") {
        this.orders=data.result;
        this.returnOrderID=data.result.returnOrderID;
        this.billingAddress = data.result.billingAddress;
        this.deliveryAddress = data.result.deliveryAddress;
        this.CustomerDetails = data.result.customerDetails;
      };
      setTimeout(() => {
        $("[name='deleveryDate'").flatpickr({
          dateFormat: 'd-M-Y',
          minDate: new Date()
        });
        this.spinner.hide();
      }, 100);
    })
  }


  getOrderByID() {
    debugger
    this.service.GetReturnOrderID(this.OrderID).subscribe(data => {
      if (data.message === "Success") {
        this.order=data.result;
        this.pickUpSchedule=data.result.pickUpSchedule;

        console.log('return',this.order)
      };
    })
  }

  GetStatus() {
    debugger
    this.service.getStatusList().subscribe(data => {
      if (data.message === "Success") {
        this.Status = data.result;

        console.log('Status',this.Status)
      };
    })
  }



  SelectStatus(OrderNO: any, StatusId: number) {
    debugger
    Swal.fire({
      // title: 'Are you sure?',
      title: 'Are you sure you want to change the status of the order?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel'

    }).then((result) => {
      if (result.value) {
    const orderDetailID = OrderNO; 
    const statusID = StatusId;

   
    var user = JSON.parse(localStorage.getItem('userInfo'));
    var users = user.userId;
    const userIDS = this.appservice.decryptValue(users);
    

    this.ReturStatusForm.patchValue({
      id:this.returnOrderID,
      orderDetailID: orderDetailID,
      statusID: statusID,
      statusChangedBy : userIDS
  });

        this.service.ManageReturnOrder(this.ReturStatusForm.value).subscribe(res => {
          if (res.message === 'Success') {
            // this.getReceviedOrderByID();
            this.toastr.success('Status has been changed successfully');
          } else {
            this.toastr.error(res.message);
          }
        }

        );

      }}
    );
    
  }




}
