import { UsertypesService } from './../usertypes/usertypes.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UserPermissionService } from '../user-permission/user-permission.service';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { settings } from 'cluster';
declare var $: any;

@Component({
  selector: 'app-role-based-permission',
  templateUrl: './role-based-permission.component.html',
  styleUrls: ['./role-based-permission.component.css']
})
export class RoleBasedPermissionComponent implements OnInit {
  UserTypePermission: any;
  data: any;
  IsMenuPresent: boolean;
  StaffList: any;

  constructor(private fb: FormBuilder, private toastr: ToastrService, private UserService: UserPermissionService,private spinner:NgxSpinnerService) {
    {

      this.UserTypePermission = this.fb.group({

        UserTypeID: ['', Validators.required],
        ClientID: ['']
      });

    }
  }

  ngOnInit(): void {
    this.GetUserTypes();
  }
  treeview(obj: any) {

    this.data = {};
    this.data.isAllSelected = false;
    this.data.isAllCollapsed = false;


    this.data.ParentChildchecklist = obj;

  }


  parentCheck(parentObj: any) {
    for (var i = 0; i < parentObj.childList.length; i++) {
      parentObj.childList[i].isSelected = parentObj.isSelected;
      parentObj.childList[i].IsCustomized = parentObj.isSelected;
      for (var j = 0; j < parentObj.childList[i].nestedchildList.length; j++) {
        parentObj.childList[i].nestedchildList[j].isSelected = parentObj.childList[i].isSelected;
        parentObj.childList[i].nestedchildList[j].IsCustomized = parentObj.childList[i].isSelected;
      }
    }
    parentObj.IsCustomized = parentObj.isSelected;

  }

  //Click event on child checkbox  
  childCheck(parentObj: any, childObj: any, ID: any) {
    parentObj.isSelected = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })
    parentObj.IsCustomized = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })


    for (let i = 0; i < childObj.length; i++) {
      if (childObj[i].id == ID) {
        childObj[i].IsCustomized = childObj[i].isSelected;
      }

    }
    for (var i = 0; i < childObj.length; i++) {

      for (var j = 0; j < childObj[i].nestedchildList.length; j++) {
        childObj[i].nestedchildList[j].isSelected = childObj[i].isSelected;
        childObj[i].nestedchildList[j].IsCustomized = childObj[i].isSelected;
      }

    }
    childObj.IsCustomized = childObj.isSelected;


  }
  ;

  nestedchildCheck(parentObj: any, childObj: any, ID: any) {
    parentObj.isSelected = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })
    parentObj.IsCustomized = childObj.some(function (itemChild: any) {

      return itemChild.isSelected == true;
    })


    for (let i = 0; i < childObj.length; i++) {
      if (childObj[i].id == ID) {
        childObj[i].IsCustomized = childObj[i].isSelected;
      }

    }
    for (var i = 0; i < childObj.length; i++) {

      for (var j = 0; j < childObj[i].nestedchildList.length; j++) {
        childObj[i].nestedchildList[j].isSelected = childObj[i].isSelected;
        childObj[i].nestedchildList[j].IsCustomized = childObj[i].isSelected;
      }

    }
    childObj.IsCustomized = childObj.isSelected;


  }
  //Click event on master select
  selectUnselectAll(obj: any) {
    obj.isAllSelected = !obj.isAllSelected;
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isSelected = obj.isAllSelected;
      for (var j = 0; j < obj.ParentChildchecklist[i].childList.length; j++) {
        obj.ParentChildchecklist[i].childList[j].isSelected = obj.isAllSelected;
      }
    }
  }

  //Expand/Collapse event on each parent
  expandCollapse(obj: any) {
    obj.isClosed = !obj.isClosed;
  }

  //Master expand/ collapse event
  expandCollapseAll(obj: any) {
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isClosed = !obj.isAllCollapsed;
    }
    obj.isAllCollapsed = !obj.isAllCollapsed;
  }

  //Just to show updated JSON object on view
  stringify(obj: any) {
    return JSON.stringify(obj);
  }

  BindUserTypePermission() {
    this.spinner.show();
    var UserID = $("#BindUserTypePermission").val();
    this.UserService.getUserTypePermissionByUserTypeID(UserID).subscribe(data => {
      if (data.message == "ok") {
        this.treeview(data.result);
        this.IsMenuPresent = true;
      }
      setTimeout(() => {
        this.spinner.hide();
      },500);
    
    },err=>{
      this.spinner.hide();
    })
  }
  AssignConfirmation() {
    var UserID = $("#BindUserTypePermission").val();
    if (UserID == "") {
      this.toastr.warning("Please Select Role")
    } else {
      Swal.fire({
        title: "Existing users will be impacted if the role-based permissions are changed. Are you sure you want to proceed?",
        showCancelButton: true,
        icon: 'question',
        confirmButtonText: 'Confirm',
        // denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.AssignPermission();

        } else {

        }
      })
    }

  }

  AssignPermission() {
    var UserID = $("#BindUserTypePermission").val();
    if (UserID == "") {
      this.toastr.warning("Please Select Role")
    }
    else {
    this.spinner.show();
      this.UserService.AssignPermissionToUserType(UserID, this.data.ParentChildchecklist).subscribe(data => {
        if (data.message == "ok") {
          Swal.fire('Saved!', '', 'success')
          this.BindUserTypePermission();
        }
        else { this.toastr.warning(data.Message); }
        this.spinner.hide();
      });
    }
  }
  Reset() {
    this.UserTypePermission.reset();
    this.IsMenuPresent = false
  }

  GetUserTypes() {
    this.spinner.show();
    this.UserService.GetUserTypesList().subscribe(data => {
      if (data.message == "Success") {
        const typelist = data.result.filter(x => x.isActive);
        this.StaffList = typelist;
      }
      else { this.toastr.warning(data.Message); }
      setTimeout(() => {
        this.spinner.hide();
      },500);
    },err=>{
      this.spinner.hide();
    });
  }

}
