import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css']
})
export class NotfoundComponent implements OnInit {

  router: Router;
  constructor(router: Router) { 
    this.router = router;
  }

  ngOnInit(): void {
  }
  searchResult(): void {
    this.router.navigate(['/search']);
}

ngAfterViewInit(){
    document.getElementById('preloader').classList.add('hide');                 
}

}
