import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogcontentService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  manageBlogImages(blogContentBO) {
    return this.http.post<any>(this.rootUrl + '/Blog/manageBlogContentFile', blogContentBO);
  }
  manageBlogContent(blogContentBO)
  {
    
    return this.http.post<any>(this.rootUrl + '/Blog/manageBlogContent',  blogContentBO); }
    getBlogContent() {
      
      return this.http.get<any>(this.rootUrl + '/Blog/getBlogContent') }
      ActiveInactiveBlogContent(active) {
        return this.http.post<any>(this.rootUrl + '/Blog/ActiveInactiveBlogsContent', active);
       }
}
