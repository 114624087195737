import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], Type: string, searchText: string): any[] {
    if (!searchText || !Type || !items) return items;

    const searchString = searchText.replace(/\s/g, '').toLowerCase();

    switch (Type) {
      case 'User':
        return items.filter(item =>
          item.email?.toLowerCase().includes(searchString) ||
          item.firstName?.toLowerCase().includes(searchString) ||
          item.mobile?.toLowerCase().includes(searchString)
        );

      case 'Product':
        return items
          .filter(item =>
            item.productName?.toLowerCase().includes(searchString) ||
            item.searchTag?.toLowerCase().includes(searchString) ||
            item.categoryName?.toLowerCase().includes(searchString) ||
            item.subcategoryLevel1?.toLowerCase().includes(searchString) ||
            item.list?.some(x => x.itemBarCode?.toLowerCase().includes(searchString))
          )
          .map(filteredItem => {
            const matchedItem = filteredItem.list?.find(x => x.itemBarCode?.toLowerCase().includes(searchString));
            if (matchedItem) {
              filteredItem.itemBarCode = matchedItem.itemBarCode;
              filteredItem.productImage = matchedItem.itemImage;
            }
            return filteredItem;
          });

      case 'Orders':
        return items.filter(item => item.itemID.toLowerCase().includes(searchString));

      case 'Orders-Two':
        return items.filter(item => item.itemOrderID.toLowerCase().includes(searchString));

      case 'CATEGORY':
        return items.filter(item => item.categoryName.toLowerCase().includes(searchString));

      case 'CATEGORY-LEVEL1':
        return items.filter(item => item.subCategory.toLowerCase().includes(searchString));

      case 'CATEGORY-LEVEL2':
        return items.filter(item => item.subCategoryTwo.toLowerCase().includes(searchString));

      case 'INVOICE':
        return items.filter(item =>
          item.customerName?.toLowerCase().includes(searchString) ||
          item.orderNumber?.toLowerCase().includes(searchString) ||
          item.invoiceNumber?.toLowerCase().includes(searchString)
        );
      case 'low-inventory-product':
        return items.filter(x => x.productItemCode.replace(/\s/g, '').toLowerCase().includes(searchString) ||
        x.productName.replace(/\s/g, '').toLowerCase().includes(searchString) ||
        x.itemPrice.toString().includes(searchString))
         
      default:
        return items;
    }
  }


}
