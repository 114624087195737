import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ViewusersService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getUserList(typeid:number,Active:any):Observable<any> {
    return this.http.get<any>(this.rootUrl + '/User/GetUserMasterAll',{params:{typeid,Active}});
  }

  GetUserByID(id:number):Observable<any>{
    return this.http.get<any>(this.rootUrl+'/pos/GetUserdetailsByID',{params:{id}});
  }

  ActiveInactiveUsers(UserID):Observable<any>{
    return this.http.get<any>(this.rootUrl+'/User/ActiveInactiveUser',{params:{id:UserID}});
  }
}
