<!-- Content Header (Page header) -->

<section class="content-header">
  <h1> Manage Users</h1>
  
</section>
<!-- Main content -->
<section class="content">
  <div class="row">
    <!-- left column -->
    <div class="col-md-12 margin-left-np">
      <div class="box box-danger">
      
        <!-- /.box-header -->
        <!-- form start -->
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="box-body margin-del">
            <div class="row">
              <div class="col-md-12 firstcol">
                <div class="row">
                  <div class="form-group col-md-4">
                    <label for="firstName">First Name <span style="color: red;">*</span></label>
                    <input id="firstName" formControlName="firstName" id="firstName" class="form-control validation-field"
                      placeholder="Enter First Name" type="text">
                    <div class="validation-error" style="color: red;"
                      *ngIf="(form.get('firstName').touched && form.get('firstName').invalid && form.get('firstName').hasError('required')) ">
                      This field is required
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="email">Email <span style="color: red;">*</span></label>
                    <input id="email" formControlName="email" id="email" class="form-control validation-field "
                      placeholder="Enter Email" type="text"  >
                    <span class="validation-error" style="color: red;" *ngIf="Input.email.touched">
                      <span *ngIf="Input.email.errors?.pattern">Email Not Valid</span>
                      <span *ngIf="Input.email.errors?.required">This field is required</span>
                    </span>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="userTypeID">Select UserType <span style="color: red;">*</span></label>
                    <ng-select
                    placeholder="Select User Type"
                      (change)="GetMenuByUserTypeID($event.id)"
                       [items]="UserTypeList" bindLabel="name" 
                       bindValue="id"
                         formControlName="userTypeID"></ng-select>
                    <div class="validation-error" style="color: red;"
                      *ngIf="(form.get('userTypeID').touched && form.get('userTypeID').invalid && form.get('userTypeID').hasError('required')) ">
                      This field is required
                    </div>
                  </div>
                </div>
               
                
                <div class="row">
                  <div class="form-group col-md-4 ">
                    <label for="doj">Date of Joining <span style="color: red;">*</span></label>
                    <input id="doj"  type="text "   name="doj" name="doj" formControlName="doj" placeholder="Choose a date" 
                      class="form-control validation-field " />
                    <span *ngIf="Input.doj.touched" class="validation-error">
                      <span *ngIf="Input.doj.errors?.required">
                        This field is required.
                      </span>
                    </span>
                  </div>
  
                  <div class="form-group col-md-4">
                    <label for="mobile">Mobile Number <span style="color: red;">*</span></label>
                    <input id="mobile" maxlength="10" (keypress)="keyPressNumbers1($event)" formControlName="mobile"
                      class="form-control validation-field" placeholder="Enter Mobile Number" type="text">
                    <span class="validation-error" style="color: red;" *ngIf="Input.mobile.touched">
                      <span *ngIf="Input.mobile.errors?.required">This field is required</span>
                      <span *ngIf="form.get('mobile').hasError('minlength')">Number must be at least 10 digits
                        long.</span>
                    </span>
                  </div>
                  
                </div>
                
              
               
              </div>
            </div>
          </div>
          <!-- /.box-body -->
          <div class="box-footer text-right">
            <button type="submit" class="btn btn-success marginright15" id="btnsubmit">Submit</button>
            <button type="button" class="btn btn-secondary" routerLink="/viewusers">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>