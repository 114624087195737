import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  
  AddEditUsers(users) :Observable<any>{
    debugger
    return this.http.post<any>(this.rootUrl + '/User/ManageUserMaster', users);
  }
  userDetailByID(UserID):Observable<any> {
    debugger;
    return this.http.get<any>(this.rootUrl + '/User/GetUserMasterByID',{params:{id:UserID}} );
  }

  UploadProfileImg(formdata:any):Observable<any>{
    debugger
    return this.http.post(this.rootUrl+'/UploadMultipleFiles',formdata);

  }
  GetallUserType():Observable<any>{
    return this.http.get<any>(this.rootUrl + '/User/GetUserTypesList');

  }
}