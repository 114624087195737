import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { UntypedFormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsertypesService } from './usertypes.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-usertypes',
  templateUrl: './usertypes.component.html',
  styleUrls: ['./usertypes.component.css']
})
export class UsertypesComponent implements OnInit {
  form: any;
  getUsertypes: any[]=[];


  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [10, 15, 20];

  constructor(public router: Router, private fb: FormBuilder, public usertypesService: UsertypesService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    this.form = fb.group({
      'id': [0],
      'typeName': ['', Validators.required],
    });


  }


  ngOnInit(): void {
    this.GetUsertypes();
  }

  




  GetUsertypes() {
    debugger
    this.spinner.show();
    this.usertypesService.GetUserTypes().subscribe(data => {
      this.getUsertypes = data.result;
      console.log("UTypes", this.getUsertypes)
    }, err => {
      this.toastr.error('', 'Not Responding');
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 200);
  }



  onSubmit(): void {
    debugger;
    if (this.form.valid) {
      this.spinner.show();
      this.usertypesService.AddEditUserTypes(this.form.value).subscribe(data => {
        if (data.message === 'OK') {
          this.toastr.success(data.activity);
          this.GetUsertypes();
          this.form.reset();
          this.form.patchValue({
            id:0
          });
        }
        else {
          this.toastr.error('', data.message);
        }

        document.getElementById('UpsertBtn').innerText = "Submit";
      }, err => { this.toastr.error('', 'Not Responding') });
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  OnTableDataChange(PageNumber: number) {
    this.page = PageNumber;
  }

  OnTableSizeChange(PageSize: number) {
    this.tableSize = PageSize;

  }


  onEdit(usertype: any) {
    debugger;
    if (!usertype.isActive) {
      Swal.fire({
        title: 'Oops...',
        icon: 'error',
        text: 'Please Activate UserType First!',
      });
      return;

    }
    console.log(usertype);
    document.getElementById('UpsertBtn').innerText = "Update";

    this.spinner.show();
    this.form.setValue({
      id: usertype.id,
      typeName: usertype.name,
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  ActiveInactiveUserType(TypeID: number, IsActive: boolean) {
    var action = 'activated';
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the user type? " : "Are you sure you want to activate the user type? ",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        if (IsActive === true) {
          action = 'deactivated';
        }
        this.usertypesService.ActiveInactiveUserType(TypeID).subscribe(res => {
          this.toastr.success('User type has been ' + action + ' successfully.')
          this.GetUsertypes();
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#checkBoxAinA' + TypeID).prop("checked", true)
        };
        function uncheck() {
          $('#checkBoxAinA' + TypeID).prop("checked", false)
        }

        IsActive ? check() : uncheck();
      }
    })


  }

}
