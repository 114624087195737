import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UsertypesService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  GetUserTypes() {
    return this.http.get<any>(this.rootUrl + '/User/GetUserTypesList');
  }
  AddEditUserTypes(usertype) {
    return this.http.post<any>(this.rootUrl + '/User/ManageUserType', usertype);
  }

  ActiveInactiveUserType(UserTypeID:number):Observable<any>{
    return this.http.get<any>(this.rootUrl+'/User/ActiveInactiveUserType',{params:{id:UserTypeID}})
  }
} 