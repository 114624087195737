<header class="main-header">
  <!-- Logo -->
  <a href="dashboard" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini text-white"><b>TH</b></span>
    <span class="short-logo"> Trendy Hub</span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg"><b>TH</b>
      <span class="short-logo"> Trendy Hub </span></span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>
    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <!-- Messages: style can be found in dropdown.less-->
        <li class="dropdown messages-menu">
          <!-- <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-envelope-o"></i>
            <span class="label label-success">4</span>
          </a> -->
          <!-- <ul class="dropdown-menu">
            <li class="header">You have 4 messages</li>
            <li>
            
              <ul class="menu">
                <li>
                  
                  <a href="#">
                    <div class="pull-left">
                      <img src="../assets/images/user2-160x160.jpg" class="img-circle" alt="User Image">
                    </div>
                    <h4>
                      Support Team
                      <small><i class="fa fa-clock-o"></i> 5 mins</small>
                    </h4>
                    <p>Why not buy a new awesome theme?</p>
                  </a>
                </li>
              
                <li>
                  <a href="#">
                    <div class="pull-left">
                      <img src="../assets/images/user2-160x160.jpg" class="img-circle" alt="User Image">
                    </div>
                    <h4>
                      AdminLTE Design Team
                      <small><i class="fa fa-clock-o"></i> 2 hours</small>
                    </h4>
                    <p>Why not buy a new awesome theme?</p>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div class="pull-left">
                      <img src="../assets/images/user2-160x160.jpg" class="img-circle" alt="User Image">
                    </div>
                    <h4>
                      Developers
                      <small><i class="fa fa-clock-o"></i> Today</small>
                    </h4>
                    <p>Why not buy a new awesome theme?</p>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div class="pull-left">
                      <img src="../assets/images/user2-160x160.jpg" class="img-circle" alt="User Image">
                    </div>
                    <h4>
                      Sales Department
                      <small><i class="fa fa-clock-o"></i> Yesterday</small>
                    </h4>
                    <p>Why not buy a new awesome theme?</p>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div class="pull-left">
                      <img src="../assets/images/user2-160x160.jpg" class="img-circle" alt="User Image">
                    </div>
                    <h4>
                      Reviewers
                      <small><i class="fa fa-clock-o"></i> 2 days</small>
                    </h4>
                    <p>Why not buy a new awesome theme?</p>
                  </a>
                </li>
              </ul>
            </li>
            <li class="footer"><a href="#">See All Messages</a></li>
          </ul> -->
        </li>
        <!-- Notifications: style can be found in dropdown.less -->
        <li class="dropdown notifications-menu">
          <!-- <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-bell-o"></i>
            <span class="label label-warning">10</span>
          </a> -->
          <ul class="dropdown-menu">
            <li class="header">You have 10 notifications</li>
            <li>
              <!-- inner menu: contains the actual data -->
              <ul class="menu">
                <li>
                  <a href="#">
                    <i class="fa fa-users text-aqua"></i> 5 new members joined today
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-warning text-yellow"></i> Very long description here that may not fit into the
                    page and may cause design problems
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-users text-red"></i> 5 new members joined
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-shopping-cart text-green"></i> 25 sales made
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-user text-red"></i> You changed your username
                  </a>
                </li>
              </ul>
            </li>
            <li class="footer"><a href="#">View all</a></li>
          </ul>
        </li>
        <!-- Tasks: style can be found in dropdown.less -->
        <li class="dropdown tasks-menu">
          <!-- <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-flag-o"></i>
            <span class="label label-danger">9</span>
          </a> -->
          <ul class="dropdown-menu">
            <li class="header">You have 9 tasks</li>
            <li>
              <!-- inner menu: contains the actual data -->
              <ul class="menu">
                <li>
                  <!-- Task item -->
                  <a href="#">
                    <h3>
                      Design some buttons
                      <small class="pull-right">20%</small>
                    </h3>
                    <div class="progress xs">
                      <div class="progress-bar progress-bar-aqua" style="width: 20%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                        <span class="sr-only">20% Complete</span>
                      </div>
                    </div>
                  </a>
                </li>
                <!-- end task item -->
                <li>
                  <!-- Task item -->
                  <a href="#">
                    <h3>
                      Create a nice theme
                      <small class="pull-right">40%</small>
                    </h3>
                    <div class="progress xs">
                      <div class="progress-bar progress-bar-green" style="width: 40%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                        <span class="sr-only">40% Complete</span>
                      </div>
                    </div>
                  </a>
                </li>
                <!-- end task item -->
                <li>
                  <!-- Task item -->
                  <a href="#">
                    <h3>
                      Some task I need to do
                      <small class="pull-right">60%</small>
                    </h3>
                    <div class="progress xs">
                      <div class="progress-bar progress-bar-red" style="width: 60%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                        <span class="sr-only">60% Complete</span>
                      </div>
                    </div>
                  </a>
                </li>
                <!-- end task item -->
                <li>
                  <!-- Task item -->
                  <a href="#">
                    <h3>
                      Make beautiful transitions
                      <small class="pull-right">80%</small>
                    </h3>
                    <div class="progress xs">
                      <div class="progress-bar progress-bar-yellow" style="width: 80%" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                        <span class="sr-only">80% Complete</span>
                      </div>
                    </div>
                  </a>
                </li>
                <!-- end task item -->
              </ul>
            </li>
            <li class="footer">
              <a href="#">View all tasks</a>
            </li>
          </ul>
        </li>
        <!-- User Account: style can be found in dropdown.less -->
        <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <img src="assets/images/favicon.png" class="user-image" alt="User Image">
            <span class="hidden-xs">{{UserName}}</span>
          </a>
          <ul class="dropdown-menu dropy">
            <!-- User image -->
            <!-- <li class="user-header">
              <img src="assets/images/default-image.png" class="img-circle" alt="User Image">
              <p>
                {{UserName}} 
              </p>
            </li> -->
            <!-- Menu Body -->
            <!-- <li class="user-body">
              <div class="row">
                <div class="col-xs-4 text-center">
                  <a href="#">Followers</a>
                </div>
                <div class="col-xs-4 text-center">
                  <a href="#">Sales</a>
                </div>
                <div class="col-xs-4 text-center">
                  <a href="#">Friends</a>
                </div>
              </div>
            </li> -->
            <!-- Menu Footer-->
            <!-- <li class="user-footer">
              <div class="pull-left">
                <a href="#" class="btn btn-default btn-flat">Profile</a>
              </div>
              <div class="pull-right">
                <a href="#" class="btn btn-default btn-flat">Sign out</a>
              </div>
            </li> -->
            <li class="user-footer user-infor"style=" padding-bottom: 5px;  ">
              <div class="pull-left">
                <a href="#" class="" data-toggle="modal" data-target="#reset"><i class="fa fa-unlock-alt"></i> Change Password</a>
              </div>
            </li>
            <li class="user-footer second-butt">
              <div class="pull-left">
                <a (click)="logOut()" class=""><i class="fa fa-arrow-right"></i> Sign out</a>
              </div>
            </li>
          </ul>
        </li>
        <!-- Control Sidebar Toggle Button -->
        <!-- <li>
          <a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>
        </li> -->
      </ul>
    </div>
  </nav>
</header>
<div id="reset" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <form [formGroup]="passwordform">
      <!-- Modal content-->
      <div class="modal-content forgot-popup">
       
        <div class="modal-header">
            <h4 class="modal-title">Change Password</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
      
        </div>
       
        <div class="modal-body">
         
          <div class="forgot-field">
            <div class="form-group has-feedback">
              <input type="password" class="form-control" placeholder="Old Password" formControlName="oldPassword">
              <span class="form-control-feedback" data-pwd="false"  >  <i class="fa fa-eye"  aria-hidden="true" ></i></span>
              
              <span *ngIf="input.oldPassword.errors?.required && input.oldPassword.touched">
                <span class="text-danger" *ngIf="input.oldPassword.errors?.required">
                  This field is required
                </span>
               
              </span>
            </div>
            <div class="form-group has-feedback">
                <input type="password" class="form-control" placeholder="New Password" formControlName="newPassword">
                <span class="form-control-feedback" data-pwdd1="false"  >  <i class="fa fa-eye" aria-hidden="true" ></i></span>
                <span *ngIf="input.newPassword.errors?.required && input.newPassword.touched">
                  <span class="text-danger" *ngIf="input.newPassword.errors?.required">
                    This field is required
                  </span>
                 
                </span>

              </div>
              <div class="form-group has-feedback">
                  <input type="password" class="form-control" placeholder="Confirm New " formControlName="confirmPassword">
                  <span class="form-control-feedback" data-pwdd2="false"  >  <i class="fa fa-eye" aria-hidden="true" ></i></span>
                  <span *ngIf="input.newPassword.errors?.required && input.newPassword.touched">
                    <span class="text-danger" *ngIf="input.newPassword.errors?.required">
                      This field is required
                    </span>
                   
                  </span>
                </div>
          </div>
        </div>
        <div class="modal-footer">
         <div class="button-forgot">
            <div  class="col-md-12 mt-2 text-right custom-button">
              <button  type="submit" id="form1submit" (click)="changePassword()" class="btn btn-success readonlyfield1"> Submit </button>
              <button  id="" class="btn btn-secondary marginright15"  data-dismiss="modal">Cancel</button>
              
            </div>
        </div>
      </div>
  
    </div>
  </form>
  </div>