import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubCategoryTwoService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http: HttpClient) { }
  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localdata.userToken
  });

  getMainCategoryDropDown() {
    return this.http.get<any>(this.rootUrl + '/MainMenu/dropdownCategory', {headers: this.reqHeader});
  }

  getSubCategoryDropwnlist(id){
    return this.http.get<any>(this.rootUrl + '/MainMenu/dropdownSubcategory', {params:{id}});
  }

  getSubCategoryTwoList(categoryid:number,subcategoryid:number,Active:boolean|string,SearchString:string){
    return this.http.get<any>(this.rootUrl + '/MainMenu/ListofSubCategoriesTwo', {params:{categoryid,subcategoryid,Active,SearchString}});
  }

  AddEditSubcategoriesTwo(categoryData) {
    return this.http.post<any>(this.rootUrl + '/MainMenu/ManageSubTwoCategoriesnew', categoryData );
  }

  getActiveDeactiveSubCatTwo(id:number,Isactive:boolean,type:string,UserID:number):Observable<any> {
    return this.http.get<any>(this.rootUrl + '/MainMenu/ActiveInactiveCategorySubCategoryTwo', {params:{id,Isactive,type,UserID}});
   }


   changeSubCategoryTwoProps(SubCategoryTwoID:number,type:string):Observable<any>{
    debugger
    return this.http.get<any>(this.rootUrl+'/MainMenu/changeSubCategoryTwoProps',{params:{SubCategoryTwoID,type}});
  }

  ListofSubCategories():Observable<any>{
    debugger
    return this.http.get<any>(this.rootUrl+'/MainMenu/SubCategories');
  }

}
