import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceTypeService {
  private readonly rooturl:string=environment.baseUrl; 
  constructor(private http:HttpClient) { }

  GetInvoiceTypeList():Observable<any>{
    return this.http.get<any>(this.rooturl+'/Pos/GetInvoiceTypeList');
  }
  ManageInvoiceType(InvoiceType:any):Observable<any>{
    return this.http.post<any>(this.rooturl+'/Pos/ManageInvoiceType',InvoiceType);
  }

}
