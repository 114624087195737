<section class="content-header sub-head">
    <h1> Status </h1>
    <!-- <ol class="breadcrumb">
      <li>
        <a href="#">
          <i class="fa fa-dashboard"></i> Home </a>
      </li>
      <li class="active">Status</li>
    </ol> -->
  </section>
  <section >
    <div class="row">
      <div class="col-md-12">
        <div class="box box-danger">
            <form  [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="box-body">
            <div class="row smpt">
              <div class="col-md-3">
                <label for="statusName" class="form-label">Status <span class="cstm-required" style="color: red;">*</span>
                </label>
                <input type="text" id="statusName" formControlName="statusName" name="statusName" placeholder="Enter Status" class="form-control ng-pristine ng-invalid ng-touched">
                <span *ngIf="f.statusName.touched" class="validation-error">
                  <span *ngIf="f.statusName.errors?.required">This Field is required </span>
                </span>
              </div>


              <div class="col-md-6">
                <label for="statusName" class="form-label">Status Description<span class="cstm-required" style="color: red;">*</span>
                </label>
                <input type="text" id="statusDescription" formControlName="statusDescription" name="statusDescription" placeholder="Enter Description" class="form-control ng-pristine ng-invalid ng-touched">
                <span *ngIf="f.statusDescription.touched" class="validation-error">
                  <span *ngIf="f.statusDescription.errors?.required">This Field is required </span>
                </span>
              </div>


              <div class="col-md-3 smpt-button">
                <ul class="list-inline wizard mt-5">
                    <li class="next ">
                      <button type="submit"class="btn btn-primary waves-effect waves-light mb-2 me-2 hlo"  id="btnsubmit">Save </button>
                      <button type="reset" class="btn btn-secondary waves-effect waves-light mb-2">Cancel</button>
                    </li>
                  </ul>
            </div>
              </div>
         
          </div>

        </form>
        </div>
      </div>
    </div>


    <section class="content-header sub-head">
      <h1>
        Status List
      </h1>
    </section>

    <div class="box">
      <div class="box-header">
        <div class="search-box-new">
          <div class="row">
            <div class="col-md-12 text-right">
               

          <select  class="btn btn-primary custom-button mr-2"  name="cars" id="period"> 
          
            <option  value="ONEWEEK" selected>Active&nbsp;List</option>
            <option value="ONEMONTH">Deactive&nbsp;List</option>
            <option value="THREEMONTHS">All</option>
            
          </select>
            </div>
          </div>
        </div>
       
      </div>

      <div class="box-body">
        <div class="row nomargin">
          <div class="col-md-12" style="margin-top: 12px;">
            <div class="table-responsive">
              <table id="example1" class="table text-center table-bordered table-striped">
                <thead>
                  <tr>
                    <th style="text-align: left !important;">S.NO</th>
                    <th style="text-align: left !important;">Status</th>
                  
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of statusList |  paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };let i=index">
                    <td style="text-align: left;">{{i+1+tableSize*(page-1)}}</td>
                    <td style="text-align: left !important;">{{item.statusName}}</td>
                   
                    <td>
                      <button  class="btn btn-icon m-1 p-1 mr-2">
                        <input type="checkbox" id="checkBoxAinA{{item.id}}" value="isActive" name="isActive" (change)="ActiveInactiveStatus(item.id,item.isActive)"  [checked]="item.isActive">
                      </button>
                      <button type="button" class="btn btn-icon" (click)="onEdit(item)" aria-label="Product details">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
        
              </table>
              <div *ngIf="statusList.length>0;else elseblock">

              </div>
              </div>

              <div class="row mt-3"style="margin-right:0;" *ngIf="statusList.length>tableSize">
               
                <div class="col-md-3 ">
                  <select (change)="onTableSizeChange($event.target.value)" class="form-control w-auto">
                    <option  *ngFor="let tblsize of tableSizes" 
                    [ngValue]="tblsize">{{tblsize}}</option>
                  </select>
                </div>
                <div class="col-md-9 text-right">
                  <pagination-controls
                  previousLabel="Prev"
                  nextLabel="Next"
                >
                </pagination-controls>
                
                </div>
              </div>
          </div>
        </div>
       
      </div>
      <!-- /.box-body -->
    </div>
  </section>

  <ng-template #elseblock>
    <div class="no-data">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      <h4>No Data Found</h4>
    </div>
  </ng-template>