import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

 gettotalcount():Observable<any>
 {
  return this.http.get<any>(this.rootUrl +"/Order/GetTotalCount");
 }
getToproducts():Observable<any>{
  return this.http.get<any>(this.rootUrl+'/Order/GetTopProducts')
}
getRecentOrders():Observable<any>{
  return this.http.get<any>(this.rootUrl+'/Order/GetRecentOrders');
}

GetChartData(year:number):Observable<any>{
  return this.http.get<any>(this.rootUrl+'/Order/GetChartData',{params:{year}});

}
}
