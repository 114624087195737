import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debug } from 'console';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewReturnOrderService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }


  GetReturnOrderID(orderID:number):Observable<any> {
    return this.http.get<any>(this.rootUrl + '/order/GetReturnOrderByOrderID',{params:{orderID}});
  }

  getStatusList() {
    
    return this.http.get<any>(this.rootUrl + '/Order/getCancelOrReturnOrderStatuslist');
  }


  ManageReturnOrder(returnOrder : any) {
    debugger
    return this.http.post<any>(this.rootUrl + '/Order/ManageReturnOrder',returnOrder);
  }
}
