import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, AbstractControl, Validators, FormArray, ValidationErrors, Form } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddproductService } from './addproduct.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MastercolorService } from '../mastercolor/mastercolor.service';
import { MastersizeService } from '../mastersize/mastersize.service';
import { MastertypeService } from '../mastertype/mastertype.service';
import { DiscountService } from '../discount/discount.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AppService } from 'src/app/app.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SeoManagementComponent } from '../seo-management/seo-management.component';
import { isatty } from 'node:tty';

declare var $: any;


@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit, AfterViewInit {
  encPassword = 'neha@hexa';
  public form1: any;
  public form2: any
  public tempVariationList: any[] = [];
  public tempCoverImageIndex: number = 0;
  private variationIndex: number = 0;
  public RelatedProductIDS: number[] = [];
  public IsEditProduct: boolean = false;
  public IsEditVariation: boolean = false;
  public RelatedItems: any[] = [];
  public rootURL = environment.baseUrl.slice(0, -3);
  public DropdownCategoryList: Array<any> = [];
  public DropdownSubCategoryList: Array<any> = [];
  public DropdownSubTwoCategoryList: Array<any> = [];
  private listProductsName: any[] = [];
  public listProductDropdown: any[] = [];
  public previewUrls: any = [];
  public SelectedFiles: any = [];
  public colorList: any[] = [];
  public selectedUnit: any;
  public sizelist: any[] = [];
  public typelist: any;
  public discountlist: any[] = []
  public DiscountForm: any;
  public ProductID: number = 0;
  private productAttributeId: number = 0;
  public ColorForm: any;
  public SizeForm: any;
  public BarCodeList: Array<any> = [];
  public IsReadOnlyFiled: boolean = false;
  private LoginUserID: number;
  public previousExistingQuantity: number = 0;
  public showNewLotNotice: boolean = false;
  public hasDiscount: boolean = false;
  public inQueue: boolean = false;
  public accordionOpen: boolean[] = [];
  public Editor = ClassicEditor;
  public discountOptions = [
    { label: 'Not-applicable', isApply: false },
    { label: 'Applicable', isApply: true }
  ];

  @ViewChild(SeoManagementComponent) seocomponent: SeoManagementComponent;
  // Assignment Category
  public AssignmentDropdwonSubCategoryList: any[] = [];
  public AssignmentDropdwonSubTwoCategoryList: any[] = [];
  public assignList: any[] = [];
  public assignGridList: any[] = [];
  public lotNumberList: any[] = [];
  // public tempLotNumberList: any[] = [];
  public assignmentCategoryForm: any;
  public editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'alphabeticalList', 'insertTable', '|', 'undo', 'redo', '|', 'style'],
  };



  constructor(private activRoute: ActivatedRoute,
    private service: DiscountService,
    private sizeservice: MastersizeService,
    private typeservice: MastertypeService,
    private colorserviice: MastercolorService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public addproductservices: AddproductService,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe,
    private router: Router,
    private mastercolorservice: MastercolorService,
    private appservice: AppService
  ) {

    this.form1 = fb.group({
      id: 0,
      productCategoryID: [null, Validators.required],
      productSubCategoryID: [null],
      productSub2CategoryID: [null],
      productName: ['', [Validators.required, this.validateProductName.bind(this)]],
      description: ['', [Validators.required, Validators.maxLength(1000)]],
      smallDescription: ['', [Validators.required, Validators.maxLength(400)]],
      relatedProducts: [''],
      additionalInformation: ['', Validators.required],
      productRating: [null],
      isOnlineProduct: [true],
      isOfflineProduct: [true],
      searchTag: ['', Validators.required],
      loginUserID: [0]
    });


    this.form2 = fb.group({
      id: 0,
      masterProductID: [0],
      colorID: [null, [Validators.required]],
      sizeID: [null, [Validators.required]],
      length: [null],
      width: [null],
      height: [null],
      unit: [null],
      discountID: [null],
      hasDiscount: [false],
      minStock: [0, [Validators.required, Validators.min(1)]],
      itemBarCode: ['', [Validators.required, this.validateBarCode.bind(this)]],
      productImages: fb.array([]),
      lot: fb.group({
        price: [0, [Validators.required, Validators.min(1)]],
        quantity: [0, [Validators.required, Validators.min(1)]],
        inQueue: [false],
        lotNumber: ['New Lot', [Validators.required]]
      }),
      coverImageIndex: null,
      loginUserID: [0]
    });

    this.DiscountForm = fb.group({
      id: [0],
      discountPercentage: ["", Validators.required],
      discountTitle: ["", Validators.required],
      discountDescription: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      isActive: [true],
    })


    this.ColorForm = fb.group({
      id: [0],
      name: ["", Validators.required],
      colorCode: ["", Validators.required],
      isActive: true
    })

    this.SizeForm = fb.group({
      id: [0],
      name: ["", Validators.required],
    });


    this.assignmentCategoryForm = fb.group({
      productID: [null],
      categoryID: [null, [Validators.required]],
      subCategoryID: [null],
      subTwoCategoryID: [null],
    });
  }

  DiscountOffered: any;
  TaxOffered: any;


  ngOnInit(): void {
    this.form2.get('lot.quantity')?.valueChanges.subscribe((newQuantity) => {
      this.checkQuantityChange();
    });
    this.CategoryList();
    this.GetProductsName();
    this.GetAllBarCode();
    this.getmastercolor()
    this.getdiscount();
    this.getmasterSize();
    this.LoginUserID = this.appservice.getUserID();

    this.activRoute.queryParams.subscribe(param => {
      let id = param['ProductID'];
      if (id) {
        this.IsEditProduct = true;
        this.ProductID = Number(id);
        this.EditProduct(id);
        $('#submitForm1').text('Update');
      }
    });

  }


  ngAfterViewInit(): void {
    $(`.date-input`).flatpickr({
      minDate: 'today',
      dateFormat: 'd/M/Y'
    });
  }

  checkQuantityChange() {
    const newQuantity = this.form2.get('lot.quantity')?.value || 0;
    const selectedLotNumber = this.form2.get('lot.lotNumber')?.value || '';
    const minStock = this.form2.get('minStock')?.value || 0;
    const formId = this.form2.get('id')?.value;

    if (newQuantity > 0 && formId) {
      const currentLotQuantity =
        this.tempVariationList[this.variationIndex]?.lots.find((x) => x.lotNumber === selectedLotNumber)?.currentQuantity || 0;

      if (newQuantity + currentLotQuantity <= minStock) {
        this.inQueue = false;
        this.form2.get('lot.inQueue')?.setValue(false);
      }
    }
  }


  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/default-product.png';
  }

  toggleButton() {
    const lotNumber = this.form2.get('lot.lotNumber')?.value;
    const selectedQuantity = this.form2.get('lot.quantity')?.value || 0;
    const minStock = this.form2.get('minStock')?.value || 0;

    if (!lotNumber) return;

    const activeLot = this.tempVariationList[this.variationIndex]?.lots.find(
      (x) => x.productItemId === this.productAttributeId && x.lotNumber === lotNumber
    );

    if (activeLot?.isActive) {
      this.showWarning("Can't add an active lot in the queue");
      return;
    }
    if (selectedQuantity + (activeLot?.inQueue ? this.previousExistingQuantity : 0) <= minStock) {
      this.showWarning("Selected quantity is below the minimum stock level. Can't add to queue.");
    }
  }

  private showWarning(message: string) {
    Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        this.inQueue = false;
        this.form2.get('lot.inQueue')?.patchValue(false);
      }
    });
  }


  get files(): FormArray {
    return this.form2.get('productImages') as FormArray;
  }


  addFile(fileData: { path: '', imageName: '', isCoverImage: boolean, rawFile: File | null }) {
    const fileControl = this.fb.control(fileData);
    this.files.push(fileControl);
  }

  onChangeProductType(elementID: string) {
    let offlineCheck = this.form1.get('isOfflineProduct').value;
    let onlineCheck = this.form1.get('isOnlineProduct').value;
    if (!onlineCheck && !offlineCheck) {
      if (elementID === 'online') {
        this.form1.patchValue({
          isOnlineProduct: true,
        });
      } else {
        this.form1.patchValue({
          isOfflineProduct: true,
        });
      };
      Swal.fire({
        // title: 'Warning',
        title: "You can't disable both online and offline options. Please select at least one option.",
        icon: 'warning'
      });
      return;
    }
  }

  ResetDiscountForm() {
    this.DiscountForm.reset();
  }
  get input() {
    return this.DiscountForm.controls;
  }
  get form1controls() {
    return this.form1.controls;
  }

  get form2controls() {
    return this.form2.controls;
  }

  get colorformcontrols() {
    return this.ColorForm.controls;
  }

  get sizeformcontrols() {
    return this.SizeForm.controls;
  }


  // keyPressNumbers(event) {

  //   var charCode = (event.which) ? event.which : event.keyCode;
  //   if ((charCode < 48 || charCode > 57) && charCode !== 46) {
  //     event.preventDefault();
  //     return false; // Return false to indicate that the input should be blocked
  //   } else {
  //     return true; // Return true to indicate that the input is allowed
  //   }
  // }

  onChangeInput(key: string) {
    this.seocomponent.seoForm.patchValue({
      metaTitle: key,
    });
  }
  validateProductName(control: AbstractControl): ValidationErrors | null {
    const ProductName = control.value.toUpperCase();
    if (this.ProductID) {
      const isSameProduct = this.listProductsName.some(x => x.productID === this.ProductID && x.productName.toUpperCase() === ProductName);
      if (isSameProduct) {
        return null;
      }
    }
    const isDuplicate = this.listProductsName.some(x => x.productName.toUpperCase() === ProductName);
    return isDuplicate ? { duplicateProductName: true } : null;
  }

  validateBarCode(control: AbstractControl): ValidationErrors | null {
    const BarCode = control.value;
    if (this.productAttributeId) {
      const isSameItem = this.BarCodeList.some(x => x.itemiD === this.productAttributeId && x.barCode === BarCode);
      if (isSameItem) {
        return null;
      }
    }
    let isDuplicate = this.BarCodeList.some(x => x.barCode === BarCode);
    return isDuplicate ? { duplicateBarCode: true } : null;
  }

  onFileChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }

    this.files.clear();
    this.tempCoverImageIndex = 0;
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const maxSize = 2 * 1024 * 1024;
    const targetWidth = 400;
    const targetHeight = 300;

    const files = Array.from(input.files);

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        this.showErrorMessage('Invalid file type. Only PNG, JPG, and JPEG are allowed.');
        return false;
      }

      if (file.size > maxSize) {
        this.showErrorMessage(`File size exceeds the limit of 5MB.`);
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) {
      input.value = '';
      return;
    }

    Promise.all(validFiles.map((file) => this.resizeImage(file, targetWidth, targetHeight)))
      .then((resizedFiles) => {
        resizedFiles.forEach((resizedFile, index) => {
          const isCoverImage = index === 0;
          this.addFile({ path: '', imageName: '', isCoverImage, rawFile: resizedFile });
        });

        return Promise.all(resizedFiles.map((file) => this.readFileAsDataURL(file)));
      })
      .then((urls) => {
        this.previewUrls = urls;
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });

    input.value = '';
  }

  resizeImage(file: File, width: number, height: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        img.src = event.target?.result as string;
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject('Canvas not supported');
          return;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type }));
            } else {
              reject('Image resizing failed');
            }
          },
          file.type,
          0.8
        );
      };

      img.onerror = () => {
        reject('Invalid image file');
      };

      reader.readAsDataURL(file);
    });
  }

  readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject('Error reading file');
      reader.readAsDataURL(file);
    });
  }

  showErrorMessage(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Validation Error',
      text: message,
      confirmButtonText: 'OK'
    });
  }


  private validateFile(): void {
    const fileControl = this.form2.get('productImages');
    const idControl = this.form2.get('id');

    if (!idControl?.value && !this.files.length) {
      fileControl?.setValidators([Validators.required]);
    } else {
      fileControl?.clearValidators();
    }
    fileControl?.updateValueAndValidity();
    // const maxFileSize = 2 * 1024 * 1024;
    // const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

    // this.files.controls.forEach((fileControl: AbstractControl, index: number) => {
    //   const file = fileControl.value;

    //   if (file.rawFile.size > maxFileSize) {
    //     fileControl.setErrors({ fileSize: true });
    //   } else if (!allowedTypes.includes(file.rawFile.type)) {
    //     fileControl.setErrors({ fileType: true });
    //   } else {
    //     fileControl.setErrors(null); 
    //   }
    // });
  }







  NextTab() {
    this.getAllLotNumbers();
    const Id = this.activRoute.snapshot.queryParamMap.get('ProductID');
    if (this.form1.valid && Id) {
      $(`#item-menu`).addClass('active in');
      $(`#productitem`).addClass('active in');
      $('#home').removeClass('active in');
      $(`#productinfo`).removeClass('active in');
      setTimeout(() => {
        this.GetVariationList();
      }, 50);
    }
    else { this.form1.markAllAsTouched() };
  }

  OnChangeStartDate(d: any) {
    let date = new Date(d);
    $('#enddate').val('');
    $('#enddate').flatpickr({
      minDate: date,
      dateFormat: 'd/M/Y'
    });


  }

  addDiscount() {
    if (this.DiscountForm.valid) {
      this.spinner.show();
      let startDate = new Date(this.DiscountForm.get('startDate').value);
      let endDate = new Date(this.DiscountForm.get('endDate').value);
      this.DiscountForm.patchValue({
        startDate: this.datepipe.transform(startDate, 'yyyy-MM-dd'),
        endDate: this.datepipe.transform(endDate, 'yyyy-MM-dd')
      })
      this.service.ManageDiscount(this.DiscountForm.value).subscribe(data => {
        if (data.message === "Success") {
          $('#largeModal3').modal('hide');
          this.toastr.success(data.activity);
          this.getdiscount();
          this.DiscountForm.reset();
        }
        this.spinner.hide();
      })
    }
    else {
      this.DiscountForm.markAllAsTouched();
    }

  }


  ManageMasterColor() {
    this.spinner.show();
    if (this.ColorForm.valid) {
      this.mastercolorservice.ManageMasterColor(this.ColorForm.value).subscribe(data => {
        if (data.message == "Success") {
          this.spinner.hide();
          $('#largeModal').modal('hide');
          this.toastr.success("Colour has been Added Successfully ");
          this.getmastercolor();
          this.ColorForm.reset();
          this.ColorForm.patchValue({
            id: 0
          })
        }
        else {
          this.spinner.hide();
          this.toastr.warning(data.message)

        }
      })
      this.spinner.hide();
    }
    else {
      this.spinner.hide();
      this.ColorForm.markAllAsTouched();
    }
  }


  async SubmitForm1() {
    if (this.form1.valid && this.seocomponent.isFormValid()) {
      this.spinner.show();
      if (!this.form1.value.relatedProducts) {
        this.form1.patchValue({ relatedProducts: [] });
      }

      const payLoad = {
        ...this.form1.value,
        productRating: this.getRandomRating(),
        loginUserID: this.LoginUserID,
      };

      try {
        const response = await this.addproductservices.AddEditproduct(payLoad).toPromise();
        if (response.message === 'Success') {
          this.ProductID = response.result.productID;
          await this.manageAssignmentCategories(this.ProductID);
          this.GetVariationList();
          this.getAllLotNumbers();
          $(`#item-menu`).addClass('active in');
          $('#home').removeClass('active in');
          $(`#productinfo`).removeClass('active');
          $(`#productitem`).addClass('active');
          $('#submitForm1').text('Update');

          this.form1.patchValue({
            id: this.ProductID
          });

          this.seocomponent.seoForm.patchValue({
            slug: response.result.slug,
            type: 'product'
          });

          await this.seocomponent.submitSEO();
          this.toastr.success(response.activity);

        } else {
          this.toastr.error(response.message);
        }
      } catch (err) {
        console.error(err);
        this.toastr.error('An error occurred while submitting the form.');
      } finally {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    } else {
      this.form1.markAllAsTouched();
      this.seocomponent.seoForm.markAllAsTouched();
    }
  }



  async addEditVariations() {
    this.validateFile();
    if (!this.form2.valid) {
      this.form2.markAllAsTouched();
      return;
    }

    this.form2.patchValue({
      masterProductID: this.ProductID,
      loginUserID: this.LoginUserID,
    });

    // Handle images
    const formdata = this.prepareImageFormData();
    if (!formdata) {
      return;
    }
    this.spinner.show();
    try {
      // Upload Variation Images if needed
      if (formdata.has('files')) {
        const uploadResponse = await this.uploadImages(formdata);
        if (uploadResponse) {
          this.addUploadedImagesToForm(uploadResponse.result);
        }
      }

      // Add or Edit Variations
      await this.addOrUpdateProductDetails();
    } catch (error) {
      console.error(error);
      this.toastr.error('An error occurred. Please try again.');
    } finally {
      this.spinner.hide();
    }
  }

  private prepareImageFormData(): FormData | null {
    const formdata = new FormData();
    if (this.files.length) {
      Array.from(this.files.controls).forEach((fileControl: AbstractControl, index: number) => {
        const file = fileControl.value;
        const isCoverImage = index === this.tempCoverImageIndex ? 'true' : 'false';
        formdata.append('files', file.rawFile);
        formdata.append('iscoverImage', isCoverImage);
      });
    }
    return formdata;
  }

  private async uploadImages(formdata: FormData): Promise<any> {
    try {
      const response = await this.addproductservices.UploadProductImages(formdata).toPromise();
      if (response.message === 'Success') {
        return response;
      } else {
        this.toastr.error(response.message);
        throw new Error(response.message);
      }
    } catch (error) {
      this.toastr.error('Image upload failed.');
      throw error;
    }
  }

  private addUploadedImagesToForm(images: any[]): void {
    this.files.clear();
    images.forEach((response) => {
      response = { ...response, rawFile: null }
      this.addFile(response);
    });
  }

  private async addOrUpdateProductDetails(): Promise<void> {
    try {
      const payLoad = {
        ...this.form2.value,
        discountID: this.form2.value.discountID ? this.form2.value.discountID : 0
      };
      const response = await this.addproductservices.AddEditProductDetail(payLoad).toPromise();
      if (response.message === 'Success') {
        Swal.fire({
          title: 'Product Submitted!',
          text: `${response.activity} with Lot number: ${response.result}.`,
          icon: 'success',
          confirmButtonText: 'OK'
        });

        this.resetForm2();
        this.GetVariationList();
        this.getAllLotNumbers();
        this.GetAllBarCode();
      } else {
        this.toastr.error(response.message);
      }
    } catch (error) {
      this.toastr.error('Failed to add or update product details.');
      throw error;
    }
  }


  toggleAccordion(index: number): void {
    this.accordionOpen[index] = !this.accordionOpen[index];
  }


  async manageAssignmentCategories(productID: number) {
    const singleAssignedCategory = {
      categoryID: this.form1.value.productCategoryID,
      subCategoryID: Math.max(this.form1.value.productSubCategoryID ?? 0, 0),
      subTwoCategoryID: Math.max(this.form1.value.productSub2CategoryID ?? 0, 0)
    };
    
    this.assignList.push(singleAssignedCategory);
    
    this.assignList = this.assignList.map(item => ({
      ...item,
      productSubCategoryID: Math.max(item.subCategoryID, 0),
      productSub2CategoryID: Math.max(item.subTwoCategoryID, 0),
      productID
    }));
    
    try {
      const response = await this.addproductservices.ManageAssignCategories(this.assignList).toPromise();
      const type = 'current-page';
      const encryt = CryptoJS.AES.encrypt(type, "neha@hexa").toString();

      this.router.navigate(['manageProduct'], { queryParams: { ProductID: productID, type: encryt } });

    } catch (err) {
      this.toastr.error(err);
    } finally {
    }
  }


  manageSize() {
    if (this.SizeForm.valid) {
      this.spinner.show();
      this.sizeservice.ManageMasterSize(this.SizeForm.value).subscribe(data => {
        if (data.message === "Success") {
          this.getmasterSize();
          this.SizeForm.reset();
          this.SizeForm.patchValue({
            id: 0
          });
          this.toastr.success(data.activity);
          $('#largeModal1').modal('hide');
        }
        else {
          this.toastr.warning(data.message);
        };
        this.spinner.hide();
      });
    }
    else {
      this.spinner.hide();
      this.SizeForm.markAllAsTouched();
    }
  }


  GetVariationList() {
    this.spinner.show();
    this.addproductservices.GetProductDetailsBYProductID(this.ProductID).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const variationList = response.result;
          variationList.forEach(element => {
            this.ManageGridData(element);
          });
        } else { $('#productItemModal').modal('show'); };
        setTimeout(() => { this.spinner.hide() }, 500)
      },
      error: (err) => {
        this.toastr.error(err)
      }
    })
  }


  checkLotInQueue(lotNumber: string): boolean {
    return this.tempVariationList[this.variationIndex].lots.some((x) => x.lotNumber === lotNumber && x.inQueue);
  }

  EditVariation(selectedVariation: any, Index: number) {
    this.productAttributeId = selectedVariation.id;
    const activeLot = selectedVariation.lots.find(x => x.isActive);
    this.previousExistingQuantity = activeLot.currentQuantity;
    this.lotNumberList = this.lotNumberList.map(item => ({
      ...item, inQueue: this.checkLotInQueue(item.lotNumber)
    }));
    this.showNewLotNotice = true;
    this.form2.patchValue({
      id: selectedVariation.id,
      productItemCode: selectedVariation.productItemCode,
      colorID: selectedVariation.colorID,
      sizeID: selectedVariation.sizeID,
      length: selectedVariation.length,
      width: selectedVariation.width,
      height: selectedVariation.height,
      unit: selectedVariation.unit,
      minStock: selectedVariation.minStock,
      discountID: selectedVariation.discountID > 0 ? selectedVariation.discountID : null,
      hasDiscount: selectedVariation.discountID > 0 ? true : false,
      itemBarCode: selectedVariation.itemBarCode,
      lot: {
        price: activeLot.price,
        // quantity: activeLot.currentQuantity,
        // lotNumber:activeLot.lotNumber
      },
    });

    this.previewUrls = selectedVariation.productImages.map((item: any, index: number) => {
      const imageName = item.isCoverImage ? item.coverImageName : item.otherImageName;
      const imagePath = this.getImageUrl(item, imageName);
      if (item.isCoverImage) {
        this.tempCoverImageIndex = index;
      }
      return imagePath;
    });

    this.updateQuantityValidators(0);
    this.IsEditVariation = true;
    this.variationIndex = Index;
    this.addRemoveValidation(true);
    this.hasDiscount = selectedVariation.discountID ? true : false;
    $('#productItemModal').modal('show');
  }

  private updateQuantityValidators(minValue: number) {
    const quantityControl = this.form2.get('lot.quantity') as FormControl;
    quantityControl.setValidators([Validators.required, Validators.min(minValue)]);
    quantityControl.updateValueAndValidity();
  }

  private getImageUrl(image: any, imageName: string = ''): string {
    return `${this.rootURL}${image.imagePath}${imageName || image.coverImageName}`;
  }

  addRemoveValidation(isApply: boolean): void {
    const fileControl = this.form2.get('productImages') as FormControl;
    if (isApply) {
      fileControl.setValidators([Validators.required]);
    } else {
      fileControl.removeValidators([Validators.required]);
    }
    fileControl.updateValueAndValidity();
  }

  getAllLotNumbers() {
    this.addproductservices.getAllLotNumbers().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          const sortedLotNumbers = response.result.sort((a, b) => {
            const numA = parseInt(a.lotNumber.split('-')[1], 10);
            const numB = parseInt(b.lotNumber.split('-')[1], 10);
            return numB - numA;
          });
          this.lotNumberList = [{ productItemId: 0, lotNumber: 'New Lot', inQueue: false },  // Ensure "New Lot" has inQueue: false
          ...response.result.map(item => ({ ...item, inQueue: item.inQueue ?? false }))];
          // this.tempLotNumberList=this.lotNumberList;
        }
      }, error: (err) => {
        this.toastr.error(err.message);
      }
    })
  }

  onLotNumberChange() {
    const selectedLotNumber = this.form2.get('lot.lotNumber')?.value;
    if (!this.tempVariationList?.[this.variationIndex]?.lots || !this.form2.get('id').value) {
      return;
    }
    if (selectedLotNumber !== 'New Lot') {
      const lot = this.tempVariationList[this.variationIndex].lots.find(x => x.lotNumber === selectedLotNumber);
      if (lot) {
        this.inQueue = lot.inQueue;
        this.previousExistingQuantity = lot.currentQuantity;
        this.form2.get('lot.price').patchValue(lot.price);

      } else {
        this.inQueue = false;
        this.previousExistingQuantity = this.getCurrentActiveLot()?.currentQuantity;
        this.form2.get('lot.price').patchValue(this.getCurrentActiveLot()?.price);
      }
    } else {
      this.inQueue = false;
      this.previousExistingQuantity = this.getCurrentActiveLot()?.currentQuantity;
      this.form2.get('lot.price').patchValue(this.getCurrentActiveLot()?.price);

    }

    this.form2.patchValue({ lot: { inQueue: this.inQueue } });
  }

  getCurrentActiveLot(): any {
    return this.tempVariationList[this.variationIndex].lots.find((x) => x.isActive);
  }


  EditProduct(id: number) {
    this.spinner.show();
    this.addproductservices.GetProductByID(id).subscribe(data => {
      if (data.message === 'Success') {
        const assignedCategories = data.result.assignedCategories;
        const lastAssignCategory = assignedCategories[assignedCategories.length - 1];
        this.addproductservices.GetSubCategoryList(lastAssignCategory.categoryID).subscribe(data1 => {
          if (data1.message === 'Success') {
            this.DropdownSubCategoryList = data1.result;
            this.addproductservices.GetSubTwoCategoryList(lastAssignCategory.subCategoryID).subscribe(data2 => {
              if (data2.message === 'Success') {
                this.DropdownSubTwoCategoryList = data2.result;
              };
            })

          };
        });
        let product = data.result;
        this.assignGridList = assignedCategories.slice(0, -1).map((item) => {
          return {
            categoryName: item.categoryName,
            subCategoryName: item.subCategoryName,
            subTwoCategoryName: item.subTwoCategoryName ?? '-'
          };
        });
        this.assignList = assignedCategories.slice(0, -1).map((item) => {
          return {
            productID: product.id,
            categoryID: item.categoryID,
            subCategoryID: item.subCategoryID,
            subTwoCategoryID: item.subTwoCategoryID
          };
        });
        if (assignedCategories.length > 1) {
          $('#toggleDiv').removeClass('hide');
          $('#AssociateMultipleCategory').prop('checked', true);
        }
        this.form1.patchValue({
          id: product.id,
          productCategoryID: lastAssignCategory.categoryID,
          productSubCategoryID: lastAssignCategory.subCategoryID ? lastAssignCategory.subCategoryID : null,
          productSub2CategoryID: lastAssignCategory.subTwoCategoryID ? lastAssignCategory.subTwoCategoryID : null,
          productName: product.productName,
          description: product.description,
          smallDescription: product.smallDescription,
          additionalInformation: product.additionalInformation,
          searchTag: product.searchTag,
          isOnlineProduct: product.isOnlineProduct,
          isOfflineProduct: product.isOfflineProduct,
        });
        let common_seprated = data.result.relatedProducts;
        if (common_seprated !== "") {
          let RelatedItems = common_seprated.split(',').map(value => parseInt(value));
          this.form1.patchValue({
            relatedProducts: RelatedItems
          });
        };

        // Bind Seo Details
        this.seocomponent.bindSeoDetail('product', product.slug);

        setTimeout(() => {
          this.spinner.hide();
        }, 500);

      }
    });
  }


  ManageGridData(variation: any): void {
    const { colorID, sizeID, discountID, productImages } = variation;
    variation.colorCode = this.colorList.find(({ id }) => id === Number(colorID))?.colorCode;
    variation.sizename = this.sizelist.find(({ id }) => id === Number(sizeID))?.name;
    variation.discountTitle = this.discountlist.find(({ id }) => id === discountID)?.discountTitle || '-';
    variation.coverImage = productImages.find(({ isCoverImage }) => isCoverImage === true) || null;
    this.tempVariationList = [
      ...this.tempVariationList.filter(({ id }) => id !== variation.id),
      variation,
    ];
  }

  getDefaultValue(value: any): string {
    return value !== null && value !== undefined ? value : '-';
  }

  getImagePath(coverImage: any): string {
    if (!coverImage) return 'assets/default-image.png';
    return `${this.rootURL}${coverImage.imagePath}${coverImage.coverImageName}`;
  }


  onApplyDiscount() {
    const control = this.form2.get('discountID') as FormControl;
    this.hasDiscount = (this.form2.get('hasDiscount') as FormControl).value;
    if (this.hasDiscount) {
      control.setValidators([Validators.required]);
    } else {
      control.clearValidators();
      control.patchValue(null);
    }
    control.updateValueAndValidity();
  }

  OpenVariationModal() {
    $('#productItemModal').modal('show');
  }


  resetForm2() {
    $('#productItemModal').modal('hide');
    this.showNewLotNotice = false;
    this.hasDiscount = false;
    this.productAttributeId = 0;
    this.previousExistingQuantity = 0;

    this.lotNumberList = this.lotNumberList.map(item => ({ ...item, inQueue: false }));
    this.form2.reset({
      id: 0,
      masterProductID: 0,
      colorID: null,
      sizeID: null,
      length: null,
      width: null,
      height: null,
      unit: null,
      discountID: null,
      hasDiscount: false,
      minStock: 0,
      itemBarCode: '',
      lot: {
        price: 0,
        quantity: 0,
        inQueue: false,
        lotNumber: 'New Lot'
      },
      coverImageIndex: null,
      loginUserID: 0,
    });
    this.files.clear();
    $('#largeImage').val('');
    $('#discount-area').val(0);
    this.updateQuantityValidators(1);
    this.tempCoverImageIndex = 0;
    this.previewUrls = [];
    this.IsEditVariation = false;
    // this.tempLotNumberList=this.lotNumberList;
  }



  onChangeMainImage(index: number) {
    this.tempCoverImageIndex = index;
    if (this.IsEditVariation) {
      this.form2.patchValue({
        coverImageIndex: index
      });
    }
  };


  CategoryList() {
    this.addproductservices.GetCategoryList().subscribe(data => {
      if (data.message === "Success") {
        this.DropdownCategoryList = data.result;
      };
    });
  }

  OnChangeCategory(e: number, type: string) {
    if (type === 'Assignment-Multiple-Category') {
      this.AssignmentDropdwonSubCategoryList = [];
      this.AssignmentDropdwonSubTwoCategoryList = [];
      this.assignmentCategoryForm.patchValue({
        subCategoryID: null,
        subTwoCategoryID: null
      });
      this.addproductservices.GetSubCategoryList(e).subscribe(data => {
        if (data.message === "Success") {
          this.AssignmentDropdwonSubCategoryList = data.result;
        } else { this.AssignmentDropdwonSubCategoryList = [] };
      });

    } else {
      this.DropdownSubCategoryList = [];
      this.DropdownSubTwoCategoryList = [];
      this.form1.patchValue({
        productSubCategoryID: null,
        productSub2CategoryID: null
      });
      this.addproductservices.GetSubCategoryList(e).subscribe(data => {
        if (data.message === "Success") {
          this.DropdownSubCategoryList = data.result;
        } else { this.DropdownSubCategoryList = [] };
      });


    }

  }

  onChangeAssignment(check: boolean) {
    if (check) {
      $('#toggleDiv').removeClass('hide');
    } else {
      $('#toggleDiv').addClass('hide');
      this.assignGridList = [];
      this.assignList = [];
      this.assignmentCategoryForm.reset();
    };
  }



  OnChangeSubCategory(e: any, type: string) {
    if (type === 'Assignment-Multiple-Category') {
      this.AssignmentDropdwonSubTwoCategoryList = [];
      this.assignmentCategoryForm.patchValue({
        subTwoCategoryID: null
      });
      this.addproductservices.GetSubTwoCategoryList(e).subscribe(data => {
        if (data.message == "Success") {
          this.AssignmentDropdwonSubTwoCategoryList = data.result;
        } else { this.AssignmentDropdwonSubTwoCategoryList = [] };

      });

    } else {
      this.DropdownSubTwoCategoryList = [];
      this.form1.patchValue({
        productSub2CategoryID: null
      });
      this.addproductservices.GetSubTwoCategoryList(e).subscribe(data => {
        if (data.message == "Success") {
          this.DropdownSubTwoCategoryList = data.result;
        } else { this.DropdownSubTwoCategoryList = [] };

      });

    }
  }

  GetProductsName() {
    this.spinner.show();
    this.addproductservices.getProductsName().subscribe(data => {
      if (data.message === 'OK') {
        this.listProductsName = data.result;
        this.listProductDropdown = data.result.filter(x => x.isActive);
      } else { console.warn('No Data Found'); }
      this.spinner.hide();
    }, err => {
      console.error(err)
    });
  }


  GetAllBarCode() {
    this.spinner.show();
    this.addproductservices.GetAllItemBarCode().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.BarCodeList = response.result;
        };
        this.spinner.hide();
      },
      error: (err) => {
        this.toastr.error(err)
        this.spinner.hide();
      }

    })
  }

  OpenSizeModal() {
    const id = Number(this.form1.get('productSubCategoryID').value)
    $('#largeModal1').modal('show');
  }


  validateNumber(e: any) {
    let input = String.fromCharCode(e.charCode);
    const reg = /^\d*(?:[.,]\d{2,2})?$/;

    if (!reg.test(input)) {
      e.preventDefault();
    }
  }
  keyPressNumbers1(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  assignMultipleCategories() {
    if (this.assignmentCategoryForm.valid) {
      // Check Duplicate Assign Categories
      let listToCheckDuplicates = [...this.assignList];
      let singleAssigned = {
        categoryID: this.form1.get('productCategoryID').value,
        subCategoryID: this.form1.get('productSubCategoryID').value,
        subTwoCategoryID: this.form1.get('productSub2CategoryID').value,
      };
      listToCheckDuplicates.push(singleAssigned);
      listToCheckDuplicates.push(this.assignmentCategoryForm.value);
      if (this.compareObjects(listToCheckDuplicates)) {
        Swal.fire({
          // title: 'Warning',
          title: 'The category is already assigned.',
          icon: 'warning',
        });
        return;
      }

      const assignCategory = this.assignmentCategoryForm.value;
      let categoryName = this.DropdownCategoryList.find(x => x.id === assignCategory.categoryID).categoryName;
      let subCategoryName = assignCategory.subCategoryID > 0 ? this.AssignmentDropdwonSubCategoryList.find(x => x.id === assignCategory.subCategoryID).subCategoryName : '-';
      let subTwoCategoryName = assignCategory.subTwoCategoryID > 0 ? this.AssignmentDropdwonSubTwoCategoryList.find(x => x.id === assignCategory.subTwoCategoryID).sub2CategoryName : '-';

      const dataObject = {
        categoryName: categoryName,
        subCategoryName: subCategoryName,
        subTwoCategoryName: subTwoCategoryName,
      };
      this.assignGridList.push(dataObject);
      this.assignList.push(assignCategory);

      // Reset Assigned Category Form And Variables
      this.assignmentCategoryForm.reset();
      this.AssignmentDropdwonSubCategoryList = [];
      this.AssignmentDropdwonSubTwoCategoryList = [];
    }
  }

  compareObjects(ObjList): boolean {
    // Iterate over each object in the list
    for (let i = 0; i < ObjList.length; i++) {
      for (let j = i + 1; j < ObjList.length; j++) {
        if (this.compareTwoObjects(ObjList[i], ObjList[j])) {
          return true; // If any two objects are identical, return true
        }
      }
    }
    return false; // If no two objects are identical, return false
  }


  compareTwoObjects(obj1, obj2): boolean {
    obj1.productID = null;
    obj2.productID = null;
    const keys1 = Object.keys(obj1);
    for (let key of keys1) {
      if (key === 'productID') continue;
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    // If all properties are the same, return true
    return true;
  }


  onRemoveAssignCategory(index: number) {
    Swal.fire({
      // title: 'Confirmation',
      title: 'Are you sure you want to remove this category',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',

    }).then((result) => {
      if (result.isConfirmed) {
        this.assignGridList.splice(index, 1);
        this.assignList.splice(index, 1);
      };
    });

  }


  getRandomRating(): number {
    let val = 3 + Math.random() * (4.5 - 3);
    return parseFloat(val.toFixed(2));
  }


  addRelatedProduct(ctrl) {
    var val = ctrl.target.value;
    this.form1.patchValue({
      RelatedProducts: val
    })
  }


  getmastercolor() {
    this.colorserviice.getMasterColorList(true).subscribe(data => {
      if (data.message == "Success") {
        this.colorList = data.result.filter(x => x.isActive == true)
      }
    })
  }


  getmasterSize() {
    this.sizeservice.getMasterSizeList(true).subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.sizelist = response.result.filter(x => x.isActive);

        } else {
          console.error(response.message);
        }

      }
    });
  }

  HideModel(Type: string) {
    if (Type == 'color') {
      $("#largeModal").modal('hide');
      this.ColorForm.reset();
      this.ColorForm.patchValue({
        id: 0
      });
    } else if (Type == 'size') {
      $("#largeModal1").modal('hide');
      this.SizeForm.reset();
      this.SizeForm.patchValue({
        id: 0
      });
    }
    else if (Type == 'discount') {
      $("#largeModal3").modal('hide');
      this.getdiscount()

    }
    else {
      $("#largeModal2").modal('hide');
      // this.getmasterType()
    }
  }


  ActivedeactiveProductDetail(ID: number, IsActive: boolean) {
    Swal.fire({
      // title: 'Are you sure?',
      title: IsActive ? "Are you sure you want to deactivate the product?" : "Are you sure you want to activate the product?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#297084',
      cancelButtonColor: '#686767',
      confirmButtonText: IsActive ? "Confirm" : "Confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        const action = IsActive === true ? 'deactivated' : 'activated';
        this.addproductservices.ActiveInactiveProductDetail(ID, this.LoginUserID).subscribe(res => {
          this.GetVariationList();
          this.toastr.success('Product has been  ' + action + ' successfully');
          setTimeout(() => {
            this.spinner.hide();
          }, 200);
        }, err => { this.spinner.hide(); this.toastr.error('', 'Not Responding'); });
      }
      else {
        function check() {
          $('#ActiveInactive' + ID).prop("checked", true)
        };
        function uncheck() {
          $('#ActiveInactive' + ID).prop("checked", false)
        };

        IsActive ? check() : uncheck();
      }
    })

  }


  getdiscount() {
    this.service.getDiscountlist(true).subscribe(data => {
      if (data.message == "Success") {
        this.discountlist = data.result;
      }
    })
  }
}
$(document).ready(function () {
  // Check for validation-error class and add margin if present
  $('.row.validation-error').css('margin', '20px');
});
