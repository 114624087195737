<section class="content-header sub-head ">
  <h1>
    Low Inventory Products
  </h1>
</section>
<div class="row">
  <div class="col-md-6">
    <div class="d-flex my-search" method="post">
      <input [(ngModel)]="searchText" id="SearchID" class="form-control me-2" type="search" placeholder="Search"
        aria-label="Search">
      <button class="btn btn-outline-success" type="button"><i class="fa fa-search"></i></button>
    </div>
  </div>
</div>
<div class="box">
  <div class="box-body">
    <div class="row">
      <div class="col-md-12">
        <div class="atri-detial">
          <div class="table-responsive">
            <table id="example1" class="table text-center table-bordered table-striped">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Product&nbsp;Image</th>
                  <th>Bar&nbsp;Code</th>
                  <th>Product&nbsp;Name</th>
                  <th>Price&nbsp;(AUD)</th>
                  <th>Quantity</th>
                  <th>Min&nbsp;Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let item of LowinventoryProductList|search:'low-inventory-product':searchText|paginate:paginationconfig let i=index">
                  <td> {{((paginationconfig.currentPage-1)*paginationconfig.itemsPerPage+i+1)}}</td>
                  <td>
                    <div class="productimage">
                      <img [src]="rootURL+item.productImage" (error)="setDefaultImage($event)"
                        [alt]="item.productName" />
                    </div>
                  </td>

                  <td> {{item.productItemCode}}</td>
                  <td> {{item.productName}}</td>
                  <td [ngClass]="{'text-success': changesInItemDetails[item.id]?.price}">
                    {{ (changesInItemDetails[item.id]?.price ?? item.itemPrice) | number:'1.2-2' }}
                  </td>
                  <td class="">
                   <div class="d-flex">
                    {{ item.totalQuantity }}
                    <span *ngIf="changesInItemDetails[item.id]?.quantity" class="update-quantity">
                      (+{{ changesInItemDetails[item.id].quantity }})
                    </span>
                   </div>
                  </td>
                  <td> {{item.minStock}}</td>
                  <td>
                    <button [ngClass]="{'btn-primary': !changesInItemDetails[item.id], 'btn-success1': changesInItemDetails[item.id]}" class="btn custom-button" aria-label="Product details"
                      (click)="openModal(item)">
                      <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;Top-Up
                    </button>
                  </td>

                </tr>
              </tbody>
            </table>


          </div>
          <div class="" *ngIf="LowinventoryProductList.length>0;else elseblock;">
          </div>
          <ng-template #elseblock>
            <div class="no-data">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <h4>No Data Found</h4>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-start mt-5 mb-5 ml-4">
        <div class="BRT  d-flex justify-content-start align-items-center text-start">
          <button [disabled]="!dataForSubmit.length" class="btn btn-success1" type="button"
            (click)="addQuantity()">Submit</button>
        </div>
      </div>
    </div>

    <div class="row mt-3 view-user-page" *ngIf="LowinventoryProductList.length>paginationconfig.itemsPerPage">
      <div class="col-sm-12 paging text-right">
        <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next" (pageChange)="OnTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>


<!-- ****************************Add Stock Modal********************************* -->

<div class="modal" tabindex="-1" id="add-stock-modal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header ">
        <h4 class="modal-title text-white">Add Stock Quantity</h4>
        <button type="button" class="close" (click)="formReset()" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form">
          <div class="form-group row nomargin">

            <!-- Product Quantity -->
            <div class="col-md-12 nopadding mt-3">
              <label for="productQuantity" class="form-label">Product Quantity</label>
              <input id="productQuantity" type="number" class="form-control CTCTextBoxes"
                placeholder="Enter Product Quantity" formControlName="productQuantity" />
              <span *ngIf="f.productQuantity.touched && f.productQuantity.errors" style="color: red;">
                <span *ngIf="f.productQuantity.errors?.required">This Field Is Required</span>
                <span *ngIf="f.productQuantity.errors?.min">
                  The value must be at least {{ f.productQuantity.errors.min.min }}
                </span>
              </span>
            </div>

            <!-- Product Price -->
            <div class="col-md-12 nopadding mt-3">
              <label for="productPrice" class="form-label">Product Price</label>
              <input id="productPrice" type="number" class="form-control CTCTextBoxes" placeholder="Enter Product Price"
                formControlName="productPrice" />
              <span *ngIf="f.productPrice.touched && f.productPrice.errors" style="color: red;">
                <span *ngIf="f.productPrice.errors?.required">This Field Is Required</span>
                <span *ngIf="f.productPrice.errors?.min">
                  The value must be at least {{ f.productPrice.errors.min.min }}
                </span>
              </span>
            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button (click)="appendQuantity()" type="button" class="btn btn-primary">Add</button>
        <button (click)="formReset()" class="btn btn-secondary">Cancel</button>
      </div>
    </div>
  </div>
</div>