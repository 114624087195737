import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http: HttpClient) { }
  Mainmenus(MenuData) {
    return this.http.post<any>(this.rootUrl + '/MainMenu/ManageMainMenu', MenuData,);
  }
  getMainMenus(Active: boolean | string,SearchString:string): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/MainMenu/ListMainCategory', { params: { Active,SearchString } });
  }

  AddCategoryImage(formData: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/UploadMultipleFiles', formData);
  }


  ActiveInactiveMainMenus(ID: number, IsActive: boolean, type: string,UserID:number) {

    return this.http.get<any>(this.rootUrl + '/MainMenu/ActiveInactiveCategory', { params: { ID, IsActive, type,UserID } });
  }

  changeCaegoryProps(categoryID: number, type: string): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/MainMenu/changeCagegoryProps', { params: { categoryID, type } });
  }

}
