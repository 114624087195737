import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError, from, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from './environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly rootUrl: string = environment.baseUrl;
  private tokenModel = {
    accessToken: null,
    refreshToken: null,
  };

  public userInfo = {
    refreshToken: null,
    userToken: null,
    userId: null,
    userTypeId: null,
    userDetail: null,
    userName: null,
    Name: null,
    companyId: null
  };

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private router: Router,private spinner:NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo && userInfo.userToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userInfo.userToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && userInfo) {
          return this.handle401Error(request, next);
        };
        return handleError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.refreshToken) {
      this.tokenModel.accessToken = userInfo.userToken;
      this.tokenModel.refreshToken = userInfo.refreshToken;

      this.userInfo.userId = userInfo.userId;
      this.userInfo.userName = userInfo.userName;
      this.userInfo.userTypeId = userInfo.userTypeId;
      this.userInfo.userDetail = userInfo.userDetail;

      if (this.isRefreshing) {
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap(() => {
            const updatedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (updatedUserInfo) {
              request = this.cloneRequestWithNewToken(request, updatedUserInfo.userToken);
              return next.handle(request);
            }
          })
        );
      }

      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.refreshToken()).pipe(
        switchMap((isRefreshed: boolean) => {
          if (isRefreshed) {
            const updatedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
            if (updatedUserInfo) {

              request = this.cloneRequestWithNewToken(request, updatedUserInfo.userToken);
              this.refreshTokenSubject.next(updatedUserInfo.userToken); // Store the new token
              return next.handle(request);
            }
          }

          this.router.navigate(['login']);
          return throwError(() => new Error('Unauthorized access - Please log in again.'));
        }),
        catchError((err) => {
          this.router.navigate(['login']);
          return throwError(() => new Error('Unauthorized access - Please log in again.'));
        }),
        finalize(() => {
          this.isRefreshing = false;
          this.spinner.hide();
        })
      );
    }

    localStorage.removeItem('userInfo');
    this.router.navigate(['login']);
    return throwError(() => new Error('Unauthorized access - Please log in again.'));
  }

  private refreshToken(): Observable<boolean> {
    return this.http.post<any>(`${this.rootUrl}/Login/RefreshToken`, this.tokenModel).pipe(
      map(response => {
        this.userInfo.userToken = response.accessToken;
        this.userInfo.refreshToken = response.refreshToken;
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        return true;
      }),
      catchError(error => {
        alert("Your refresh token has expired. Please log in again.");
        console.error('Error refreshing token:', error);
        return of(false); // or throwError if you want the caller to handle the error
      })
    );
  }



  private cloneRequestWithNewToken(request: HttpRequest<any>, token?: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}

const handleError = (error: HttpErrorResponse) => {
  let errorMessage = 'Unknown error occurred';
  if (error.error instanceof ErrorEvent) {
    errorMessage = `Client-side error: ${error.error.message}`;
  } else {
    switch (error.status) {
      case 400:
        errorMessage = `Bad Request: The server could not understand the request due to invalid syntax.`;
        break;
      case 401:
        errorMessage = `Unauthorized: Access is denied due to invalid credentials. Please log in again.`;
        break;
      case 403:
        errorMessage = `Forbidden: You do not have permission to access this resource.`;
        break;
      case 404:
        errorMessage = `Not Found: The requested resource could not be found.`;
        break;
      case 500:
        if (error.error) {
          const backendError = error.error;
          errorMessage = `Error: ${backendError.message}\nDetails: ${backendError.details}\nTrace ID: ${backendError.traceId}`;
        } else {
          errorMessage = `Internal Server Error: The server encountered an unexpected condition. Please try again later.`;
        }
        break;
      case 502:
        errorMessage = `Bad Gateway: The server was acting as a gateway or proxy and received an invalid response.`;
        break;
      case 503:
        errorMessage = `Service Unavailable: The server is not ready to handle the request. Please try again later.`;
        break;
      case 504:
        errorMessage = `Gateway Timeout: The server, while acting as a gateway, did not receive a timely response from the upstream server.`;
        break;
      default:
        errorMessage = `Error: Server returned status code ${error.status}. Please try again later.`;
        break;
    }
  }

  console.error(errorMessage);
  return throwError(errorMessage);
};














