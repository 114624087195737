import { Component, OnInit } from '@angular/core';
import { RecivedreviewService } from './recivedreview.service';
import { ToastrService } from 'ngx-toastr';
import Swal  from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $:any;

@Component({
  selector: 'app-recivedreviews',
  templateUrl: './recivedreviews.component.html',
  styleUrls: ['./recivedreviews.component.css']
})
export class RecivedreviewsComponent implements OnInit{

  public RecivedReviewList:any=[];
  public page: number = 1;
  public count: number = 0;
  public tableSize: number = 10;
  public tableSizes: any = [10,15,20];  
private Approve:any=true;
  constructor(private recivedreviewservice:RecivedreviewService,private toastr:ToastrService,private spinner:NgxSpinnerService){}

ngOnInit(): void {
  this.GetRecivedReviews();
}

GetRecivedReviews(){
  this.spinner.show();
  this.recivedreviewservice.GetRecivedReviews(this.Approve).subscribe(data=>{
    if(data.message=="Success"){
      this.RecivedReviewList=data.result
    }else{this.RecivedReviewList=[]};
    setTimeout(() => {
      this.spinner.hide();
    },300);
  },err=>{
    console.error('Error in GetRecivedReviews :',err);
    this.spinner.hide();
  });
}

Filter(key:any,type:string){
  if(type==='APPROVE-REJECT'){
    this.Approve=key;
  };
  this.page=1;
  this.GetRecivedReviews();

}

ApproveRejectReview(Id:Number,IsApprove:boolean){
  let actionText=IsApprove?'approve':'reject';
  Swal.fire({
    icon:'question',
    // title: 'Confirmation',
    title:`Are you sure you want to ${actionText} this review?`,
    showCancelButton: true,
    confirmButtonText:'Confirm',
  }).then((result) => {
    if (result.isConfirmed) {
      this.recivedreviewservice.ApproveRejectReview(Id,IsApprove).subscribe(data=>{
        if(data.message==='Success'){
          this.GetRecivedReviews();
          this.toastr.success(data.activity);
        }
      });
    }
    else{
      function check() {
        $('#Approve' + Id).prop("checked", false);
        $('#Reject' + Id).prop("checked", true);
      };
      function uncheck() {
        $('#Approve' + Id).prop("checked", true);
        $('#Reject' + Id).prop("checked", false);
      }

      IsApprove ? check() : uncheck();
    }

  });



  

}


onTableDataChange(nextpage:any){
  this.page=nextpage;
}

onTableSizeChange(TableSize:any){
  this.tableSize=TableSize;

}
}
