<section class="content-header sub-head ">
  <h1>
      Low Inventory Products
  </h1>
</section>
<div class="box">
  <div class="box-body">
    <div class="row">
      <div class="col-md-12">
        <div class="atri-detial">
          <div class="table-responsive">
            <table id="example1" class="table text-center table-bordered table-striped">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Product&nbsp;Image</th>
                  <th>Bar&nbsp;Code</th>
                  <th>Product&nbsp;Name</th>
                  <th>Price&nbsp;(AUD)</th>
                  <th>Quantity</th>
                  <th>Min&nbsp;Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
  
                <tr *ngFor="let item of LowinventoryProductList|paginate:paginationconfig let i=index">
                  <td> {{i+1}}</td>
                  <td>
                    <div class="productimage">
                    <img  src="{{rootURL}}{{item.productImage}}"/>
                  </div>
                  </td>
                
                  <td> {{item.productItemCode}}</td>
                  <td> {{item.productName}}</td>
                  <td> {{item.price}}</td>
                  <td> {{item.stock}}</td>
                  <td> {{item.minStock}}</td>
                  <td>
                    <button  class="btn btn-primary custom-button"
                      aria-label="Product details" (click)="OpenModal(item.id)" >
                      <i class="fa fa-plus" aria-hidden="true"></i> &nbsp;Re-Order
                    </button>
                  </td>
  
                </tr>
  

             
  
              </tbody>
  
            </table>
           
          </div>
          <div class="" *ngIf="LowinventoryProductList.length>0;else elseblock;">
          </div>
          <ng-template #elseblock>
            <div class="no-data">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <h4>No Data Found</h4>
            </div>
          </ng-template>
  
        </div>
      </div>
    </div>
    <div class="row mt-3 view-user-page" *ngIf="LowinventoryProductList.length>paginationconfig.itemsPerPage">
      <div class="col-sm-12 paging text-right">
        <pagination-controls maxSize="5" previousLabel="Prev" nextLabel="Next"
          (pageChange)="OnTableDataChange($event)">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>


  <!-- ****************************Add Stock Modal********************************* -->

<div class="modal" tabindex="-1" id="StockModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header ">
        <h4 class="modal-title text-white">Add Stock Quantity</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form">
          <div class="form-group row nomargin">
            <div class="col-md-12 nopadding mt-3">
              <input type="number"  class="form-control CTCTextBoxes" placeholder="Enter Product Stock" formControlName="ProductStock">
              <span *ngIf="f.ProductStock.touched" style="color: red;">
                <span *ngIf="f.ProductStock?.errors?.required">This Field Is Required</span>
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button (click)="AddStock()" type="button" class="btn btn-primary">Submit</button>
        <button  type="reset" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
    
      </div>
    </div>
  </div>
</div>