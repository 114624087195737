import { AppService } from './../app.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { from } from 'rxjs';
import { ContentmanagementService } from '../pages/contentmanagement/contentmanagement.service';
import { UserPermissionService } from '../pages/user-permission/user-permission.service';
import { OrderhistoryService } from '../pages/orderhistory/orderhistory.service';

declare var $:any;
@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})

export class MenusComponent implements OnInit {
  encPassword = 'neha@hexa';
   public UserName:string;
   isSmallScreen: boolean = false;
  UserId: any;
  BindUser: any;

  constructor(public router: Router, private service: AppService, private contentmanagementService: ContentmanagementService, private Userservice: UserPermissionService,private orderhistoryservice:OrderhistoryService) {

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

setTimeout(() => {

  this.isSmallScreen = window.innerWidth <= 768;
}, 1000);

  }
  SplitMenu(url: any, split: string, index: number) {


    if (url != null) {
      return url.split(split)[index];
    } else {
      return "";
    }
  }
  removeSidebarOpenClass() {
    if (this.isSmallScreen) {
      document.body.classList.remove('sidebar-open');
    }
  }
  ngOnInit(): void {
    var Userdt = JSON.parse(localStorage.getItem('userInfo'));
    this.UserId = this.service.decryptValue(Userdt.userId);
    this.UserName=this.service.decryptValue(Userdt.userName);
    this.BindUserPermission()
  }


  goto(path: any, q1: any, q2: any) {
    this.contentmanagementService.pagename = q2;
    this.router.navigate([path], { queryParams: { Type: q1 } });
    if(q1!==null){
      let BO={
        type:'reload',
        param:q1
      };
      this.contentmanagementService.PassData(BO);
    };
  }
  BindUserPermission() {
    var UserID = this.UserId;
    this.Userservice.getUserPermissionByUserID(UserID).subscribe(data => {
      if (data.message ==="ok") {
        this.BindUser = data.result;
      }
    },err=>{

    })
  }
}
