import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SubcategoriesService {
  readonly rootUrl = environment.baseUrl;
  localdata = JSON.parse(localStorage.getItem('userInfo'));
  constructor(private http: HttpClient) { }
  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.localdata.userToken
  });
  getMainMenus() {
    return this.http.get<any>(this.rootUrl + '/MainMenu/dropdownCategory', {headers: this.reqHeader});
  }
  getSubCategoriesList(categoryid:number,Active:boolean|string,SearchString:string) {
    return this.http.get<any>(this.rootUrl + '/MainMenu/ListofSubCategories', {params:{categoryid,Active,SearchString}});
   }
   AddEditcategories(category) {
    return this.http.post<any>(this.rootUrl + '/MainMenu/ManageSubCategories', category );
  }
  getActiveDeactive(id:number,Isactive:boolean,type:string,UserID:number):Observable<any> {
    return this.http.get<any>(this.rootUrl + '/MainMenu/ActiveInactiveCategorySubCategory', {params:{id,Isactive,type,UserID}});
   }
   UploadImage(data:any):Observable<any>{
    return this.http.post<any>(this.rootUrl+'/UploadMultipleFiles',data);
   }
   changeSubCategoryProps(SubCategoryID:number,type:string):Observable<any>{
    debugger
    return this.http.get<any>(this.rootUrl+'/MainMenu/changeSubCategoryProps',{params:{SubCategoryID,type}});
  }
}
