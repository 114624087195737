<!-- <section class="content-header sub-head ">

  <h1>
    SEO-Management

  </h1>
</section> -->
<div class=" areas">

  <section class="content sub-cat">
    <div class="box" style="    margin: 0px;">
      <div class="box-body" style="    margin: 0px;">

        <div class="seo-cell">

          <!-- <div id="searchresultsarea">

            <div class="searchresult">
              <h2>{{seoDetail.Title}}</h2>
              <a>{{seoDetail.URL}}</a>
              <p>{{seoDetail.Description}}</p>

            </div>
          </div> -->

          <div class="row">
            <div class="col-md-12">
              <div class="tabbs">

                <ul class="nav nav-tabs">
                  <li id="productinfo" class="active"><a data-toggle="tab" href="#home" class="link-tab"> SEO Basics</a>
                  </li>


                </ul>
                <div class="tab-content">
                  <form [formGroup]="seoForm" style="padding: 0px;">
                    <div id="home" class="tab-pane fade in active">
                      <div class="row">
                        <!-- <div class="col-md-4 mb-2">
                          <label>Slug</label>
                          <input readonly formControlName="slug"  type="text" placeholder="Enter Slug" class="form-control">
                        </div> -->
                        <div class="col-md-6 mb-2">
                          <label>SEO title <span style="color:red;">*</span></label>
                          <input  formControlName="metaTitle" type="text" placeholder="Enter SEO title" class="form-control">
                          <div *ngIf="seoForm.get('metaTitle').touched && seoForm.get('metaTitle').invalid" style="color: red;">
                            <span *ngIf="seoForm.get('metaTitle').errors?.required">This filed is required</span>
                        </div>
                        </div>
                        <div class="col-md-6 mb-2">
                          <label>Canonical URL</label>
                          <input formControlName="canonicalUrl" type="text" placeholder="Enter Canonical URL" class="form-control">
                        </div>
                        <div class="col-md-12 mb-2">
                          <label>Meta description </label>
                          <textarea   formControlName="metaDescription" class="form-control" placeholder="Enter Meta Description" rows="5"></textarea>
                          <!-- <div *ngIf="seoForm.get('metaDescription').touched && seoForm.get('metaDescription').invalid" style="color: red;">
                              <span *ngIf="seoForm.get('metaDescription').errors?.required">This filed is required</span>
                          </div> -->

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
 
            <div class="col-md-12 mt-2 text-right custom-button">
              <button class="btn btn-success readonlyfield1" type="submit" id="submitForm1" (click)="submitSEO()"
               >
               Add
              </button>
              <button  class="btn btn-secondary " >Cancel</button>
             
 
 
            </div>
          </div> -->
        </div>






      </div>
    </div>

  </section>
</div>