import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ViewhiredvehiclesService {
  readonly rootUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  ListOfHiredVehicleStatus(status)
  {
    debugger;
    return this.http.get<any>(this.rootUrl + '/Vehicle/ListOfHiredVehicleStatus?status=' + status) 
 
}
}
