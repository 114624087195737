import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { OrdersService } from '../orders/orders.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Fancybox } from '@fancyapps/ui';
declare const $: any;

@Component({
  selector: 'app-received-orders',
  templateUrl: './custom-orders.component.html',
  styleUrls: ['./received-orders.component.css']
})
export class CustomOrdersComponent implements OnInit {
  public OrderList: any[] = [];
  public Order: any;
  public rootURL: string = environment.baseUrl.slice(0, -3);
  URLS: Array<any> = [];
  public ReplyForm: any;
  public tableSizes: any[] = [10, 15, 20];

  public PaginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,

  }
  private FancyboxInstance: any;



  constructor(private orderservice: OrdersService,
    private renderer: Renderer2, private el: ElementRef, private spinner: NgxSpinnerService, private fb: FormBuilder, private toastr: ToastrService, private elRef: ElementRef) {
    this.ReplyForm = fb.group({
      emailID: [''],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }


  ngOnInit(): void {
    this.GetAllOrders();

  }



  get replyformcontrols() {
    return this.ReplyForm.controls;
  }

  GetAllOrders() {
    this.spinner.show();
    this.orderservice.GetCustomizeOrderList().subscribe({
      next: (response) => {
        if (response.message === 'Success') {
          this.OrderList = response.result;
        } else (console.error(response.message));
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err.message)
      }
    })
  }

  OnView(data: any) {
    let list: any = data.referenceImage.split('*');
    this.AddGallaryElements(list);
    $('#largeModal').modal('show');
    this.Order = data;
    console.log('or',this.Order)
  }

  AddGallaryElements(urls: string[]) {
    const previousGallery = this.el.nativeElement.querySelector('#mygallary');
    if (previousGallery) {
      previousGallery.innerHTML = "";
    }
    const owlCarouselInstance = $('.owl-carousel').data('owl.carousel');
    if (owlCarouselInstance) {
      owlCarouselInstance.destroy();
    }

    urls.map((url: string) => {
      const item = this.renderer.createElement('div');
      this.renderer.addClass(item, 'item');

      const link = this.renderer.createElement('a');
      this.renderer.setAttribute(link, 'data-fancybox', 'gallery');
      this.renderer.setAttribute(link, 'data-src', this.rootURL + url);

      const img = this.renderer.createElement('img');
      this.renderer.setAttribute(img, 'src', this.rootURL + url);

      this.renderer.appendChild(link, img);
      this.renderer.appendChild(item, link);
      this.renderer.appendChild(previousGallery, item);
      //   this.renderer.appendChild(this.el.nativeElement, container);

    });

    Fancybox.bind('#mygallary a', {
      Thumbs: {
        type: "modern",
      },
      Toolbar: {
        display: {
          left: ["infobar"],
          middle: [
            "zoomIn",
            "zoomOut",
            "toggle1to1",
            "rotateCCW",
            "rotateCW",
            "flipX",
            "flipY",
          ],
          right: ["slideshow", "download", "thumbs", "close"],
        },
      }
    });

    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });


  }
  OnReply(email: string) {
    this.ReplyForm.patchValue({
      emailID: email
    });
    $('#reply').modal('show');
  }


  SubmitReply() {
    if (this.ReplyForm.valid) {
      this.spinner.show();
      this.orderservice.ReplyCustomizeOrder(this.ReplyForm.value).subscribe({
        next: (response) => {
          if (response.message === 'Success') {
            this.toastr.success('Message Send Successfully');
          } else { this.toastr.error(response.message) };
          setTimeout(() => {
            this.spinner.hide();
            $('#reply').modal('hide');
            this.ReplyForm.reset();
          }, 500);
        },
        error: (err) => {
          this.spinner.hide();
          console.error(err.message);
        }
      })
    }
    else {
      this.ReplyForm.markAllAsTouched()
    }
  }

  OnPageChange(pageno): void {
    this.PaginationConfig.currentPage = pageno;
  }

 

  RejectOrder(Id: number) {
    Swal.fire({
      icon:'question',
      title: 'Confirmation',
      text: `Are you sure you want to reject this order enquiry?`,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.orderservice.RejectOrderEnquiry(Id).subscribe({
          next:(response)=>{
            if(response.message==='Success'){
              this.toastr.success('Order enquiry rejected successfully');
              this.GetAllOrders();
            }
          }
        });
      }
    });

  }
}
